import axios from "axios";

import * as actionTypes from "../actionTypes";
import { API_BASE } from "../../lookup/api";
import { checkMissingSections } from "../../util";
const SET_STATUS_URL = `${API_BASE}/setstatus.ashx`;

export const clearAllFormValuesAction = (key = null) => {
    const userKey = localStorage.getItem("userKey");

    Object.keys(localStorage).forEach((localStorageKey) => {
        if (
            localStorageKey !== "userKey" &&
            localStorageKey !== "totalCholesterolMMH" &&
            localStorageKey !== "hdlCholesterolMMH"
        ) {
            if (key && key === userKey) {
                if (
                    localStorageKey === "riskTutorial" ||
                    localStorageKey === "nextButtonTutorial" ||
                    localStorageKey === "stepButtonsTutorial" ||
                    localStorageKey === "sliderTutorial" ||
                    localStorageKey === "helpTutorial"
                ) {
                    return;
                }
            }

            localStorage.removeItem(localStorageKey);
        }
    });

    return {
        type: actionTypes.CLEAR_ALL_FORM_VALUES
    };
};

export const setAllFormValues = (formValues) => ({
    type: actionTypes.SET_ALL_FORM_VALUES,
    formValues
});

export const setPostcodeAction = (value) => ({
    type: actionTypes.SET_POSTCODE,
    value
});

export const setPostcodeValidAction = (postalCode) => {
    return {
        type: actionTypes.SET_POSTCODE_VALID,
        postalCode
    };
};

export const setPostcodeInvalidAction = () => ({
    type: actionTypes.SET_POSTCODE_INVALID
});

export const setAgeAction = (value) => ({
    type: actionTypes.SET_AGE,
    value
});

export const setGenderAction = (value) => ({
    type: actionTypes.SET_GENDER,
    value
});

export const setEthnicityAction = (value) => ({
    type: actionTypes.SET_ETHNICITY,
    value
});

export const setHeightUnitAction = (value) => ({
    type: actionTypes.SET_HEIGHT_UNIT,
    value
});

export const setHeightInInchesAction = (value) => ({
    type: actionTypes.SET_HEIGHT_IN_INCHES,
    value
});

export const setHeightInFeetAction = (value) => ({
    type: actionTypes.SET_HEIGHT_IN_FEET,
    value
});

export const setHeightInCmAction = (value) => ({
    type: actionTypes.SET_HEIGHT_IN_CM,
    value
});

export const setWeightUnitAction = (value) => ({
    type: actionTypes.SET_WEIGHT_UNIT,
    value
});

export const setWeightInStoneAction = (value) => ({
    type: actionTypes.SET_WEIGHT_IN_STONE,
    value
});

export const setWeightInLbsAction = (value) => ({
    type: actionTypes.SET_WEIGHT_IN_LBS,
    value
});

export const setWeightInKgAction = (value) => ({
    type: actionTypes.SET_WEIGHT_IN_KG,
    value
});

export const setSmokingLevelAction = (value) => ({
    type: actionTypes.SET_SMOKING_LEVEL,
    value
});

export const setDiabetesTypeAction = (value) => ({
    type: actionTypes.SET_DIABETES_TYPE,
    value
});

export const setFamilyHistoryAction = (value) => ({
    type: actionTypes.SET_FAMILY_HISTORY,
    value
});

export const setKidneyDiseaseAction = (value) => ({
    type: actionTypes.SET_KIDNEY_DISEASE,
    value
});

export const setMedicalConditionsAction = (value) => ({
    type: actionTypes.SET_MEDICAL_CONDITIONS,
    value
});

export const setNoMedicalConditionsAction = () => ({
    type: actionTypes.SET_NO_MEDICAL_CONDITIONS
});

export const setAtrialFibrillationAction = (value) => ({
    type: actionTypes.SET_ATRIAL_FIBRILLATION_STEP,
    value
});

export const setSystolicBloodPressureReadingsAction = (value) => ({
    type: actionTypes.SET_SYSTOLIC_BLOOD_PRESSURE_READINGS,
    value
});

export const setDiastolicBloodPressureReadingsAction = (value) => ({
    type: actionTypes.SET_DIASTOLIC_BLOOD_PRESSURE_READINGS,
    value
});

export const setBloodPressureValidAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_VALID
});

export const setBloodPressureInvalidAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_INVALID
});

export const setBloodPressureEmergencyAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_EMERGENCY
});

export const setBloodPressureNormalAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_NORMAL
});

export const setBloodPressureHighAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_HIGH
});

export const setBloodPressureLowAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_LOW
});

export const setBloodPressureHighWarningReadAction = () => ({
    type: actionTypes.SET_BLOOD_PRESSURE_HIGH_WARNING_READ
});

export const setBloodPressureHighWarningShowAction = () => {
    localStorage.setItem("bpWarningShown", true);
    return {
        type: actionTypes.SET_BLOOD_PRESSURE_HIGH_WARNING_SHOW
    };
};

export const requestMoreBPReadingsAction = (value) => ({
    type: actionTypes.REQUEST_MORE_BP_READINGS,
    value
});

export const setBloodPressureLocationAction = (location) => ({
    type: actionTypes.SET_BLOOD_PRESSURE_LOCATION,
    location
});

export const setPulseAction = (value) => ({
    type: actionTypes.SET_PULSE,
    value
});

export const setCholesterolStatusAction = (value) => ({
    type: actionTypes.SET_CHOLESTEROL_STATUS,
    value
});

export const setTotalCholesterolValueAction = (value) => ({
    type: actionTypes.SET_TOTAL_CHOLESTEROL_VALUE,
    value
});

export const setHDLCholesterolValueAction = (value) => ({
    type: actionTypes.SET_HDL_CHOLESTEROL_VALUE,
    value
});

export const setDiabetesInFamilyAction = (value) => ({
    type: actionTypes.SET_DIABETES_IN_FAMILY,
    value
});

export const setWaistUnitAction = (value) => ({
    type: actionTypes.SET_WAIST_UNIT,
    value
});

export const setWaistMeasurementAction = (value) => ({
    type: actionTypes.SET_WAIST_MEASUREMENT,
    value
});

export const setAlcoholConsumptionAction = (value) => ({
    type: actionTypes.SET_ALCOHOL_CONSUMPTION_VALUE,
    value
});

export const setAlcoholUnitsAction = (value) => ({
    type: actionTypes.SET_ALCOHOL_UNITS_VALUE,
    value
});

export const setBingeSessionsAction = (value) => ({
    type: actionTypes.SET_BINGE_DRINKING_SESSIONS,
    value
});

export const setAdditionalAUDITAction = (key, value) => ({
    type: actionTypes.SET_ADDITIONAL_AUDIT_C_ACTION,
    key,
    value
});

export const setActivityHoursCyclingAction = (value) => ({
    type: actionTypes.SET_ACTIVITY_HOURS_CYCLING,
    value
});

export const setActivityHoursWalkingAction = (value) => ({
    type: actionTypes.SET_ACTIVITY_HOURS_WALKING,
    value
});

export const setActivityHoursExerciseAction = (value) => ({
    type: actionTypes.SET_ACTIVITY_HOURS_EXERCISE,
    value
});

export const setActivityHoursHouseworkChildcareAction = (value) => ({
    type: actionTypes.SET_ACTIVITY_HOURS_HOUSEWORK_CHILDCARE,
    value
});

export const setActivityHoursGardeningDIYAction = (value) => ({
    type: actionTypes.SET_ACTIVITY_HOURS_GARDENING_DIY,
    value
});

export const setWalkingPaceAction = (value) => ({
    type: actionTypes.SET_WALKING_PACE,
    value
});

export const setPhysicalWorkDefinitionAction = (value) => ({
    type: actionTypes.SET_PHYSICAL_WORK_DEFINITION,
    value
});

export const setVegetablesAction = (value) => ({
    type: actionTypes.SET_VEGETABLE_PORTIONS,
    value
});

export const setDietFoodsAction = (value) => ({
    type: actionTypes.SET_DIET_FOODS,
    value
});

export const setWeightClassAction = (value) => ({
    type: actionTypes.SET_WEIGHT_CLASS,
    value
});

export const setBodyMassIndexAction = (value) => ({
    type: actionTypes.SET_BODY_MASS_INDEX,
    value
});

export const setDiabetesRiskScoreAction = (value) => ({
    type: actionTypes.SET_DIABETES_RISK_SCORE,
    value
});

export const setAUDITCScoreAction = (value) => ({
    type: actionTypes.SET_AUDIT_C_SCORE,
    value
});

export const setFullAUDITScoreAction = (value) => ({
    type: actionTypes.SET_FULL_AUDIT_SCORE,
    value
});

export const setGPAQScoreAction = (value) => ({
    type: actionTypes.SET_GPAQ_SCORE,
    value
});

export const changeFormPartAction = (part) => ({
    type: actionTypes.CHANGE_FORM_PART,
    part
});

export const completeFormPartChangeAction = () => ({
    type: actionTypes.COMPLETE_FORM_PART_CHANGE
});

export const setStatusAction = (token, status) => {
    let form = {
        Token: token,
        Status: status
    };

    return (dispatch) => {
        return axios
            .post(SET_STATUS_URL, JSON.stringify(form), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                dispatch(setStatusSuccessAction(status));
            })
            .catch((error) => {
                dispatch(setStatusFailureAction());
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const setStatusSuccessAction = (status) => {
    localStorage.setItem("userStatus", status);
    return {
        type: actionTypes.SET_STATUS_SUCCESS,
        status
    };
};

export const setStatusFailureAction = () => ({
    type: actionTypes.SET_STATUS_FAILURE
});

export const checkMissingSectionsAction = () => {
    return (dispatch, getState) => {
        const form = getState().form;
        const missingSections = checkMissingSections(form);

        dispatch(setMissingSectionsAction(missingSections));
    };
};

export const checkMissingSectionsNotIncludingBPAction = () => {
    return (dispatch, getState) => {
        const form = getState().form;
        const missingSections = checkMissingSections(form, false);

        dispatch(setMissingSectionsNotIncludingBPAction(missingSections));
    };
};

export const setMissingSectionsAction = (missingSections) => ({
    type: actionTypes.SET_MISSING_FIELDS,
    missingSections
});

export const setMissingSectionsNotIncludingBPAction = (missingSections) => ({
    type: actionTypes.SET_MISSING_FIELDS_NOT_INCLUDING_BP,
    missingSections
});

export const setIncludeDiabetesAction = (value) => ({
    type: actionTypes.SET_INCLUDE_DIABETES,
    value
});

export const setBoookingLinkAction = (value) => {
    localStorage.setItem("bookingLink", value);
    return {
        type: actionTypes.SET_BOOKING_LINK,
        value
    };
};

export const setRemoteBloodPressureAction = (value) => {
    var parsedValue;
    if (value !== null && typeof value !== "undefined" && value !== "") {
        parsedValue = value === true || value.toLowerCase() === "true";
        localStorage.setItem("remoteBloodPressure", parsedValue);
    } else {
        parsedValue = null;
    }
    return {
        type: actionTypes.SET_REMOTE_BLOOD_PRESSURE,
        value: parsedValue
    };
};

export const setRemoteBloodTestAction = (value) => {
    var parsedValue;
    if (value !== null && typeof value !== "undefined" && value !== "") {
        parsedValue = value === true || value.toLowerCase() === "true";
        localStorage.setItem("remoteBloodTest", parsedValue);
    } else {
        parsedValue = null;
    }
    return {
        type: actionTypes.SET_REMOTE_BLOOD_TEST,
        value: parsedValue
    };
};
