import { LOCATION_CHANGE } from "connected-react-router";

import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    token: null,
    location: null,
    userVerification: {
        verified: false,
        failedVerification: false
    },
    pinRequired: false,
    pinInvalid: false,
    showAuthMessage: false,
    authMessage: "",
    redirect: false,
    pinRedirect: false,
    contactDetailsRedirect: false,
    inactiveUser: false,
    contactDetails: {
        telephone: "",
        email: ""
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            const location = action.payload.location.pathname;
            return {
                ...state,
                location,
                showAuthMessage:
                    state.showAuthMessage &&
                    action.payload.location.pathname === "/"
            };
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.value
            };
        case actionTypes.INVALIDATE_TOKEN:
            return {
                ...state,
                token: null
            };
        case actionTypes.CLEAR_ALL_FORM_VALUES:
            return {
                ...state,
                token: null
            };
        case actionTypes.SHOW_AUTH_MESSAGE:
            return {
                ...state,
                showAuthMessage: true,
                authMessage: action.message
            };
        case actionTypes.VERIFY_USER_SUCCESS:
            return {
                ...state,
                redirect: action.redirect,
                userVerification: {
                    ...action.userVerification,
                    verified: true,
                    failedVerification: false
                }
            };
        case actionTypes.VERIFY_USER_FAILED:
            return {
                ...state,
                userVerification: {
                    verified: false,
                    failedVerification: true
                }
            };
        case actionTypes.SET_PIN_REQUIRED:
            return {
                ...state,
                pinRequired: action.pinRequired
            };
        case actionTypes.SET_PIN_INVALID:
            return {
                ...state,
                pinInvalid: true
            };
        case actionTypes.SET_PIN_VALID:
            return {
                ...state,
                pinInvalid: false
            };
        case actionTypes.SET_PIN_SUCCESS:
        case actionTypes.SET_NO_PIN:
            return {
                ...state,
                pinRedirect: true
            };
        case actionTypes.SET_USER_INACTIVE:
            return {
                ...state,
                inactiveUser: true
            };
        case actionTypes.CLEAR_VERIFICATION:
            return INITIAL_STATE;
        case actionTypes.CLEAR_REDIRECT:
            return {
                ...state,
                redirect: false,
                pinRedirect: false
            };
        case actionTypes.SET_CONTACT_DETAILS:
            return {
                ...state,
                contactDetails: {
                    ...state.contactDetails,
                    ...action.contactDetails
                }
            };
        case actionTypes.SET_CONTACT_DETAILS_SUCCESS:
        case actionTypes.SET_NO_CONTACT_DETAILS:
            return {
                ...state,
                contactDetailsRedirect: true
            };
        default:
            return state;
    }
};
