import React from "react";

import "./AtrialFibrillationStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import NoIdea from "../../no-idea/NoIdea";
import content from "../../../lookup/en/content";

const PLAY_STORE_URL =
    "//play.google.com/store/apps/details?id=com.qompium.fibricheck&hl=en_GB";
const APPLE_STORE_URL = "//apps.apple.com/be/app/fibricheck/id1102327473?l=en";
const WEB_URL = "//www.fibricheck.com/";

class AtrialFibrilllationStep extends React.Component {
    componentDidMount() {
        const { setAtrialFibrillationAction } = this.props;
        const defaultAtrialFibrillationValue = localStorage.getItem(
            "atrialFibrillationStatus"
        );
        setAtrialFibrillationAction(defaultAtrialFibrillationValue);
    }

    getAppUrl() {
        const userAgent =
            navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return PLAY_STORE_URL;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return APPLE_STORE_URL;
        }

        return WEB_URL;
    }

    onAtrialFibrillationStatusChange(value) {
        const { setAtrialFibrillationAction, updateRisk } = this.props;
        setAtrialFibrillationAction(value);
        updateRisk("AF", value);
        localStorage.setItem("atrialFibrillationStatus", value);
    }

    render() {
        const { activeStep, atrialFibrillationStatus } = this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={7}>
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                <section className="atrial-fibrillation-step">
                    <FieldCard
                        cardTitle={content.Steps.AtrialFibrillation.Title}
                        cardDescription={
                            content.Steps.AtrialFibrillation.Description
                        }
                        helpTitle={content.Steps.AtrialFibrillation.HelpTitle}
                        helpText={content.Steps.AtrialFibrillation.HelpText}
                    >
                        <div className="atrial-fibrillation-items">
                            <OptionToggle
                                selected={
                                    atrialFibrillationStatus === "True" ||
                                    atrialFibrillationStatus === "true" ||
                                    atrialFibrillationStatus === true
                                }
                                select={this.onAtrialFibrillationStatusChange.bind(
                                    this,
                                    true
                                )}
                                secondaryClass="atrial-fibrillation-item"
                                content="Yes"
                            />
                            <OptionToggle
                                selected={
                                    atrialFibrillationStatus === "False" ||
                                    atrialFibrillationStatus === "false" ||
                                    atrialFibrillationStatus === false
                                }
                                select={this.onAtrialFibrillationStatusChange.bind(
                                    this,
                                    false
                                )}
                                secondaryClass="atrial-fibrillation-item"
                                content="No"
                            />
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default AtrialFibrilllationStep;
