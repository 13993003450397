import React from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";

import {
    postcodeLookupAction,
    clearAddressesAction,
    chooseAddressAction,
    setPersonalDetailsAction,
    setAddressComponentAction,
    submitAddressForm
} from "../../actions/address/addressActions";
import { setPostcodeAction } from "../../actions/form/formActions";
import InputField from "../../components/input-field/InputField";
import "./BloodPressureKit.scss";
import Button from "../../components/button/Button";
import content from "../../lookup/en/content";

const ORDER_CONFIRM_URL = `${process.env.PUBLIC_URL}/bp-kit-order-placed`;

class BloodPressureKit extends React.Component {
    state = {
        addressFormOpen: null
    };

    componentWillMount() {
        const { setPostcodeAction, postalCode } = this.props;
        if (postalCode) return;

        setPostcodeAction(localStorage.getItem("postalCode"));
    }

    postcodeLookup(value) {
        this.props.postcodeLookupAction(value);
    }

    mapAddress(rawAddress) {
        let components = rawAddress.split(", ");
        let addressString = "";
        components.forEach((component, index) => {
            if (index > 0 && component !== "") addressString += ", ";
            if (component !== "") addressString += component;
        });

        return {
            line1: components[0],
            line2: components[1],
            locality: components[4],
            town: components[5],
            county: components[6],
            addressString
        };
    }

    chooseAddress(address) {
        this.props.chooseAddressAction(address);
        this.setState({
            addressFormOpen: false
        });
    }

    setPersonalDetails(key, value) {
        this.props.setPersonalDetailsAction(key, value);
    }

    setAddressComponent(key, value) {
        this.props.setAddressComponentAction(key, value);
    }

    openAddressForm() {
        this.props.clearAddressesAction();
        this.setState({
            addressFormOpen: true
        });
    }

    submitForm() {
        const { token, addresses, submitAddressForm } = this.props;
        submitAddressForm(token, addresses, "bpKit");
    }

    close() {
        this.props.history.goBack();
    }

    render() {
        const { postalCode, addresses } = this.props;
        const { addressFormOpen } = this.state;

        if (addresses.bpKitLoanOrderPlaceSuccess)
            return <Redirect to={ORDER_CONFIRM_URL} />;

        return (
            <div className="blood-pressure-kit">
                <header className="close">
                    <div>
                        <span className="close" onClick={this.close.bind(this)}>
                            {content.Header.Close}
                        </span>
                    </div>
                </header>

                <div className="content">
                    <div className="intro">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/blood-test-kit.png`}
                        />
                        <p>{content.BloodPressureKit.Intro}</p>
                    </div>

                    <form>
                        <InputField
                            label={content.BloodPressureKit.Form.FirstName}
                            name="firstName"
                            error={addresses.errors.firstName}
                            onInput={this.setPersonalDetails.bind(
                                this,
                                "firstName"
                            )}
                        />
                        <InputField
                            label={content.BloodPressureKit.Form.LastName}
                            name="lastName"
                            error={addresses.errors.lastName}
                            onInput={this.setPersonalDetails.bind(
                                this,
                                "lastName"
                            )}
                        />
                        <InputField
                            label={content.BloodPressureKit.Form.Email}
                            name="email"
                            error={addresses.errors.email}
                            onInput={this.setPersonalDetails.bind(
                                this,
                                "email"
                            )}
                        />
                        <InputField
                            label={content.BloodPressureKit.Form.Telephone}
                            name="mobile"
                            error={addresses.errors.mobile}
                            onInput={this.setPersonalDetails.bind(
                                this,
                                "mobile"
                            )}
                        />
                        <InputField
                            label={content.BloodPressureKit.Form.PostCode}
                            name="postalCode"
                            error={addresses.errors.postalCode}
                            buttonText={
                                content.BloodPressureKit.Form
                                    .PostCodeLookupButton
                            }
                            defaultValue={postalCode}
                            buttonClick={this.postcodeLookup.bind(this)}
                        />
                        {addresses.lookupAddresses.length > 0 && (
                            <>
                                <span
                                    className="address-not-listed"
                                    onClick={this.openAddressForm.bind(this)}
                                >
                                    {content.BloodPressureKit.Form.CantFind}
                                </span>
                                <ul className="address-lookup">
                                    {addresses.lookupAddresses.map(
                                        (address) => {
                                            const addressMap =
                                                this.mapAddress(address);
                                            return (
                                                <li
                                                    key={addressMap.line1}
                                                    onClick={this.chooseAddress.bind(
                                                        this,
                                                        addressMap
                                                    )}
                                                >
                                                    {addressMap.addressString}
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </>
                        )}

                        {addresses.selectedAddress.line1 &&
                            !addressFormOpen && (
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .Title
                                    }
                                    name="address"
                                    prefilledValue={
                                        addresses.selectedAddress.addressString
                                    }
                                    buttonText={
                                        content.BloodPressureKit.Form.Address
                                            .Edit
                                    }
                                    buttonClick={this.openAddressForm.bind(
                                        this
                                    )}
                                />
                            )}

                        {addressFormOpen && (
                            <>
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .Line1
                                    }
                                    name="line1"
                                    error={addresses.errors.line1}
                                    onInput={this.setAddressComponent.bind(
                                        this,
                                        "line1"
                                    )}
                                    defaultValue={
                                        addresses.selectedAddress.line1
                                    }
                                />
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .Line2
                                    }
                                    name="line2"
                                    onInput={this.setAddressComponent.bind(
                                        this,
                                        "line2"
                                    )}
                                    defaultValue={
                                        addresses.selectedAddress.line2
                                    }
                                />
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .Locality
                                    }
                                    error={addresses.errors.locality}
                                    name="locality"
                                    onInput={this.setAddressComponent.bind(
                                        this,
                                        "locality"
                                    )}
                                    defaultValue={
                                        addresses.selectedAddress.locality
                                    }
                                />
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .Town
                                    }
                                    name="town"
                                    error={addresses.errors.town}
                                    onInput={this.setAddressComponent.bind(
                                        this,
                                        "town"
                                    )}
                                    defaultValue={
                                        addresses.selectedAddress.town
                                    }
                                />
                                <InputField
                                    label={
                                        content.BloodPressureKit.Form.Address
                                            .County
                                    }
                                    name="county"
                                    error={addresses.errors.county}
                                    onInput={this.setAddressComponent.bind(
                                        this,
                                        "county"
                                    )}
                                    defaultValue={
                                        addresses.selectedAddress.county
                                    }
                                />
                            </>
                        )}
                        <div className="submit">
                            <Button
                                isPrimary
                                onClick={this.submitForm.bind(this)}
                            >
                                {content.BloodPressureKit.Form.Submit}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        postalCode: state.form.postalCode,
        addresses: state.address,
        token: state.auth.token
    }),
    {
        postcodeLookupAction,
        clearAddressesAction,
        setPostcodeAction,
        chooseAddressAction,
        setPersonalDetailsAction,
        setAddressComponentAction,
        submitAddressForm
    }
)(BloodPressureKit);
