import React from "react";

import "./SmokingStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import content from "../../../lookup/en/content";
import { smokingLevels } from "../../../lookup/en/formValues";

class SmokingStep extends React.Component {
    componentDidMount() {
        const { setSmokingLevelAction, setFamilyHistoryAction } = this.props;

        const defaultSmokingLevelValue =
            localStorage.getItem("smokingLevel") || 5; // 5 represents not selected;
        setSmokingLevelAction(defaultSmokingLevelValue);

        const defaultFamilyHistoryValue = localStorage.getItem("familyHistory");
        setFamilyHistoryAction(defaultFamilyHistoryValue);
    }

    onSmokingLevelChange(value) {
        const { setSmokingLevelAction, updateRisk } = this.props;
        setSmokingLevelAction(value);
        updateRisk("SmokingStatus", value);
        localStorage.setItem("smokingLevel", value);
    }

    onFamilyHistoryChange(value) {
        const { setFamilyHistoryAction, updateRisk } = this.props;
        setFamilyHistoryAction(value);
        updateRisk("Familyheart", value);
        localStorage.setItem("familyHistory", value);
    }

    render() {
        const { activeStep, smokingLevel, familyHistory, age } = this.props;
        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={6}
                skipPrevious={age < 65}
            >
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                <section className="smoking-step">
                    <FieldCard
                        cardTitle={content.Steps.Smoking.Title}
                        helpTitle={content.Steps.Smoking.HelpTitle}
                        helpText={content.Steps.Smoking.HelpText}
                    >
                        <div className="smoking-items">
                            {smokingLevels.map((sl) => (
                                <OptionToggle
                                    selected={parseInt(smokingLevel) === sl.id}
                                    select={this.onSmokingLevelChange.bind(
                                        this,
                                        sl.id
                                    )}
                                    secondaryClass="smoking-item"
                                    content={sl.description}
                                    key={sl.id}
                                />
                            ))}
                        </div>
                    </FieldCard>
                    <FieldCard
                        cardTitle={content.Steps.FamilyHistory.Title}
                        helpTitle={content.Steps.FamilyHistory.HelpTitle}
                        helpText={content.Steps.FamilyHistory.HelpText}
                    >
                        <div className="family-history-items">
                            <OptionToggle
                                selected={
                                    familyHistory &&
                                    familyHistory !== "False" &&
                                    familyHistory !== "false"
                                }
                                select={this.onFamilyHistoryChange.bind(
                                    this,
                                    true
                                )}
                                secondaryClass="family-history-item"
                                content={content.Steps.FamilyHistory.Yes}
                            />
                            <OptionToggle
                                selected={
                                    familyHistory === false ||
                                    familyHistory === "False" ||
                                    familyHistory === "false"
                                }
                                select={this.onFamilyHistoryChange.bind(
                                    this,
                                    false
                                )}
                                secondaryClass="family-history-item"
                                content={content.Steps.FamilyHistory.No}
                            />
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default SmokingStep;
