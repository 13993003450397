import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Routes from "../Routes";
import Header from "../components/header/Header";
import content from "../lookup/en/content";

import {
    setTokenAction,
    invalidateTokenAction,
    refreshTokenAction,
    showAuthMessageAction,
    setUserKeyAction,
    checkKeyAction,
    setPinRequiredAction
} from "../actions/auth/authActions";
import { clearAllFormValuesAction } from "../actions/form/formActions";
import { setPreferenceAction } from "../actions/preferences/preferencesActions";
import CookiePopup from "../components/cookie-popup/CookiePopup";
import { Footer } from "../components/footer/Footer";

class Root extends React.Component {
    state = {
        redirect: false
    };

    tidioPreload;

    componentWillMount() {
        const { setPreferenceAction, showAuthMessageAction } = this.props;

        this.tidioPreload = setInterval(this.checkIfTidioShown.bind(this), 5);

        let preferences = localStorage.getItem("preferences");
        if (preferences) {
            preferences = JSON.parse(preferences);
            Object.keys(preferences).forEach((preference) => {
                if (preference === "consent") return;
                setPreferenceAction(preference, preferences[preference]);
            });
        }

        let params = new URLSearchParams(window.location.search);
        if (params.get("timeout")) {
            showAuthMessageAction(content.Header.SessionTimeout);
        }

        if (params.get("invalid_token")) {
            showAuthMessageAction(content.Header.SessionInvalid);
        }

        this.setUserKey();
    }

    componentWillUpdate() {
        this.authenticate(true);
        this.checkIfTidioShown();
    }

    checkIfTidioShown() {
        const { location } = this.props;

        if (typeof window.tidioChatApi !== "undefined" && location) {
            if (
                location === "/" ||
                location === "/verification" ||
                location.indexOf("/key/") > -1
            ) {
                window.tidioChatApi.hide();
            } else {
                window.tidioChatApi.show();
            }

            clearInterval(this.tidioPreload);
        }
    }

    authenticate(skipQueryString = false) {
        const {
            refreshTokenAction,
            clearAllFormValuesAction,
            setPinRequiredAction
        } = this.props;

        const userKey = localStorage.getItem("userKey");

        let params = new URLSearchParams(window.location.search);
        if (params.get("t") && !skipQueryString) {
            this.updateToken(params.get("t"));
            this.setState({
                redirect: true
            });
        } else {
            let existingToken = localStorage.getItem("token");
            let timeSinceLastRefresh = localStorage.getItem(
                "timeSinceLastRefresh"
            );
            let currentTime = new Date().getTime();
            let timeDifference;
            if (timeSinceLastRefresh !== null) {
                timeDifference =
                    parseInt(currentTime) - parseInt(timeSinceLastRefresh);
            }
            localStorage.setItem("timeSinceLastRefresh", currentTime);

            if ((!timeDifference || timeDifference > 30000) && existingToken) {
                refreshTokenAction(existingToken);
            } else if (
                timeDifference &&
                !existingToken &&
                window.location.pathname !== "/pin"
            ) {
                console.log("authenticating");
                clearAllFormValuesAction(userKey);
            } else if (window.location.pathname === "/pin") {
                setPinRequiredAction(
                    localStorage.getItem("pinRequired") === "true"
                );
            }
        }
    }

    setUserKey() {
        const { setUserKeyAction, checkKeyAction, clearAllFormValuesAction } =
            this.props;
        let params = new URLSearchParams(window.location.search);

        if (params.get("key")) {
            setUserKeyAction(params.get("key"));
            console.log("setting key");
            clearAllFormValuesAction(params.get("key"));
            this.setState({
                redirect: true
            });
        }

        let key = localStorage.getItem("userKey");
        if (key) {
            checkKeyAction(key);
        }
    }

    updateToken(token) {
        const {
            setTokenAction,
            refreshTokenAction,
            showAuthMessageAction,
            clearAllFormValuesAction
        } = this.props;

        const showAuthMessage = localStorage.getItem("token") !== null;

        console.log("refershing token");

        clearAllFormValuesAction();

        localStorage.setItem("token", token);
        setTokenAction(token);
        refreshTokenAction(token);

        if (showAuthMessage)
            showAuthMessageAction(content.Header.SessionTimeout);
    }

    iOS() {
        return [
            "iPhone Simulator",
            "iPod Simulator",
            "iPhone",
            "iPod"
        ].includes(navigator.platform);
    }

    render() {
        const { logoVisible, showAuthMessage, authMessage } = this.props;

        return (
            <>
                {this.state.redirect && (
                    <Redirect to={window.location.pathname} />
                )}
                <Header
                    logoVisible={logoVisible}
                    showAuthMessage={showAuthMessage}
                    authMessage={authMessage}
                />
                <main className={this.iOS() ? "ios" : ""}>
                    <Routes />
                </main>
                <CookiePopup />
                <Footer />
            </>
        );
    }
}

export default connect(
    (state) => ({
        logoVisible: state.header.logoVisible,
        token: state.auth.token,
        verified: state.auth.userVerification.verified,
        form: state.form,
        location: state.auth.location,
        showAuthMessage: state.auth.showAuthMessage,
        authMessage: state.auth.authMessage
    }),
    {
        setTokenAction,
        invalidateTokenAction,
        refreshTokenAction,
        clearAllFormValuesAction,
        showAuthMessageAction,
        setPreferenceAction,
        setUserKeyAction,
        checkKeyAction,
        setPinRequiredAction
    }
)(Root);
