import React from "react";
import content from "../../lookup/en/content";
import Button from "../button/Button";

import "./TestStep.scss";

class TestStep extends React.Component {
    state = {
        mounted: false,
        className: "step"
    };

    componentDidMount() {
        const checkClassName = this.checkClassName.bind(this);
        setTimeout(() => {
            this.setState({
                className: checkClassName(),
                mounted: true
            });
        }, 10);
    }

    checkClassName() {
        const { activeStep, stepIndex, alignTop } = this.props;

        let className = "step";

        if (parseInt(activeStep) === stepIndex) className += " active";
        else if (activeStep > stepIndex) className += " previous";

        if (activeStep > 50) className += " final";

        if (alignTop) className += " align-top";

        return className;
    }

    redraw() {
        this.props.redraw();
    }

    highlightAlert() {
        document.body.classList.add("highlight-error");
        setTimeout(() => {
            document.body.classList.remove("highlight-error");
        }, 2500);
    }

    componentDidUpdate() {
        const activeStepEl = document.querySelector(".step.active");
        const firstActiveInput =
            activeStepEl?.querySelector("input[type=text]");
        if (firstActiveInput) {
            firstActiveInput.focus();
        }
    }

    render() {
        const {
            stepIndex,
            lastStep,
            fakeLastStep,
            disableNavigation,
            disableNext,
            skipNext,
            skipPrevious,
            history,
            onNextStep,
            remoteBT,
            remoteBP,
            missingSections,
            missingSectionsNotIncludingBP,
            isValidating
        } = this.props;

        let className = this.state.className;
        if (this.state.mounted) {
            className = this.checkClassName();
        }

        let nextIndex = stepIndex + 1;
        let backIndex = stepIndex - 1;
        if (skipNext) nextIndex++;
        if (skipPrevious) backIndex--;

        return (
            <div className={className}>
                {this.props.children}
                {isValidating ? (
                    <>
                        <Button
                            onClick={
                                onNextStep
                                    ? (e) => {
                                          if (!onNextStep()) {
                                              e.preventDefault();
                                          }
                                      }
                                    : () => {}
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="15"
                                viewBox="0 0 120 30"
                                fill="#fff"
                            >
                                <circle cx="15" cy="15" r="15">
                                    <animate
                                        attributeName="r"
                                        from="15"
                                        to="15"
                                        begin="0s"
                                        dur="0.8s"
                                        values="15;9;15"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                    <animate
                                        attributeName="fill-opacity"
                                        from="1"
                                        to="1"
                                        begin="0s"
                                        dur="0.8s"
                                        values="1;.5;1"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                                <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                                    <animate
                                        attributeName="r"
                                        from="9"
                                        to="9"
                                        begin="0s"
                                        dur="0.8s"
                                        values="9;15;9"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                    <animate
                                        attributeName="fill-opacity"
                                        from="0.5"
                                        to="0.5"
                                        begin="0s"
                                        dur="0.8s"
                                        values=".5;1;.5"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                                <circle cx="105" cy="15" r="15">
                                    <animate
                                        attributeName="r"
                                        from="15"
                                        to="15"
                                        begin="0s"
                                        dur="0.8s"
                                        values="15;9;15"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                    <animate
                                        attributeName="fill-opacity"
                                        from="1"
                                        to="1"
                                        begin="0s"
                                        dur="0.8s"
                                        values="1;.5;1"
                                        calcMode="linear"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                            </svg>
                        </Button>
                        {fakeLastStep && (
                            <p className="last-step-description">
                                {content.Steps.SummaryDescription}
                            </p>
                        )}

                        <Button
                            href={`${process.env.PUBLIC_URL}/test/${backIndex}`}
                            greyed
                        >
                            {content.Steps.Back}
                        </Button>
                    </>
                ) : (
                    <>
                        {!disableNavigation && !lastStep && !disableNext && (
                            <div className="next-step-tutorial">
                                <Button
                                    href={`${process.env.PUBLIC_URL}/test/${nextIndex}`}
                                    onClick={
                                        onNextStep
                                            ? (e) => {
                                                  if (!onNextStep()) {
                                                      e.preventDefault();
                                                  } else {
                                                      history.push(
                                                          `${process.env.PUBLIC_URL}/test/${nextIndex}`
                                                      );
                                                  }
                                              }
                                            : null
                                    }
                                >
                                    {fakeLastStep
                                        ? content.Steps.Summary
                                        : content.Steps.Next}
                                </Button>
                                {fakeLastStep && (
                                    <p className="last-step-description">
                                        {content.Steps.SummaryDescription}
                                    </p>
                                )}
                            </div>
                        )}

                        {!disableNavigation && !disableNext && lastStep && (
                            <>
                                <Button
                                    href={
                                        remoteBT &&
                                        ((remoteBP &&
                                            missingSections.length === 0) ||
                                            (!remoteBP &&
                                                missingSectionsNotIncludingBP.length ===
                                                    0))
                                            ? `${process.env.PUBLIC_URL}/blood-test`
                                            : `${process.env.PUBLIC_URL}/test-summary`
                                    }
                                    onClick={
                                        onNextStep
                                            ? (e) => {
                                                  if (!onNextStep()) {
                                                      e.preventDefault();
                                                  } else {
                                                      history.push(
                                                          remoteBT &&
                                                              ((remoteBP &&
                                                                  missingSections.length ===
                                                                      0) ||
                                                                  (!remoteBP &&
                                                                      missingSectionsNotIncludingBP.length ===
                                                                          0))
                                                              ? `${process.env.PUBLIC_URL}/blood-test`
                                                              : `${process.env.PUBLIC_URL}/test-summary`
                                                      );
                                                  }
                                              }
                                            : null
                                    }
                                >
                                    {content.Steps.Summary}
                                </Button>
                                <p className="last-step-description">
                                    {content.Steps.SummaryDescription}
                                </p>
                            </>
                        )}

                        {!disableNavigation &&
                            stepIndex > 1 &&
                            !this.props.bloodPressureInvalid && (
                                <Button
                                    href={`${process.env.PUBLIC_URL}/test/${backIndex}`}
                                    greyed
                                >
                                    {content.Steps.Back}
                                </Button>
                            )}

                        {disableNavigation && lastStep && (
                            <>
                                <Button
                                    onClick={this.highlightAlert.bind(this)}
                                >
                                    {content.Steps.Summary}
                                </Button>
                                <p className="last-step-description">
                                    {content.Steps.SummaryDescription}
                                </p>
                                <Button
                                    onClick={this.highlightAlert.bind(this)}
                                    greyed
                                >
                                    {content.Steps.Back}
                                </Button>
                            </>
                        )}

                        {disableNavigation && stepIndex > 1 && !lastStep && (
                            <>
                                <Button
                                    onClick={
                                        onNextStep
                                            ? (e) => {
                                                  if (!onNextStep()) {
                                                      e.preventDefault();
                                                  }
                                              }
                                            : () => {}
                                    }
                                    disabled
                                >
                                    {content.Steps.Next}
                                </Button>
                                <Button
                                    href={`${process.env.PUBLIC_URL}/test/${backIndex}`}
                                    greyed
                                >
                                    {content.Steps.Back}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default TestStep;
