import React from "react";
import content from "../../lookup/en/content";

import "./Instructions.scss";

const Instructions = () => [
    <div className="instructions-container">
        <article>
            <h2>{content.Instructions.Title}</h2>
            {content.Instructions.Content}
            <iframe
                className="video"
                src="https://www.youtube.com/embed/fyhyqajHeT4"
            ></iframe>
        </article>
    </div>
];

export default Instructions;
