import * as actionTypes from "../actionTypes";
import axios from "axios";

import {
    setBodyMassIndexAction,
    setDiabetesRiskScoreAction,
    setAUDITCScoreAction,
    setFullAUDITScoreAction,
    setGPAQScoreAction,
    setRemoteBloodTestAction,
    setRemoteBloodPressureAction,
    setBoookingLinkAction
} from "../form/formActions";

import { API_BASE } from "../../lookup/api";
import {
    calculateAUDITC,
    calculateBmi,
    calculateDiabetesRisk,
    calculateFullAUDITScore,
    calculateGPAQScore,
    checkMissingSections
} from "../../util";
const RISK_API_URL = `${API_BASE}/calculaterisk.ashx`;

export const setRiskAction = (value) => ({
    type: actionTypes.SET_RISK,
    value
});

export const setHeartAgeAction = (heartAge) => ({
    type: actionTypes.SET_HEART_AGE,
    heartAge
});

export const setTypicalScoreAction = (typicalScore) => ({
    type: actionTypes.SET_TYPICAL_SCORE,
    typicalScore
});

export const mapForm = (
    token,
    formValues,
    changedKey = null,
    changedValue = null
) => {
    if (process.env.REACT_APP_MOCK_API || process.env.REACT_APP_MOCK_RISK) {
        return (dispatch) =>
            dispatch(setRiskAction((Math.random() * 30).toFixed(1)));
    }

    const bmi = calculateBmi(formValues, setBodyMassIndexAction);
    const diabetesRiskScore = calculateDiabetesRisk(
        bmi,
        formValues,
        setDiabetesRiskScoreAction
    );
    const auditCScore = calculateAUDITC(formValues, setAUDITCScoreAction);
    let auditScore = calculateFullAUDITScore(
        formValues,
        setFullAUDITScoreAction
    );
    if (auditCScore < 5) auditScore = "";
    const gpaqScore = calculateGPAQScore(formValues, setGPAQScoreAction);

    const missingSections = checkMissingSections(formValues, false);

    let form = {
        Token: token,
        Postcode: formValues.postalCode,
        Age: formValues.age,
        Sex: formValues.gender,
        EthnicBackground: formValues.ethnicity,
        Height: formValues.heightInCm,
        Weight: formValues.weightInKg,
        HeightUnit: formValues.heightUnit,
        WeightUnit: formValues.weightUnit,
        SmokingStatus: formValues.smokingLevel,
        DiabetesStatus: formValues.diabetesType,
        Familyheart: formValues.familyHistory,
        Kidneydisease: formValues.kidneyDisease,
        Migraines: formValues.medicalConditions.indexOf("Migraines") > -1,
        AF: formValues.atrialFibrillationStatus,
        Bloodpressuretreatment:
            formValues.medicalConditions.indexOf("Bloodpressuretreatment") > -1,
        Arthritis: formValues.medicalConditions.indexOf("Arthritis") > -1,
        Lupus: formValues.medicalConditions.indexOf("Lupus") > -1,
        Mentalillness:
            formValues.medicalConditions.indexOf("Mentalillness") > -1,
        Antipsychotic:
            formValues.medicalConditions.indexOf("Antipsychotic") > -1,
        Steroidtablets:
            formValues.medicalConditions.indexOf("Steroidtablets") > -1,
        Erectiledisfunction:
            formValues.medicalConditions.indexOf("Erectiledisfunction") > -1,
        NoAilments: formValues.noMedicalConditions,
        Cholesterol: localStorage.getItem("totalCholesterol"),
        Hdl: localStorage.getItem("hdlCholesterol"),
        Systolicbp1: formValues.systolicBloodPressureReadings[0],
        Systolicbp2: formValues.systolicBloodPressureReadings[1],
        Systolicbp3: formValues.systolicBloodPressureReadings[2],
        DiastolicBP1: formValues.diastolicBloodPressureReadings[0],
        DiastolicBP2: formValues.diastolicBloodPressureReadings[1],
        DiastolicBP3: formValues.diastolicBloodPressureReadings[2],
        bloodPressureLocation: formValues.bloodPressureLocation,
        DiabetesFamily: formValues.diabetesInFamily,
        Waist: formValues.waistMeasurement,
        AlcoholOften: formValues.alcoholConsumption,
        AlcoholUnits: formValues.alcoholUnits,
        AlcoholBinge: formValues.bingeSessions,
        PhysicalActivityHoursCycling: formValues.activityHoursCycling,
        PhysicalActivityHoursWalking: formValues.activityHoursWalking,
        PhysicalActivityHours: formValues.activityHoursExercise,
        PhysicalActivityHoursHouseworkChildcare:
            formValues.activityHoursHouseworkChildcare,
        PhysicalActivityHoursGardeningDIY: formValues.activityHoursGardeningDIY,
        PhysicalActivityWork: formValues.physicalWork,
        WalkingPace: formValues.walkingPace,
        unableToStop: auditCScore < 5 ? "" : formValues.unableToStop,
        failedExpectations:
            auditCScore < 5 ? "" : formValues.failedExpectations,
        morningDrinking: auditCScore < 5 ? "" : formValues.morningDrinking,
        drinkingGuilt: auditCScore < 5 ? "" : formValues.drinkingGuilt,
        blackout: auditCScore < 5 ? "" : formValues.blackout,
        drinkingInjury: auditCScore < 5 ? "" : formValues.drinkingInjury,
        drinkingConcern: auditCScore < 5 ? "" : formValues.drinkingConcern,
        DiabetesRiskScore: diabetesRiskScore,
        AuditCScore: auditCScore,
        AuditScore: auditScore,
        GPAQScore: gpaqScore,
        IncompleteSteps: missingSections.length
    };

    Object.keys(form).forEach((key) => {
        if (form[key] === null) delete form[key];
    });

    if (changedKey) form[changedKey] = changedValue;
    if (changedKey === "medicalConditions" && changedValue === null) {
        delete form.Migraines;
        delete form.Bloodpressuretreatment;
        delete form.Arthritis;
        delete form.Lupus;
        delete form.Mentalillness;
        delete form.Antipsychotic;
        delete form.Steroidtablets;
        delete form.Erectiledisfunction;

        form.NoAilments = true;
    }

    if (
        [
            "Migraines",
            "Bloodpressuretreatment",
            "Arthritis",
            "Lupus",
            "Mentalillness",
            "Antipsychotic",
            "Steroidtablest",
            "Erectiledisfunction"
        ].includes(changedKey)
    ) {
        form.NoAilments = false;
    }

    localStorage.setItem("riskForm", JSON.stringify(form));

    return {
        type: actionTypes.MAP_RISK_FORM
    };
};

export const getRisk = () => {
    const form = localStorage.getItem("riskForm");

    return (dispatch) => {
        return axios
            .post(RISK_API_URL, form, {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(data);
                    if (!process.env.REACT_APP_DEBUG)
                        window.location = `${process.env.PUBLIC_URL}/?invalid_token=true`;
                } else {
                    dispatch(setRiskAction(data.Risk.toFixed(1)));
                    dispatch(setHeartAgeAction(data.heartage));
                    dispatch(setTypicalScoreAction(data.TypicalScore));
                    dispatch(setRemoteBloodTestAction(data.RemoteBloodTests));
                    dispatch(
                        setRemoteBloodPressureAction(data.RemoteBloodPressure)
                    );
                    dispatch(setBoookingLinkAction(data.BookingLink));
                    localStorage.setItem("userMMHStatus", data.MMHOrderStateID);
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const mapFormAndGetRisk = (
    token,
    formValues,
    changedKey = null,
    changedValue = null
) => {
    return (dispatch) => {
        dispatch(mapForm(token, formValues, changedKey, changedValue));
        dispatch(getRisk());
    };
};
