// Action Types

export const VOID = "VOID";

// Form action types

export const CLEAR_ALL_FORM_VALUES = "CLEAR_ALL_FORM_VALUES";
export const SET_ALL_FORM_VALUES = "SET_ALL_FORM_VALUES";
export const SET_INCLUDE_DIABETES = "SET_INCLUDE_DIABETES";
export const SET_REMOTE_BLOOD_PRESSURE = "SET_REMOTE_BLOOD_PRESSURE";
export const SET_REMOTE_BLOOD_TEST = "SET_REMOTE_BLOOD_TEST";
export const SET_BOOKING_LINK = "SET_BOOKING_LINK";
export const SET_POSTCODE = "SET_POSTCODE";
export const SET_POSTCODE_VALID = "SET_POSTCODE_VALID";
export const SET_POSTCODE_INVALID = "SET_POSTCODE_INVALID";
export const BEGIN_POSTCODE_VALIDATION = "BEGIN_POSTCODE_VALIDATION";
export const SET_AGE = "SET_AGE";
export const SET_GENDER = "SET_GENDER";
export const SET_ETHNICITY = "SET_ETHNICITY";
export const SET_HEIGHT_UNIT = "SET_HEIGHT_UNIT";
export const SET_HEIGHT_IN_CM = "SET_HEIGHT_IN_CM";
export const SET_HEIGHT_IN_FEET = "SET_HEIGHT_IN_FEET";
export const SET_HEIGHT_IN_INCHES = "SET_HEIGHT_IN_INCHES";
export const SET_WEIGHT_UNIT = "SET_WEIGHT_UNIT";
export const SET_WEIGHT_IN_KG = "SET_WEIGHT_IN_KG";
export const SET_WEIGHT_IN_STONE = "SET_WEIGHT_IN_STONE";
export const SET_WEIGHT_IN_LBS = "SET_WEIGHT_IN_LBS";
export const SET_SMOKING_LEVEL = "SET_SMOKING_LEVEL";
export const SET_DIABETES_TYPE = "SET_DIABETES_TYPE";
export const SET_FAMILY_HISTORY = "SET_FAMILY_HISTORY";
export const SET_KIDNEY_DISEASE = "SET_KIDNEY_DISEASE";
export const SET_MEDICAL_CONDITIONS = "SET_MEDICAL_CONDITIONS";
export const SET_NO_MEDICAL_CONDITIONS = "SET_NO_MEDICAL_CONDITIONS";
export const SET_ATRIAL_FIBRILLATION_STEP = "SET_ATRIAL_FIBRILLATION_STEP";
export const SET_SYSTOLIC_BLOOD_PRESSURE_READINGS =
    "SET_SYSTOLIC_BLOOD_PRESSURE_READINGS";
export const SET_DIASTOLIC_BLOOD_PRESSURE_READINGS =
    "SET_DIASTOLIC_BLOOD_PRESSURE_READINGS";
export const SET_BLOOD_PRESSURE_VALID = "SET_BLOOD_PRESSURE_VALID";
export const SET_BLOOD_PRESSURE_INVALID = "SET_BLOOD_PRESSURE_INVALID";
export const SET_BLOOD_PRESSURE_EMERGENCY = "SET_BLOOD_PRESSURE_EMERGENCY";
export const SET_BLOOD_PRESSURE_NORMAL = "SET_BLOOD_PRESSURE_NORMAL";
export const SET_BLOOD_PRESSURE_HIGH = "SET_BLOOD_PRESSURE_HIGH";
export const SET_BLOOD_PRESSURE_LOW = "SET_BLOOD_PRESSURE_LOW";
export const SET_BLOOD_PRESSURE_HIGH_WARNING_READ =
    "SET_BLOOD_PRESSURE_HIGH_WARNING_READ";
export const SET_BLOOD_PRESSURE_HIGH_WARNING_SHOW =
    "SET_BLOOD_PRESSURE_HIGH_WARNING_SHOW";
export const REQUEST_MORE_BP_READINGS = "REQUEST_MORE_BP_READINGS";
export const SET_BLOOD_PRESSURE_LOCATION = "SET_BLOOD_PRESSURE_LOCATION";
export const SET_PULSE = "SET_PULSE";
export const SET_CHOLESTEROL_STATUS = "SET_CHOLESTEROL_STATUS";
export const SET_TOTAL_CHOLESTEROL_VALUE = "SET_TOTAL_CHOLESTEROL_VALUE";
export const SET_HDL_CHOLESTEROL_VALUE = "SET_HDL_CHOLESTEROL_VALUE";
export const SET_DIABETES_IN_FAMILY = "SET_DIABETES_IN_FAMILY";
export const SET_WAIST_MEASUREMENT = "SET_WAIST_MEASUREMENT";
export const SET_WAIST_UNIT = "SET_WAIST_UNIT";
export const SET_ALCOHOL_CONSUMPTION_VALUE = "SET_ALCOHOL_CONSUMPTION_VALUE";
export const SET_ALCOHOL_UNITS_VALUE = "SET_ALCOHOL_UNITS_VALUE";
export const SET_BINGE_DRINKING_SESSIONS = "SET_BINGE_DRINKING_SESSIONS";
export const SET_ADDITIONAL_AUDIT_C_ACTION = "SET_ADDITIONAL_AUDIT_C_ACTION";
export const SET_ACTIVITY_HOURS_CYCLING = "SET_ACTIVITY_HOURS_CYLING";
export const SET_ACTIVITY_HOURS_WALKING = "SET_ACTIVITY_HOURS_WALKING";
export const SET_ACTIVITY_HOURS_EXERCISE = "SET_ACTIVITY_HOURS_EXERCISE";
export const SET_ACTIVITY_HOURS_HOUSEWORK_CHILDCARE =
    "SET_ACTIVITY_HOURS_HOUSEWORK_CHILDCARE";
export const SET_ACTIVITY_HOURS_GARDENING_DIY =
    "SET_ACTIVITY_HOURS_GARDENING_DIY";
export const SET_WALKING_PACE = "SET_WALKING_PACE";
export const SET_PHYSICAL_WORK_DEFINITION = "SET_PHYSICAL_WORK_DEFINITION";
export const SET_VEGETABLE_PORTIONS = "SET_VEGETABLE_PORTIONS";
export const SET_DIET_FOODS = "SET_DIET_FOODS";
export const SET_WEIGHT_CLASS = "SET_WEIGHT_CLASS";
export const SET_BODY_MASS_INDEX = "SET_BODY_MASS_INDEX";
export const SET_DIABETES_RISK_SCORE = "SET_DIABETES_RISK_SCORE";
export const SET_AUDIT_C_SCORE = "SET_AUDIT_C_SCORE";
export const SET_FULL_AUDIT_SCORE = "SET_FULL_AUDIT_SCORE";
export const SET_GPAQ_SCORE = "SET_GPAQ_SCORE";
export const CHANGE_FORM_PART = "CHANGE_FORM_PART";
export const COMPLETE_FORM_PART_CHANGE = "COMPLETE_FORM_PART_CHANGE";
export const SET_STATUS = "SET_STATUS";
export const SET_STATUS_SUCCESS = "SET_STATUS_SUCCESS";
export const SET_STATUS_FAILURE = "SET_STATUS_FAILURE";
export const SET_MISSING_FIELDS = "SET_MISSING_FIELDS";
export const SET_MISSING_FIELDS_NOT_INCLUDING_BP =
    "SET_MISSING_FIELDS_NOT_INCLUDING_BP";

// Auth action types

export const CHECK_KEY = "CHECK_KEY";
export const SET_TOKEN = "SET_TOKEN";
export const INVALIDATE_TOKEN = "INVALIDATE_TOKEN";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILED = "VERIFY_USER_FAILED";
export const SET_USER_KEY = "SET_USER_KEY";
export const CLEAR_VERIFICATION = "CLEAR_VERIFICAITON";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const SET_PIN_REQUIRED = "SET_PIN_REQUIRED";
export const SET_PIN = "SET_PIN";
export const SET_PIN_VALID = "SET_PIN_VALID";
export const SET_PIN_INVALID = "SET_PIN_INVALID";
export const SET_PIN_SUCCESS = "SET_PIN_SUCCESS";
export const SET_NO_PIN = "SET_NO_PIN";
export const SET_USER_INACTIVE = "SET_USER_INACTIVE";
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const SET_CONTACT_DETAILS_SUCCESS = "SET_CONTACT_DETAILS_SUCCESS";
export const SET_CONTACT_DETAILS_ERROR = "SET_CONTACT_DETAILS_ERROR";
export const SET_NO_CONTACT_DETAILS = "SET_NO_CONTACT_DETAILS";

// Risk action types

export const MAP_RISK_FORM = "MAP_RISK_FORM";
export const SET_RISK = "SET_RISK";
export const SET_HEART_AGE = "SET_HEART_AGE";
export const SET_TYPICAL_SCORE = "SET_TYPICAL_SCORE";

// Address actions

export const POSTCODE_LOOKUP = "POSTCODE_LOOKUP";
export const SET_LOOKUP_ADDRESSES = "SET_LOOKUP_ADDRESSES";
export const CLEAR_LOOKUP_ADDRESSES = "CLEAR_LOOKUP_ADDRESSES";
export const CHOOSE_ADDRESS = "CHOOSE_ADDRESS";
export const SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS";
export const SET_ADDRESS_COMPONENT = "SET_ADDRESS_COMPONENT";
export const SET_LAT_LNG = "SET_LAT_LNG";
export const SET_ADDRESS_FORM_ERRORS = "SET_ADDRESS_FORM_ERRORS";
export const BP_KIT_LOAN_ORDER_PLACE_SUCCESS =
    "BP_KIT_LOAN_ORDER_PLACE_SUCCESS";
export const CHOLESTEROL_TEST_ORDER_PLACE_SUCCESS =
    "CHOLESTEROL_TEST_ORDER_PLACE_SUCCESS";
export const ADDRESS_VALIDATED = "ADDRESS_VALIDATED";

// Pharmacy actions

export const SET_PHARMACIES = "SET_PHARMACIES";
export const CHOOSE_PHARMACY = "CHOOSE_PHARMACY";

// Header actions

export const SET_CONTENTS_INDEX = "SET_CONTENTS_INDEX";
export const SET_TUTORIAL_VIEWED = "SET_TUTORIAL_VIEWED";

// Preferences actions

export const SET_PREFERENCE = "SET_PREFERENCE";

// Contact actions

export const SEND_ENQUIRY = "SEND_ENQUIRY";
export const SEND_ENQUIRY_SUCCESS = "SEND_ENQUIRY_SUCCESS";
export const SEND_ENQUIRY_FAILURE = "SEND_ENQUIRY_FAILURE";

// Analytics actions

export const TRACK_PAGEVIEW = "TRACK_PAGEVIEW";
export const TRACK_PAGEVIEW_SUCCESS = "TRACK_PAGEVIEW_SUCCESS";
export const TRACK_PAGEVIEW_FAILURE = "TRACK_PAGEVIEW_FAILURE";
