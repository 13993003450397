import React from "react";

import "./LinkedRangeInput.scss";

const NUDGE_TIMEOUT = 500;

class LinkedRangeInput extends React.Component {
    nudgeChange = null;

    onInputChange(e) {
        const { min, idKey } = this.props;

        let value = e.target.value;
        if (value === "") value = min;

        const rangeInput = document.getElementById(`${idKey}_range`);
        rangeInput.value = value;
        rangeInput.dispatchEvent(new Event("blur", { bubbles: true }));
    }

    onInputBlur(e) {
        const { min, max, fixedPoints, idKey, onChange } = this.props;

        let value = e.target.value;

        if (fixedPoints) {
            value = parseFloat(value).toFixed(fixedPoints);
            e.target.value = value;
        }

        if (parseInt(value) < min || value === "") {
            e.target.value = min;
            onChange(min);
        } else if (parseInt(value) > max) {
            e.target.value = max;
            onChange(max);
        } else {
            onChange(value);
        }

        const rangeInput = document.getElementById(`${idKey}_range`);
        rangeInput.value = e.target.value;
        rangeInput.dispatchEvent(new Event("blur", { bubbles: true }));
    }

    nudge(direction) {
        const { step, min, max, idKey, onChange } = this.props;
        clearTimeout(this.nudgeChange);

        const textInput = document.getElementById(`${idKey}_text`);

        let change = step * direction;
        let newValue = parseFloat(textInput.value) + change;

        if (newValue < min) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        }

        let decimals = step.toString().split(".");
        if (decimals.length > 1)
            newValue = newValue.toFixed(decimals[1].length);

        textInput.value = newValue;

        const rangeInput = document.getElementById(`${idKey}_range`);
        rangeInput.value = newValue;
        rangeInput.dispatchEvent(new Event("blur", { bubbles: true }));

        this.nudgeChange = setTimeout(() => {
            textInput.dispatchEvent(new Event("blur", { bubbles: true }));
            onChange(newValue);
        }, NUDGE_TIMEOUT);
    }

    render() {
        const { defaultValue, min, max, idKey } = this.props;

        return (
            <>
                <input
                    className="linked-range-input"
                    type="number"
                    defaultValue={defaultValue}
                    min={min}
                    max={max}
                    onChange={this.onInputChange.bind(this)}
                    onBlur={this.onInputBlur.bind(this)}
                    id={`${idKey}_text`}
                />
                <div className="nudgers">
                    <div className="up" onClick={this.nudge.bind(this, 1)} />
                    <div className="down" onClick={this.nudge.bind(this, -1)} />
                </div>
            </>
        );
    }
}

export default LinkedRangeInput;
