import React from "react";

import "./FieldCard.scss";
import InfoModal from "../info-modal/InfoModal";
import content from "../../lookup/en/content";

class FieldCard extends React.Component {
    state = {
        infoModalOpen: false
    };

    toggleInfoModal() {
        this.setState({
            infoModalOpen: !this.state.infoModalOpen
        });
    }

    onUnitToggle() {
        const {
            defaultUnit,
            alternativeUnit,
            currentUnit,
            changeUnit
        } = this.props;

        let unit = currentUnit === defaultUnit ? alternativeUnit : defaultUnit;
        changeUnit(unit);
    }

    render() {
        const {
            cardTitle,
            cardDescription,
            helpTitle,
            helpText,
            defaultUnit,
            alternativeUnit,
            currentUnit,
            children
        } = this.props;

        const { infoModalOpen } = this.state;

        return (
            <fieldset
                className={
                    infoModalOpen ? "field-card open-modal" : "field-card"
                }
            >
                <header>
                    <div>
                        <h4>{cardTitle}</h4>
                        {cardDescription && <p>{cardDescription}</p>}
                    </div>
                    {helpText && (
                        <span
                            className="help"
                            onClick={this.toggleInfoModal.bind(this)}
                        >
                            ?
                        </span>
                    )}
                    {helpText && (
                        <InfoModal
                            title={helpTitle}
                            content={helpText}
                            open={infoModalOpen}
                            closeModal={this.toggleInfoModal.bind(this)}
                        />
                    )}
                    {alternativeUnit && (
                        <span
                            className={
                                currentUnit === alternativeUnit
                                    ? "unit-switcher on"
                                    : "unit-switcher off"
                            }
                            onClick={this.onUnitToggle.bind(this)}
                        >
                            {content.Steps.SwitchUnit}{" "}
                            {currentUnit === defaultUnit
                                ? alternativeUnit
                                : defaultUnit}
                        </span>
                    )}
                </header>
                <article>{children}</article>
            </fieldset>
        );
    }
}

export default FieldCard;
