import React from "react";
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs
} from "react-google-maps";

const DEFAULT_LAT = 51.476852;
const DEFAULT_LNG = 0;

const PharmacyMap = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: props.latCenter, lng: props.lngCenter }}
            center={{
                lat: props.latCenter || DEFAULT_LAT,
                lng: props.lngCenter || DEFAULT_LNG
            }}
        >
            {props.markers.map((marker) => {
                let scaledSize = {
                    width: 23,
                    height: 28
                };

                if (marker.Postcode === props.chosenPharmacy.Postcode) {
                    scaledSize.width *= 1.5;
                    scaledSize.height *= 1.5;
                }

                return (
                    <Marker
                        position={{
                            lat: marker.Latitude,
                            lng: marker.Longitude
                        }}
                        key={marker.Address1}
                        icon={{
                            url: `${process.env.PUBLIC_URL}/assets/pin.svg`,
                            scaledSize
                        }}
                        onClick={props.markerClick.bind(this, marker)}
                    />
                );
            })}
        </GoogleMap>
    ))
);

export default PharmacyMap;
