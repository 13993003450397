import React from "react";

import "./MedicalConditionsStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import content from "../../../lookup/en/content";
import { relevantMedicalConditions } from "../../../lookup/en/formValues";

class MedicalConditionsStep extends React.Component {
    componentDidMount() {
        const { setMedicalConditionsAction, setNoMedicalConditionsAction } =
            this.props;
        const defaultValue =
            JSON.parse(localStorage.getItem("medicalConditions")) || [];
        setMedicalConditionsAction(defaultValue);

        if (
            localStorage.getItem("noMedicalConditions") &&
            localStorage.getItem("noMedicalConditions") !== "False"
        )
            setNoMedicalConditionsAction();
    }

    onInputChange(value) {
        const { medicalConditions, setMedicalConditionsAction, updateRisk } =
            this.props;

        const index = medicalConditions.indexOf(value);
        let apiValue;
        if (index === -1) {
            medicalConditions.push(value);
            apiValue = true;
        } else {
            medicalConditions.splice(index, 1);
            apiValue = false;
        }
        setMedicalConditionsAction(medicalConditions);
        updateRisk(value, apiValue);
        localStorage.setItem(
            "medicalConditions",
            JSON.stringify(medicalConditions)
        );
        localStorage.removeItem("noMedicalConditions");
    }

    selectNoneOfThese() {
        const {
            setMedicalConditionsAction,
            setNoMedicalConditionsAction,
            updateRisk
        } = this.props;

        setMedicalConditionsAction([]);
        setNoMedicalConditionsAction();
        updateRisk("medicalConditions", null);
        localStorage.setItem("medicalConditions", JSON.stringify([]));
        localStorage.setItem("noMedicalConditions", true);
    }

    render() {
        const { activeStep, medicalConditions, gender, noMedicalConditions } =
            this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={7}>
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                <section className="medical-conditions-step">
                    <FieldCard
                        cardTitle={content.Steps.MedicalConditions.Title}
                    >
                        <div className="medical-conditions-items">
                            {relevantMedicalConditions.map((mc) => (
                                <OptionToggle
                                    selected={
                                        medicalConditions.indexOf(mc.key) > -1
                                    }
                                    disabled={
                                        mc.key === "Erectiledisfunction" &&
                                        parseInt(gender) === 2
                                    }
                                    select={this.onInputChange.bind(
                                        this,
                                        mc.key
                                    )}
                                    secondaryClass="medical-conditions-item"
                                    content={mc.description}
                                    helpTitle={mc.description}
                                    helpText={mc.helpText ? mc.helpText : ""}
                                    key={mc.key}
                                />
                            ))}
                            <OptionToggle
                                selected={noMedicalConditions}
                                select={this.selectNoneOfThese.bind(this)}
                                secondaryClass="medical-conditions-item no-medical-conditions"
                                content={content.Steps.MedicalConditions.None}
                                key="no-medical-conditions"
                            />
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default MedicalConditionsStep;
