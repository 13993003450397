import React from "react";

import "./AgeGenderStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import RangeGroup from "../../range-group/RangeGroup";
import content from "../../../lookup/en/content";

const MIN_AGE = 40;
const MAX_AGE = 74;

class AgeGenderStep extends React.Component {
    state = {
        initialAgeValue: MIN_AGE,
        gender: null
    };

    componentWillMount() {
        const { setAgeAction, setGenderAction } = this.props;

        let defaultAgeValue = parseInt(localStorage.getItem("age"));

        if (!defaultAgeValue) {
            const today = new Date();
            const verifiedDob = JSON.parse(
                localStorage.getItem("userVerification")
            );

            let age = today.getFullYear() - parseInt(verifiedDob.yearOfBirth);
            if (
                today.getMonth() + 1 < parseInt(verifiedDob.monthOfBirth) ||
                (today.getMonth() + 1 === parseInt(verifiedDob.monthOfBirth) &&
                    today.getDate() < parseInt(verifiedDob.dayOfBirth))
            ) {
                age--;
            }

            defaultAgeValue = age;
            localStorage.setItem("age", age);
        }

        setAgeAction(defaultAgeValue);

        const defaultGender = localStorage.getItem("gender");
        setGenderAction(defaultGender);

        this.setState({
            initialAgeValue: defaultAgeValue || MIN_AGE
        });
    }

    pushAgeChangeToState(value) {
        const { setAgeAction, updateRisk } = this.props;
        setAgeAction(value);
        updateRisk("Age", value);
        localStorage.setItem("age", value);
    }

    chooseGender(gender) {
        const { setGenderAction, updateRisk } = this.props;
        setGenderAction(gender);
        updateRisk("Sex", gender);
        localStorage.setItem("gender", gender);
    }

    render() {
        const { activeStep, age, gender, ethnicity } = this.props;

        const ethnicityId = ethnicity ? ethnicity : 0;

        return (
            <TestStep activeStep={activeStep} stepIndex={4} skipNext={age < 65}>
                <SectionHeader>{content.Header.AboutYou}</SectionHeader>
                <section className="age-gender-step">
                    <FieldCard cardTitle={content.Steps.Age.Title}>
                        <h3>{age}</h3>
                        {/* <RangeGroup
                            min={MIN_AGE}
                            max={MAX_AGE}
                            step={1}
                            onChange={this.pushAgeChangeToState.bind(this)}
                            defaultValue={age}
                            initialValue={this.state.initialAgeValue}
                            idKey="age"
                        /> */}
                    </FieldCard>

                    <FieldCard
                        cardTitle={content.Steps.Gender.Title}
                        helpTitle={content.Steps.Gender.HelpTitle}
                        helpText={content.Steps.Gender.HelpText}
                    >
                        <div className="gender-step">
                            <div
                                className="gender-selector"
                                onClick={this.chooseGender.bind(this, "1")}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/ethnicity/male_${ethnicityId}.png`}
                                    alt={content.Steps.Gender.Male}
                                    className={
                                        gender === "1"
                                            ? "male selected"
                                            : "male"
                                    }
                                />
                                <p>{content.Steps.Gender.Male}</p>
                            </div>

                            <div
                                className="gender-selector"
                                onClick={this.chooseGender.bind(this, "2")}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/ethnicity/female_${ethnicityId}.png`}
                                    alt={content.Steps.Gender.Female}
                                    className={
                                        gender === "2"
                                            ? "female selected"
                                            : "female"
                                    }
                                />
                                <p>{content.Steps.Gender.Female}</p>
                            </div>
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default AgeGenderStep;
