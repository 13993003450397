import React, { Component } from "react";
import axios from "axios";

import "./Cookies.scss";
import { connect } from "react-redux";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { PAGE_IDS } from "../../lookup/api";

class Cookies extends Component {
    state = {
        content: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.COOKIES);

        axios.get(`${process.env.PUBLIC_URL}/cookies.html`).then(({ data }) => {
            this.setState({
                content: data
            });
        });
    }

    render() {
        return (
            <div className="cookies-container">
                <h1>Cookies</h1>
                <section
                    dangerouslySetInnerHTML={{
                        __html: this.state.content
                    }}
                />
            </div>
        );
    }
}

export default connect((state) => ({}), {
    trackPageViewAction
})(Cookies);
