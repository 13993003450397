import React, { Component } from "react";
import content from "../../lookup/en/content";

import "./QuestionnaireLink.scss";

class QuestionnaireLink extends Component {
    state = {
        sticky: false
    };

    componentDidMount() {
        const component = this;
        document.getElementById("root").onscroll = function () {
            component.setState({
                sticky: document.getElementById("root").scrollTop > 100
            });
        };
    }

    render() {
        const { sticky } = this.state;
        return (
            <div
                className={
                    sticky ? "questionnaire-link sticky" : "questionnaire-link"
                }
            >
                <section>
                    {content.Questionnaire.TitleForPostTest}
                    {/*{sticky
                        ? content.Questionnaire.TitleSmall
                        : content.Questionnaire.Title}
                     <a
                        href="https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fstaffordshire.qualtrics.com%2Fjfe%2Fform%2FSV_3CTKiUFcATwhYYm&data=04%7C01%7CAlicia.Nolan%40dhsc.gov.uk%7C78eb510de2a64da1bff708da10bac428%7C61278c3091a84c318c1fef4de8973a1c%7C1%7C0%7C637840691649193455%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=xzSl5rtwN6EOKhXDdzYhA5zmV2H4fUzpblrrklKp9Hg%3D&reserved=0"
                        class="button"
                        target="_blank"
                    >
                        {content.Questionnaire.Button}
                    </a> */}
                </section>
            </div>
        );
    }
}

export default QuestionnaireLink;
