import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./HowToReturn.scss";
import content from "../../lookup/en/content";
import Button from "../../components/button/Button";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { PAGE_IDS } from "../../lookup/api";

class HowToReturn extends React.Component {
    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.HOW_TO_RETURN);
    }

    render() {
        const { auth } = this.props;

        let link;
        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        ) {
            link = "/verification";
        } else {
            link = "/what-happens";
        }

        return (
            <div className="how-to-return">
                <div className="content">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/key.png`}
                        alt={content.HowToReturn.Title}
                    />
                    <h2>{content.HowToReturn.Title}</h2>
                    <>{content.HowToReturn.Content}</>
                    <Button href={link}>{content.HowToReturn.Return}</Button>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth
    }),
    {
        trackPageViewAction
    }
)(HowToReturn);
