import React from "react";

import "./PromoModal.scss";
import Button from "../button/Button";

class PromoModal extends React.Component {
    state = {
        iframeVisible: false
    };

    close() {
        const { closeModal } = this.props;
        closeModal();
    }

    openIframe() {
        const { history, linkHref } = this.props;
        this.setState({
            iframeVisible: true
        });

        setTimeout(() => {
            history.push(linkHref);
        }, 650);
    }

    render() {
        const {
            title,
            content,
            open,
            linkText,
            linkHref,
            showInFrame
        } = this.props;
        const { iframeVisible } = this.state;

        return (
            <>
                <div className={open ? "promo-modal open" : "promo-modal"}>
                    <div className="modal">
                        <span
                            className="close-modal"
                            onClick={this.close.bind(this)}
                        >
                            &#10005;
                        </span>
                        <h2>{title}</h2>
                        <div className="content">{content}</div>
                        {showInFrame && (
                            <Button
                                isPrimary
                                onClick={this.openIframe.bind(this)}
                            >
                                {linkText}
                            </Button>
                        )}
                        {!showInFrame && linkHref && (
                            <Button isExternal isPrimary href={linkHref}>
                                {linkText}
                            </Button>
                        )}
                    </div>
                </div>
                {showInFrame && (
                    <div
                        className={
                            iframeVisible
                                ? "promo-modal-frame visible"
                                : "promo-modal-frame"
                        }
                    >
                        <iframe src={linkHref} title={linkText} />
                    </div>
                )}
            </>
        );
    }
}

export default PromoModal;
