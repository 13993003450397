import React, { Component } from "react";

import "./RangeGroup.scss";

import LinkedRangeInput from "../linked-range-input/LinkedRangeInput";
import Range from "../range/Range";

class RangeGroup extends Component {
    state = {
        lookupValue: ""
    };

    componentDidMount() {
        const { lookup, initialValue } = this.props;

        if (lookup) {
            let index = initialValue;
            if (initialValue === null) {
                index = 0;
            }
            this.setState({
                lookupValue: lookup[index]
            });
        }
    }

    onChange(value) {
        const { lookup, onChange } = this.props;

        if (lookup)
            this.setState({
                lookupValue: lookup[value]
            });

        onChange(value);
    }

    render() {
        const {
            defaultValue,
            initialValue,
            min,
            max,
            fixedPoints,
            idKey,
            step,
            small,
            lookup,
            notches
        } = this.props;
        return (
            <div className={small ? "range-group small" : "range-group"}>
                {!lookup && (
                    <LinkedRangeInput
                        defaultValue={defaultValue}
                        min={min}
                        max={max}
                        fixedPoints={fixedPoints}
                        onChange={this.onChange.bind(this)}
                        step={step}
                        idKey={idKey}
                    />
                )}

                {lookup && (
                    <p className="range-lookup-label">
                        {this.state.lookupValue}
                    </p>
                )}

                <Range
                    initialValue={initialValue}
                    min={lookup ? 0 : min}
                    max={lookup ? lookup.length - 1 : max}
                    step={lookup ? 1 : step}
                    onChange={this.onChange.bind(this)}
                    idKey={idKey}
                    lookup={lookup}
                    notches={notches}
                />
            </div>
        );
    }
}

export default RangeGroup;
