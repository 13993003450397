import React from "react";

import "./EthnicityStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import content from "../../../lookup/en/content";

import { ethnicityMap } from "../../../lookup/en/formValues";

class EthnicityStep extends React.Component {
    // This may be replaced with an API call
    componentDidMount() {
        const { setEthnicityAction } = this.props;
        const defaultValue = localStorage.getItem("ethnicity");
        setEthnicityAction(defaultValue);
    }

    onInputChange(value) {
        const { setEthnicityAction, updateRisk } = this.props;
        setEthnicityAction(value);
        updateRisk("EthnicBackground", value);
        localStorage.setItem("ethnicity", value);
    }

    render() {
        const { activeStep, ethnicity } = this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={1}>
                <SectionHeader>{content.Header.AboutYou}</SectionHeader>
                <section className="ethnicity-step">
                    <FieldCard
                        cardTitle={content.Steps.Ethnicity.Title}
                        helpTitle={content.Steps.Ethnicity.HelpTitle}
                        helpText={content.Steps.Ethnicity.HelpText}
                    >
                        <div className="ethnicity-items">
                            {ethnicityMap
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((e) => {
                                    if (e.hyphenated) {
                                        return (
                                            <React.Fragment key={e.id}>
                                                <OptionToggle
                                                    selected={
                                                        parseInt(ethnicity) ===
                                                        e.id
                                                    }
                                                    select={this.onInputChange.bind(
                                                        this,
                                                        e.id
                                                    )}
                                                    secondaryClass="ethnicity-item desktop-only"
                                                    content={e.name}
                                                    key={`${e.id}-desktop`}
                                                />
                                                <OptionToggle
                                                    selected={
                                                        parseInt(ethnicity) ===
                                                        e.id
                                                    }
                                                    select={this.onInputChange.bind(
                                                        this,
                                                        e.id
                                                    )}
                                                    secondaryClass="ethnicity-item small-devices-only"
                                                    content={e.hyphenated}
                                                    key={`${e.id}-mobile`}
                                                />
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <OptionToggle
                                                selected={
                                                    parseInt(ethnicity) === e.id
                                                }
                                                select={this.onInputChange.bind(
                                                    this,
                                                    e.id
                                                )}
                                                secondaryClass="ethnicity-item"
                                                content={e.name}
                                                key={e.id}
                                            />
                                        );
                                    }
                                })}
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default EthnicityStep;
