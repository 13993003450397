import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./containers/home/Home";
import Key from "./containers/key/Key";
import Test from "./containers/test/Test";
import NotFound from "./containers/not-found/NotFound";
import Summary from "./containers/summary/Summary";
import FreeBloodTest from "./containers/free-blood-test/FreeBloodTest";
import RequiredBloodTest from "./containers/required-blood-test/RequiredBloodTest";
import BloodPressureTests from "./containers/blood-pressure-tests/BloodPressureTests";
import About from "./containers/about/About";
import TestOrderPlaced from "./containers/test-order-placed/TestOrderPlaced";
import HeartHealthAdvice from "./containers/heart-health-advice/HeartHealthAdvice";
import Verification from "./containers/verification/Verification";
import PinAuth from "./containers/pin-auth/PinAuth";
import Preferences from "./containers/preferences/Preferences";
import BloodPressureKit from "./containers/blood-pressure-kit/BloodPressureKit";
import BloodPressureKitOrderPlaced from "./containers/bp-kit-order-placed/BloodPressureKitOrderPlaced";
import HealthyLivingAdvice from "./containers/healthy-living-advice/HealthyLivingAdvice";
import TermsAndConditions from "./containers/terms-and-conditions/TermsAndConditions";
import Disclaimers from "./containers/disclaimers/Disclaimers";
import About2 from "./containers/about-2/About2";
import Cookies from "./containers/cookies/Cookies";
import Contact from "./containers/contact/Contact";
import Help from "./containers/help/Help";
import Accessibility from "./containers/accessibility/Accessibility";
import RequiredTestOrderPlaced from "./containers/required-test-order-placed/RequiredTestOrderPlaced";
import Checklist from "./containers/checklist/Checklist";
import HowToReturn from "./containers/how-to-return/HowToReturn";
import WhatHappens from "./containers/what-happens/WhatHappens";
import AccessibleTest from "./containers/accessibile-test/AccessibleTest";
import Instructions from "./containers/instructions/Instructions";
import FAQS from "./containers/faqs/FAQS";
import Inactive from "./containers/inactive/Inactive";
import ContactDetails from "./containers/contact-details/ContactDetails";

export default function Routes() {
    return (
        <Switch>
            <Route
                path={`${process.env.PUBLIC_URL}/`}
                exact
                component={Home}
                key="home-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/key/:key`}
                component={Key}
                key="key-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/preferences`}
                component={Preferences}
                key="preferences-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/verification`}
                component={Verification}
                key="verification-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/pin`}
                component={PinAuth}
                key="pin-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/contact-details`}
                component={ContactDetails}
                key="contact-details-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/checklist`}
                component={Checklist}
                key="checklist-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/how-to-return`}
                component={HowToReturn}
                key="how-to-return-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/what-happens`}
                component={WhatHappens}
                key="what-happens-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/test/:step`}
                component={Test}
                key="test-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/accessible-test`}
                component={AccessibleTest}
                key="accessible-test-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/test-summary`}
                component={Summary}
                key="summary-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/free-blood-test`}
                component={FreeBloodTest}
                key="free-test-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/blood-test`}
                component={RequiredBloodTest}
                key="required-test-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/loan-blood-pressure-kit`}
                component={BloodPressureKit}
                key="bp-kit-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/required-test-order-placed`}
                component={RequiredTestOrderPlaced}
                key="required-test-order-placed"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/test-order-placed`}
                component={TestOrderPlaced}
                key="test-order-placed"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/bp-kit-order-placed`}
                component={BloodPressureKitOrderPlaced}
                key="bp-kit-order-placed"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/blood-pressure-tests`}
                component={BloodPressureTests}
                key="blood-pressure-test-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/what-is-nhs-health-check`}
                component={About}
                key="about-what-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/heart-health-advice`}
                component={HeartHealthAdvice}
                key="heart-health-advice-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/advice`}
                component={HealthyLivingAdvice}
                key="healthy-living-advice-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/about`}
                component={About2}
                key="about2-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/terms`}
                component={TermsAndConditions}
                key="terms-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/accessibility`}
                component={Accessibility}
                key="accessibility-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/privacy`}
                component={Disclaimers}
                key="disclaimers-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/faqs`}
                component={FAQS}
                key="faqs-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/cookies`}
                component={Cookies}
                key="cookies-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/help`}
                component={Help}
                key="help-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/contact`}
                component={Contact}
                key="contact-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/instructions`}
                component={Instructions}
                key="contact-route"
            />
            <Route
                path={`${process.env.PUBLIC_URL}/inactive`}
                component={Inactive}
                key="inactive-user-route"
            />
            <Route component={NotFound} key="not-found-route" />
        </Switch>
    );
}
