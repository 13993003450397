import React, { Component } from "react";
import axios from "axios";

import "./Accessibility.scss";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { PAGE_IDS } from "../../lookup/api";
import { connect } from "react-redux";

class Accessibility extends Component {
    state = {
        content: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.ACCESSIBILITY);
        axios
            .get(`${process.env.PUBLIC_URL}/accessibility.html`)
            .then(({ data }) => {
                this.setState({
                    content: data
                });
            });
    }

    render() {
        return (
            <div className="accessibility-container">
                <h1>Accessibility terms</h1>
                <section
                    dangerouslySetInnerHTML={{
                        __html: this.state.content
                    }}
                />
            </div>
        );
    }
}

export default connect((state) => ({}), {
    trackPageViewAction
})(Accessibility);
