import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import {
    setPinValidAction,
    setPinInvalidAction,
    setPinAction,
    setNoPinAction,
    clearRedirectAction
} from "../../actions/auth/authActions";
import Button from "../../components/button/Button";
import { PAGE_IDS } from "../../lookup/api";

import content from "../../lookup/en/content";
import "./PinAuth.scss";

class PinAuth extends Component {
    state = {
        PIN: "",
        ConfirmPIN: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.ENTER_PIN);
    }

    onInputChange(e) {
        const input = e.target.name;
        this.setState({
            [input]: e.target.value
        });
    }

    onSubmit() {
        const { PIN, ConfirmPIN } = this.state;

        const {
            setPinValidAction,
            setPinInvalidAction,
            setPinAction,
            setNoPinAction,
            auth
        } = this.props;

        if (PIN !== "") {
            if (
                PIN.length !== 4 ||
                isNaN(parseInt(PIN)) ||
                PIN !== ConfirmPIN
            ) {
                setPinInvalidAction();
            } else {
                setPinValidAction();
                setPinAction(PIN, auth.token);
            }
        } else {
            setNoPinAction();
        }
    }

    render() {
        const { auth, userStatus } = this.props;

        const testStatus = localStorage.getItem("userMMHStatus");

        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        )
            return <Redirect to={`${process.env.PUBLIC_URL}/verification`} />;

        if (auth.pinRedirect)
            return (
                <Redirect to={`${process.env.PUBLIC_URL}/contact-details`} />
            );

        return (
            <div className="pin-container">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/pin.png`}
                    alt={content.Verification.AddPINTitle}
                />
                <h2>{content.Verification.AddPINTitle}</h2>
                {auth.pinInvalid && (
                    <p className="verification-error">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/alert.png`}
                            alt={content.Verification.InvalidPIN}
                        />
                        {content.Verification.InvalidPIN}
                    </p>
                )}
                <>{content.Verification.AddPIN}</>
                <form>
                    <label>{content.Verification.PIN}</label>
                    <input
                        type="password"
                        name="PIN"
                        onChange={this.onInputChange.bind(this)}
                        onBlur={this.onInputChange.bind(this)}
                    />
                    <label>{content.Verification.PINConfirm}</label>
                    <input
                        type="password"
                        name="ConfirmPIN"
                        onChange={this.onInputChange.bind(this)}
                        onBlur={this.onInputChange.bind(this)}
                    />

                    <Button onClick={this.onSubmit.bind(this)}>
                        {content.Steps.Next}
                    </Button>
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        userStatus: state.form.userStatus
    }),
    {
        setPinValidAction,
        setPinInvalidAction,
        setPinAction,
        setNoPinAction,
        clearRedirectAction,
        trackPageViewAction
    }
)(PinAuth);
