import React, { Component } from "react";

import TestStep from "../../test-step/TestStep";
import FieldCard from "../../field-card/FieldCard";
import RangeGroup from "../../range-group/RangeGroup";
import OptionToggle from "../../option-toggle/OptionToggle";

import content from "../../../lookup/en/content";
import {
    exerciseOptions,
    newexerciseOptions,
    newwalkingPaceOptions,
    physicalWorkDefinitions,
    walkingPaceOptions
} from "../../../lookup/en/formValues";
import "./PhysicalActivityStep.scss";

class PhysicalActivityStep extends Component {
    state = {
        initialActivityHoursExercise: null,
        initialPhysicalWorkDefinition: null
    };

    componentWillMount() {
        const {
            setActivityHoursCyclingAction,
            setActivityHoursWalkingAction,
            setActivityHoursExerciseAction,
            setActivityHoursHouseworkChildcareAction,
            setActivityHoursGardeningDIYAction,
            setWalkingPaceAction,
            setPhysicalWorkDefinitionAction
        } = this.props;

        const defaultActivityHoursCycling =
            localStorage.getItem("activityHoursCycling") || 0;
        const defaultActivityHoursWalking =
            localStorage.getItem("activityHoursWalking") || 0;
        const defaultActivityHoursExercise =
            localStorage.getItem("activityHoursExercise") || 0;
        const defaultActivityHoursHouseworkChildcare =
            localStorage.getItem("activityHoursHouseworkChildcare") || 0;
        const defaultActivityHoursGardeningDIY =
            localStorage.getItem("activityHoursGardeningDIY") || 0;
        const defaultWalkingPace = localStorage.getItem("walkingPace") || 0;
        const defaultPhysicalWorkDefinition =
            localStorage.getItem("physicalWork") || null;

        setActivityHoursCyclingAction(defaultActivityHoursCycling);
        setActivityHoursWalkingAction(defaultActivityHoursWalking);
        setActivityHoursExerciseAction(defaultActivityHoursExercise);
        setActivityHoursHouseworkChildcareAction(
            defaultActivityHoursHouseworkChildcare
        );
        setActivityHoursGardeningDIYAction(defaultActivityHoursGardeningDIY);
        setPhysicalWorkDefinitionAction(defaultPhysicalWorkDefinition);
        setWalkingPaceAction(defaultWalkingPace);

        this.setState({
            initialActivityHoursCycling: defaultActivityHoursCycling,
            initialActivityHoursWalking: defaultActivityHoursWalking,
            initialActivityHoursExercise: defaultActivityHoursExercise,
            initialActivityHoursHouseworkChildcare:
                defaultActivityHoursHouseworkChildcare,
            initialActivityHoursGardeningDIY: defaultActivityHoursGardeningDIY,
            initialPhysicalWorkDefinition: defaultPhysicalWorkDefinition,
            initialWalkingPace: defaultWalkingPace
        });
    }

    onActivityHoursCyclingChange(key) {
        const { setActivityHoursCyclingAction, updateRisk } = this.props;

        setActivityHoursCyclingAction(key);
        updateRisk("PhysicalActivityHoursCycling", key);
        localStorage.setItem("activityHoursCycling", key);
    }

    onWorkDefinitionChange(key) {
        const { setPhysicalWorkDefinitionAction, updateRisk } = this.props;

        setPhysicalWorkDefinitionAction(key);
        updateRisk("PhysicalActivityWork", key);
        localStorage.setItem("physicalWork", key);
    }

    onActivityHoursWalkingChange(value) {
        const { setActivityHoursWalkingAction, updateRisk } = this.props;

        setActivityHoursWalkingAction(value);
        updateRisk("PhysicalActivityHoursWalking", value);
        localStorage.setItem("activityHoursWalking", value);
    }

    onActivityHoursExerciseChange(value) {
        const { setActivityHoursExerciseAction, updateRisk } = this.props;

        setActivityHoursExerciseAction(value);
        updateRisk("PhysicalActivityHours", value);
        localStorage.setItem("activityHoursExercise", value);
    }

    onActivityHoursHouseworkChildcareChange(value) {
        const { setActivityHoursHouseworkChildcareAction, updateRisk } =
            this.props;

        setActivityHoursHouseworkChildcareAction(value);
        updateRisk("PhysicalActivityHoursHouseworkChildcare", value);
        localStorage.setItem("activityHoursHouseworkChildcare", value);
    }

    onActivityHoursGardeningDIYChange(value) {
        const { setActivityHoursGardeningDIYAction, updateRisk } = this.props;

        setActivityHoursGardeningDIYAction(value);
        updateRisk("PhysicalActivityHoursGardeningDIY", value);
        localStorage.setItem("activityHoursGardeningDIY", value);
    }

    onWalkingPaceChange(value) {
        const { setWalkingPaceAction, updateRisk } = this.props;

        setWalkingPaceAction(value);
        updateRisk("WalkingPace", value);
        localStorage.setItem("walkingPace", value);
    }

    render() {
        const {
            activeStep,
            activityHoursCycling,
            activityHoursWalking,
            activityHoursExercise,
            activityHoursHouseworkChildcare,
            activityHoursGardeningDIY,
            walkingPace,
            physicalWork
        } = this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={10} alignTop>
                <section className="physical-activity-step">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/yoga.png`}
                        alt={content.Steps.PhysicalActivity.Title}
                    />
                    <FieldCard cardTitle={content.Steps.PhysicalActivity.Title}>
                        <p>
                            <strong>
                                {content.Steps.PhysicalActivity.PhysicalWork}
                            </strong>
                        </p>
                        <div className="physical-activity-items">
                            {physicalWorkDefinitions.map((d) => (
                                <OptionToggle
                                    selected={physicalWork === d.key}
                                    select={this.onWorkDefinitionChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>{content.Steps.PhysicalActivity.HoursCycling}</p>
                        <div className="physical-activity-items">
                            {newexerciseOptions.map((d) => (
                                <OptionToggle
                                    selected={activityHoursCycling === d.key}
                                    select={this.onActivityHoursCyclingChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>{content.Steps.PhysicalActivity.HoursWalking}</p>
                        <div className="physical-activity-items">
                            {newexerciseOptions.map((d) => (
                                <OptionToggle
                                    selected={activityHoursWalking === d.key}
                                    select={this.onActivityHoursWalkingChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>{content.Steps.PhysicalActivity.HoursExercise}</p>
                        <div className="physical-activity-items">
                            {newexerciseOptions.map((d) => (
                                <OptionToggle
                                    selected={activityHoursExercise === d.key}
                                    select={this.onActivityHoursExerciseChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>
                            {
                                content.Steps.PhysicalActivity
                                    .HoursHouseworkChildcare
                            }
                        </p>
                        <div className="physical-activity-items">
                            {newexerciseOptions.map((d) => (
                                <OptionToggle
                                    selected={
                                        activityHoursHouseworkChildcare ===
                                        d.key
                                    }
                                    select={this.onActivityHoursHouseworkChildcareChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>
                            {content.Steps.PhysicalActivity.HoursGardeningDIY}
                        </p>
                        <div className="physical-activity-items">
                            {newexerciseOptions.map((d) => (
                                <OptionToggle
                                    selected={
                                        activityHoursGardeningDIY === d.key
                                    }
                                    select={this.onActivityHoursGardeningDIYChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>{content.Steps.PhysicalActivity.WalkingPace}</p>

                        <div className="physical-activity-items">
                            {newwalkingPaceOptions.map((d) => (
                                <OptionToggle
                                    selected={walkingPace === d.key}
                                    select={this.onWalkingPaceChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="physical-activity-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default PhysicalActivityStep;
