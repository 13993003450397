import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import {
    saveContactDetailsAction,
    setNoContactDetailsAction
} from "../../actions/auth/authActions";
import Button from "../../components/button/Button";
import { PAGE_IDS } from "../../lookup/api";

import content from "../../lookup/en/content";
import "./ContactDetails.scss";

const MOBILE_REGEX = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

class ContactDetails extends Component {
    state = {
        telephone: "",
        telephoneError: false,
        email: "",
        emailError: false
    };

    componentWillMount() {
        const { trackPageViewAction, auth } = this.props;

        trackPageViewAction(PAGE_IDS.CONTACT_DETAILS);
        this.setState({
            telephone: auth.contactDetails.telephone,
            email: auth.contactDetails.email
        });
    }

    onInputChange(e) {
        const input = e.target.name;
        this.setState({
            [input]: e.target.value,
            [`${input}Error`]: false
        });
    }

    onSubmit() {
        const { telephone, email } = this.state;

        const { saveContactDetailsAction, setNoContactDetailsAction, auth } =
            this.props;

        if (telephone !== "" || email !== "") {
            let valid = true;
            if (telephone !== "" && !MOBILE_REGEX.test(telephone)) {
                this.setState({ telephoneError: true });
                valid = false;
            }
            if (email !== "" && !EMAIL_REGEX.test(email)) {
                this.setState({ emailError: true });
                valid = false;
            }

            if (!valid) return;
            saveContactDetailsAction({ telephone, email }, auth.token);
        } else {
            setNoContactDetailsAction();
        }
    }

    render() {
        const { auth, userStatus } = this.props;

        const testStatus = localStorage.getItem("userMMHStatus");

        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        )
            return <Redirect to={`${process.env.PUBLIC_URL}/verification`} />;

        if (auth.contactDetailsRedirect)
            if (
                parseInt(userStatus) === 9 ||
                (parseInt(userStatus) > 4 && parseInt(testStatus) > 5)
            )
                return (
                    <Redirect to={`${process.env.PUBLIC_URL}/test-summary`} />
                );
            else return <Redirect to={`${process.env.PUBLIC_URL}/checklist`} />;

        return (
            <div className="contact-details-container">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/contact.png`}
                    alt={content.Verification.ContactDetails.Title}
                />
                <h2>{content.Verification.ContactDetails.Title}</h2>

                <>{content.Verification.ContactDetails.Description}</>
                <form>
                    <label>
                        {content.Verification.ContactDetails.Telephone}
                    </label>
                    <input
                        type="tel"
                        name="telephone"
                        defaultValue={auth.contactDetails.telephone}
                        onChange={this.onInputChange.bind(this)}
                        onBlur={this.onInputChange.bind(this)}
                        className={this.state.telephoneError ? "error" : ""}
                    />
                    {this.state.telephoneError && (
                        <p className="error-message">
                            {content.Verification.ContactDetails.TelephoneError}
                        </p>
                    )}
                    <label>{content.Verification.ContactDetails.Email}</label>
                    <input
                        type="email"
                        name="email"
                        defaultValue={auth.contactDetails.email}
                        onChange={this.onInputChange.bind(this)}
                        onBlur={this.onInputChange.bind(this)}
                        className={this.state.emailError ? "error" : ""}
                    />
                    {this.state.emailError && (
                        <p className="error-message">
                            {content.Verification.ContactDetails.EmailError}
                        </p>
                    )}

                    <Button onClick={this.onSubmit.bind(this)}>
                        {content.Steps.Next}
                    </Button>
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        userStatus: state.form.userStatus
    }),
    {
        saveContactDetailsAction,
        setNoContactDetailsAction,
        trackPageViewAction
    }
)(ContactDetails);
