import React from "react";

import "./FamilyHistoryKidneyDiseaseStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import content from "../../../lookup/en/content";

class FamilyHistoryKidneyDiseaseStep extends React.Component {
    componentDidMount() {
        const { setFamilyHistoryAction, setKidneyDiseaseAction } = this.props;
        const defaultFamilyHistoryValue = localStorage.getItem("familyHistory");
        const defaultKidneyDiseaseValue = localStorage.getItem("kidneyDisease");
        setFamilyHistoryAction(defaultFamilyHistoryValue);
        setKidneyDiseaseAction(defaultKidneyDiseaseValue);
    }

    onFamilyHistoryChange(value) {
        const { setFamilyHistoryAction, updateRisk } = this.props;
        setFamilyHistoryAction(value);
        updateRisk("Familyheart", value);
        localStorage.setItem("familyHistory", value);
    }

    onKidneyDiseaseChange(value) {
        const { setKidneyDiseaseAction, updateRisk } = this.props;
        setKidneyDiseaseAction(value);
        updateRisk("Kidneydisease", value);
        localStorage.setItem("kidneyDisease", value);
    }

    render() {
        const { activeStep, familyHistory, kidneyDisease } = this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={6}>
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                <section className="family-history-kidney-disease-step">
                    <FieldCard
                        cardTitle={content.Steps.FamilyHistory.Title}
                        helpTitle={content.Steps.FamilyHistory.HelpTitle}
                        helpText={content.Steps.FamilyHistory.HelpText}
                    >
                        <div className="family-history-items">
                            <OptionToggle
                                selected={
                                    familyHistory &&
                                    familyHistory !== "False" &&
                                    familyHistory !== "false"
                                }
                                select={this.onFamilyHistoryChange.bind(
                                    this,
                                    true
                                )}
                                secondaryClass="family-history-item"
                                content={content.Steps.FamilyHistory.Yes}
                            />
                            <OptionToggle
                                selected={
                                    familyHistory === false ||
                                    familyHistory === "False" ||
                                    familyHistory === "false"
                                }
                                select={this.onFamilyHistoryChange.bind(
                                    this,
                                    false
                                )}
                                secondaryClass="family-history-item"
                                content={content.Steps.FamilyHistory.No}
                            />
                        </div>
                    </FieldCard>
                    <FieldCard
                        cardTitle={content.Steps.KidneyDisease.Title}
                        helpTitle={content.Steps.KidneyDisease.HelpTitle}
                        helpText={content.Steps.KidneyDisease.HelpText}
                    >
                        <div className="kidney-disease-items">
                            <OptionToggle
                                selected={
                                    kidneyDisease === false ||
                                    kidneyDisease === "False" ||
                                    kidneyDisease === "false"
                                }
                                select={this.onKidneyDiseaseChange.bind(
                                    this,
                                    false
                                )}
                                secondaryClass="kidney-disease-item"
                                content={content.Steps.KidneyDisease.No}
                            />
                            <OptionToggle
                                selected={
                                    kidneyDisease === true ||
                                    kidneyDisease === "True" ||
                                    kidneyDisease === "true"
                                }
                                select={this.onKidneyDiseaseChange.bind(
                                    this,
                                    true
                                )}
                                secondaryClass="kidney-disease-item"
                                content={content.Steps.KidneyDisease.Yes}
                            />
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default FamilyHistoryKidneyDiseaseStep;
