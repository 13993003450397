import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Swipe from "react-easy-swipe";

import Divider from "../../components/divider/Divider";
import Tutorial from "../../components/tutorial/Tutorial";
import content from "../../lookup/en/content";
import "./Test.scss";

import {
    setPostcodeAction,
    setAgeAction,
    setGenderAction,
    setEthnicityAction,
    setHeightUnitAction,
    setHeightInInchesAction,
    setHeightInFeetAction,
    setHeightInCmAction,
    setWeightUnitAction,
    setWeightInKgAction,
    setWeightInStoneAction,
    setWeightInLbsAction,
    setSmokingLevelAction,
    setDiabetesTypeAction,
    setFamilyHistoryAction,
    setKidneyDiseaseAction,
    setMedicalConditionsAction,
    setNoMedicalConditionsAction,
    setAtrialFibrillationAction,
    setSystolicBloodPressureReadingsAction,
    setDiastolicBloodPressureReadingsAction,
    setBloodPressureValidAction,
    setBloodPressureInvalidAction,
    setBloodPressureHighAction,
    setBloodPressureLowAction,
    setBloodPressureEmergencyAction,
    setBloodPressureNormalAction,
    setBloodPressureHighWarningReadAction,
    setBloodPressureHighWarningShowAction,
    requestMoreBPReadingsAction,
    setBloodPressureLocationAction,
    setPulseAction,
    setCholesterolStatusAction,
    setTotalCholesterolValueAction,
    setHDLCholesterolValueAction,
    setDiabetesInFamilyAction,
    setWaistMeasurementAction,
    setAlcoholConsumptionAction,
    setAlcoholUnitsAction,
    setBingeSessionsAction,
    setAdditionalAUDITAction,
    setActivityHoursCyclingAction,
    setActivityHoursWalkingAction,
    setActivityHoursExerciseAction,
    setActivityHoursHouseworkChildcareAction,
    setActivityHoursGardeningDIYAction,
    setWalkingPaceAction,
    setPhysicalWorkDefinitionAction,
    setVegetablesAction,
    setDietFoodsAction,
    setWeightClassAction,
    changeFormPartAction,
    completeFormPartChangeAction,
    checkMissingSectionsAction,
    checkMissingSectionsNotIncludingBPAction,
    setStatusAction,
    setRemoteBloodPressureAction,
    setRemoteBloodTestAction,
    setBoookingLinkAction
} from "../../actions/form/formActions";
import { refreshTokenAction } from "../../actions/auth/authActions";
import { mapForm, mapFormAndGetRisk } from "../../actions/risk/riskActions";
import {
    beginPostcodeValidationAction,
    postcodeLookupAction
} from "../../actions/address/addressActions";
import { setTutorialViewedAction } from "../../actions/header/headerActions";

import Risk from "../../components/risk/Risk";
import PostcodeStep from "../../components/steps/postcode-step/PostcodeStep";
import AgeGenderStep from "../../components/steps/age-gender-step/AgeGenderStep";
import NotFound from "../not-found/NotFound";
import EthnicityStep from "../../components/steps/ethnicity-step/EthnicityStep";
import HeightWeightStep from "../../components/steps/height-weight-step/HeightWeightStep";
import SmokingStep from "../../components/steps/smoking-step/SmokingStep";
import FamilyHistoryKidneyDiseaseStep from "../../components/steps/family-history-kidney-disease-step/FamilyHistoryKidneyDiseaseStep";
import MedicalConditionsStep from "../../components/steps/medical-conditions-step/MedicalConditionsStep";
import AtrialFibrilllationStep from "../../components/steps/atrial-fibrillation-step/AtrialFibrillationStep";
import BloodPressureStep from "../../components/steps/blood-pressure-step/BloodPressureStep";
import CholesterolStep from "../../components/steps/cholesterol-step/CholesterolStep";
import DiabetesStep from "../../components/steps/diabetes-step/DiabetesStep";
import AlcoholStep from "../../components/steps/alcohol-step/AlcoholStep";
import PhysicalActivityStep from "../../components/steps/physical-activity-step/PhysicalActivityStep";
import DementiaStep from "../../components/steps/dementia-step/DementiaStep";
import BPWarningStep from "../../components/steps/bp-warning-step/BPWarningStep";
import { checkAuditRequirements } from "../../util";

class Test extends React.Component {
    state = {
        swipeDirection: null,
        onSummary: false,
        redirect: false,
        redraw: false,
        mounted: false,
        returnedTo: false
    };

    partOneLastStep = 12;
    partTwoLastStep = 100;

    updateRisk(changedKey = null, changedValue = null) {
        const { form, auth, mapForm } = this.props;
        mapForm(
            auth.token || localStorage.getItem("token"),
            form,
            changedKey,
            changedValue
        );
    }

    onSwipeMove(position) {
        let xChange = position.x < 0 ? position.x * -1 : position.x;
        let yChange = position.y < 0 ? position.y * -1 : position.y;

        if (position.y < 0 && yChange > xChange && yChange > 100) {
            this.setState({
                swipeDirection: "up"
            });
        } else if (position.y > 0 && yChange > xChange && yChange > 100) {
            this.setState({
                swipeDirection: "down"
            });
        }
    }

    componentDidMount() {
        const {
            userStatus,
            checkMissingSectionsAction,
            checkMissingSectionsNotIncludingBPAction,
            setStatusAction
        } = this.props;
        checkMissingSectionsAction();
        checkMissingSectionsNotIncludingBPAction();

        const params = new URLSearchParams(window.location.search);
        const token = localStorage.getItem("token");
        if (!token) {
            window.location = "/";
        }

        if (userStatus < 4) {
            setStatusAction(token, 4);
        }

        this.setState({
            mounted: true,
            returnedTo: params.get("return") ? true : false
        });
    }

    componentWillReceiveProps(nextProps) {
        const {
            auth,
            match,
            form,
            mapFormAndGetRisk,
            checkMissingSectionsAction,
            checkMissingSectionsNotIncludingBPAction
        } = this.props;

        if (match.url !== nextProps.match.url) {
            mapFormAndGetRisk(
                auth.token || localStorage.getItem("token"),
                form
            );
            checkMissingSectionsAction();
            checkMissingSectionsNotIncludingBPAction();
        }
    }

    componentDidUpdate() {
        const { match, form, changeFormPartAction } = this.props;

        let step = parseInt(match.params.step);
        const currentPart = form.formPart;

        let part = currentPart;
        if (step <= this.partOneLastStep) {
            part = 0;
        } else if (step < this.partTwoLastStep) {
            part = 1;
        }

        if (currentPart !== part) {
            changeFormPartAction(part);
        }
    }

    onSwipeEnd(e) {
        let step = parseInt(this.props.match.params.step);
        const steps = this.steps();

        if (step === 7 || step === 8) {
            const scrollCard = e.target.closest(".field-card");
            if (
                scrollCard &&
                scrollCard.scrollTop !== 0 &&
                scrollCard.offsetHeight + scrollCard.scrollTop <
                    scrollCard.scrollHeight
            ) {
                return;
            }
        }

        if (this.state.swipeDirection === "down" && step > 1)
            this.props.history.push(
                `${process.env.PUBLIC_URL}/test/${step - 1}`
            );
        if (this.state.swipeDirection === "up" && step < steps.length)
            this.props.history.push(
                `${process.env.PUBLIC_URL}/test/${step + 1}`
            );
        if (this.state.swipeDirection === "up" && step >= steps.length)
            this.goToSummary();

        this.setState({
            swipeDirection: null
        });

        this.redraw();
    }

    goToSummary() {
        this.setState({
            onSummary: true
        });

        setTimeout(() => {
            this.setState({
                redirect: true
            });
        }, 750);
    }

    divider() {
        const { form, completeFormPartChangeAction } = this.props;

        return null;

        const currentPart = form.formPart;

        const dividers = [
            <Divider
                icon={`${process.env.PUBLIC_URL}/assets/heart-health.png`}
                partNumber={content.Steps.Dividers.PartOne.Number}
                partTitle={content.Steps.Dividers.PartOne.Title}
                show={currentPart === 0 && form.formPartChanging}
                changing={form.formPartChanging}
                completeFormPartChangeAction={completeFormPartChangeAction}
                key="Divider 0"
            />,
            <Divider
                icon={`${process.env.PUBLIC_URL}/assets/exercise.png`}
                partNumber={content.Steps.Dividers.PartTwo.Number}
                partTitle={content.Steps.Dividers.PartTwo.Title}
                show={currentPart === 1 && form.formPartChanging}
                changing={form.formPartChanging}
                completeFormPartChangeAction={completeFormPartChangeAction}
                key="Divider 1"
            />
        ];

        return dividers[currentPart];
    }

    steps() {
        let step = parseInt(this.props.match.params.step);
        if (this.state.onSummary) step = this.partTwoLastStep;

        const {
            form,
            history,
            addresses,
            setPostcodeAction,
            setAgeAction,
            setGenderAction,
            setEthnicityAction,
            setHeightUnitAction,
            setHeightInInchesAction,
            setHeightInFeetAction,
            setHeightInCmAction,
            setWeightUnitAction,
            setWeightInLbsAction,
            setWeightInStoneAction,
            setWeightInKgAction,
            setSmokingLevelAction,
            setDiabetesTypeAction,
            setFamilyHistoryAction,
            setKidneyDiseaseAction,
            setMedicalConditionsAction,
            setNoMedicalConditionsAction,
            setAtrialFibrillationAction,
            setSystolicBloodPressureReadingsAction,
            setDiastolicBloodPressureReadingsAction,
            setBloodPressureValidAction,
            setBloodPressureInvalidAction,
            setBloodPressureHighAction,
            setBloodPressureLowAction,
            setBloodPressureEmergencyAction,
            setBloodPressureNormalAction,
            setBloodPressureHighWarningReadAction,
            setBloodPressureHighWarningShowAction,
            requestMoreBPReadingsAction,
            setBloodPressureLocationAction,
            setPulseAction,
            setCholesterolStatusAction,
            setTotalCholesterolValueAction,
            setHDLCholesterolValueAction,
            setDiabetesInFamilyAction,
            setWaistMeasurementAction,
            setAlcoholConsumptionAction,
            setAlcoholUnitsAction,
            setBingeSessionsAction,
            setAdditionalAUDITAction,
            setActivityHoursCyclingAction,
            setActivityHoursWalkingAction,
            setActivityHoursExerciseAction,
            setActivityHoursHouseworkChildcareAction,
            setActivityHoursGardeningDIYAction,
            setWalkingPaceAction,
            setPhysicalWorkDefinitionAction,
            setVegetablesAction,
            setDietFoodsAction,
            setWeightClassAction,
            postcodeLookupAction,
            beginPostcodeValidationAction,
            setRemoteBloodPressureAction,
            setRemoteBloodTestAction,
            setBoookingLinkAction,
            setStatusAction
        } = this.props;

        const steps = [
            <EthnicityStep
                activeStep={step}
                ethnicity={form.ethnicity}
                setEthnicityAction={setEthnicityAction}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="ethnicity-step"
            />,

            <HeightWeightStep
                activeStep={step}
                heightUnit={form.heightUnit}
                heightInCm={form.heightInCm}
                heightInFeet={form.heightInFeet}
                heightInInches={form.heightInInches}
                weightUnit={form.weightUnit}
                weightInKg={form.weightInKg}
                weightInStone={form.weightInStone}
                weightInLbs={form.weightInLbs}
                setHeightUnitAction={setHeightUnitAction}
                setHeightInCmAction={setHeightInCmAction}
                setHeightInFeetAction={setHeightInFeetAction}
                setHeightInInchesAction={setHeightInInchesAction}
                setWeightUnitAction={setWeightUnitAction}
                setWeightInLbsAction={setWeightInLbsAction}
                setWeightInStoneAction={setWeightInStoneAction}
                setWeightInKgAction={setWeightInKgAction}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="height-weight-step"
            />,

            <PostcodeStep
                activeStep={step}
                postalCode={form.postalCode}
                postalCodeValidating={form.postalCodeValidating}
                postalCodeValidated={form.postalCodeValidated}
                postalCodeInvalid={form.postalCodeInvalid}
                setPostcodeAction={setPostcodeAction}
                postcodeLookupAction={postcodeLookupAction}
                beginPostcodeValidationAction={beginPostcodeValidationAction}
                addresses={addresses}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="postcode-step"
            />,

            <AgeGenderStep
                activeStep={step}
                age={form.age}
                gender={form.gender}
                ethnicity={form.ethnicity}
                setAgeAction={setAgeAction}
                setGenderAction={setGenderAction}
                includeDiabetes={form.includeDiabetes}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="age-gender-step"
            />,

            <SmokingStep
                activeStep={step}
                smokingLevel={form.smokingLevel}
                age={form.age}
                familyHistory={form.familyHistory}
                setFamilyHistoryAction={setFamilyHistoryAction}
                setSmokingLevelAction={setSmokingLevelAction}
                includeDiabetes={form.includeDiabetes}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="smoking-step"
            />,

            <MedicalConditionsStep
                activeStep={step}
                medicalConditions={form.medicalConditions}
                setMedicalConditionsAction={setMedicalConditionsAction}
                setNoMedicalConditionsAction={setNoMedicalConditionsAction}
                gender={form.gender}
                noMedicalConditions={form.noMedicalConditions}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="medical-conditions-step"
            />,

            <DiabetesStep
                activeStep={step}
                waistMeasurement={form.waistMeasurement}
                setWaistMeasurementAction={setWaistMeasurementAction}
                diabetesInFamily={form.diabetesInFamily}
                setDiabetesInFamilyAction={setDiabetesInFamilyAction}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="diabetes-step"
            />,

            <AlcoholStep
                activeStep={step}
                alcoholConsumption={form.alcoholConsumption}
                alcoholUnits={form.alcoholUnits}
                bingeSessions={form.bingeSessions}
                unableToStop={form.unableToStop}
                failedExpectations={form.failedExpectations}
                morningDrinking={form.morningDrinking}
                drinkingGuilt={form.drinkingGuilt}
                blackout={form.blackout}
                drinkingInjury={form.drinkingInjury}
                drinkingConcern={form.drinkingConcern}
                setAlcoholConsumptionAction={setAlcoholConsumptionAction}
                setAlcoholUnitsAction={setAlcoholUnitsAction}
                setBingeSessionsAction={setBingeSessionsAction}
                setAdditionalAUDITAction={setAdditionalAUDITAction}
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="alcohol-step"
            />,

            <PhysicalActivityStep
                activeStep={step}
                activityHoursCycling={form.activityHoursCycling}
                activityHoursWalking={form.activityHoursWalking}
                activityHoursExercise={form.activityHoursExercise}
                activityHoursHouseworkChildcare={
                    form.activityHoursHouseworkChildcare
                }
                activityHoursGardeningDIY={form.activityHoursGardeningDIY}
                walkingPace={form.walkingPace}
                physicalWork={form.physicalWork}
                setActivityHoursCyclingAction={setActivityHoursCyclingAction}
                setActivityHoursWalkingAction={setActivityHoursWalkingAction}
                setActivityHoursExerciseAction={setActivityHoursExerciseAction}
                setActivityHoursHouseworkChildcareAction={
                    setActivityHoursHouseworkChildcareAction
                }
                setActivityHoursGardeningDIYAction={
                    setActivityHoursGardeningDIYAction
                }
                setWalkingPaceAction={setWalkingPaceAction}
                setPhysicalWorkDefinitionAction={
                    setPhysicalWorkDefinitionAction
                }
                lastStep
                updateRisk={this.updateRisk.bind(this)}
                missingSections={form.missingSections}
                key="physical-activity-step"
            />,

            <BloodPressureStep
                activeStep={step}
                form={form}
                systolicBloodPressureReadings={
                    form.systolicBloodPressureReadings
                }
                diastolicBloodPressureReadings={
                    form.diastolicBloodPressureReadings
                }
                pulse={form.pulse}
                bloodPressureLocation={form.bloodPressureLocation}
                setSystolicBloodPressureReadingsAction={
                    setSystolicBloodPressureReadingsAction
                }
                setDiastolicBloodPressureReadingsAction={
                    setDiastolicBloodPressureReadingsAction
                }
                setBloodPressureValidAction={setBloodPressureValidAction}
                setBloodPressureInvalidAction={setBloodPressureInvalidAction}
                setBloodPressureHighAction={setBloodPressureHighAction}
                setBloodPressureLowAction={setBloodPressureLowAction}
                setBloodPressureNormalAction={setBloodPressureNormalAction}
                setBloodPressureEmergencyAction={
                    setBloodPressureEmergencyAction
                }
                setBloodPressureHighWarningReadAction={
                    setBloodPressureHighWarningReadAction
                }
                setBloodPressureHighWarningShowAction={
                    setBloodPressureHighWarningShowAction
                }
                requestMoreBPReadingsAction={requestMoreBPReadingsAction}
                setBloodPressureLocationAction={setBloodPressureLocationAction}
                setRemoteBloodPressureAction={setRemoteBloodPressureAction}
                setRemoteBloodTestAction={setRemoteBloodTestAction}
                setBoookingLinkAction={setBoookingLinkAction}
                setStatusAction={setStatusAction}
                bloodPressureInvalid={form.bloodPressureInvalid}
                bloodPressureLow={form.bloodPressureLow}
                bloodPressureHigh={form.bloodPressureHigh}
                bloodPressureEmergency={form.bloodPressureEmergency}
                bloodPressureHighWarningRead={form.bloodPressureHighWarningRead}
                bloodPressureHighWarningShow={form.bloodPressureHighWarningShow}
                bloodPressureMoreReadingsRequested={
                    form.bloodPressureMoreReadingsRequested
                }
                setPulseAction={setPulseAction}
                updateRisk={this.updateRisk.bind(this)}
                history={history}
                remoteBP={form.remoteBloodPressure}
                remoteBT={form.remoteBloodTest}
                bookingLink={form.bookingLink}
                missingSections={form.missingSections}
                missingSectionsNotIncludingBP={
                    form.missingSectionsNotIncludingBP
                }
                userStatus={form.userStatus}
                lastStep={!form.remoteBloodPressure}
                key="blood-pressure-step"
            />,
            <BPWarningStep
                bloodPressureLow={form.bloodPressureLow}
                bloodPressureHigh={form.bloodPressureHigh}
                bloodPressureEmergency={form.bloodPressureEmergency}
                remoteBP={form.remoteBloodPressure}
                remoteBT={form.remoteBloodTest}
                missingSections={form.missingSections}
                missingSectionsNotIncludingBP={
                    form.missingSectionsNotIncludingBP
                }
                userStatus={form.userStatus}
                activeStep={step}
                key="bp-warning-step"
            />
        ];

        if (form.age >= 65)
            steps.push(
                <DementiaStep
                    activeStep={step}
                    key="dementia-step"
                    remoteBT={form.remoteBloodTest}
                    missingSections={form.missingSections}
                />
            );

        return steps;
    }

    mapStepNavigation() {
        const { form } = this.props;
        let step = parseInt(this.props.match.params.step);

        const stepCompletionRequirements = [
            form.ethnicity !== null && parseInt(form.ethnicity) !== 0,
            form.heightInCm !== null && form.weightInKg !== null,
            form.postalCode !== "",
            form.age !== null &&
                form.gender !== null &&
                parseInt(form.gender) !== 0,
            localStorage.getItem("dementiaScreen") === "viewed",
            form.smokingLevel !== null && form.familyHistory !== null,
            form.medicalConditions.length > 0 || form.noMedicalConditions,
            form.waistMeasurement !== null &&
                parseInt(form.waistMeasurement) !== 0 &&
                form.diabetesInFamily !== null,
            checkAuditRequirements(form),
            form.activityHoursCycling !== null &&
                parseInt(form.activityHoursCycling) !== 0 &&
                form.activityHoursWalking !== null &&
                parseInt(form.activityHoursWalking) !== 0 &&
                form.activityHoursExercise !== null &&
                parseInt(form.activityHoursExercise) !== 0 &&
                form.activityHoursHouseworkChildcare !== null &&
                parseInt(form.activityHoursHouseworkChildcare) !== 0 &&
                form.activityHoursGardeningDIY !== null &&
                parseInt(form.activityHoursGardeningDIY) !== 0 &&
                form.walkingPace !== null &&
                parseInt(form.walkingPace) !== 0 &&
                form.physicalWork !== null &&
                parseInt(form.physicalWork) !== 0,
            form.remoteBloodPressure === false ||
                (form.systolicBloodPressureReadings[0] !== null &&
                    form.diastolicBloodPressureReadings[0] !== null &&
                    form.bloodPressureLocation !== null),
            false
        ];

        const stepTitles = content.StepTitles;

        return stepCompletionRequirements.map((stepRequirements, index) => {
            let stepNumber = index + 1;

            let className = "step-nav-item";
            // if (form.postalCodeInvalid && step === 3)
            //     className += " nav-disabled";
            // if (form.bloodPressureInvalid && step === 10)
            //     className += " nav-disabled";

            if (index === 4 && form.age < 65) className += " nav-removed";
            if (index === 11) className += " nav-removed";

            if (stepNumber === step) {
                className += " active";
            } else if (stepRequirements) {
                className += " completed";
            }

            if (!form.bloodPressureInvalid) {
                return (
                    <Link
                        to={`${process.env.PUBLIC_URL}/test/${stepNumber}`}
                        className={className}
                        key={`step-nav-item-${index}`}
                    >
                        <span>{stepTitles[index]}</span>
                    </Link>
                );
            } else {
                return (
                    <Link
                        onClick={this.highlightAlert.bind(this)}
                        className={className}
                        key={`step-nav-item-${index}`}
                    >
                        <span>{stepTitles[index]}</span>
                    </Link>
                );
            }
        });
    }

    redraw() {
        setTimeout(() => {
            this.setState({
                redraw: !this.state.redraw
            });
        }, 1000);
    }

    highlightAlert() {
        document.body.classList.add("highlight-error");
        setTimeout(() => {
            document.body.classList.remove("highlight-error");
        }, 5000);
    }

    dismissNextStepTutorial() {
        localStorage.setItem("nextButtonTutorial", true);
    }

    dismissStepButtonsTutorial() {
        localStorage.setItem("stepButtonsTutorial", true);
    }

    dismissSliderTutorial() {
        localStorage.setItem("sliderTutorial", true);
    }

    dismissHelpTutorial() {
        localStorage.setItem("helpTutorial", true);
    }

    render() {
        const { form, risk, auth, setTutorialViewedAction, tutorials } =
            this.props;

        const { mounted, returnedTo } = this.state;

        let params = new URLSearchParams(window.location.search);
        if (params.get("check") && mounted) {
            if (form.missingSections.length === 0) {
                return (
                    <Redirect to={`${process.env.PUBLIC_URL}/test-summary`} />
                );
            } else if (parseInt(form.userStatus) < 4) {
                return <Redirect to={`${process.env.PUBLIC_URL}/checklist`} />;
            }
        }

        const testStatus = localStorage.getItem("userMMHStatus");
        let step = parseInt(this.props.match.params.step);

        if (
            mounted &&
            !returnedTo &&
            (parseInt(form.userStatus) > 5 || parseInt(testStatus) > 5) &&
            step === 1
        ) {
            return <Redirect to={`${process.env.PUBLIC_URL}/test-summary`} />;
        }

        let steps = this.steps();
        let { onSummary, redirect } = this.state;

        let stepNavigationClass = "step-navigation";
        if (onSummary) stepNavigationClass += " leaving";
        if (step > this.partOneLastStep) stepNavigationClass += " part-two";

        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        )
            return <Redirect to={`${process.env.PUBLIC_URL}/verification`} />;

        const accessibleVersion = sessionStorage.getItem(
            "preference_visualImpairment"
        );
        if (mounted && accessibleVersion === "true") {
            let accessibleUrl = "accessible-test";
            if (returnedTo)
                accessibleUrl += `?return=${this.props.match.params.step}`;

            return (
                <Redirect to={`${process.env.PUBLIC_URL}/${accessibleUrl}`} />
            );
        }

        if (redirect)
            return <Redirect to={`${process.env.PUBLIC_URL}/test-summary`} />;

        // if (step > steps.length) return <NotFound />;

        let containerClass = "test-container";
        if (navigator.appVersion.match(/Chrome\/[.0-9]* Mobile/)) {
            containerClass += " chrome";
        }

        const riskTutorial = true; //localStorage.getItem("riskTutorial");
        const nextButtonTutorial = localStorage.getItem("nextButtonTutorial");
        const stepButtonsTutorial = localStorage.getItem("stepButtonsTutorial");
        const sliderTutorial = localStorage.getItem("sliderTutorial");
        const helpTutorial = localStorage.getItem("helpTutorial");

        return (
            <>
                {form.missingSections && form.missingSections.length > 0 && (
                    <>
                        <Tutorial
                            show={riskTutorial && !nextButtonTutorial}
                            elementReference=".next-step-tutorial"
                            position="top"
                            text={content.Tutorials.Next}
                            dismiss={this.dismissNextStepTutorial.bind(this)}
                            setTutorialViewed={setTutorialViewedAction}
                        />
                        <Tutorial
                            show={
                                riskTutorial &&
                                nextButtonTutorial &&
                                !helpTutorial
                            }
                            elementReference=".step.active .help"
                            position="left"
                            text={content.Tutorials.Help}
                            dismiss={this.dismissHelpTutorial.bind(this)}
                            setTutorialViewed={setTutorialViewedAction}
                        />
                        <Tutorial
                            show={
                                riskTutorial &&
                                nextButtonTutorial &&
                                helpTutorial &&
                                !sliderTutorial
                            }
                            elementReference=".step.active .range-group"
                            position="bottom"
                            text={content.Tutorials.Slider}
                            dismiss={this.dismissSliderTutorial.bind(this)}
                            setTutorialViewed={setTutorialViewedAction}
                        />
                    </>
                )}
                <div className={containerClass}>
                    {step <= this.partOneLastStep && (
                        <Risk
                            riskValue={risk}
                            onSummary={onSummary}
                            setTutorialViewed={setTutorialViewedAction}
                            tutorials={tutorials}
                        />
                    )}

                    <section className="test-steps">
                        {this.divider()}
                        {steps}
                    </section>

                    <div className={stepNavigationClass}>
                        {this.mapStepNavigation()}
                    </div>
                </div>
            </>
        );
    }
}

export default connect(
    (state) => ({
        form: state.form,
        risk: state.risk.value,
        auth: state.auth,
        addresses: state.address,
        tutorials: state.header.tutorials,
        userStatus: state.form.userStatus
    }),
    {
        setPostcodeAction,
        setAgeAction,
        setGenderAction,
        setEthnicityAction,
        setHeightUnitAction,
        setHeightInFeetAction,
        setHeightInInchesAction,
        setHeightInCmAction,
        setWeightUnitAction,
        setWeightInKgAction,
        setWeightInStoneAction,
        setWeightInLbsAction,
        setSmokingLevelAction,
        setDiabetesTypeAction,
        setFamilyHistoryAction,
        setKidneyDiseaseAction,
        setMedicalConditionsAction,
        setNoMedicalConditionsAction,
        setAtrialFibrillationAction,
        setSystolicBloodPressureReadingsAction,
        setDiastolicBloodPressureReadingsAction,
        setBloodPressureValidAction,
        setBloodPressureInvalidAction,
        setBloodPressureHighAction,
        setBloodPressureLowAction,
        setBloodPressureNormalAction,
        setBloodPressureEmergencyAction,
        setBloodPressureHighWarningReadAction,
        setBloodPressureHighWarningShowAction,
        setBloodPressureLocationAction,
        requestMoreBPReadingsAction,
        setPulseAction,
        setCholesterolStatusAction,
        setTotalCholesterolValueAction,
        setHDLCholesterolValueAction,
        setDiabetesInFamilyAction,
        setWaistMeasurementAction,
        setAlcoholConsumptionAction,
        setAlcoholUnitsAction,
        setBingeSessionsAction,
        setAdditionalAUDITAction,
        setActivityHoursCyclingAction,
        setActivityHoursWalkingAction,
        setActivityHoursExerciseAction,
        setActivityHoursHouseworkChildcareAction,
        setActivityHoursGardeningDIYAction,
        setWalkingPaceAction,
        setPhysicalWorkDefinitionAction,
        setVegetablesAction,
        setDietFoodsAction,
        setWeightClassAction,
        setTutorialViewedAction,
        mapForm,
        mapFormAndGetRisk,
        beginPostcodeValidationAction,
        postcodeLookupAction,
        changeFormPartAction,
        completeFormPartChangeAction,
        refreshTokenAction,
        checkMissingSectionsAction,
        checkMissingSectionsNotIncludingBPAction,
        setStatusAction,
        setRemoteBloodPressureAction,
        setRemoteBloodTestAction,
        setBoookingLinkAction
    }
)(Test);
