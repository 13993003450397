export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const convertFeetAndInchesToCm = (feetValue = 0, inchValue = 0) => {
    const totalInchValue =
        parseInt(feetValue ? feetValue : 0) * 12 +
        parseInt(inchValue ? inchValue : 0);
    return (totalInchValue * 2.54).toFixed(2);
};

export const convertCmToFeetAndInches = (cmValue = 0) => {
    const totalInchValue = parseFloat(cmValue ? cmValue : 0) / 2.54;
    let feetValue = Math.floor(totalInchValue / 12);
    let inchValue = Math.round(totalInchValue % 12);

    if (inchValue === 12) {
        inchValue = 0;
        feetValue++;
    }
    return {
        feet: feetValue,
        inches: inchValue
    };
};

export const convertStoneAndLbsToKg = (stoneValue = 0, lbValue = 0) => {
    const totalLbValue =
        parseInt(stoneValue ? stoneValue : 0) * 14 +
        parseInt(lbValue ? lbValue : 0);

    return (totalLbValue * 0.453592).toFixed(2);
};

export const convertKgToStoneAndLbs = (kgValue = 0) => {
    const totalLbValue = parseFloat(kgValue ? kgValue : 0) / 0.453592;
    let stoneValue = Math.floor(totalLbValue / 14);
    let lbValue = Math.round(totalLbValue % 14);

    if (lbValue === 14) {
        lbValue = 0;
        stoneValue++;
    }
    return {
        stone: stoneValue,
        lbs: lbValue
    };
};

export const calculateBmi = (form, setBodyMassIndexAction) => {
    if (!form.weightInKg || !form.heightInCm) {
        return;
    }
    let heightInM = form.heightInCm / 100;
    let bmi = form.weightInKg / (heightInM * heightInM);

    setBodyMassIndexAction(bmi.toFixed(1));
    return bmi.toFixed(1);
};

export const calculateDiabetesRisk = (
    bmi,
    form,
    setDiabetesRiskScoreAction
) => {
    if (
        parseInt(form.diabetesType) === 1 ||
        parseInt(form.diabetesType) === 2
    ) {
        setDiabetesRiskScoreAction(100);
        return;
    }

    let riskScore = 0;

    // Age risk
    if (form.age >= 70) riskScore += 13;
    else if (form.age >= 60) riskScore += 9;
    else if (form.age >= 50) riskScore += 5;

    // Gender risk
    if (parseInt(form.gender) === 1) riskScore += 1;

    // Ethnicity risk
    if (form.ethnicity !== null && parseInt(form.ethnicity) !== 1)
        riskScore += 6;

    // Family risk
    if (
        form.diabetesInFamily === "true" ||
        form.diabetesInFamily === true ||
        parseInt(form.diabetesInFamily) === 1
    )
        riskScore += 5;

    // Waist measurement
    const waistMeasurement = parseInt(form.waistMeasurement);
    if (waistMeasurement > 90 && waistMeasurement <= 100) riskScore += 4;
    else if (waistMeasurement > 100 && waistMeasurement <= 110) riskScore += 6;
    else if (waistMeasurement > 110) riskScore += 9;

    // BMI
    let bodyMassIndex = form.bodyMassIndex || bmi;
    if (bodyMassIndex >= 35) riskScore += 8;
    else if (bodyMassIndex >= 30) riskScore += 5;
    else if (bodyMassIndex >= 25) riskScore += 3;

    // Blood pressure
    if (form.medicalConditions.indexOf("Bloodpressuretreatment") > -1)
        riskScore += 5;

    setDiabetesRiskScoreAction(riskScore);

    return riskScore;
};

export const calculateAUDITC = (form, setAUDITCScoreAction) => {
    let alcoholConsumption = form.alcoholConsumption;
    let alcoholUnits = form.alcoholUnits;
    let bingeSessions = form.bingeSessions;

    let AUDITCScore = -3;

    if (alcoholConsumption === 0 && alcoholUnits === 0 && bingeSessions === 0) {
        // setAUDITCScoreAction(0);
    } else {
        AUDITCScore += parseInt(alcoholConsumption) || 0;
        AUDITCScore += parseInt(alcoholUnits) || 0;
        AUDITCScore += parseInt(bingeSessions) || 0;
    }

    if (AUDITCScore < 0) AUDITCScore = "";
    setAUDITCScoreAction(AUDITCScore);

    return AUDITCScore;
};

export const calculateFullAUDITScore = (form, setFullAUDITScoreAction) => {
    let AUDITScore = 0;
    AUDITScore +=
        parseInt(form.alcoholConsumption) > 0
            ? parseInt(form.alcoholConsumption) - 1
            : 0;
    AUDITScore +=
        parseInt(form.alcoholUnits) > 0 ? parseInt(form.alcoholUnits) - 1 : 0;
    AUDITScore +=
        parseInt(form.bingeSessions) > 0 ? parseInt(form.bingeSessions) - 1 : 0;
    AUDITScore +=
        parseInt(form.unableToStop) > 0 ? parseInt(form.unableToStop) - 1 : 0;
    AUDITScore +=
        parseInt(form.failedExpectations) > 0
            ? parseInt(form.failedExpectations) - 1
            : 0;
    AUDITScore +=
        parseInt(form.morningDrinking) > 0
            ? parseInt(form.morningDrinking) - 1
            : 0;
    AUDITScore +=
        parseInt(form.drinkingGuilt) > 0 ? parseInt(form.drinkingGuilt) - 1 : 0;
    AUDITScore += parseInt(form.blackout) > 0 ? parseInt(form.blackout) - 1 : 0;
    AUDITScore +=
        parseInt(form.drinkingInjury) > 0
            ? (parseInt(form.drinkingInjury) - 1) * 2
            : 0;
    AUDITScore +=
        parseInt(form.drinkingConcern) > 0
            ? (parseInt(form.drinkingConcern) - 1) * 2
            : 0;

    setFullAUDITScoreAction(AUDITScore);

    return AUDITScore;
};

export const calculateGPAQScore = (form, setGPAQScoreAction) => {
    let GPAQScore = 2;

    GPAQScore +=
        parseInt(form.physicalWork) > 1 ? parseInt(form.physicalWork) - 2 : 0;
    GPAQScore +=
        parseInt(form.activityHoursCycling) > 0
            ? parseInt(form.activityHoursCycling) - 1
            : 0;
    GPAQScore +=
        parseInt(form.activityHoursExercise) > 0
            ? parseInt(form.activityHoursExercise) - 1
            : 0;

    setGPAQScoreAction(GPAQScore);

    return GPAQScore;
};

export const getLowestBPReadings = (form) => {
    const cleanedBPReadings = form.systolicBloodPressureReadings.map((a) =>
        !isNaN(parseInt(a)) ? parseInt(a) : Infinity
    );
    const lowValue = Math.min(...cleanedBPReadings);
    let lowIndex;

    const appearances = cleanedBPReadings.filter(
        (reading) => reading === lowValue
    );
    if (appearances.length === 1) {
        lowIndex = cleanedBPReadings.indexOf(lowValue);
    } else {
        const cleanedDiastolicReadings =
            form.diastolicBloodPressureReadings.map((a, index) =>
                parseInt(cleanedBPReadings[index]) === lowValue
                    ? !isNaN(parseInt(a))
                        ? parseInt(a)
                        : Infinity
                    : Infinity
            );
        const lowDiastolicReading = Math.min(...cleanedDiastolicReadings);
        lowIndex = cleanedDiastolicReadings.indexOf(lowDiastolicReading);
    }

    const lowestSystolicValue = form.systolicBloodPressureReadings[lowIndex];
    const lowestDiastolicValue = form.diastolicBloodPressureReadings[lowIndex];

    return {
        lowestSystolicValue,
        lowestDiastolicValue
    };
};

export const checkAuditRequirements = (form) => {
    let complete = true;

    if (
        form.alcoholConsumption === null ||
        parseInt(form.alcoholConsumption) === 0 ||
        form.alcoholUnits === null ||
        parseInt(form.alcoholUnits) === 0 ||
        form.bingeSessions === null ||
        parseInt(form.bingeSessions) === 0
    ) {
        complete = false;
    } else {
        let alcoholConsumption = parseInt(form.alcoholConsumption);
        let alcoholUnits = parseInt(form.alcoholUnits);
        let bingeSessions = parseInt(form.bingeSessions);

        if (alcoholConsumption > 0) alcoholConsumption--;
        if (alcoholUnits > 0) alcoholUnits--;
        if (bingeSessions > 0) bingeSessions--;

        const fullAudit =
            parseInt(alcoholConsumption) +
                parseInt(alcoholUnits) +
                parseInt(bingeSessions) >=
            5;

        if (
            fullAudit &&
            (form.unableToStop === null ||
                parseInt(form.unableToStop) === 0 ||
                form.failedExpectations === null ||
                parseInt(form.failedExpectations) === 0 ||
                form.morningDrinking === null ||
                parseInt(form.morningDrinking) === 0 ||
                form.drinkingGuilt === null ||
                parseInt(form.drinkingGuilt) === 0 ||
                form.blackout === null ||
                parseInt(form.blackout) === 0 ||
                form.drinkingInjury === null ||
                parseInt(form.drinkingInjury) === 0 ||
                form.drinkingConcern === null ||
                parseInt(form.drinkingConcern) === 0)
        ) {
            complete = false;
        }
    }

    return complete;
};

export const checkMissingSections = (form, includeBloodPressure = true) => {
    const missingSections = [];

    if (form.ethnicity === null) missingSections.push(0);
    if (form.heightInCm === null || form.weightInKg === null)
        missingSections.push(1);
    if (form.postalCode === "") missingSections.push(2);
    if (
        form.age === null ||
        form.gender === null ||
        parseInt(form.gender) === 0
    )
        missingSections.push(3);
    if (
        form.smokingLevel === null ||
        parseInt(form.smokingLevel) === 5 ||
        form.familyHistory === null
    )
        missingSections.push(5);
    if (form.medicalConditions.length === 0 && !form.noMedicalConditions)
        missingSections.push(6);
    if (form.diabetesInFamily === null || form.waistMeasurement === null)
        missingSections.push(7);
    if (!checkAuditRequirements(form)) {
        missingSections.push(8);
    }
    if (
        form.activityHoursCycling === null ||
        parseInt(form.activityHoursCycling) === 0 ||
        form.activityHoursWalking === null ||
        parseInt(form.activityHoursWalking) === 0 ||
        form.activityHoursExercise === null ||
        parseInt(form.activityHoursExercise) === 0 ||
        form.activityHoursHouseworkChildcare === null ||
        parseInt(form.activityHoursHouseworkChildcare) === 0 ||
        form.activityHoursGardeningDIY === null ||
        parseInt(form.activityHoursGardeningDIY) === 0 ||
        form.walkingPace === null ||
        parseInt(form.walkingPace) === 0 ||
        form.physicalWork === null ||
        parseInt(form.physicalWork) === 0
    )
        missingSections.push(9);
    if (
        includeBloodPressure &&
        (form.systolicBloodPressureReadings[0] === null ||
            form.diastolicBloodPressureReadings[0] === null ||
            form.bloodPressureLocation === null)
    )
        missingSections.push(10);

    /*if (form.totalCholesterol === null || form.hdlCholesterol === null)
            missingSections.push("Cholesterol");*/
    return missingSections;
};
