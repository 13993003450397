import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Button from "../../components/button/Button";
import content from "../../lookup/en/content";
import {
    verifyUserAction,
    clearVerificationAction
} from "../../actions/auth/authActions";
import { setPreferenceAction } from "../../actions/preferences/preferencesActions";
import { clearAllFormValuesAction } from "../../actions/form/formActions";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";

import "./Verification.scss";
import { PAGE_IDS } from "../../lookup/api";

class Verification extends Component {
    state = {
        visualImpairment: false,
        consent: false,
        surname: "",
        dayOfBirth: "",
        monthOfBirth: "",
        yearOfBirth: "",
        PIN: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.VERIFY);
    }

    onKeyDown(e) {
        let code = e.keyCode ? e.keyCode : e.which;
        if (code === 13 || code === 10) {
            e.target.blur();
        }
    }

    onInputChange(e) {
        const input = e.target.name;

        this.setState({
            [input]: e.target.value
        });
    }

    onInputBlur(e) {
        const input = e.target.name;
        let value = e.target.value;

        if (input === "dayOfBirth" || input === "monthOfBirth") {
            if (parseInt(value) < 10 && value[0] !== "0") value = "0" + value;
            if (parseInt(value) <= 0) value = "";

            if (input === "dayOfBirth" && parseInt(value) > 31) value = 31;
            if (input === "monthOfBirth" && parseInt(value) > 12) value = 12;
        }

        if (input === "yearOfBirth") {
            if (parseInt(value) < 1900) value = 1900;
            if (parseInt(value) > 2020) value = 2020;
        }

        e.target.value = value;

        this.setState({
            [input]: value
        });
    }

    onSubmit() {
        this.props.verifyUserAction(this.state, true);
    }

    checkbox(field) {
        const { preferences, setPreferenceAction } = this.props;

        this.setState({
            [field]: !preferences[field]
        });

        setPreferenceAction(field, !preferences[field]);
    }

    clearVerification() {
        const { clearVerificationAction, clearAllFormValuesAction } =
            this.props;

        console.log("clearing ver");

        clearVerificationAction();
        clearAllFormValuesAction();
    }

    render() {
        const { auth, preferences, userStatus } = this.props;

        if (!localStorage.getItem("userKey")) {
            return (
                <div className="verification-container">
                    <p className="verification-error">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/alert.png`}
                            alt={content.Verification.Failed}
                        />
                        {content.Verification.InvalidKey}
                    </p>
                </div>
            );
        }

        if (auth.inactiveUser) {
            return <Redirect to={`${process.env.PUBLIC_URL}/inactive`} />;
        }

        if (auth.redirect) {
            if (!auth.pinRequired)
                return <Redirect to={`${process.env.PUBLIC_URL}/pin`} />;

            if (auth.pinRequired && userStatus !== null)
                if (parseInt(userStatus) === 4) {
                    return (
                        <Redirect
                            to={`${process.env.PUBLIC_URL}/test/1?check=true`}
                        />
                    );
                } else if (parseInt(userStatus) === 5) {
                    return (
                        <Redirect to={`${process.env.PUBLIC_URL}/blood-test`} />
                    );
                } else if (parseInt(userStatus) > 5) {
                    return (
                        <Redirect
                            to={`${process.env.PUBLIC_URL}/test-summary`}
                        />
                    );
                }
        }

        return (
            <div className="verification-container">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/key.png`}
                    alt={content.Verification.Title}
                />
                <h2>{content.Verification.Title}</h2>
                {auth.userVerification.failedVerification && (
                    <p className="verification-error">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/alert.png`}
                            alt={content.Verification.Failed}
                        />
                        {!preferences.consent && content.Verification.NoConsent}
                        {preferences.consent && content.Verification.Failed}
                    </p>
                )}

                {!auth.userVerification.failedVerification && (
                    <p className="verification-headline">
                        {content.Verification.Header}
                    </p>
                )}

                <form>
                    <label>{content.Verification.Surname}</label>
                    <input
                        type="text"
                        name="surname"
                        onChange={this.onInputChange.bind(this)}
                        onBlur={this.onInputChange.bind(this)}
                    />
                    <label>{content.Verification.DOB}</label>
                    <fieldset className="dob">
                        <input
                            type="number"
                            name="dayOfBirth"
                            placeholder="dd"
                            maxLength="2"
                            onChange={this.onInputChange.bind(this)}
                            onBlur={this.onInputBlur.bind(this)}
                            value={this.state.dayOfBirth}
                        />
                        <input
                            type="number"
                            name="monthOfBirth"
                            placeholder="mm"
                            maxLength="2"
                            onChange={this.onInputChange.bind(this)}
                            onBlur={this.onInputBlur.bind(this)}
                            value={this.state.monthOfBirth}
                        />
                        <input
                            type="number"
                            name="yearOfBirth"
                            placeholder="yyyy"
                            maxLength="4"
                            onChange={this.onInputChange.bind(this)}
                            onBlur={this.onInputBlur.bind(this)}
                            value={this.state.yearOfBirth}
                        />
                    </fieldset>
                    {auth.pinRequired && (
                        <>
                            <label>{content.Verification.PIN}</label>
                            <input
                                type="password"
                                name="PIN"
                                onChange={this.onInputChange.bind(this)}
                                onBlur={this.onInputChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                            />
                            <p className="pin-forgotten">
                                If you have forgotten your PIN contact{" "}
                                <a href="mailto:info@tickertest.co.uk">
                                    info@tickertest.co.uk
                                </a>
                            </p>
                        </>
                    )}

                    <fieldset
                        className="for-checkbox"
                        onClick={this.checkbox.bind(this, "consent")}
                    >
                        <input type="checkbox" />
                        <div
                            className={
                                this.state.consent
                                    ? "checkbox checked"
                                    : "checkbox"
                            }
                        ></div>
                        <label>{content.Preferences.Consent}</label>
                    </fieldset>
                    <fieldset
                        style={{ display: "none" }}
                        className="for-checkbox"
                        onClick={this.checkbox.bind(this, "visualImpairment")}
                    >
                        <input type="checkbox" />
                        <div
                            className={
                                this.state.visualImpairment
                                    ? "checkbox checked"
                                    : "checkbox"
                            }
                        ></div>
                        <label>{content.Preferences.Visual}</label>
                    </fieldset>
                    <Button onClick={this.onSubmit.bind(this)}>
                        {content.Steps.Next}
                    </Button>
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        preferences: state.preferences,
        userStatus: state.form.userStatus
    }),
    {
        verifyUserAction,
        clearVerificationAction,
        clearAllFormValuesAction,
        setPreferenceAction,
        trackPageViewAction
    }
)(Verification);
