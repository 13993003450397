export const API_BASE = process.env.REACT_APP_API_BASE;

export const PAGE_IDS = {
    HOMEPAGE: 1,
    VERIFY: 2,
    ENTER_PIN: 3,
    CHECKLIST: 4,
    HOW_TO_RETURN: 5,
    WHAT_HAPPENS: 6,
    BLOOD_TEST_ADDRESS_INPUT: 7,
    BLOOD_TEST_ADDRESS_CONFIRM: 8,
    ABOUT: 9,
    ACCESSIBILITY: 10,
    TERMS_AND_CONDITIONS: 11,
    PRIVACY: 12,
    COOKIES: 13,
    HELP: 14,
    CONTACT_US: 15,
    FAQS: 16,
    CONTACT_DETAILS: 17
};
