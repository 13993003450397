import React from "react";

import content from "../../lookup/en/content";
import "./TableOfContent.scss";

class TableOfContent extends React.Component {
    render() {
        const { items, activeIndex } = this.props;

        return (
            <>
                <h5 className="table-of-contents-header">
                    {content.Header.TableOfContent}
                </h5>
                <ul className="table-of-contents">
                    {items.map((item, index) => (
                        <a href={`#${item.id}`} key={`toc-item-${index}`}>
                            <li
                                className={
                                    index === activeIndex
                                        ? "item active"
                                        : "item"
                                }
                            >
                                {item.header}
                            </li>
                        </a>
                    ))}
                </ul>
            </>
        );
    }
}

export default TableOfContent;
