import React from "react";
import { connect } from "react-redux";

import "./WhatHappens.scss";
import content from "../../lookup/en/content";
import Button from "../../components/button/Button";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { PAGE_IDS } from "../../lookup/api";

class WhatHappens extends React.Component {
    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.WHAT_HAPPENS);
    }

    render() {
        const { auth } = this.props;
        const testStatus = localStorage.getItem("userMMHStatus");

        let link;
        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        ) {
            link = "/verification";
        } else if (parseInt(testStatus) <= 5) {
            link = "/test/1";
        } else if (parseInt(testStatus) > 5) {
            link = "/test-summary";
        }

        return (
            <div className="what-happens">
                <div className="content">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/dial.jpg`}
                        alt={content.WhatHappens.Title}
                    />
                    <h2>{content.WhatHappens.Title}</h2>
                    <>{content.WhatHappens.Content}</>
                    <Button href={link}>{content.WhatHappens.Return}</Button>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        userStatus: state.form.userStatus
    }),
    {
        trackPageViewAction
    }
)(WhatHappens);
