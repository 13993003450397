import React from "react";

import "./About.scss";
import content from "../../lookup/en/content";
import { WhatIsHealthCheck } from "../../components/what-is-health-check/WhatIsHealthCheck";
import Button from "../../components/button/Button";

const About = () => [
    <div className="about-container">
        <WhatIsHealthCheck />
        <Button href="/verification" isPrimary>
            {content.Home.TestButton}
        </Button>
    </div>
];

export default About;
