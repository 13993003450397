import React from "react";

import "./BloodPressureStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import NoIdea from "../../no-idea/NoIdea";
import content from "../../../lookup/en/content";
import InfoModal from "../../info-modal/InfoModal";
import InputField from "../../input-field/InputField";
import Button from "../../button/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { checkMissingSections } from "../../../util";

const MAX_SYSTOLIC = 205;
const MIN_SYSTOLIC = 65;
const MAX_DIASTOLIC = 125;
const MIN_DIASTOLIC = 35;

const LOW_SYSTOLIC = 85;
const LOW_DIASTOLIC = 55;
const EMERGENCY_SYSTOLIC = 176;
const EMERGENCY_DIASTOLIC = 116;
const HIGH_SYSTOLIC = 135;
const HIGH_DIASTOLIC = 85;

const BLOOD_PRESSURE_MODAL_HREF = `${process.env.PUBLIC_URL}/blood-pressure-tests`;

const READINGS_REQUESTED = 3;

class BloodPressureStep extends React.Component {
    componentWillMount() {
        const {
            setSystolicBloodPressureReadingsAction,
            setDiastolicBloodPressureReadingsAction,
            setBloodPressureLocationAction,
            setRemoteBloodPressureAction,
            setRemoteBloodTestAction,
            setBoookingLinkAction,
            setPulseAction
        } = this.props;

        let defaultSystolicBloodPressureReadings = localStorage.getItem(
            "systolicBloodPressureReadings"
        );
        defaultSystolicBloodPressureReadings =
            defaultSystolicBloodPressureReadings
                ? defaultSystolicBloodPressureReadings.split(",")
                : [null, null, null];
        if (defaultSystolicBloodPressureReadings[0] === "0")
            defaultSystolicBloodPressureReadings = [null, null, null];

        let defaultDiastolicBloodPressureReadings = localStorage.getItem(
            "diastolicBloodPressureReadings"
        );
        defaultDiastolicBloodPressureReadings =
            defaultDiastolicBloodPressureReadings
                ? defaultDiastolicBloodPressureReadings.split(",")
                : [null, null, null];
        if (defaultDiastolicBloodPressureReadings[0] === "0")
            defaultDiastolicBloodPressureReadings = [null, null, null];

        let defaultPulse = localStorage.getItem("pulse");
        let defaultLocation = localStorage.getItem("bloodPressureLocation");

        setDiastolicBloodPressureReadingsAction(
            defaultDiastolicBloodPressureReadings.map((reading) =>
                reading === "0" ? null : reading
            )
        );
        setSystolicBloodPressureReadingsAction(
            defaultSystolicBloodPressureReadings.map((reading) =>
                reading === "0" ? null : reading
            )
        );
        setPulseAction(defaultPulse);
        setBloodPressureLocationAction(defaultLocation);

        const remoteBP = localStorage.getItem("remoteBloodPressure");
        const remoteBT = localStorage.getItem("remoteBloodTest");
        const bookingLink = localStorage.getItem("bookingLink");

        setRemoteBloodPressureAction(remoteBP);
        setRemoteBloodTestAction(remoteBT);
        setBoookingLinkAction(bookingLink);

        this.checkBPValue(0, defaultSystolicBloodPressureReadings[0], "SYS");
        this.checkBPValue(0, defaultDiastolicBloodPressureReadings[0], "DIA");
    }

    componentWillReceiveProps(nextProps) {
        const {
            setStatusAction,
            missingSectionsNotIncludingBP,
            bloodPressureLocation,
            systolicBloodPressureReadings,
            diastolicBloodPressureReadings,
            remoteBP,
            remoteBT,
            userStatus
        } = this.props;

        if (
            remoteBP === false &&
            remoteBT === false &&
            missingSectionsNotIncludingBP.length === 0 &&
            userStatus < 7
        ) {
            const token = localStorage.getItem("token");
            setStatusAction(token, 7);
        }

        if (bloodPressureLocation !== nextProps.bloodPressureLocation) {
            setTimeout(() => {
                this.checkBPValue(0, systolicBloodPressureReadings[0], "SYS");
                this.checkBPValue(0, diastolicBloodPressureReadings[0], "DIA");
            }, 10);
        }
    }

    checkBPValue(index, value, type) {
        const {
            systolicBloodPressureReadings,
            diastolicBloodPressureReadings,
            setBloodPressureValidAction,
            setBloodPressureInvalidAction,
            setBloodPressureHighAction,
            setBloodPressureLowAction,
            setBloodPressureEmergencyAction,
            setBloodPressureNormalAction,
            missingSectionsNotIncludingBP,
            bloodPressureLocation
        } = this.props;

        let maxSystolicValue = MAX_SYSTOLIC;
        let minSystolicValue = MIN_SYSTOLIC;
        let maxDiastolicValue = MAX_DIASTOLIC;
        let minDiastolicValue = MIN_DIASTOLIC;

        let emergencySystolicComparisonValue = EMERGENCY_SYSTOLIC;
        let highSystolicComparisonValue = HIGH_SYSTOLIC;
        let lowSystolicComparisonValue = LOW_SYSTOLIC;

        let emergencyDiastolicComparisonValue = EMERGENCY_DIASTOLIC;
        let highDiastolicComparisonValue = HIGH_DIASTOLIC;
        let lowDiastolicComparisonValue = LOW_DIASTOLIC;

        if (bloodPressureLocation !== "Home") {
            maxSystolicValue += 5;
            maxDiastolicValue += 5;
            lowSystolicComparisonValue += 5;
            lowDiastolicComparisonValue += 5;

            if (
                bloodPressureLocation !== "" &&
                bloodPressureLocation !== null
            ) {
                minSystolicValue += 5;
                minDiastolicValue += 5;
                emergencySystolicComparisonValue += 5;
                emergencyDiastolicComparisonValue += 5;
                highSystolicComparisonValue += 5;
                highDiastolicComparisonValue += 5;
            }
        }

        if (!value) {
            // setBloodPressureValidAction();
        } else if (
            (type === "SYS" &&
                (value > maxSystolicValue || value < minSystolicValue)) ||
            (type === "DIA" &&
                (value > maxDiastolicValue || value < minDiastolicValue))
        ) {
            setBloodPressureNormalAction();
            setBloodPressureInvalidAction();
            return;
        } else {
            setBloodPressureValidAction();
        }

        if (value) {
            if (type === "SYS") {
                systolicBloodPressureReadings[index] = value;
                if (
                    diastolicBloodPressureReadings[index] &&
                    diastolicBloodPressureReadings[index] !== ""
                ) {
                    if (value < parseInt(diastolicBloodPressureReadings[index]))
                        setBloodPressureInvalidAction();
                }
            } else if (type === "DIA") {
                diastolicBloodPressureReadings[index] = value;
                if (
                    systolicBloodPressureReadings[index] &&
                    systolicBloodPressureReadings[index] !== ""
                ) {
                    if (value > parseInt(systolicBloodPressureReadings[index]))
                        setBloodPressureInvalidAction();
                }
            }
        }

        const cleanedReadings = systolicBloodPressureReadings.map((a) =>
            !isNaN(parseInt(a)) ? parseInt(a) : Infinity
        );
        const lowValue = Math.min(...cleanedReadings);
        const lowIndex = cleanedReadings.indexOf(lowValue);

        const lowestSystolicValue = parseInt(
            systolicBloodPressureReadings[lowIndex]
        );
        const lowestDiastolicValue = parseInt(
            diastolicBloodPressureReadings[lowIndex]
        );

        if (
            lowestSystolicValue >= emergencySystolicComparisonValue ||
            lowestDiastolicValue >= emergencyDiastolicComparisonValue
        ) {
            setBloodPressureEmergencyAction();
        } else if (
            lowestSystolicValue >= highSystolicComparisonValue ||
            lowestDiastolicValue >= highDiastolicComparisonValue
        ) {
            setBloodPressureHighAction();
        } else if (
            lowestSystolicValue < lowSystolicComparisonValue ||
            lowestDiastolicValue < lowDiastolicComparisonValue
        ) {
            setBloodPressureLowAction();
        } else {
            setBloodPressureNormalAction();
        }
    }

    onSystolicBlur(index, event) {
        this.checkBPValue(index, event.target.value, "SYS");
        this.updateSystolicBloodPressureValue(index, event.target.value);
    }

    onDiastolicBlur(index, event) {
        this.checkBPValue(index, event.target.value, "DIA");
        this.updateDiastolicBloodPressureValue(index, event.target.value);
    }

    updateSystolicBloodPressureValue(index, value) {
        const {
            setSystolicBloodPressureReadingsAction,
            systolicBloodPressureReadings,
            updateRisk
        } = this.props;
        systolicBloodPressureReadings[index] = value ? value : null;
        const riskKey = `Systolicbp${index + 1}`;

        setSystolicBloodPressureReadingsAction(systolicBloodPressureReadings);
        localStorage.setItem(
            "systolicBloodPressureReadings",
            systolicBloodPressureReadings
        );
        updateRisk(riskKey, systolicBloodPressureReadings[index]);
    }

    updateDiastolicBloodPressureValue(index, value) {
        const {
            setDiastolicBloodPressureReadingsAction,
            diastolicBloodPressureReadings,
            updateRisk
        } = this.props;

        diastolicBloodPressureReadings[index] = value ? value : null;
        const riskKey = `Diastolicbp${index + 1}`;

        setDiastolicBloodPressureReadingsAction(diastolicBloodPressureReadings);
        localStorage.setItem(
            "diastolicBloodPressureReadings",
            diastolicBloodPressureReadings
        );
        updateRisk(riskKey, diastolicBloodPressureReadings[index]);
    }

    pulseChange(e) {
        const { setPulseAction } = this.props;

        setPulseAction(e.target.value);
        localStorage.setItem("pulse", e.target.value);
    }

    toggleInfoModal() {
        const { requestMoreBPReadingsAction } = this.props;

        requestMoreBPReadingsAction(false);
    }

    setBloodPressureLocation(prefix, location) {
        const { setBloodPressureLocationAction, updateRisk } = this.props;

        setBloodPressureLocationAction(`${prefix}${location}`);
        localStorage.setItem("bloodPressureLocation", `${prefix}${location}`);
        updateRisk("bloodPressureLocation", `${prefix}${location}`);
    }

    showWarning() {
        const {
            systolicBloodPressureReadings,
            diastolicBloodPressureReadings,
            bloodPressureMoreReadingsRequested,
            remoteBP,
            setBloodPressureValidAction,
            setBloodPressureInvalidAction,
            requestMoreBPReadingsAction
        } = this.props;

        const totalReadings = [];
        setBloodPressureValidAction();
        let valid = true;

        systolicBloodPressureReadings.forEach((reading, index) => {
            const completeReading = {};
            if (reading && reading !== "") completeReading.systolic = reading;
            if (
                diastolicBloodPressureReadings[index] &&
                diastolicBloodPressureReadings[index] !== ""
            ) {
                completeReading.diastolic =
                    diastolicBloodPressureReadings[index];
            } else {
                // Catch when a systolic has been added without a diastolic
                if (reading && reading !== "") {
                    setBloodPressureInvalidAction();
                    valid = false;
                }
            }

            if (Object.keys(completeReading).length === 2)
                totalReadings.push(completeReading);
        });

        if (
            valid &&
            totalReadings.length < 3 &&
            remoteBP &&
            !bloodPressureMoreReadingsRequested
        ) {
            requestMoreBPReadingsAction(true);
            return false;
        }

        return valid;
    }

    missingSectionLinks(sections) {
        return sections.map((item) => {
            if (item !== "Cholesterol") {
                return (
                    <Link
                        to={`/test/${item + 1}?return=true`}
                        key={`link${item}`}
                    >
                        <li>{content.StepTitles[item]}</li>
                    </Link>
                );
            } else {
                return null;
                return (
                    <Link to="#'" key={`link${item}`}>
                        <li>{content.Summary.MissingValues.CholesterolTest}</li>
                    </Link>
                );
            }
        });
    }

    render() {
        const {
            activeStep,
            systolicBloodPressureReadings,
            diastolicBloodPressureReadings,
            bloodPressureInvalid,
            bloodPressureMoreReadingsRequested,
            bloodPressureLocation,
            fakeLastStep,
            lastStep,
            history,
            remoteBP,
            remoteBT,
            form,
            missingSections,
            missingSectionsNotIncludingBP,
            userStatus
        } = this.props;

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={11}
                fakeLastStep={fakeLastStep}
                lastStep={lastStep}
                disableNavigation={bloodPressureInvalid}
                history={history}
                onNextStep={this.showWarning.bind(this)}
                remoteBT={remoteBT}
                remoteBP={remoteBP}
                disableNext={remoteBP === null}
                missingSections={missingSections}
                missingSectionsNotIncludingBP={missingSectionsNotIncludingBP}
            >
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                {remoteBP === null && (
                    <section className="blood-pressure-step">
                        <FieldCard
                            cardTitle={content.Steps.BloodPressure.Title}
                            helpTitle={content.Steps.BloodPressure.HelpTitle}
                            helpText={content.Steps.BloodPressure.HelpText}
                        >
                            {content.Steps.BloodPressure.CompleteQuestionnaire}
                        </FieldCard>
                    </section>
                )}

                {remoteBP !== null && (
                    <section className="blood-pressure-step">
                        <FieldCard
                            cardTitle={content.Steps.BloodPressure.Title}
                            helpTitle={content.Steps.BloodPressure.HelpTitle}
                            helpText={content.Steps.BloodPressure.HelpText}
                        >
                            {!remoteBP && (
                                <>
                                    <div className="blood-pressure-advice">
                                        {!remoteBP &&
                                            remoteBT &&
                                            content.Steps.BloodPressure.GP}
                                        {!remoteBP &&
                                            !remoteBT &&
                                            content.Steps.BloodPressure
                                                .GPWithBloods}
                                    </div>

                                    {/* <a
                                        href={bookingLink}
                                        class="button wide external"
                                        target="_blank"
                                    >
                                        {
                                            content.Steps.BloodPressure
                                                .BookingLink
                                        }
                                    </a> */}
                                </>
                            )}
                            {remoteBP && (
                                <>
                                    {bloodPressureInvalid && (
                                        <p className="invalid-readings">
                                            {
                                                content.Steps.BloodPressure
                                                    .ErrorHandling
                                            }
                                        </p>
                                    )}
                                    <div className="blood-pressure-locations">
                                        <div
                                            className={
                                                bloodPressureLocation === "Home"
                                                    ? "blood-pressure-location selected"
                                                    : "blood-pressure-location"
                                            }
                                            onClick={this.setBloodPressureLocation.bind(
                                                this,
                                                "",
                                                "Home"
                                            )}
                                        >
                                            <label>
                                                {
                                                    content.Steps.BloodPressure
                                                        .Location.Home
                                                }
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                bloodPressureLocation &&
                                                bloodPressureLocation.indexOf(
                                                    "Pharmacy"
                                                ) > -1
                                                    ? "blood-pressure-location selected"
                                                    : "blood-pressure-location"
                                            }
                                            onClick={this.setBloodPressureLocation.bind(
                                                this,
                                                "",
                                                "Pharmacy: "
                                            )}
                                        >
                                            <label>
                                                {
                                                    content.Steps.BloodPressure
                                                        .Location.Pharmacy
                                                }
                                            </label>
                                            <div>
                                                <InputField
                                                    label={
                                                        content.Steps
                                                            .BloodPressure
                                                            .Location
                                                            .PharmacyAdvice
                                                    }
                                                    defaultValue={
                                                        bloodPressureLocation
                                                            ? bloodPressureLocation
                                                                  .replace(
                                                                      "Pharmacy: ",
                                                                      ""
                                                                  )
                                                                  .replace(
                                                                      "Home",
                                                                      ""
                                                                  )
                                                            : ""
                                                    }
                                                    name="pharmacy"
                                                    onInput={this.setBloodPressureLocation.bind(
                                                        this,
                                                        "Pharmacy: "
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {bloodPressureLocation === "Home" && (
                                        <iframe
                                            src="https://www.youtube.com/embed/8C06spK9Md4"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    )}
                                    <div className="blood-pressure-advice">
                                        {content.Steps.BloodPressure.Remote}
                                    </div>
                                    <div className="blood-pressure-reading key">
                                        <p></p>
                                        <p>
                                            {
                                                content.Steps.BloodPressure
                                                    .Systolic
                                            }
                                        </p>
                                        <p>
                                            {
                                                content.Steps.BloodPressure
                                                    .Diastolic
                                            }
                                        </p>
                                    </div>
                                    {Array(READINGS_REQUESTED)
                                        .fill()
                                        .map((x, index) => (
                                            <div className="blood-pressure-reading">
                                                <p>
                                                    {
                                                        content.Steps
                                                            .BloodPressure
                                                            .OrdinalReadings[
                                                            index
                                                        ]
                                                    }
                                                </p>
                                                <div className="blood-pressure-changer">
                                                    <input
                                                        type="number"
                                                        name="currentBloodPressure"
                                                        onBlur={this.onSystolicBlur.bind(
                                                            this,
                                                            index
                                                        )}
                                                        defaultValue={
                                                            systolicBloodPressureReadings[
                                                                index
                                                            ]
                                                        }
                                                        disabled={!remoteBP}
                                                    />
                                                    <span
                                                        className={
                                                            remoteBP
                                                                ? "separator"
                                                                : "remote separator"
                                                        }
                                                    >
                                                        /
                                                    </span>
                                                    <input
                                                        type="number"
                                                        name="currentBloodPressure"
                                                        onBlur={this.onDiastolicBlur.bind(
                                                            this,
                                                            index
                                                        )}
                                                        defaultValue={
                                                            diastolicBloodPressureReadings[
                                                                index
                                                            ]
                                                        }
                                                        disabled={!remoteBP}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    {remoteBP && (
                                        <NoIdea
                                            modalTitle={
                                                content.Steps.BloodPressure
                                                    .Modal.Title
                                            }
                                            modalContent={
                                                content.Steps.BloodPressure
                                                    .Modal.Content
                                            }
                                            modalLinkHref={
                                                BLOOD_PRESSURE_MODAL_HREF
                                            }
                                            modalLinkText={
                                                content.Steps.BloodPressure
                                                    .Modal.ButtonText
                                            }
                                            history={history}
                                            showInFrame
                                            noModal
                                        />
                                    )}
                                    <InfoModal
                                        content={
                                            <>
                                                {
                                                    content.Steps.BloodPressure
                                                        .MoreReadingsRequested
                                                }
                                                <div className="actions">
                                                    <Button
                                                        onClick={this.toggleInfoModal.bind(
                                                            this
                                                        )}
                                                    >
                                                        {content.Steps.Back}
                                                    </Button>
                                                    <Button
                                                        greyed
                                                        onClick={() => {
                                                            this.toggleInfoModal();
                                                            let nextPage = `${
                                                                process.env
                                                                    .PUBLIC_URL
                                                            }/test/${
                                                                parseInt(
                                                                    activeStep
                                                                ) + 1
                                                            }`;
                                                            if (
                                                                remoteBT &&
                                                                ((remoteBP &&
                                                                    missingSections.length ===
                                                                        0) ||
                                                                    (!remoteBP &&
                                                                        missingSectionsNotIncludingBP.length ===
                                                                            0)) &&
                                                                userStatus <
                                                                    6 &&
                                                                lastStep
                                                            ) {
                                                                nextPage = `${process.env.PUBLIC_URL}/blood-test`;
                                                            } else if (
                                                                lastStep
                                                            ) {
                                                                nextPage = `${process.env.PUBLIC_URL}/test-summary`;
                                                            }

                                                            history.push(
                                                                nextPage
                                                            );
                                                        }}
                                                    >
                                                        {
                                                            content.Steps
                                                                .SummaryContinue
                                                        }
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        open={
                                            bloodPressureMoreReadingsRequested
                                        }
                                        closeModal={this.toggleInfoModal.bind(
                                            this
                                        )}
                                    />
                                </>
                            )}
                        </FieldCard>
                    </section>
                )}

                <div className="missing-values in-test">
                    <ul>
                        {this.missingSectionLinks(
                            checkMissingSections(form, false)
                        )}
                    </ul>
                </div>
            </TestStep>
        );
    }
}

export default BloodPressureStep;
