import * as actionTypes from "../actionTypes";
import axios from "axios";

import { API_BASE } from "../../lookup/api";

const CONTACT_API_URL = `${API_BASE}/enquiry.ashx`;

export const sendEnquiryAction = (email, message) => {
    const errors = {};
    const EMAIL_REGEX =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!EMAIL_REGEX.test(email)) {
        errors.email = "invalid";
    }
    if (message === "") {
        errors.message = "invalid";
    }

    if (Object.keys(errors).length > 0) {
        return (dispatch) => dispatch(sendEnquiryFailureAction(errors));
    }

    if (process.env.REACT_APP_MOCK_API) {
        return (dispatch) => dispatch(sendEnquirySuccessAction(email));
    }

    let form = {
        email,
        message
    };

    return (dispatch) => {
        return axios
            .post(CONTACT_API_URL, JSON.stringify(form), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(`Error: ${data.ErrorMessage}`);
                } else {
                    dispatch(sendEnquirySuccessAction(email));
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const sendEnquirySuccessAction = (email) => ({
    type: actionTypes.SEND_ENQUIRY_SUCCESS,
    email
});

export const sendEnquiryFailureAction = (errors) => ({
    type: actionTypes.SEND_ENQUIRY_FAILURE,
    errors
});
