import React from "react";
import "./SectionHeader.scss";

const SectionHeader = ({ isMedical, children }) => (
    <header className={isMedical ? "section-header medical" : "section-header"}>
        <h3>{children}</h3>
    </header>
);

export default SectionHeader;
