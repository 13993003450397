import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    pharmacies: [],
    chosenPharmacy: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_PHARMACIES:
            return {
                ...state,
                pharmacies: action.pharmacies
            };
        case actionTypes.CHOOSE_PHARMACY: {
            return {
                ...state,
                chosenPharmacy: action.pharmacy
            };
        }
        default:
            return state;
    }
};
