import React from "react";
import { Link } from "react-router-dom";

import "./Header.scss";
import content from "../../lookup/en/content";

class Header extends React.Component {
    state = {
        flyoutOpen: false
    };

    toggleFlyoutMenu() {
        this.setState({
            flyoutOpen: !this.state.flyoutOpen
        });
    }

    render() {
        const { logoVisible, showAuthMessage, authMessage } = this.props;

        return (
            <>
                <header
                    className={
                        window.location.pathname === "/"
                            ? "main-nav-header home-header"
                            : "main-nav-header"
                    }
                >
                    <nav className="top-navigation">
                        <Link
                            to={`${process.env.PUBLIC_URL}/`}
                            className={
                                logoVisible ? "logo visible" : "logo hidden"
                            }
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/logo_nhsht.jpg`}
                                alt="tickertest"
                                className="small-devices-only"
                            />
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/logo_nhsht_large.jpg`}
                                alt="tickertest"
                                className="desktop-only"
                            />
                        </Link>
                        <div
                            className="hamburger"
                            onClick={this.toggleFlyoutMenu.bind(this)}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </nav>
                </header>
                <nav
                    className={
                        this.state.flyoutOpen
                            ? "flyout-navigation open"
                            : "flyout-navigation"
                    }
                >
                    <section>
                        <div
                            className="close"
                            onClick={this.toggleFlyoutMenu.bind(this)}
                        >
                            &#10005;
                        </div>
                        <Link
                            to={`${process.env.PUBLIC_URL}`}
                            onClick={this.toggleFlyoutMenu.bind(this)}
                        >
                            {content.Header.Home}
                        </Link>
                        <Link
                            to={`${process.env.PUBLIC_URL}/what-is-nhs-health-check`}
                            onClick={this.toggleFlyoutMenu.bind(this)}
                        >
                            {content.Home.AboutButton}
                        </Link>

                        <div className="sub-links">
                            <Link
                                to={`${process.env.PUBLIC_URL}/about`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.About}
                            </Link>

                            <Link
                                to={`${process.env.PUBLIC_URL}/faqs`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.FAQs}
                            </Link>

                            <Link
                                to={`${process.env.PUBLIC_URL}/terms`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.Terms}
                            </Link>
                            <Link
                                to={`${process.env.PUBLIC_URL}/privacy`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.Disclaimers}
                            </Link>
                            <Link
                                to={`${process.env.PUBLIC_URL}/cookies`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.Cookies}
                            </Link>

                            <Link
                                to={`${process.env.PUBLIC_URL}/contact`}
                                onClick={this.toggleFlyoutMenu.bind(this)}
                            >
                                {content.Footer.Contact}
                            </Link>
                        </div>
                    </section>
                </nav>
                <div className="header-spacer" />
                {showAuthMessage && (
                    <div className="session-timeout">{authMessage}</div>
                )}
            </>
        );
    }
}

export default Header;
