import React, { Component } from "react";

import content from "../../lookup/en/content";

import "./HealthyLivingAdvice.scss";

class HealthyLivingAdvice extends Component {
    render() {
        return (
            <div className="advice-container">
                <h1>{content.HealthyLivingAdvice.Title}</h1>
                <p className="read-length">
                    {content.HealthyLivingAdvice.ReadingLength}
                </p>

                <div className="social"></div>

                <section>
                    <h2>{content.HealthyLivingAdvice.StopSmoking.Title}</h2>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/stop-smoking.png`}
                        alt={content.HealthyLivingAdvice.StopSmoking.Title}
                        className="left"
                    />
                    <div className="advice-content">
                        {content.HealthyLivingAdvice.StopSmoking.Content}
                    </div>
                </section>

                <section>
                    <h2>{content.HealthyLivingAdvice.Alcohol.Title}</h2>
                    <div className="advice-content">
                        {content.HealthyLivingAdvice.Alcohol.Content}
                    </div>
                </section>

                <section>
                    <h2>
                        {content.HealthyLivingAdvice.PhysicalActivity.Title}
                    </h2>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/physical-activity.png`}
                        alt={content.HealthyLivingAdvice.StopSmoking.Title}
                        className="center"
                    />
                    <div className="advice-content break">
                        {content.HealthyLivingAdvice.PhysicalActivity.Content}
                    </div>
                </section>

                <section>
                    <h2>
                        {content.HealthyLivingAdvice.WeightManagement.Title}
                    </h2>
                    <div className="advice-content">
                        {content.HealthyLivingAdvice.WeightManagement.Content}
                    </div>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/weight-management.png`}
                        alt={content.HealthyLivingAdvice.StopSmoking.Title}
                        className="right"
                    />
                </section>

                <section>
                    <h2>{content.HealthyLivingAdvice.Diabetes.Title}</h2>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/diabetes.png`}
                        alt={content.HealthyLivingAdvice.StopSmoking.Title}
                        className="left"
                    />
                    <div className="advice-content">
                        {content.HealthyLivingAdvice.Diabetes.Content}
                    </div>
                </section>

                <section>
                    <h2>{content.HealthyLivingAdvice.Dementia.Title}</h2>
                    <div className="advice-content">
                        {content.HealthyLivingAdvice.Dementia.Content}
                    </div>
                </section>
            </div>
        );
    }
}

export default HealthyLivingAdvice;
