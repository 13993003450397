import React, { Component } from "react";
import { Link } from "react-router-dom";

import content from "../../lookup/en/content";
import Button from "../button/Button";
import QuestionnaireLink from "../questionnaire-link/QuestionnaireLink";
import ResultsListing from "../results-listing/ResultsListing";

import "./IncompleteTest.scss";

const SAFE_WORD = "FINISH";

class IncompleteTest extends Component {
    state = {
        alertOpen: false,
        safeWord: ""
    };

    componentDidMount() {
        const { form, submit, missingSections } = this.props;

        const testStatus = localStorage.getItem("userMMHStatus");
        if (
            form.remoteBT &&
            parseInt(testStatus) === 6 &&
            missingSections &&
            missingSections.length === 0
        ) {
            submit();
        }
    }

    missingSectionLinks(sections) {
        return sections.map((item) => {
            if (item !== "Cholesterol") {
                return (
                    <Link
                        to={`/test/${item + 1}?return=true`}
                        key={`link${item}`}
                    >
                        <li>{content.StepTitles[item]}</li>
                    </Link>
                );
            } else {
                return null;
                return (
                    <Link to="#'" key={`link${item}`}>
                        <li>{content.Summary.MissingValues.CholesterolTest}</li>
                    </Link>
                );
            }
        });
    }

    toggleWarning() {
        this.setState({
            alertOpen: !this.state.alertOpen
        });
    }

    updateSafeWord(value) {
        this.setState({
            safeWord: value
        });
    }

    render() {
        const { alertOpen, safeWord } = this.state;
        const {
            missingSections,
            missingSectionsNotIncludingBP,
            form,
            includeDiabetes
        } = this.props;

        const accessibleVersion = sessionStorage.getItem(
            "preference_visualImpairment"
        );

        const testStatus = localStorage.getItem("userMMHStatus");

        let sectionsRequired = missingSections;
        if (form.remoteBloodPressure === false) {
            sectionsRequired = missingSectionsNotIncludingBP;
        }

        return (
            <>
                <section className="missing-values">
                    <div>
                        <img
                            src={
                                sectionsRequired && sectionsRequired.length > 0
                                    ? `${process.env.PUBLIC_URL}/assets/nearly-there.png`
                                    : parseInt(testStatus) > 1
                                    ? `${process.env.PUBLIC_URL}/assets/man-with-phone.jpg`
                                    : `${process.env.PUBLIC_URL}/assets/test-kit.png`
                            }
                            alt={content.Summary.MissingValues.Title}
                        />
                        {sectionsRequired && sectionsRequired.length > 0 && (
                            <>
                                <h3>{content.Summary.MissingValues.Title}</h3>
                                <p>{content.Summary.MissingValues.Intro}</p>
                                <ul>
                                    {this.missingSectionLinks(sectionsRequired)}
                                </ul>
                                {sectionsRequired &&
                                    sectionsRequired.indexOf("Cholesterol") >
                                        -1 && (
                                        <p>
                                            {
                                                content.Summary.MissingValues
                                                    .CholesterolTestExplanation
                                            }
                                        </p>
                                    )}
                            </>
                        )}

                        {sectionsRequired &&
                            sectionsRequired.length === 0 &&
                            form.remoteBloodTest === false && (
                                <>
                                    <h3>
                                        {
                                            content.Summary.MissingValues
                                                .TitleComplete
                                        }
                                    </h3>
                                    {
                                        content.Summary.MissingValues
                                            .IntroComplete
                                    }

                                    {/* <a
                                        href={form.bookingLink}
                                        class="button wide external"
                                        target="_blank"
                                    >
                                        {
                                            content.Steps.BloodPressure
                                                .BookingLink
                                        }
                                    </a> */}
                                </>
                            )}

                        {sectionsRequired &&
                            sectionsRequired.length === 0 &&
                            form.remoteBloodTest && (
                                <>
                                    <h3>
                                        {testStatus > 6
                                            ? content.Summary.MissingValues
                                                  .TitleError
                                            : content.Summary.MissingValues
                                                  .TitleComplete}
                                    </h3>
                                    {testStatus > 6 && testStatus < 13
                                        ? ""
                                        : testStatus < 3
                                        ? content.Summary.MissingValues
                                              .IntroCompleteRemote
                                        : content.Summary.MissingValues
                                              .IntroProcessing}
                                </>
                            )}

                        {form.remoteBloodTest && (
                            <div className="blood-test-status">
                                <h4>
                                    {
                                        content.Summary.MissingValues
                                            .CholesterolTest
                                    }
                                </h4>
                                <ul>
                                    <li
                                        className={
                                            parseInt(testStatus) > 0
                                                ? "checked"
                                                : "not-checked"
                                        }
                                    >
                                        {
                                            content.Summary.MissingValues
                                                .CholesterolTestStatus
                                                .OrderReceived
                                        }
                                    </li>
                                    <li
                                        className={
                                            parseInt(testStatus) > 1
                                                ? "checked"
                                                : "not-checked"
                                        }
                                    >
                                        {
                                            content.Summary.MissingValues
                                                .CholesterolTestStatus.KitSent
                                        }
                                    </li>
                                    <li
                                        className={
                                            parseInt(testStatus) > 2
                                                ? "checked"
                                                : "not-checked"
                                        }
                                    >
                                        {
                                            content.Summary.MissingValues
                                                .CholesterolTestStatus
                                                .SampleReceived
                                        }
                                    </li>
                                    <li
                                        className={
                                            parseInt(testStatus) > 6 &&
                                            parseInt(testStatus) < 13
                                                ? "error"
                                                : parseInt(testStatus) === 6 ||
                                                  parseInt(testStatus) === 13
                                                ? "checked"
                                                : "not-checked"
                                        }
                                    >
                                        {parseInt(testStatus) <= 6 ||
                                        parseInt(testStatus) === 13
                                            ? content.Summary.MissingValues
                                                  .CholesterolTestStatus
                                                  .Complete
                                            : content.Summary.MissingValues
                                                  .CholesterolTestStatus.Error}
                                    </li>
                                </ul>
                            </div>
                        )}

                        {testStatus > 6 && testStatus < 13
                            ? content.Summary.MissingValues.IntroError(
                                  includeDiabetes
                              )
                            : ""}

                        {testStatus <= 6 && (
                            <>
                                <ResultsListing form={form} />

                                <Button
                                    greyed
                                    href={`${process.env.PUBLIC_URL}/test/11`}
                                >
                                    {content.Steps.Back}
                                </Button>
                            </>
                        )}
                    </div>
                </section>
            </>
        );
    }
}

export default IncompleteTest;
