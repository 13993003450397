export const cornwallPharmacies = [
    {
        "@search.score": 1,
        Address1: "St Clare Rd",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 363340"}]',
        County: "",
        Latitude: 50.1232238124314,
        Longitude: -5.546549806,
        OpeningTimes: "",
        OrganisationName: "St Clare Medical centre",
        Postcode: "TR183DX"
    },
    {
        "@search.score": 1,
        Address1: "St Clare St",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 330 909"}]',
        County: "",
        Latitude: 50.123224297421416,
        Longitude: -5.546933002,
        OpeningTimes: "",
        OrganisationName: "Rosmellyn Surgery (St Clare Medical centre)",
        Postcode: "TR183DX"
    },
    {
        "@search.score": 1,
        Address1: "St Clare Street, Penzance,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 362110"}]',
        County: "",
        Latitude: 50.123150783225455,
        Longitude: -5.546518592,
        OpeningTimes: "",
        OrganisationName: "Day Lewis Pharmacy (St Clare Medical Centre)",
        Postcode: "TR18 3DX"
    },
    {
        "@search.score": 1,
        Address1: "5 The Strand, Newlyn, Penzance,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 362324 "}]',
        County: "",
        Latitude: 50.10673262636401,
        Longitude: -5.550164014,
        OpeningTimes: "",
        OrganisationName: "Newlyn Pharmacy",
        Postcode: "TR18 5HH"
    },
    {
        "@search.score": 1,
        Address1: "The Square, Marazion, Penzance, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 710206 "}]',
        County: "",
        Latitude: 50.12386635568467,
        Longitude: -5.473823633,
        OpeningTimes: "",
        OrganisationName: "Baden House Pharmacy",
        Postcode: "TR17 0AP"
    },
    {
        "@search.score": 1,
        Address1: "Units 1 - 2, Trevose House, Carbis Bay,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 799025 "}]',
        County: "",
        Latitude: 50.19292555172843,
        Longitude: -5.462915123,
        OpeningTimes: "",
        OrganisationName: "Carbis Bay Pharmacy",
        Postcode: "TR26 2FZ"
    },
    {
        "@search.score": 1,
        Address1: "42 Fore Street, St Just In Penwith, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 788440"}]',
        County: "",
        Latitude: 50.123905907946515,
        Longitude: -5.680031441,
        OpeningTimes: "",
        OrganisationName: "Ramsay Pharmacy",
        Postcode: "TR19 7LJ"
    },
    {
        "@search.score": 1,
        Address1: "Stennack, St. Ives, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 795047 "}]',
        County: "",
        Latitude: 50.210705232469785,
        Longitude: -5.484988174,
        OpeningTimes: "",
        OrganisationName: "Boots",
        Postcode: "TR26 1RU"
    },
    {
        "@search.score": 1,
        Address1: "7 Fore Street, St Ives,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 795432 "}]',
        County: "",
        Latitude: 50.212982066396336,
        Longitude: -5.480374273,
        OpeningTimes: "",
        OrganisationName: "Leddra Pharmacy (Naimans)",
        Postcode: "TR26 1AB"
    },
    {
        "@search.score": 1,
        Address1: "44-46 Fore St, Hayle, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 752189"}]',
        County: "",
        Latitude: 50.191936033493,
        Longitude: -5.413226392,
        OpeningTimes: "",
        OrganisationName: "Boots",
        Postcode: "TR27 4DY"
    },
    {
        "@search.score": 1,
        Address1: "Landlake Rd, Launceston",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01566 772 131"}]',
        County: "",
        Latitude: 50.62795259741264,
        Longitude: -4.360682993,
        OpeningTimes: "",
        OrganisationName: "Launceston Medical centre ",
        Postcode: "PL15 9HH"
    },
    {
        "@search.score": 1,
        Address1: "9, Broad Street, Launceston, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01566 772081"}]',
        County: "",
        Latitude: 50.63688336662083,
        Longitude: -4.361439029,
        OpeningTimes: "",
        OrganisationName: "Day Lewis Pharmacy",
        Postcode: "PL15 8AA"
    },
    {
        "@search.score": 1,
        Address1: "17-18 Fore Street, Callington,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01579 383106"}]',
        County: "",
        Latitude: 50.50326568434334,
        Longitude: -4.316280636,
        OpeningTimes: "",
        OrganisationName: "LloydsPharmacy",
        Postcode: "PL17 7AE"
    },
    {
        "@search.score": 1,
        Address1: "Haye Rd, Callington ",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01579 382666 "}]',
        County: "",
        Latitude: 50.50371948743961,
        Longitude: -4.320478292,
        OpeningTimes: "",
        OrganisationName: "Callington Community Clinic",
        Postcode: "PL17 7JJ"
    },
    {
        "@search.score": 1,
        Address1: "The Orchard, Gunnislake, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01822 832641  "}]',
        County: "",
        Latitude: 50.52518441679058,
        Longitude: -4.216004863,
        OpeningTimes: "",
        OrganisationName: "Gunnislake Health Centre",
        Postcode: "PL18 9JZ"
    },
    {
        "@search.score": 1,
        Address1: "28, Plymouth Road, Tavistock, Devon,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01822 612104 "}]',
        County: "",
        Latitude: 50.548059345346175,
        Longitude: -4.148576779,
        OpeningTimes: "",
        OrganisationName: "Day Lewis Pharmacy",
        Postcode: "PL19 8BU"
    },
    {
        "@search.score": 1,
        Address1: "Abbey Rise, Tavistock, Devon,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01822 617894 "}]',
        County: "",
        Latitude: 50.54780629760558,
        Longitude: -4.145970265,
        OpeningTimes: "",
        OrganisationName: "Tavyside Pharmacy (Modbury)",
        Postcode: "PL19 9FD"
    },
    {
        "@search.score": 1,
        Address1: "Plymouth Road, Tavistock, Devon,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01822 613153  "}]',
        County: "",
        Latitude: 50.537985833724505,
        Longitude: -4.147662665,
        OpeningTimes: "",
        OrganisationName: "Morrisons Pharmacy",
        Postcode: "PL19 9AA"
    },
    {
        "@search.score": 1,
        Address1: "Oak Tree Surgery, Clemo Road, Liskeard, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01579 552562 "}]',
        County: "",
        Latitude: 50.460521043577124,
        Longitude: -4.451354347,
        OpeningTimes: "",
        OrganisationName: "Oak Tree Pharmacy",
        Postcode: "PL14 3XA"
    },
    {
        "@search.score": 1,
        Address1: "Pengarth",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01873 553 881 "}]',
        County: "",
        Latitude: 50.31113114102372,
        Longitude: -5.202288896,
        OpeningTimes: "",
        OrganisationName: "St Agnes Surgery ",
        Postcode: "TR5 0TN"
    },
    {
        "@search.score": 1,
        Address1: "55 Vicarage Road, St Agnes",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01872 552340"}]',
        County: "",
        Latitude: 50.309677,
        Longitude: -5.204196,
        OpeningTimes: "",
        OrganisationName: "Boots ",
        Postcode: "TR5 0TH"
    },
    {
        "@search.score": 1,
        Address1: "2 Pengelly Way, Threemilestone, Truro, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01872 277343 "}]',
        County: "",
        Latitude: 50.26292164924645,
        Longitude: -5.115407222,
        OpeningTimes: "",
        OrganisationName: "Victoria Pharmacy (Naimans)",
        Postcode: "TR3 6DP"
    },
    {
        "@search.score": 1,
        Address1: "Basset Rd, Redruth, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01209 843856 "}]',
        County: "",
        Latitude: 50.245647460407675,
        Longitude: -5.267234712,
        OpeningTimes: "",
        OrganisationName: "Boots",
        Postcode: "TR16 4SS"
    },
    {
        "@search.score": 1,
        Address1: "84 Fore St, Redruth",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01209 215056"}]',
        County: "",
        Latitude: 50.23344873291552,
        Longitude: -5.229537987,
        OpeningTimes: "",
        OrganisationName: "Boots",
        Postcode: "TR152BL"
    },
    {
        "@search.score": 1,
        Address1: "16 Chapel Street, Redruth, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01209 211056 "}]',
        County: "",
        Latitude: 50.23370535667623,
        Longitude: -5.230548056,
        OpeningTimes: "",
        OrganisationName: "Day Lewis Pharmacy",
        Postcode: "TR15 2DB"
    },
    {
        "@search.score": 1,
        Address1: "Infirmary Hill, Truro, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01872 243700  "}]',
        County: "",
        Latitude: 50.26010737066316,
        Longitude: -5.055634701,
        OpeningTimes: "",
        OrganisationName: "Lander medical practice",
        Postcode: "TR1 2JA"
    },
    {
        "@search.score": 1,
        Address1: "13 Boscawen Street, Truro,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01872 274838 "}]',
        County: "",
        Latitude: 50.26322913978767,
        Longitude: -5.05194395,
        OpeningTimes: "",
        OrganisationName: "Superdrug Stores",
        Postcode: "TR1 2QU"
    },
    {
        "@search.score": 1,
        Address1: "10 Commercial St, Camborne, Cornwall,",
        Address2: null,
        Address3: null,
        City: null,
        Contacts: '[{"OrganisationContactValue":"01209 713194  "}]',
        County: "",
        Latitude: 50.21321753669369,
        Longitude: -5.300805881,
        OpeningTimes: "",
        OrganisationName: "Boots",
        Postcode: "TR14 8JY"
    },
    {
        "@search.score": 1,
        Address1: "TESCO STORE",
        Address2: "TAVISTOCK ROAD",
        Address3: "LAUNCESTON",
        City: null,
        Contacts: '[{"OrganisationContactValue":"01566 776975"}]',
        County: "",
        Latitude: 50.63010397106943,
        Longitude: -4.36,
        OpeningTimes: "",
        OrganisationName: "TESCO STORES LIMITED",
        Postcode: ""
    },
    {
        "@search.score": 1,
        Address1: "28 BROAD STREET",
        Address2: "LAUNCESTON",
        Address3: "CORNWALL",
        City: null,
        Contacts: '[{"OrganisationContactValue":"01566 772085"}]',
        County: "",
        Latitude: 50.63695171381907,
        Longitude: -4.36,
        OpeningTimes: "",
        OrganisationName: "BOOTS UK LIMITED",
        Postcode: ""
    },
    {
        "@search.score": 1,
        Address1: "55 VICARAGE ROAD",
        Address2: "0",
        Address3: "ST AGNES",
        City: null,
        Contacts: '[{"OrganisationContactValue":"01872 552340"}]',
        County: "",
        Latitude: 50.30954712547546,
        Longitude: -5.2,
        OpeningTimes: "",
        OrganisationName: "BOOTS UK LIMITED",
        Postcode: ""
    },
    {
        "@search.score": 1,
        Address1: "MORRAB SURGERY",
        Address2: "2 MORRAB ROAD",
        Address3: "PENZANCE",
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 332008"}]',
        County: "",
        Latitude: 50.117348352167795,
        Longitude: -5.54,
        OpeningTimes: "",
        OrganisationName: "MORRAB PHARMACY LIMITED",
        Postcode: ""
    },
    {
        "@search.score": 1,
        Address1: "EASTERN GREEN",
        Address2: "0",
        Address3: "PENZANCE",
        City: null,
        Contacts: '[{"OrganisationContactValue":""}]',
        County: "",
        Latitude: 50.128480664496095,
        Longitude: -5.52,
        OpeningTimes: "",
        OrganisationName: "LLOYDS PHARMACY LTD",
        Postcode: ""
    },
    {
        "@search.score": 1,
        Address1: "100-102 MARKET JEW STREET",
        Address2: "PENZANCE",
        Address3: "CORNWALL",
        City: null,
        Contacts: '[{"OrganisationContactValue":"01736 362135"}]',
        County: "",
        Latitude: 50.11988910048525,
        Longitude: -5.54,
        OpeningTimes: "",
        OrganisationName: "BOOTS UK LIMITED",
        Postcode: ""
    }
];

export const wolverhamptonPharmacies = [
    {
        OrganisationName: "Murrays Healthcare",
        Address1: "128 Childs Avenue",
        Address2: "Bilston",
        Address3: "Wolverhampton",
        Postcode: "WV14 9XB",
        Latitude: 52.55068,
        Longitude: -2.10054,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Bradley Chemist",
        Address1: "83 Hall Green Street",
        Address2: "Bradley",
        Address3: "Wolverhampton",
        Postcode: "WV14 8TH",
        Latitude: 52.55286,
        Longitude: -2.07281,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Brutons Pharmacy (Bradley)",
        Address1: "1 Mervyn Place",
        Address2: "Bradley",
        Address3: "Wolverhampton",
        Postcode: "WV14 8DD",
        Latitude: 52.55402,
        Longitude: -2.06022,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "High Street Pharmacy",
        Address1: "76 High Street",
        Address2: "Bilston",
        Address3: "Wolverhampton",
        Postcode: "WV14 0EP",
        Latitude: 52.56392,
        Longitude: -2.08004,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Superdrug Pharmacy",
        Address1: "1 Market Way",
        Address2: "Bilston",
        Address3: "Wolverhampton",
        Postcode: "WV14 0DR",
        Latitude: 52.56467,
        Longitude: -2.07688,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Church Pharmacy",
        Address1: "45 Church Street",
        Address2: "Bilston",
        Address3: "Wolverhampton",
        Postcode: "WV14 0AX",
        Latitude: 52.56541,
        Longitude: -2.07649,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Boots",
        Address1: "The Health Centre Prounds Lane",
        Address2: "Bilston",
        Address3: "Wolverhampton",
        Postcode: "WV14 6PW",
        Latitude: 52.56892,
        Longitude: -2.07711,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Rexall Chemist",
        Address1: "204 Penn Road",
        Address2: "Penn",
        Address3: "Wolverhampton",
        Postcode: "WV4 4AA",
        Latitude: 52.56784,
        Longitude: -2.14482,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Ettingshall Pharmacy",
        Address1: "3 New Street",
        Address2: "Ettingshall",
        Address3: "Wolverhampton",
        Postcode: "WV2 2LR",
        Latitude: 52.56989,
        Longitude: -2.09863,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Pennfields Pharmacy",
        Address1: "248 Jeffcock Road",
        Address2: "Pennfields",
        Address3: "Wolverhampton",
        Postcode: "WV3 7AH",
        Latitude: 52.57337,
        Longitude: -2.14645,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "All Saints Pharmacy",
        Address1: "91-93 Vicarage Road",
        Address2: "All Saints",
        Address3: "Wolverhampton",
        Postcode: "WV2 1DR",
        Latitude: 52.5782,
        Longitude: -2.12139,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Mayfield Pharmacy",
        Address1: "272 Willenhall Road",
        Address3: "Wolverhampton",
        Postcode: "WV1 2GZ",
        Latitude: 52.58305,
        Longitude: -2.09328,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Superdrug Pharmacy",
        Address1: "Unit 12, Central Arcade, Mander Centre",
        Address3: "Wolverhampton",
        Postcode: "WV1 3NJ",
        Latitude: 52.5845,
        Longitude: -2.1281,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Brooklands Pharmacy",
        Address1: "48 Brooklands Parade",
        Address3: "Wolverhampton",
        Postcode: "WV1 2NE",
        Latitude: 52.58554,
        Longitude: -2.09642,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Boots",
        Address1: "40-41 Dudley Street",
        Address3: "Wolverhampton",
        Postcode: "WV1 3ER",
        Latitude: 52.58487,
        Longitude: -2.12689,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Bridgnorth Road Pharmacy",
        Address1: "41 Bridgnorth Road",
        Address2: "Compton",
        Address3: "Wolverhampton",
        Postcode: "WV6 8AF",
        Latitude: 52.58574,
        Longitude: -2.17699,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Jhoots Pharmacy",
        Address1: "34-35 Thornley Street",
        Address3: "Wolverhampton",
        Postcode: "WV1 1JP",
        Latitude: 52.58843,
        Longitude: -2.12579,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Asda Pharmacy",
        Address1: "Waterloo Road",
        Address3: "Wolverhampton",
        Postcode: "WV1 4AZ",
        Latitude: 52.5916,
        Longitude: -2.13417,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Jhoots Pharmacy",
        Address1: "255 Tettenhall Road",
        Address3: "Wolverhampton",
        Postcode: "WV6 0DE",
        Latitude: 52.5936,
        Longitude: -2.1553,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Boots",
        Address1: "Unit 84, Bentley Bridge Retail Park",
        Address2: "Wednesfield",
        Address3: "Wolverhampton",
        Postcode: "WV11 1BP",
        Latitude: 52.59681,
        Longitude: -2.09025,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "LloydsPharmacy",
        Address1: "Rookery Street",
        Address2: "Wednesfield",
        Address3: "Wolverhampton",
        Postcode: "WV11 1UP",
        Latitude: 52.59756,
        Longitude: -2.08624,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Newbridge Pharmacy",
        Address1: "325-327 Tettenhall Road",
        Address3: "Wolverhampton",
        Postcode: "WV6 0JZ",
        Latitude: 52.5967,
        Longitude: -2.15964,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Fallings Park Pharmacy",
        Address1: "212 Bushbury Road",
        Address2: "Fallings Park",
        Address3: "Wolverhampton",
        Postcode: "WV10 0NT",
        Latitude: 52.60228,
        Longitude: -2.10347,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Your Pharmacy First",
        Address1: "1 Raynor Road",
        Address2: "Fallings Park",
        Address3: "Wolverhampton",
        Postcode: "WV10 9QY",
        Latitude: 52.60496,
        Longitude: -2.10592,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    },
    {
        OrganisationName: "Low Hill Pharmacy",
        Address1: "8 Showell Circus",
        Address2: "Low Hill",
        Address3: "Wolverhampton",
        Postcode: "WV10 9BA",
        Latitude: 52.61022,
        Longitude: -2.1128,
        City: null,
        Contacts: "[]",
        County: "",
        OpeningTimes: "",
        "@search.score": 1
    }
];
