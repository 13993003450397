import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    lookupAddresses: [],
    selectedAddress: {
        line1: null,
        line2: null,
        locality: null,
        town: null,
        county: null,
        postalCode: null
    },
    personalDetails: {
        titleID: null,
        firstName: null,
        lastName: null
    },
    latitude: null,
    longitude: null,
    errors: {},
    bloodTestOrderPlaceSuccess: false,
    bpKitLoanOrderPlaceSuccess: false,
    addressConfirmed: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                bloodTestOrderPlaceSuccess:
                    INITIAL_STATE.bloodTestOrderPlaceSuccess
            };
        case actionTypes.SET_LOOKUP_ADDRESSES:
            return {
                ...state,
                lookupAddresses: action.addresses
            };
        case actionTypes.CLEAR_LOOKUP_ADDRESSES:
            return {
                ...state,
                lookupAddresses: []
            };
        case actionTypes.CHOOSE_ADDRESS:
            return {
                ...state,
                lookupAddresses: [],
                selectedAddress: {
                    ...state.selectedAddress,
                    ...action.address
                }
            };
        case actionTypes.SET_PERSONAL_DETAILS:
            return {
                ...state,
                personalDetails: {
                    ...state.personalDetails,
                    [action.key]: action.value
                }
            };
        case actionTypes.SET_ADDRESS_COMPONENT:
            return {
                ...state,
                selectedAddress: {
                    ...state.selectedAddress,
                    [action.key]: action.value
                }
            };
        case actionTypes.SET_POSTCODE_VALID:
            return {
                ...state,
                selectedAddress: {
                    ...INITIAL_STATE.selectedAddress,
                    postalCode: action.postalCode
                }
            };
        case actionTypes.SET_LAT_LNG:
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude
            };
        case actionTypes.SET_ADDRESS_FORM_ERRORS:
            return {
                ...state,
                errors: action.errors,
                bloodTestOrderPlaceSuccess: false,
                bpKitLoanOrderPlaceSuccess: false
            };
        case actionTypes.ADDRESS_VALIDATED: {
            return {
                ...state,
                bloodTestOrderPlaceSuccess: true
            };
        }
        case actionTypes.CHOLESTEROL_TEST_ORDER_PLACE_SUCCESS: {
            return {
                ...state,
                addressConfirmed: true
            };
        }
        case actionTypes.BP_KIT_LOAN_ORDER_PLACE_SUCCESS: {
            return {
                ...state,
                bpKitLoanOrderPlaceSuccess: true
            };
        }
        default:
            return state;
    }
};
