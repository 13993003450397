import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import InputField from "../../components/input-field/InputField";
import PharmacyMap from "../../components/pharmacy-map/PharmacyMap";
import "./BloodPressureTests.scss";
import content from "../../lookup/en/content";
import { GOOGLE_MAPS_API_KEY } from "../../lookup/en/formValues";
import { setPostcodeAction } from "../../actions/form/formActions";
import { postcodeLookupAction } from "../../actions/address/addressActions";
import {
    getPharmaciesAction,
    getWolverhamptonPharmaciesAction,
    choosePharmacyAction
} from "../../actions/pharmacy/pharmacyActions";
import Button from "../../components/button/Button";

const PENZANCE_LATITUDE = 50.1188;
const PENZANCE_LONGITUDE = -5.5376;
const WOLVERHAMPTON_LATITUDE = 52.58567;
const WOLVERHAMPTON_LONGITUDE = -2.1234;
class BloodPressureTests extends React.Component {
    state = {
        leaving: false,
        pinFocused: false
    };

    componentWillMount() {
        const {
            setPostcodeAction,
            postcodeLookupAction,
            getWolverhamptonPharmaciesAction,
            postalCode
        } = this.props;

        getWolverhamptonPharmaciesAction();

        if (!postalCode) {
            const defaultValue = localStorage.getItem("postalCode");
            setPostcodeAction(defaultValue);
            if (defaultValue) postcodeLookupAction(defaultValue);
        } else {
            postcodeLookupAction(postalCode);
        }
    }

    close() {
        this.setState({
            leaving: true
        });

        setTimeout(() => {
            this.props.history.goBack();
        }, 500);
    }

    getDistanceBetweenLatLngPoints(lat1, lng1, lat2, lng2) {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lng1 - lng2;
        const radtheta = (Math.PI * theta) / 180;

        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return parseFloat(dist.toFixed(2));
    }

    mapPharmacyAddress(pharmacy) {
        let components = [
            pharmacy.Address1,
            pharmacy.Address2,
            pharmacy.Address3,
            pharmacy.City,
            pharmacy.County,
            pharmacy.Postcode
        ]
            .join(", ")
            .replace(/ ,/g, "");
        return components;
    }

    postcodeLookup(value) {
        const { postcodeLookupAction, getPharmaciesAction, token } = this.props;

        postcodeLookupAction(value, (data) => {
            getPharmaciesAction(token, data.latitude, data.longitude);
        });
    }

    markerClick(markerData) {
        const { chosenPharmacy, choosePharmacyAction } = this.props;

        if (
            chosenPharmacy &&
            markerData.Postcode === chosenPharmacy.Postcode &&
            markerData.OrganisationName === chosenPharmacy.OrganisationName
        )
            choosePharmacyAction({});
        else choosePharmacyAction(markerData);

        this.setState({
            pinFocused: true
        });
    }

    mapOpeningHours(rawData) {
        const data = JSON.parse(rawData);

        const openingTimes = {
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: "",
            Sunday: ""
        };

        data.forEach((entry) => {
            if (entry.WeekDay === "" || entry.Times === "") return;

            let day = openingTimes[entry.WeekDay];
            if (day !== "") day += ", ";

            day += entry.Times;

            openingTimes[entry.WeekDay] = day;
        });

        return Object.keys(openingTimes).map((day) => (
            <React.Fragment key={day}>
                <span>{day}</span>
                <span>{openingTimes[day] || "Closed"}</span>
            </React.Fragment>
        ));
    }

    openTidio() {
        document.body.classList.add("show-chat");
        window.tidioChatApi.open();
        window.tidioChatApi.on("close", () => {
            document.body.classList.remove("show-chat");
        });
    }

    render() {
        const {
            postalCode,
            latCenter,
            lngCenter,
            pharmacies,
            chosenPharmacy,
            preferences
        } = this.props;

        let containerClassName = "blood-pressure-tests";
        if (window.frameElement) containerClassName += " framed";
        if (this.state.leaving) containerClassName += " leaving";

        if (preferences.mobilityIssues)
            return (
                <Redirect
                    to={`${process.env.PUBLIC_URL}/loan-blood-pressure-kit`}
                />
            );

        return (
            <div className={containerClassName}>
                <header>
                    <div>{content.BloodPressureTests.Title}</div>
                    <span className="close" onClick={this.close.bind(this)}>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/close.png`}
                            alt="Close"
                        />
                    </span>
                </header>
                <div className="spacer" />
                <PharmacyMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_API_KEY}`}
                    loadingElement={<div className="pharmacy-map" />}
                    containerElement={
                        <div
                            className={
                                this.state.pinFocused
                                    ? "pharmacy-map minimised"
                                    : "pharmacy-map"
                            }
                        />
                    }
                    mapElement={<div className="pharmacy-map" />}
                    latCenter={
                        chosenPharmacy.Latitude || WOLVERHAMPTON_LATITUDE
                    }
                    lngCenter={
                        chosenPharmacy.Longitude || WOLVERHAMPTON_LONGITUDE
                    }
                    markers={pharmacies}
                    chosenPharmacy={chosenPharmacy}
                    markerClick={this.markerClick.bind(this)}
                />
                {/* <InputField
                    placeholder={content.BloodPressureTests.InputText}
                    name="postcode"
                    buttonText={content.BloodPressureTests.ButtonText}
                    defaultValue={postalCode}
                    buttonClick={this.postcodeLookup.bind(this)}
                /> */}
                <div className="bp-content">
                    {content.BloodPressureTests.Content}
                </div>
                {/* <Button onClick={this.openTidio.bind(this)}>
                    {content.BloodPressureTests.Chat}
                </Button> */}
                <div className="pharmacies">
                    {pharmacies
                        .sort((a, b) => {
                            if (
                                this.getDistanceBetweenLatLngPoints(
                                    latCenter,
                                    lngCenter,
                                    a.Latitude,
                                    a.Longitude
                                ) >
                                this.getDistanceBetweenLatLngPoints(
                                    latCenter,
                                    lngCenter,
                                    b.Latitude,
                                    b.Longitude
                                )
                            )
                                return 1;

                            return -1;
                        })
                        .map((pharmacy, index) => (
                            <div
                                className={
                                    pharmacy.Postcode ===
                                        chosenPharmacy.Postcode &&
                                    pharmacy.OrganisationName ===
                                        chosenPharmacy.OrganisationName
                                        ? "pharmacy selected"
                                        : "pharmacy"
                                }
                                onClick={this.markerClick.bind(this, pharmacy)}
                                key={`${pharmacy.Postcode}${index}`}
                            >
                                <section>
                                    <div className="details">
                                        <h3>{pharmacy.OrganisationName}</h3>
                                        <p className="address">
                                            {this.mapPharmacyAddress(pharmacy)}
                                        </p>
                                    </div>
                                    <div className="distance">
                                        {latCenter && lngCenter && (
                                            <p>
                                                {this.getDistanceBetweenLatLngPoints(
                                                    latCenter,
                                                    lngCenter,
                                                    pharmacy.Latitude,
                                                    pharmacy.Longitude
                                                )}{" "}
                                                {
                                                    content.BloodPressureTests
                                                        .Kilometres
                                                }
                                            </p>
                                        )}
                                    </div>
                                    <div className="expando" />
                                    <div className="more-details">
                                        {/*<div className="opening-times">
                                        <strong>
                                            {content.BloodPressureTests.Hours}
                                        </strong>
                                        <div>
                                             {this.mapOpeningHours(
                                                pharmacy.OpeningTimes
                                            )}
                                        </div>
                                    </div>*/}
                                        {JSON.parse(pharmacy.Contacts).map(
                                            (contact) => (
                                                <div
                                                    key={
                                                        contact.OrganisationContactValue
                                                    }
                                                >
                                                    <strong>
                                                        {
                                                            contact.OrganisationContactMethodType
                                                        }
                                                    </strong>
                                                    <div>
                                                        {
                                                            contact.OrganisationContactValue
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </section>
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        token: state.auth.token,
        postalCode: state.form.postalCode,
        latCenter: state.address.latitude,
        lngCenter: state.address.longitude,
        pharmacies: state.pharmacies.pharmacies,
        chosenPharmacy: state.pharmacies.chosenPharmacy,
        preferences: state.preferences
    }),
    {
        postcodeLookupAction,
        setPostcodeAction,
        getPharmaciesAction,
        getWolverhamptonPharmaciesAction,
        choosePharmacyAction
    }
)(BloodPressureTests);
