import axios from "axios";

import { API_BASE } from "../../lookup/api";
import * as actionTypes from "../actionTypes";

const TRACK_PAGEVIEW_URL = `${API_BASE}/trackpageview.ashx`;

export const trackPageViewAction = (pageid) => {
    let form = {
        token: localStorage.getItem("token") || "",
        userkey: localStorage.getItem("userKey") || "",
        pageid
    };

    return (dispatch) => {
        return axios
            .post(TRACK_PAGEVIEW_URL, JSON.stringify(form), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                dispatch(trackPageViewSuccessAction());
            })
            .catch((error) => {
                dispatch(trackPageViewErrorAction());
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const trackPageViewSuccessAction = () => ({
    type: actionTypes.TRACK_PAGEVIEW_SUCCESS
});

export const trackPageViewErrorAction = () => ({
    type: actionTypes.TRACK_PAGEVIEW_FAILURE
});
