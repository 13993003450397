import React, { Component } from "react";

import TestStep from "../../test-step/TestStep";
import FieldCard from "../../field-card/FieldCard";

import content from "../../../lookup/en/content";

import "./DementiaStep.scss";

class DementiaStep extends Component {
    componentDidUpdate() {
        const { activeStep } = this.props;
        if (activeStep === 5) {
            localStorage.setItem("dementiaScreen", "viewed");
        }
    }

    render() {
        const {
            activeStep,
            remoteBT,
            missingSections,
            missingSectionsNotIncludingBP
        } = this.props;

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={5}
                remoteBT={remoteBT}
                missingSections={missingSections}
                missingSectionsNotIncludingBP={missingSectionsNotIncludingBP}
            >
                <section className="dementia-step">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/dementia.png`}
                        alt="Dementia awareness"
                        className="dementia-image"
                    />
                    <FieldCard cardTitle={content.Steps.Dementia.Title}>
                        {content.Steps.Dementia.Content}
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default DementiaStep;
