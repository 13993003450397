import React, { Component } from "react";
import { connect } from "react-redux";

import content from "../../lookup/en/content";
import {
    alcoholConsumptionOptions,
    alcoholUnitOptions,
    binaryDrinkingOptions,
    drinkingFrequencyOptions,
    ethnicityMap,
    exerciseOptions,
    physicalWorkDefinitions,
    smokingLevels,
    waistMeasurements,
    newwalkingPaceOptions
} from "../../lookup/en/formValues";
import { mapFormAndGetRisk } from "../../actions/risk/riskActions";

import {
    convertCmToFeetAndInches,
    convertFeetAndInchesToCm,
    convertKgToStoneAndLbs,
    convertStoneAndLbsToKg
} from "../../util";

import "./AccessibleTest.scss";
import Button from "../../components/button/Button";

class AccessibileTest extends Component {
    state = {
        Postcode: null,
        Age: null,
        Sex: null,
        EthnicBackground: null,
        Height: null,
        HeightFeet: null,
        HeightInches: null,
        HeightUnit: "cm",
        Weight: null,
        WeightStone: null,
        WeightPounds: null,
        WeightUnit: "kg",
        SmokingStatus: null,
        Familyheart: null,
        Systolicbp1: null,
        DiastolicBP1: null,
        bloodPressureLocation: null,
        DiabetesFamily: null,
        Waist: null,
        WaistUnit: "cm",
        AlcoholOften: null,
        AlcoholUnits: null,
        AlcoholBinge: null,
        unableToStop: null,
        failedExpectations: null,
        morningDrinking: null,
        drinkingGuilt: null,
        blackout: null,
        drinkingInjury: null,
        drinkingConcern: null,
        PhysicalActivityHoursCycling: null,
        PhysicalActivityHoursWalking: null,
        PhysicalActivityHours: null,
        PhysicalActivityHoursHouseworkChildcare: null,
        PhysicalActivityHoursGardeningDIY: null,
        PhysicalActivityWork: null,
        WalkingPace: null
    };

    constructor(props) {
        super(props);
        this.stepRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef()
        ];
    }

    scrollToRef(ref) {
        const offset = document.querySelector("header").clientHeight;
        document
            .querySelector("#root")
            .scrollTo(0, ref.current.offsetTop - offset);
    }

    componentWillMount() {
        let defaultAgeValue = parseInt(localStorage.getItem("age"));

        if (!defaultAgeValue) {
            const today = new Date();
            const verifiedDob = JSON.parse(
                localStorage.getItem("userVerification")
            );

            let age = today.getFullYear() - parseInt(verifiedDob.yearOfBirth);
            if (
                today.getMonth() + 1 < parseInt(verifiedDob.monthOfBirth) ||
                (today.getMonth() + 1 === parseInt(verifiedDob.monthOfBirth) &&
                    today.getDate() < parseInt(verifiedDob.dayOfBirth))
            ) {
                age--;
            }

            defaultAgeValue = age;
            localStorage.setItem("age", age);
        }

        this.setState({
            Postcode: localStorage.getItem("postalCode"),
            Age: defaultAgeValue,
            Sex: localStorage.getItem("gender"),
            EthnicBackground: localStorage.getItem("ethnicity"),
            Height: localStorage.getItem("heightInCm"),
            HeightFeet: localStorage.getItem("heightInFeet"),
            HeightInches: localStorage.getItem("heightInInches"),
            Weight: localStorage.getItem("weightInKg"),
            WeightStone: localStorage.getItem("weightInStone"),
            WeightPounds: localStorage.getItem("weightInLbs"),
            SmokingStatus: localStorage.getItem("smokingLevel"),
            Familyheart: localStorage.getItem("familyHistory"),
            Systolicbp1: localStorage.getItem("systolicBloodPressureReadings")
                ? localStorage
                      .getItem("systolicBloodPressureReadings")
                      .split(",")[0]
                : null,
            DiastolicBP1: localStorage.getItem("diastolicBloodPressureReadings")
                ? localStorage
                      .getItem("diastolicBloodPressureReadings")
                      .split(",")[0]
                : null,
            bloodPressureLocation: localStorage.getItem(
                "bloodPressureLocation"
            ),
            DiabetesFamily: localStorage.getItem("diabetesInFamily"),
            Waist: localStorage.getItem("waistMeasurement"),
            AlcoholOften: localStorage.getItem("alcoholConsumption"),
            AlcoholUnits: localStorage.getItem("alcoholUnits"),
            AlcoholBinge: localStorage.getItem("bingeSessions"),
            unableToStop: localStorage.getItem("unableToStop"),
            failedExpectations: localStorage.getItem("failedExpectations"),
            morningDrinking: localStorage.getItem("morningDrinking"),
            drinkingGuilt: localStorage.getItem("drinkingGuilt"),
            blackout: localStorage.getItem("blackout"),
            drinkingInjury: localStorage.getItem("drinkingInjury"),
            drinkingConcern: localStorage.getItem("drinkingConcern"),
            PhysicalActivityHoursCycling: localStorage.getItem(
                "activityHoursCycling"
            ),
            PhysicalActivityHoursWalking: localStorage.getItem(
                "activityHoursWalking"
            ),
            PhysicalActivityHours: localStorage.getItem(
                "activityHoursExercise"
            ),
            PhysicalActivityHoursHouseworkChildcare: localStorage.getItem(
                "activityHoursHouseworkChildcare"
            ),
            PhysicalActivityHoursGardeningDIY: localStorage.getItem(
                "activityHoursGardeningDIY"
            ),
            PhysicalActivityWork: localStorage.getItem("physicalWork"),
            WalkingPace: localStorage.getItem("walkingPace")
        });

        this.showAdditionalAuditQuestions();
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const returnStep = params.get("return");
        if (returnStep) {
            const refIndex = parseInt(returnStep) - 1;
            setTimeout(() => {
                this.scrollToRef(this.stepRefs[refIndex]);
            }, 1);
        }
    }

    updateForm(key, storageKey, event) {
        const { auth, form, mapFormAndGetRisk } = this.props;

        this.setState({
            [key]: event.target.value
        });

        localStorage.setItem(storageKey, event.target.value);

        this.showAdditionalAuditQuestions();

        mapFormAndGetRisk(
            localStorage.getItem("token"),
            form,
            key,
            event.target.value
        );
    }

    updateHeightFeet(key, event) {
        let feet, inches;
        if (key === "HeightFeet") {
            feet = event.target.value;
            inches = this.state.HeightInches;
        } else if (key === "HeightInches") {
            feet = this.state.HeightFeet;
            inches = event.target.value;
        }

        this.setState({
            HeightFeet: feet,
            HeightInches: inches
        });

        convertFeetAndInchesToCm(feet, inches);
    }

    updateWeightStone(key, event) {
        let stone, pounds;
        if (key === "WeightStone") {
            stone = event.target.value;
            pounds = this.state.WeightPounds;
        } else if (key === "WeightPounds") {
            stone = this.state.WeightStone;
            pounds = event.target.value;
        }

        this.setState({
            WeightStone: stone,
            WeightPounds: pounds
        });

        convertStoneAndLbsToKg(stone, pounds);
    }

    showAdditionalAuditQuestions() {
        let alcoholConsumption =
            parseInt(localStorage.getItem("alcoholConsumption")) || 0;
        let alcoholUnits = parseInt(localStorage.getItem("alcoholUnits")) || 0;
        let bingeSessions =
            parseInt(localStorage.getItem("bingeSessions")) || 0;

        if (alcoholConsumption > 0) alcoholConsumption--;
        if (alcoholUnits > 0) alcoholUnits--;
        if (bingeSessions > 0) bingeSessions--;

        this.setState({
            fullAudit: alcoholConsumption + alcoholUnits + bingeSessions >= 5
        });
    }

    render() {
        return (
            <form className="accessible-test">
                <fieldset ref={this.stepRefs[0]}>
                    <label htmlFor="EthnicBackground">
                        {content.Steps.Ethnicity.Title}
                    </label>
                    <div className="radio-group">
                        {ethnicityMap
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((e) => {
                                return (
                                    <label className="radio-label">
                                        <input
                                            type="radio"
                                            name="EthnicBackground"
                                            value={e.id}
                                            checked={
                                                parseInt(
                                                    this.state.EthnicBackground
                                                ) === e.id
                                            }
                                            onChange={this.updateForm.bind(
                                                this,
                                                "EthnicBackground",
                                                "ethnicity"
                                            )}
                                        />{" "}
                                        {e.displayName}
                                    </label>
                                );
                            })}
                    </div>
                </fieldset>

                <fieldset ref={this.stepRefs[1]}>
                    <label htmlFor="Height">{content.Steps.Height.Title}</label>

                    <div className="radio-group unit-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="HeightUnit"
                                value="cm"
                                onChange={this.updateForm.bind(
                                    this,
                                    "HeightUnit",
                                    "HeightUnit"
                                )}
                                checked={this.state.HeightUnit === "cm"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Height.Centimetres}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="HeightUnit"
                                value="feet"
                                onChange={this.updateForm.bind(
                                    this,
                                    "HeightUnit",
                                    "HeightUnit"
                                )}
                                checked={this.state.HeightUnit === "feet"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Height.Feet}
                        </label>
                    </div>

                    {this.state.HeightUnit === "cm" && (
                        <div className="multi-unit">
                            <label htmlFor="Height">
                                {content.Steps.Height.Centimetres}
                                <input
                                    name="Height"
                                    value={this.state.Height}
                                    onChange={this.updateForm.bind(
                                        this,
                                        "Height",
                                        "heightInCm"
                                    )}
                                />
                            </label>
                        </div>
                    )}

                    {this.state.HeightUnit === "feet" && (
                        <div className="multi-unit">
                            <label htmlFor="HeightFeet">
                                {content.Steps.Height.Feet}
                                <input
                                    name="HeightFeet"
                                    value={this.state.HeightFeet}
                                    onChange={this.updateHeightFeet.bind(
                                        this,
                                        "HeightFeet",
                                        "heightInFeet"
                                    )}
                                />
                            </label>
                            <label htmlFor="HeightInches">
                                {content.Steps.Height.Inches}
                                <input
                                    name="HeightInches"
                                    value={this.state.HeightInches}
                                    onChange={this.updateHeightFeet.bind(
                                        this,
                                        "HeightInches",
                                        "heightInInches"
                                    )}
                                />
                            </label>
                        </div>
                    )}
                </fieldset>

                <fieldset>
                    <label htmlFor="Weight">{content.Steps.Weight.Title}</label>

                    <div className="radio-group unit-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="WeightUnit"
                                value="kg"
                                onChange={this.updateForm.bind(
                                    this,
                                    "WeightUnit",
                                    "WeightUnit"
                                )}
                                checked={this.state.WeightUnit === "kg"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Weight.Kilograms}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="WeightUnit"
                                value="stone"
                                onChange={this.updateForm.bind(
                                    this,
                                    "WeightUnit",
                                    "WeightUnit"
                                )}
                                checked={this.state.WeightUnit === "stone"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Weight.Stone}
                        </label>
                    </div>

                    {this.state.WeightUnit === "kg" && (
                        <div className="multi-unit">
                            <label htmlFor="Weight">
                                {content.Steps.Weight.Kilograms}
                                <input
                                    name="Weight"
                                    value={this.state.Weight}
                                    onChange={this.updateForm.bind(
                                        this,
                                        "Weight",
                                        "weightInKg"
                                    )}
                                />
                            </label>
                        </div>
                    )}

                    {this.state.WeightUnit === "stone" && (
                        <div className="multi-unit">
                            <label htmlFor="WeightStone">
                                {content.Steps.Weight.Stone}
                                <input
                                    name="WeightStone"
                                    value={this.state.WeightStone}
                                    onChange={this.updateWeightStone.bind(
                                        this,
                                        "WeightStone",
                                        "weightInStone"
                                    )}
                                />
                            </label>
                            <label htmlFor="WeightPounds">
                                {content.Steps.Weight.Pounds}
                                <input
                                    name="WeightPounds"
                                    value={this.state.WeightPounds}
                                    onChange={this.updateWeightStone.bind(
                                        this,
                                        "WeightPounds",
                                        "weightInLbs"
                                    )}
                                />
                            </label>
                        </div>
                    )}
                </fieldset>

                <fieldset ref={this.stepRefs[2]}>
                    <label htmlFor="Postcode">
                        {content.Steps.PostCode.Title}
                    </label>
                    <input
                        name="Postcode"
                        onChange={this.updateForm.bind(
                            this,
                            "Postcode",
                            "postalCode"
                        )}
                        value={this.state.Postcode}
                    />
                </fieldset>

                <fieldset ref={this.stepRefs[3]}>
                    <label htmlFor="Age">{content.Steps.Age.Title}</label>
                    <p>{this.state.Age}</p>
                </fieldset>

                <fieldset>
                    <label htmlFor="Sex">{content.Steps.Gender.Title}</label>
                    <div className="radio-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="Sex"
                                value={1}
                                checked={
                                    this.state.Sex === "1" ||
                                    this.state.Sex === 1
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "Sex",
                                    "gender"
                                )}
                            />{" "}
                            {content.Steps.Gender.Male}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="Sex"
                                value={2}
                                checked={
                                    this.state.Sex === "2" ||
                                    this.state.Sex === 2
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "Sex",
                                    "gender"
                                )}
                            />{" "}
                            {content.Steps.Gender.Female}
                        </label>
                    </div>
                </fieldset>

                <fieldset ref={this.stepRefs[4]}>
                    <label htmlFor="SmokingStatus">
                        {content.Steps.Smoking.Title}
                    </label>
                    <div className="radio-group">
                        {smokingLevels.map((sl) => {
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="SmokingStatus"
                                        value={sl.id}
                                        checked={
                                            parseInt(
                                                this.state.SmokingStatus
                                            ) === sl.id
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "SmokingStatus",
                                            "smokingLevel"
                                        )}
                                    />{" "}
                                    {sl.description}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="Familyheart">
                        {content.Steps.FamilyHistory.Title}
                    </label>
                    <div className="radio-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="Familyheart"
                                value={true}
                                checked={
                                    this.state.Familyheart === "True" ||
                                    this.state.Familyheart === "true" ||
                                    this.state.Familyheart
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "Familyheart",
                                    "familyHistory"
                                )}
                            />{" "}
                            {content.Steps.FamilyHistory.Yes}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="Familyheart"
                                value={false}
                                checked={
                                    this.state.Familyheart === "False" ||
                                    this.state.Familyheart === "false" ||
                                    !this.state.Familyheart
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "Familyheart",
                                    "familyHistory"
                                )}
                            />{" "}
                            {content.Steps.FamilyHistory.No}
                        </label>
                    </div>
                </fieldset>

                <fieldset ref={this.stepRefs[5]}>
                    <label htmlFor="DiabetesFamily">
                        {content.Steps.DiabetesRisk.Family}
                    </label>
                    <div className="radio-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="DiabetesFamily"
                                value={true}
                                checked={
                                    this.state.DiabetesFamily === "True" ||
                                    this.state.DiabetesFamily === "true" ||
                                    this.state.DiabetesFamily === true ||
                                    parseInt(this.state.DiabetesFamily) === 1
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "DiabetesFamily",
                                    "diabetesInFamily"
                                )}
                            />{" "}
                            {content.Steps.DiabetesRisk.Yes}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="DiabetesFamily"
                                value={false}
                                checked={
                                    this.state.DiabetesFamily === "False" ||
                                    this.state.DiabetesFamily === "false" ||
                                    this.state.DiabetesFamily === false ||
                                    parseInt(this.state.DiabetesFamily) === 0
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "DiabetesFamily",
                                    "diabetesInFamily"
                                )}
                            />{" "}
                            {content.Steps.DiabetesRisk.No}
                        </label>
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="Waist">
                        {content.Steps.DiabetesRisk.WaistMeasurement}
                    </label>

                    <div className="radio-group unit-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="WaistUnit"
                                value="cm"
                                onChange={this.updateForm.bind(
                                    this,
                                    "WaistUnit",
                                    "WaistUnit"
                                )}
                                checked={this.state.WaistUnit === "cm"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Height.Centimetres}
                        </label>
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="WaistUnit"
                                value="inches"
                                onChange={this.updateForm.bind(
                                    this,
                                    "WaistUnit",
                                    "WaistUnit"
                                )}
                                checked={this.state.WaistUnit === "inches"}
                            />{" "}
                            {content.Steps.SwitchUnit}{" "}
                            {content.Steps.Height.Inches}
                        </label>
                    </div>

                    <div className="radio-group">
                        {waistMeasurements.map((wm) => {
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="Waist"
                                        value={wm.value}
                                        checked={
                                            parseInt(this.state.Waist) ===
                                            wm.value
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "Waist",
                                            "waistMeasurement"
                                        )}
                                    />{" "}
                                    {wm.displayNames[this.state.WaistUnit]}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset ref={this.stepRefs[6]}>
                    <label htmlFor="AlcoholOften">
                        {content.Steps.Alcohol.HowOften}
                    </label>
                    <div className="radio-group">
                        {alcoholConsumptionOptions.map((a, index) => {
                            if (a === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="AlcoholOften"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state.AlcoholOften
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "AlcoholOften",
                                            "alcoholConsumption"
                                        )}
                                    />{" "}
                                    {a.displayName}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="AlcoholUnits">
                        {content.Steps.Alcohol.HowMuch}
                    </label>
                    <div className="radio-group">
                        {alcoholUnitOptions.map((a, index) => {
                            if (a === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="AlcoholUnits"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state.AlcoholUnits
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "AlcoholUnits",
                                            "alcoholUnits"
                                        )}
                                    />{" "}
                                    {a.displayName}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="AlcoholBinge">
                        {content.Steps.Alcohol.BingeDrinking}
                    </label>
                    <div className="radio-group">
                        {alcoholConsumptionOptions.map((a, index) => {
                            if (a === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="AlcoholBinge"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state.AlcoholBinge
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "AlcoholBinge",
                                            "bingeSessions"
                                        )}
                                    />{" "}
                                    {a.displayName}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                {this.state.fullAudit && (
                    <>
                        <fieldset>
                            <label htmlFor="unableToStop">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .UnableToStop
                                }
                            </label>
                            <div className="radio-group">
                                {drinkingFrequencyOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="unableToStop"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state.unableToStop
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "unableToStop",
                                                    "unableToStop"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="failedExpectations">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .FailedExpectations
                                }
                            </label>
                            <div className="radio-group">
                                {drinkingFrequencyOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="failedExpectations"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state
                                                            .failedExpectations
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "failedExpectations",
                                                    "failedExpectations"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="morningDrinking">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .MorningDrinking
                                }
                            </label>
                            <div className="radio-group">
                                {drinkingFrequencyOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="morningDrinking"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state
                                                            .morningDrinking
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "morningDrinking",
                                                    "morningDrinking"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="drinkingGuilt">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingGuilt
                                }
                            </label>
                            <div className="radio-group">
                                {drinkingFrequencyOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="drinkingGuilt"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state.drinkingGuilt
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "drinkingGuilt",
                                                    "drinkingGuilt"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="blackout">
                                {content.Steps.Alcohol.AdditionalSteps.Blackout}
                            </label>
                            <div className="radio-group">
                                {drinkingFrequencyOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="blackout"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state.blackout
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "blackout",
                                                    "blackout"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="drinkingInjury">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingInjury
                                }
                            </label>
                            <div className="radio-group">
                                {binaryDrinkingOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="drinkingInjury"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state
                                                            .drinkingInjury
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "drinkingInjury",
                                                    "drinkingInjury"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="drinkingConcern">
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingConcern
                                }
                            </label>
                            <div className="radio-group">
                                {binaryDrinkingOptions.map((a, index) => {
                                    if (a === "Please select") return null;
                                    return (
                                        <label className="radio-label">
                                            <input
                                                type="radio"
                                                name="drinkingConcern"
                                                value={index}
                                                checked={
                                                    parseInt(
                                                        this.state
                                                            .drinkingConcern
                                                    ) === index
                                                }
                                                onChange={this.updateForm.bind(
                                                    this,
                                                    "drinkingConcern",
                                                    "drinkingConcern"
                                                )}
                                            />{" "}
                                            {a.displayName}
                                        </label>
                                    );
                                })}
                            </div>
                        </fieldset>
                    </>
                )}

                <fieldset ref={this.stepRefs[7]}>
                    <label htmlFor="PhysicalActivityWork">
                        {content.Steps.PhysicalActivity.PhysicalWork}
                    </label>
                    <div className="radio-group">
                        {physicalWorkDefinitions.map((d) => {
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityWork"
                                        value={d.key}
                                        checked={
                                            parseInt(
                                                this.state.PhysicalActivityWork
                                            ) === parseInt(d.key)
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityWork",
                                            "physicalWork"
                                        )}
                                    />{" "}
                                    {d.displayName}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="PhysicalActivityHoursCycling">
                        {content.Steps.PhysicalActivity.HoursCycling}
                    </label>
                    <div className="radio-group">
                        {exerciseOptions.map((e, index) => {
                            if (e === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityHoursCycling"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state
                                                    .PhysicalActivityHoursCycling
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityHoursCycling",
                                            "activityHoursCycling"
                                        )}
                                    />{" "}
                                    {e}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="PhysicalActivityHoursWalking">
                        {content.Steps.PhysicalActivity.HoursWalking}
                    </label>
                    <div className="radio-group">
                        {exerciseOptions.map((e, index) => {
                            if (e === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityHoursWalking"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state
                                                    .PhysicalActivityHoursWalking
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityHoursWalking",
                                            "activityHoursWalking"
                                        )}
                                    />{" "}
                                    {e}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="PhysicalActivityHours">
                        {content.Steps.PhysicalActivity.HoursExercise}
                    </label>
                    <div className="radio-group">
                        {exerciseOptions.map((e, index) => {
                            if (e === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityHours"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state.PhysicalActivityHours
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityHours",
                                            "activityHoursExercise"
                                        )}
                                    />{" "}
                                    {e}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="PhysicalActivityHoursHouseworkChildcare">
                        {content.Steps.PhysicalActivity.HoursHouseworkChildcare}
                    </label>
                    <div className="radio-group">
                        {exerciseOptions.map((e, index) => {
                            if (e === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityHoursHouseworkChildcare"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state
                                                    .PhysicalActivityHoursHouseworkChildcare
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityHoursHouseworkChildcare",
                                            "activityHoursHouseworkChildcare"
                                        )}
                                    />{" "}
                                    {e}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="PhysicalActivityHoursGardeningDIY">
                        {content.Steps.PhysicalActivity.HoursGardeningDIY}
                    </label>
                    <div className="radio-group">
                        {exerciseOptions.map((e, index) => {
                            if (e === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="PhysicalActivityHoursGardeningDIY"
                                        value={index}
                                        checked={
                                            parseInt(
                                                this.state
                                                    .PhysicalActivityHoursGardeningDIY
                                            ) === index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "PhysicalActivityHoursGardeningDIY",
                                            "activityHoursGardeningDIY"
                                        )}
                                    />{" "}
                                    {e}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset>
                    <label htmlFor="WalkingPace">
                        {content.Steps.PhysicalActivity.WalkingPace}
                    </label>
                    <div className="radio-group">
                        {newwalkingPaceOptions.map((w, index) => {
                            if (w === "Please select") return null;
                            return (
                                <label className="radio-label">
                                    <input
                                        type="radio"
                                        name="WalkingPace"
                                        value={index}
                                        checked={
                                            parseInt(this.state.WalkingPace) ===
                                            index
                                        }
                                        onChange={this.updateForm.bind(
                                            this,
                                            "WalkingPace",
                                            "walkingPace"
                                        )}
                                    />{" "}
                                    {w}
                                </label>
                            );
                        })}
                    </div>
                </fieldset>

                <fieldset ref={this.stepRefs[8]}>
                    <label htmlFor="BloodPressure">
                        {content.Steps.BloodPressure.Title}
                    </label>

                    <p>{content.Steps.BloodPressure.Systolic}</p>
                    <input
                        name="Systolicbp1"
                        value={this.state.Systolicbp1}
                        onChange={this.updateForm.bind(
                            this,
                            "Systolicbp1",
                            "systolicBloodPressureReadings"
                        )}
                    />

                    <p>{content.Steps.BloodPressure.Diastolic}</p>
                    <input
                        name="DiastolicBP1"
                        value={this.state.DiastolicBP1}
                        onChange={this.updateForm.bind(
                            this,
                            "DiastolicBP1",
                            "diastolicBloodPressureReadings"
                        )}
                    />

                    <div className="radio-group">
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="bloodPressureLocation"
                                value="Home"
                                checked={
                                    this.state.bloodPressureLocation &&
                                    this.state.bloodPressureLocation.indexOf(
                                        "Home"
                                    ) > -1
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "bloodPressureLocation",
                                    "bloodPressureLocation"
                                )}
                            />{" "}
                            {content.Steps.BloodPressure.Location.Home}
                        </label>
                        {this.state.bloodPressureLocation === "Home" && (
                            <p>
                                {
                                    content.Steps.BloodPressure.Location
                                        .HomeAdvice
                                }
                            </p>
                        )}
                        <label className="radio-label">
                            <input
                                type="radio"
                                name="bloodPressureLocation"
                                value="Pharmacy"
                                checked={
                                    this.state.bloodPressureLocation &&
                                    this.state.bloodPressureLocation.indexOf(
                                        "Pharmacy"
                                    ) > -1
                                }
                                onChange={this.updateForm.bind(
                                    this,
                                    "bloodPressureLocation",
                                    "bloodPressureLocation"
                                )}
                            />{" "}
                            {content.Steps.BloodPressure.Location.Pharmacy}
                        </label>
                    </div>
                </fieldset>

                <Button href={`${process.env.PUBLIC_URL}/test-summary`}>
                    {content.Steps.Summary}
                </Button>
            </form>
        );
    }
}

export default connect(
    (state) => ({
        form: state.form
    }),
    {
        mapFormAndGetRisk
    }
)(AccessibileTest);
