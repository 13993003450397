import React, { Component, Fragment } from "react";

import content from "../../lookup/en/content";
import {
    ethnicityMap,
    smokingLevels,
    diabetesTypes,
    relevantMedicalConditions,
    alcoholConsumptionOptions,
    alcoholUnitOptions,
    drinkingFrequencyOptions,
    exerciseOptions,
    physicalWorkDefinitions,
    binaryDrinkingOptions,
    newwalkingPaceOptions
} from "../../lookup/en/formValues";
import {
    convertCmToFeetAndInches,
    convertKgToStoneAndLbs,
    getLowestBPReadings
} from "../../util";

import "./ResultsListing.scss";

class ResultsListing extends Component {
    render() {
        const { form } = this.props;
        const { lowestSystolicValue, lowestDiastolicValue } =
            getLowestBPReadings(form);

        return (
            <dl className="full-parameters">
                {form.ethnicity && parseInt(form.ethnicity) !== 0 && (
                    <Fragment key="listing-4">
                        <dt>{content.Steps.Ethnicity.Title}</dt>
                        <dd>
                            {
                                ethnicityMap.find(
                                    (ethnicity) =>
                                        ethnicity.id ===
                                        parseInt(form.ethnicity)
                                ).displayName
                            }
                        </dd>
                    </Fragment>
                )}
                {form.heightUnit === "cm" && form.heightInCm && (
                    <Fragment key="listing-6">
                        <dt>{content.Steps.Height.Title}</dt>
                        <dd>
                            {form.heightInCm} {content.Steps.Height.Centimetres}
                        </dd>
                    </Fragment>
                )}
                {form.heightUnit === "feet" && form.heightInCm && (
                    <Fragment key="listing-5">
                        <dt>{content.Steps.Height.Title}</dt>
                        <dd>
                            {convertCmToFeetAndInches(form.heightInCm).feet}{" "}
                            {content.Steps.Height.Feet}{" "}
                            {convertCmToFeetAndInches(form.heightInCm).inches}{" "}
                            {content.Steps.Height.Inches}
                        </dd>
                    </Fragment>
                )}

                {form.weightUnit === "kg" && form.weightInKg && (
                    <Fragment key="listing-8">
                        <dt>{content.Steps.Weight.Title}</dt>
                        <dd>
                            {form.weightInKg} {content.Steps.Weight.Kilograms}
                        </dd>
                    </Fragment>
                )}

                {form.weightUnit === "stone" && form.weightInKg && (
                    <Fragment key="listing-7">
                        <dt>{content.Steps.Weight.Title}</dt>
                        <dd>
                            {convertKgToStoneAndLbs(form.weightInKg).stone}{" "}
                            {content.Steps.Weight.Stone}{" "}
                            {convertKgToStoneAndLbs(form.weightInKg).lbs}{" "}
                            {content.Steps.Weight.Pounds}
                        </dd>
                    </Fragment>
                )}
                {form.postalCode && (
                    <Fragment key="listing-1">
                        <dt>{content.Steps.PostCode.Title}</dt>
                        <dd>{form.postalCode}</dd>
                    </Fragment>
                )}
                {form.age && (
                    <Fragment key="listing-2">
                        <dt>{content.Steps.Age.Title}</dt>
                        <dd>{form.age}</dd>
                    </Fragment>
                )}
                {form.gender && parseInt(form.gender) !== 0 && (
                    <Fragment key="listing-3">
                        <dt>{content.Steps.Gender.Title}</dt>
                        <dd>
                            {parseInt(form.gender) === 1 ? "Male" : "Female"}
                        </dd>
                    </Fragment>
                )}
                {form.smokingLevel && parseInt(form.smokingLevel) !== 5 && (
                    <Fragment key="listing-10">
                        <dt>{content.Steps.Smoking.Title}</dt>
                        <dd>
                            {
                                smokingLevels.find(
                                    (smokingLevel) =>
                                        smokingLevel.id ===
                                        parseInt(form.smokingLevel)
                                ).description
                            }
                        </dd>
                    </Fragment>
                )}
                {form.familyHistory !== null && (
                    <Fragment key="listing-13">
                        <dt>{content.Steps.FamilyHistory.Title}</dt>
                        <dd>
                            {form.familyHistory === "False" ||
                            form.familyHistory === "false" ||
                            !form.familyHistory
                                ? content.Steps.FamilyHistory.No
                                : content.Steps.FamilyHistory.Yes}
                        </dd>
                    </Fragment>
                )}
                {form.diabetesInFamily !== null && (
                    <Fragment key="listing-12">
                        <dt>{content.Steps.DiabetesRisk.Family}</dt>
                        <dd>
                            {parseInt(form.diabetesInFamily) === 1
                                ? content.Steps.DiabetesRisk.Yes
                                : content.Steps.DiabetesRisk.No}
                        </dd>
                    </Fragment>
                )}
                {form.waistMeasurement !== null &&
                    form.waistMeasurement !== "0" && (
                        <Fragment key="listing-9">
                            <dt>
                                {content.Steps.DiabetesRisk.WaistMeasurement}
                            </dt>
                            <dd>
                                {form.waistUnit === "inches"
                                    ? form.waistMeasurement / 2.54
                                    : form.waistMeasurement}
                                {form.waistUnit === "inches" ? "in" : "cm"}
                            </dd>
                        </Fragment>
                    )}
                {/*
                {form.diabetesType && (
                    <Fragment key="listing-11">
                        <dt>{content.Steps.Diabetes.Title}</dt>
                        <dd>
                            {
                                diabetesTypes.find(
                                    diabetesType =>
                                        diabetesType.id ===
                                        parseInt(form.diabetesType)
                                ).type
                            }
                        </dd>
                    </Fragment>
                )}

                {form.kidneyDisease !== null && (
                    <Fragment key="listing-14">
                        <dt>{content.Steps.KidneyDisease.Title}</dt>
                        <dd>
                            {form.kidneyDisease === "false" ||
                            form.kidneyDisease === "False" ||
                            !form.kidneyDisease
                                ? content.Steps.KidneyDisease.No
                                : content.Steps.KidneyDisease.Yes}
                        </dd>
                    </Fragment>
                )}

                {form.atrialFibrillationStatus !== null && (
                    <Fragment key="listing-17">
                        <dt>{content.Steps.AtrialFibrillation.Title}</dt>
                        <dd>
                            {form.atrialFibrillationStatus === "false" ||
                            !form.atrialFibrillationStatus
                                ? content.Steps.AtrialFibrillation.No
                                : content.Steps.AtrialFibrillation.Yes}
                        </dd>
                    </Fragment>
                )}*/}

                {relevantMedicalConditions.map((medicalCondition) => {
                    if (
                        form.medicalConditions.indexOf(medicalCondition.key) ===
                        -1
                    ) {
                        return null;
                    }
                    return (
                        <Fragment
                            key={`listing-15-${medicalCondition.displayName}`}
                        >
                            <dt>{medicalCondition.displayName}</dt>
                            <dd>
                                {form.medicalConditions.indexOf(
                                    medicalCondition.key
                                ) > -1
                                    ? content.Steps.MedicalConditions.Yes
                                    : content.Steps.MedicalConditions.No}
                            </dd>
                        </Fragment>
                    );
                })}

                {parseFloat(form.glyhb) > 0 && (
                    <Fragment key="listing-16">
                        <dt>{content.Steps.Glyhb.Results}</dt>
                        <dd>
                            {form.glyhb} {content.Steps.Glyhb.Unit}
                        </dd>
                    </Fragment>
                )}

                {parseFloat(form.totalCholesterol) > 0 && (
                    <Fragment key="listing-16a">
                        <dt>{content.Steps.Cholesterol.Total}</dt>
                        <dd>
                            {form.totalCholesterol}{" "}
                            {content.Steps.Cholesterol.Unit}
                        </dd>
                    </Fragment>
                )}

                {parseFloat(form.hdlCholesterol) > 0 && (
                    <Fragment key="listing-16b">
                        <dt>{content.Steps.Cholesterol.HDL}</dt>
                        <dd>
                            {form.hdlCholesterol}{" "}
                            {content.Steps.Cholesterol.Unit}
                        </dd>
                    </Fragment>
                )}

                {form.alcoholConsumption !== null &&
                    parseInt(form.alcoholConsumption) !== 0 && (
                        <Fragment key="listing-19">
                            <dt>{content.Steps.Alcohol.HowOften}</dt>
                            <dd>
                                {
                                    alcoholConsumptionOptions.find(
                                        (g) => g.key === form.alcoholConsumption
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.alcoholUnits !== null &&
                    parseInt(form.alcoholUnits) !== 0 && (
                        <Fragment key="listing-20">
                            <dt>{content.Steps.Alcohol.HowMuch}</dt>
                            <dd>
                                {
                                    alcoholUnitOptions.find(
                                        (g) => g.key === form.alcoholUnits
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.bingeSessions !== null &&
                    parseInt(form.bingeSessions) !== 0 && (
                        <Fragment key="listing-21">
                            <dt>{content.Steps.Alcohol.BingeDrinking}</dt>
                            <dd>
                                {
                                    drinkingFrequencyOptions.find(
                                        (g) => g.key === form.bingeSessions
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.unableToStop !== null &&
                    parseInt(form.unableToStop) !== 0 && (
                        <Fragment key="listing-21a">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .UnableToStop
                                }
                            </dt>
                            <dd>
                                {
                                    drinkingFrequencyOptions.find(
                                        (g) => g.key === form.unableToStop
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.failedExpectations !== null &&
                    parseInt(form.failedExpectations) !== 0 && (
                        <Fragment key="listing-21b">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .FailedExpectations
                                }
                            </dt>
                            <dd>
                                {
                                    drinkingFrequencyOptions.find(
                                        (g) => g.key === form.failedExpectations
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.morningDrinking !== null &&
                    parseInt(form.morningDrinking) !== 0 && (
                        <Fragment key="listing-21c">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .MorningDrinking
                                }
                            </dt>
                            <dd>
                                {
                                    drinkingFrequencyOptions.find(
                                        (g) => g.key === form.morningDrinking
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.drinkingGuilt !== null &&
                    parseInt(form.drinkingGuilt) !== 0 && (
                        <Fragment key="listing-21d">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingGuilt
                                }
                            </dt>
                            <dd>
                                {
                                    drinkingFrequencyOptions.find(
                                        (g) => g.key === form.drinkingGuilt
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.blackout !== null && parseInt(form.blackout) !== 0 && (
                    <Fragment key="listing-21e">
                        <dt>
                            {content.Steps.Alcohol.AdditionalSteps.Blackout}
                        </dt>
                        <dd>
                            {
                                drinkingFrequencyOptions.find(
                                    (g) => g.key === form.blackout
                                ).displayName
                            }
                        </dd>
                    </Fragment>
                )}
                {form.drinkingInjury !== null &&
                    parseInt(form.drinkingInjury) !== 0 && (
                        <Fragment key="listing-21f">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingInjury
                                }
                            </dt>
                            <dd>
                                {
                                    binaryDrinkingOptions.find(
                                        (g) => g.key === form.drinkingInjury
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.drinkingConcern !== null &&
                    parseInt(form.drinkingConcern) !== 0 && (
                        <Fragment key="listing-21g">
                            <dt>
                                {
                                    content.Steps.Alcohol.AdditionalSteps
                                        .DrinkingConcern
                                }
                            </dt>
                            <dd>
                                {
                                    binaryDrinkingOptions.find(
                                        (g) => g.key === form.drinkingConcern
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}

                {form.physicalWork !== null &&
                    parseInt(form.physicalWork) !== 0 && (
                        <Fragment key="listing-23">
                            <dt>
                                {
                                    content.Steps.PhysicalActivity
                                        .PhysicalWorkDescription
                                }
                            </dt>
                            <dd>
                                {
                                    physicalWorkDefinitions.find(
                                        (work) => work.key === form.physicalWork
                                    ).displayName
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.activityHoursCycling !== null &&
                    parseInt(form.activityHoursCycling) !== 0 && (
                        <Fragment key="listing-22c">
                            <dt>
                                {content.Steps.PhysicalActivity.HoursCycling}
                            </dt>
                            <dd>
                                {exerciseOptions[form.activityHoursCycling]}
                            </dd>
                        </Fragment>
                    )}
                {form.activityHoursWalking !== null &&
                    parseInt(form.activityHoursWalking) !== 0 && (
                        <Fragment key="listing-22w">
                            <dt>
                                {content.Steps.PhysicalActivity.HoursWalking}
                            </dt>
                            <dd>
                                {exerciseOptions[form.activityHoursWalking]}
                            </dd>
                        </Fragment>
                    )}
                {form.activityHoursExercise !== null &&
                    parseInt(form.activityHoursExercise) !== 0 && (
                        <Fragment key="listing-22e">
                            <dt>
                                {content.Steps.PhysicalActivity.HoursExercise}
                            </dt>
                            <dd>
                                {exerciseOptions[form.activityHoursExercise]}
                            </dd>
                        </Fragment>
                    )}
                {form.activityHoursHouseworkChildcare !== null &&
                    parseInt(form.activityHoursHouseworkChildcare) !== 0 && (
                        <Fragment key="listing-22hc">
                            <dt>
                                {
                                    content.Steps.PhysicalActivity
                                        .HoursHouseworkChildcare
                                }
                            </dt>
                            <dd>
                                {
                                    exerciseOptions[
                                        form.activityHoursHouseworkChildcare
                                    ]
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.activityHoursGardeningDIY !== null &&
                    parseInt(form.activityHoursGardeningDIY) !== 0 && (
                        <Fragment key="listing-22gd">
                            <dt>
                                {
                                    content.Steps.PhysicalActivity
                                        .HoursGardeningDIY
                                }
                            </dt>
                            <dd>
                                {
                                    exerciseOptions[
                                        form.activityHoursGardeningDIY
                                    ]
                                }
                            </dd>
                        </Fragment>
                    )}
                {form.walkingPace !== null && parseInt(form.walkingPace) !== 0 && (
                    <Fragment key="listing-22wp">
                        <dt>{content.Steps.PhysicalActivity.WalkingPace}</dt>
                        <dd>
                            {
                                newwalkingPaceOptions.find(
                                    (wp) => wp.key === form.walkingPace
                                )?.displayName
                            }
                        </dd>
                    </Fragment>
                )}

                {form.systolicBloodPressureReadings[0] !== null && (
                    <Fragment key="listing-18">
                        <dt>{content.Steps.BloodPressure.Title}</dt>
                        <dd>
                            {lowestSystolicValue}
                            {"/"}
                            {lowestDiastolicValue}{" "}
                            {content.Steps.BloodPressure.Unit}
                        </dd>
                    </Fragment>
                )}

                {form.bloodPressureLocation !== null && (
                    <Fragment key="listing-24">
                        <dt>{content.Steps.BloodPressure.Location.Title}</dt>
                        <dd>{form.bloodPressureLocation}</dd>
                    </Fragment>
                )}
            </dl>
        );
    }
}

export default ResultsListing;
