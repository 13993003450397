import React from "react";
import { Redirect } from "react-router-dom";

import "./Summary.scss";
import { connect } from "react-redux";

import { mapFormAndGetRisk } from "../../actions/risk/riskActions";
import {
    setAllFormValues,
    setBodyMassIndexAction,
    setDiabetesRiskScoreAction,
    setAUDITCScoreAction,
    changeFormPartAction,
    completeFormPartChangeAction,
    setStatusAction,
    checkMissingSectionsAction,
    checkMissingSectionsNotIncludingBPAction,
    setFullAUDITScoreAction,
    setGPAQScoreAction
} from "../../actions/form/formActions";
import Button from "../../components/button/Button";
import Divider from "../../components/divider/Divider";
import Dial from "../../components/dial/Dial";
import ResultsListing from "../../components/results-listing/ResultsListing";
import IncompleteTest from "../../components/incomplete-test/IncompleteTest";

import content from "../../lookup/en/content";
import {
    HEALTHY_CHOLESTEROL_CUTOFF,
    HEALTHY_GLYHB,
    HIGH_GLYHB
} from "../../lookup/thresholds";
import DialStatic from "../../components/dial-static/DialStatic";
import {
    calculateAUDITC,
    calculateBmi,
    calculateDiabetesRisk,
    calculateFullAUDITScore,
    calculateGPAQScore,
    getLowestBPReadings
} from "../../util";
import QuestionnaireLink from "../../components/questionnaire-link/QuestionnaireLink";

class Summary extends React.Component {
    state = {
        className: "summary",
        mounted: false,
        iframeVisible: false,
        peopleDiagram: null,
        bpReadings: {}
    };

    riskAnimation = null;

    constructor(props) {
        super(props);
        this.heartAgeRef = React.createRef();
        this.relativeRiskRef = React.createRef();
        this.qRiskRef = React.createRef();
        this.heartHealthRef = React.createRef();
        this.bmiRef = React.createRef();
        this.bloodPressureRef = React.createRef();
        this.diabetesRef = React.createRef();
        this.alcoholRef = React.createRef();
        this.cholesterolRef = React.createRef();
        this.activityRef = React.createRef();
        this.topRef = React.createRef();
        this.answersRef = React.createRef();
    }

    componentDidMount() {
        const {
            form,
            token,
            setAllFormValues,
            mapFormAndGetRisk,
            changeFormPartAction,
            checkMissingSectionsAction,
            checkMissingSectionsNotIncludingBPAction,
            setDiabetesRiskScoreAction,
            setBodyMassIndexAction,
            setAUDITCScoreAction,
            setFullAUDITScoreAction,
            setGPAQScoreAction
        } = this.props;

        Object.keys(form).forEach((key) => {
            let localStorageValue = localStorage.getItem(key);
            if (localStorageValue) {
                if (
                    key === "systolicBloodPressureReadings" ||
                    key === "diastolicBloodPressureReadings"
                )
                    localStorageValue = localStorageValue.split(",");

                if (
                    key === "remoteBloodPressure" ||
                    key === "remoteBloodTest"
                ) {
                    if (
                        localStorageValue === true ||
                        localStorageValue.toLowerCase() === "true"
                    )
                        localStorageValue = true;
                    else if (
                        localStorageValue === false ||
                        localStorageValue.toLowerCase() === "false"
                    )
                        localStorageValue = false;
                    else localStorageValue = null;
                }

                form[key] = localStorageValue;
            }
        });

        setAllFormValues(form);
        checkMissingSectionsAction();
        checkMissingSectionsNotIncludingBPAction();
        if (form)
            mapFormAndGetRisk(token || localStorage.getItem("token"), form);

        setTimeout(() => {
            this.setState({
                className: "summary mounted",
                mounted: true
            });
        }, 2510);

        changeFormPartAction(2);

        const bmi = calculateBmi(form, setBodyMassIndexAction);
        calculateDiabetesRisk(bmi, form, setDiabetesRiskScoreAction);
        calculateAUDITC(form, setAUDITCScoreAction);
        calculateFullAUDITScore(form, setFullAUDITScoreAction);
        calculateGPAQScore(form, setGPAQScoreAction);

        this.setState({
            bpReadings: getLowestBPReadings(form)
        });
    }

    componentDidUpdate() {
        this.buildPeopleDiagram();
    }

    canImprove() {
        const { form } = this.props;
        return (
            !form.cholesterolKnownStatus ||
            form.cholesterolStatus === "false" ||
            form.atrialFibrillationStatus === null ||
            form.systolicBloodPressureReadings[0] === null
        );
    }

    openBloodTestIframe() {
        window.scrollTo(0, 0);

        this.setState({
            iframeVisible: true
        });

        setTimeout(() => {
            this.props.history.push(
                `${process.env.PUBLIC_URL}/free-blood-test`
            );
        }, 650);
    }

    highRisk() {
        const { bpReadings } = this.state;
        const { risk, form } = this.props;

        return (
            risk >= 20 ||
            form.bodyMassIndex >= 30 ||
            bpReadings.lowestSystolicValue > 140 ||
            bpReadings.lowestDiastolicValue > 90 ||
            form.diabetesRiskScore >= 25 ||
            form.AUDITScore >= 7
        );
    }

    scrollToRef(ref) {
        const offset =
            document.querySelector(".questionnaire-link")?.clientHeight || 0;
        document
            .querySelector("#root")
            .scrollTo(0, ref.current.offsetTop - offset);
    }

    completeTest() {
        const { token, setStatusAction } = this.props;

        console.log("completing test");
        setStatusAction(token, 6);
    }

    buildPeopleDiagram() {
        const { peopleDiagram, storedRisk } = this.state;
        const { risk } = this.props;

        const roundedRisk = Math.round(parseFloat(risk));
        if (peopleDiagram && storedRisk === roundedRisk) return peopleDiagram;
        if (parseFloat(risk) === 0 || !roundedRisk) return null;

        const diagram = [];
        for (let i = 0; i < 100; i++) {
            if (i < roundedRisk)
                diagram.push(
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/sad-face.png`}
                        alt="Sad face"
                        key={`sadface${i}`}
                    />
                );
            else
                diagram.push(
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/happy-face.png`}
                        alt="Happy face"
                        key={`happyface${i}`}
                    />
                );
        }
        diagram.sort((a, b) => Math.random() - Math.random());

        this.setState({
            peopleDiagram: diagram,
            storedRisk: roundedRisk
        });
    }

    render() {
        const { mounted, className, peopleDiagram, bpReadings } = this.state;

        const {
            form,
            risk,
            heartAge,
            typicalScore,
            includeDiabetes,
            completeFormPartChangeAction
        } = this.props;

        const relativeRisk = parseFloat(risk) / parseFloat(typicalScore);
        const roundedRisk = Math.round(parseFloat(risk));
        const testStatus = localStorage.getItem("userMMHStatus");

        if (
            form.remoteBloodTest &&
            ((form.remoteBloodPressure && form.missingSections.length === 0) ||
                (!form.remoteBloodPressure &&
                    form.missingSectionsNotIncludingBP.length === 0)) &&
            form.userStatus < 5
        ) {
            return <Redirect to={`${process.env.PUBLIC_URL}/blood-test`} />;
        }

        if (
            parseInt(form.userStatus) !== 9 &&
            (parseInt(form.userStatus) < 6 ||
                (parseInt(testStatus) !== 6 && parseInt(testStatus) !== 13))
        ) {
            if (
                parseInt(form.userStatus) < 6 &&
                form.missingSections &&
                form.missingSections.length === 0 &&
                !form.remoteBloodTest
            ) {
                this.completeTest();
            }
            return (
                <div className="summary mounted">
                    <IncompleteTest
                        form={form}
                        missingSections={form.missingSections}
                        missingSectionsNotIncludingBP={
                            form.missingSectionsNotIncludingBP
                        }
                        submit={this.completeTest.bind(this)}
                        includeDiabetes={includeDiabetes}
                    />
                </div>
            );
        }

        return (
            <>
                <Divider
                    icon={`${process.env.PUBLIC_URL}/assets/scales.png`}
                    partNumber={<br />}
                    partTitle={content.Steps.Dividers.Summary.Title}
                    show={form.formPartChanging}
                    changing={form.formPartChanging}
                    completeFormPartChangeAction={completeFormPartChangeAction}
                    spinner
                    key="Summary Divider"
                />
                <div className={className} ref={this.topRef}>
                    <section className="summary-head">
                        <h2>{content.Summary.Title}</h2>
                    </section>

                    {mounted && (
                        <div className="summary-intro">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/high-five.png`}
                            />
                            <h3>
                                {/* TODO This will be replaced with blood test flag */}
                                {parseInt(form.userStatus) < 4
                                    ? content.Summary.Intro.HeaderIncomplete
                                    : content.Summary.Intro.Header}
                            </h3>
                            {content.Summary.Intro.Content}
                        </div>
                    )}

                    {mounted && (
                        <div className="heart-health">
                            <h3>{content.Summary.HeartHealth.Title}</h3>
                            <section>
                                <div className="qrisk">
                                    <div className="risk-range">
                                        <p>{content.Summary.RiskRange}</p>
                                        <span>{risk}%</span>
                                    </div>
                                    <div className="people">
                                        {peopleDiagram}
                                    </div>
                                    <p>
                                        {content.Summary.HeartHealth.QRisk.Explanation(
                                            roundedRisk
                                        )}
                                    </p>
                                </div>
                                <Button
                                    onClick={this.scrollToRef.bind(
                                        this,
                                        this.qRiskRef
                                    )}
                                >
                                    {content.Summary.Details}
                                </Button>
                            </section>
                        </div>
                    )}

                    {mounted && (
                        <div className="dials">
                            <DialStatic
                                title={content.Summary.BMI}
                                targetValue={form.bodyMassIndex}
                                unit=""
                                highValue={30}
                                medValue={25}
                                lowerBound={18.5}
                                decimals={1}
                                imageKey="bmi"
                                disabled={
                                    parseInt(form.bodyMassIndex) === 0 ||
                                    form.bodyMassIndex === null
                                }
                                onClick={this.scrollToRef.bind(
                                    this,
                                    this.bmiRef
                                )}
                            />
                            <DialStatic
                                title={content.Summary.BloodPressure}
                                targetValue={bpReadings.lowestSystolicValue}
                                unit=""
                                secondaryValue={bpReadings.lowestDiastolicValue}
                                highValue={
                                    form.bloodPressureLocation === "Home"
                                        ? 175
                                        : 180
                                }
                                secondaryHighValue={
                                    form.bloodPressureLocation === "Home"
                                        ? 105
                                        : 110
                                }
                                medValue={
                                    form.bloodPressureLocation === "Home"
                                        ? 135
                                        : 140
                                }
                                secondaryMedValue={
                                    form.bloodPressureLocation === "Home"
                                        ? 85
                                        : 90
                                }
                                lowerBound={
                                    form.bloodPressureLocation === "Home"
                                        ? 85
                                        : 90
                                }
                                secondaryLowerBound={
                                    form.bloodPressureLocation === "Home"
                                        ? 55
                                        : 60
                                }
                                decimals={0}
                                imageKey="blood-pressure"
                                disabled={
                                    !form.systolicBloodPressureReadings[0] ||
                                    form.systolicBloodPressureReadings[0] ===
                                        "0"
                                }
                                onClick={this.scrollToRef.bind(
                                    this,
                                    this.bloodPressureRef
                                )}
                            />
                            {!form.glyhb && (
                                <DialStatic
                                    title={content.Summary.DiabetesRisk}
                                    targetValue={form.diabetesRiskScore}
                                    unit=""
                                    veryHighValue={25}
                                    highValue={16}
                                    medValue={7}
                                    imageKey="diabetes"
                                    interpretedValue
                                    customInterpretedValue={
                                        form.diabetesType &&
                                        form.diabetesType !== "0"
                                            ? `Type ${form.diabetesType}`
                                            : null
                                    }
                                    onClick={this.scrollToRef.bind(
                                        this,
                                        this.diabetesRef
                                    )}
                                    alternativeMediumRiskClass="increased"
                                />
                            )}
                            {form.glyhb &&
                                parseInt(form.glyhb) < HEALTHY_GLYHB && (
                                    <DialStatic
                                        title={content.Summary.DiabetesRisk}
                                        targetValue={form.glyhb}
                                        unit=""
                                        highValue={HIGH_GLYHB}
                                        medValue={HEALTHY_GLYHB}
                                        imageKey="glyhb"
                                        interpretedValue
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.diabetesRef
                                        )}
                                        alternativeLowRiskClass="healthy"
                                    />
                                )}
                            {form.glyhb &&
                                parseInt(form.glyhb) >= HEALTHY_GLYHB && (
                                    <div class="static-dial-container warning">
                                        <h3>{content.Summary.DiabetesRisk}</h3>
                                        <div>
                                            {parseInt(form.glyhb) >=
                                                HEALTHY_GLYHB && (
                                                <>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/alert.png`}
                                                        alt={
                                                            content.Summary
                                                                .DiabetesBTWarning
                                                        }
                                                    />
                                                    <p>
                                                        {parseInt(form.glyhb) >=
                                                            HEALTHY_GLYHB &&
                                                            parseInt(
                                                                form.glyhb
                                                            ) < HIGH_GLYHB &&
                                                            content.Summary
                                                                .DiabetesTestSummary
                                                                .RaisedHead}
                                                        {parseInt(form.glyhb) >=
                                                            HIGH_GLYHB &&
                                                            content.Summary
                                                                .DiabetesTestSummary
                                                                .HighHead}
                                                    </p>
                                                </>
                                            )}
                                            <Button
                                                onClick={this.scrollToRef.bind(
                                                    this,
                                                    this.diabetesRef
                                                )}
                                            >
                                                {content.Summary.Details}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            <DialStatic
                                title={content.Summary.AlcoholScreening}
                                targetValue={form.AUDITScore}
                                unit=""
                                veryHighValue={20}
                                highValue={16}
                                medValue={8}
                                interpretedValue
                                imageKey="alcohol"
                                disabled={
                                    parseInt(form.AUDITScore) < 0 ||
                                    form.AUDITScore === null
                                }
                                onClick={this.scrollToRef.bind(
                                    this,
                                    this.alcoholRef
                                )}
                            />
                            <DialStatic
                                title={content.Summary.CholesterolLevels}
                                targetValue={form.totalCholesterol}
                                unit=""
                                highValue={HEALTHY_CHOLESTEROL_CUTOFF}
                                medValue={HEALTHY_CHOLESTEROL_CUTOFF}
                                interpretedValue
                                imageKey="cholesterol"
                                disabled={form.totalCholesterol === null}
                                onClick={this.scrollToRef.bind(
                                    this,
                                    this.cholesterolRef
                                )}
                                alternativeLowRiskClass="healthy"
                                alternativeMediumRiskClass="raised"
                                alternativeHighRiskClass="raised"
                            />
                            <DialStatic
                                title={content.Summary.ActivityLevel}
                                targetValue={form.GPAQScore}
                                unit=""
                                // These values are in reverse... higher == better
                                veryHighValue={5}
                                highValue={4}
                                medValue={3}
                                hiddenValue
                                imageKey="gpaq"
                                disabled={form.GPAQScore === null}
                                onClick={this.scrollToRef.bind(
                                    this,
                                    this.activityRef
                                )}
                                alternativeLowRiskClass="no-activity"
                                alternativeMediumRiskClass="low-activity"
                                alternativeHighRiskClass="med-activity"
                                alternativeVeryHighRiskClass="high-activity"
                            />
                        </div>
                    )}

                    {mounted && (
                        <>
                            <section
                                className="risk-results"
                                style={{ display: "none" }}
                                ref={this.heartAgeRef}
                            >
                                <h2>
                                    {content.Summary.HeartHealthScoreDetails}
                                </h2>
                                <h3>
                                    {content.Summary.HeartHealthHeartAgeDetails}
                                </h3>

                                <div className="risk-details">
                                    <DialStatic
                                        title={
                                            content.Summary.HeartHealth.HeartAge
                                                .Title
                                        }
                                        prefix={
                                            content.Summary.HeartHealth.HeartAge
                                                .Title
                                        }
                                        targetValue={heartAge}
                                        boxless
                                        noBoundaries
                                    />
                                    <div>
                                        {
                                            content.Summary
                                                .HeartHealthHeartAgeSummary
                                        }
                                    </div>
                                </div>
                                <div
                                    className="scroll-to-top"
                                    onClick={this.scrollToRef.bind(
                                        this,
                                        this.topRef
                                    )}
                                >
                                    {content.Summary.ReturnToTop}
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                    />
                                </div>
                            </section>
                            <section
                                className="risk-results"
                                ref={this.qRiskRef}
                            >
                                <h3>
                                    {
                                        content.Summary
                                            .HeartHealthQRiskScoreDetails
                                    }
                                </h3>

                                <div className="risk-details">
                                    <DialStatic
                                        title={content.Summary.HeartHealthScore}
                                        targetValue={`${risk}%`}
                                        prefix={
                                            content.Summary
                                                .HeartHealthQRiskScoreDetails
                                        }
                                        boxless
                                        noBoundaries
                                    />
                                    <div>
                                        {risk > 10 &&
                                            content.Summary.HeartHealthQRiskScoreSummary.Neutral(
                                                risk
                                            )}
                                        {risk < 10 &&
                                            content.Summary.HeartHealthQRiskScoreSummary.Low(
                                                risk
                                            )}
                                        {content.Summary.HeartHealthWhatIsQRisk}
                                    </div>
                                </div>
                                <div
                                    className="scroll-to-top"
                                    onClick={this.scrollToRef.bind(
                                        this,
                                        this.topRef
                                    )}
                                >
                                    {content.Summary.ReturnToTop}
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                    />
                                </div>
                            </section>
                            {form.bodyMassIndex > 0 && (
                                <section
                                    className="risk-results"
                                    ref={this.bmiRef}
                                >
                                    <h2>{content.Summary.BMI}</h2>
                                    <div className="risk-details">
                                        <Dial
                                            title={content.Summary.BMI}
                                            targetValue={form.bodyMassIndex}
                                            unit=""
                                            highValue={30}
                                            medValue={25}
                                            lowerBound={18.5}
                                            decimals={1}
                                            boxless
                                        />

                                        <div>
                                            <span className="section-summary">
                                                {form.bodyMassIndex < 18.5 &&
                                                    content.Summary.BMISummary
                                                        .UnderweightHead}
                                                {form.bodyMassIndex >= 18.5 &&
                                                    form.bodyMassIndex < 25 &&
                                                    content.Summary.BMISummary
                                                        .HealthyHead}
                                                {form.bodyMassIndex >= 25 &&
                                                    form.bodyMassIndex < 30 &&
                                                    content.Summary.BMISummary
                                                        .OverweightHead}
                                                {form.bodyMassIndex >= 30 &&
                                                    content.Summary.BMISummary
                                                        .ObeseHead}
                                            </span>

                                            {form.bodyMassIndex < 18.5 &&
                                                content.Summary.BMISummary
                                                    .Underweight}
                                            {form.bodyMassIndex >= 18.5 &&
                                                form.bodyMassIndex < 25 &&
                                                content.Summary.BMISummary
                                                    .Healthy}
                                            {form.bodyMassIndex >= 25 &&
                                                form.bodyMassIndex < 30 &&
                                                content.Summary.BMISummary
                                                    .Overweight}
                                            {form.bodyMassIndex >= 30 &&
                                                content.Summary.BMISummary
                                                    .Obese}

                                            {
                                                content.Summary.BMISummary
                                                    .Explanation
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {form.systolicBloodPressureReadings[0] > 0 && (
                                <section
                                    className="risk-results"
                                    ref={this.bloodPressureRef}
                                >
                                    <h2>{content.Summary.BloodPressure}</h2>
                                    <div className="risk-details">
                                        <Dial
                                            title={
                                                content.Summary.BloodPressure
                                            }
                                            targetValue={
                                                bpReadings.lowestSystolicValue
                                            }
                                            unit=""
                                            secondaryValue={
                                                bpReadings.lowestDiastolicValue
                                            }
                                            highValue={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 175
                                                    : 180
                                            }
                                            secondaryHighValue={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 105
                                                    : 110
                                            }
                                            medValue={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 135
                                                    : 140
                                            }
                                            secondaryMedValue={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 85
                                                    : 90
                                            }
                                            lowerBound={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 85
                                                    : 90
                                            }
                                            secondaryLowerBound={
                                                form.bloodPressureLocation ===
                                                "Home"
                                                    ? 55
                                                    : 60
                                            }
                                            decimals={0}
                                            boxless
                                        />

                                        <div>
                                            <span className="section-summary">
                                                {form.bloodPressureLocation !==
                                                    "Home" && (
                                                    <>
                                                        {(bpReadings.lowestSystolicValue <
                                                            90 ||
                                                            bpReadings.lowestDiastolicValue <
                                                                60) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .LowHead}
                                                        {bpReadings.lowestSystolicValue >
                                                            90 &&
                                                            bpReadings.lowestDiastolicValue >
                                                                60 &&
                                                            bpReadings.lowestSystolicValue <=
                                                                140 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                90 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .NormalHead}
                                                        {(bpReadings.lowestSystolicValue >
                                                            140 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                90) &&
                                                            bpReadings.lowestSystolicValue <=
                                                                180 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                110 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .PreHighHead}
                                                        {(bpReadings.lowestSystolicValue >
                                                            180 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                110) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .HighHead}
                                                    </>
                                                )}
                                                {form.bloodPressureLocation ===
                                                    "Home" && (
                                                    <>
                                                        {(bpReadings.lowestSystolicValue <
                                                            85 ||
                                                            bpReadings.lowestDiastolicValue <
                                                                55) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .LowHead}
                                                        {bpReadings.lowestSystolicValue >
                                                            85 &&
                                                            bpReadings.lowestDiastolicValue >
                                                                55 &&
                                                            bpReadings.lowestSystolicValue <=
                                                                135 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                85 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .NormalHead}
                                                        {(bpReadings.lowestSystolicValue >
                                                            135 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                85) &&
                                                            bpReadings.lowestSystolicValue <=
                                                                175 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                105 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .PreHighHead}
                                                        {(bpReadings.lowestSystolicValue >
                                                            175 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                105) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .HighHead}
                                                    </>
                                                )}
                                            </span>

                                            <>
                                                {form.bloodPressureLocation !==
                                                    "Home" && (
                                                    <>
                                                        {(bpReadings.lowestSystolicValue <
                                                            90 ||
                                                            bpReadings.lowestDiastolicValue <
                                                                60) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .Low}
                                                        {bpReadings.lowestSystolicValue >
                                                            90 &&
                                                            bpReadings.lowestDiastolicValue >
                                                                60 &&
                                                            bpReadings.lowestSystolicValue <=
                                                                140 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                90 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .Normal}
                                                        {(bpReadings.lowestSystolicValue >
                                                            140 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                90) &&
                                                            bpReadings.lowestSystolicValue <=
                                                                180 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                110 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .PreHigh}
                                                        {(bpReadings.lowestSystolicValue >
                                                            180 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                110) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .High}
                                                    </>
                                                )}
                                                {form.bloodPressureLocation ===
                                                    "Home" && (
                                                    <>
                                                        {(bpReadings.lowestSystolicValue <
                                                            85 ||
                                                            bpReadings.lowestDiastolicValue <
                                                                55) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .Low}
                                                        {bpReadings.lowestSystolicValue >
                                                            85 &&
                                                            bpReadings.lowestDiastolicValue >
                                                                55 &&
                                                            bpReadings.lowestSystolicValue <=
                                                                135 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                85 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .Normal}
                                                        {(bpReadings.lowestSystolicValue >
                                                            135 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                85) &&
                                                            bpReadings.lowestSystolicValue <=
                                                                175 &&
                                                            bpReadings.lowestDiastolicValue <=
                                                                105 &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .PreHigh}
                                                        {(bpReadings.lowestSystolicValue >
                                                            175 ||
                                                            bpReadings.lowestDiastolicValue >
                                                                105) &&
                                                            content.Summary
                                                                .BloodPressureSummary
                                                                .High}
                                                    </>
                                                )}
                                            </>

                                            {
                                                content.Summary
                                                    .BloodPressureSummary
                                                    .Explanation
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {!form.glyhb && form.diabetesRiskScore !== null && (
                                <section
                                    className="risk-results"
                                    ref={this.diabetesRef}
                                >
                                    <h2>{content.Summary.DiabetesRisk}</h2>
                                    <div className="risk-details">
                                        <DialStatic
                                            title={content.Summary.DiabetesRisk}
                                            targetValue={form.diabetesRiskScore}
                                            unit=""
                                            veryHighValue={25}
                                            highValue={16}
                                            medValue={7}
                                            interpretedValue
                                            customInterpretedValue={
                                                form.diabetesType &&
                                                form.diabetesType !== "0"
                                                    ? `Type ${form.diabetesType}`
                                                    : null
                                            }
                                            boxless
                                            alternativeMediumRiskClass="increased"
                                        />
                                        <div>
                                            {form.diabetesRiskScore < 7 &&
                                                content.Summary.DiabetesRiskSummary.Low(
                                                    form.diabetesRiskScore
                                                )}
                                            {form.diabetesRiskScore >= 7 &&
                                                form.diabetesRiskScore < 16 &&
                                                content.Summary.DiabetesRiskSummary.Medium(
                                                    form.diabetesRiskScore
                                                )}
                                            {form.diabetesRiskScore >= 16 &&
                                                form.diabetesRiskScore < 25 &&
                                                content.Summary.DiabetesRiskSummary.High(
                                                    form.diabetesRiskScore
                                                )}
                                            {form.diabetesRiskScore >= 25 &&
                                                form.diabetesRiskScore !==
                                                    100 &&
                                                content.Summary.DiabetesRiskSummary.VeryHigh(
                                                    form.diabetesRiskScore
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {form.glyhb && (
                                <section
                                    className="risk-results"
                                    ref={this.diabetesRef}
                                >
                                    <h2>{content.Summary.DiabetesRisk}</h2>
                                    <div className="risk-details">
                                        <DialStatic
                                            title={content.Summary.DiabetesRisk}
                                            targetValue={form.glyhb}
                                            unit=""
                                            highValue={HIGH_GLYHB}
                                            medValue={HEALTHY_GLYHB}
                                            interpretedValue
                                            alternativeLowRiskClass="healthy"
                                            alternativeMediumRiskClass="raised_med"
                                            boxless
                                        />
                                        <p>
                                            {parseInt(form.glyhb) <
                                                HEALTHY_GLYHB && (
                                                <>
                                                    {
                                                        content.Summary
                                                            .DiabetesTestSummary
                                                            .HealthyHead
                                                    }
                                                    {
                                                        content.Summary
                                                            .DiabetesTestSummary
                                                            .Healthy
                                                    }
                                                </>
                                            )}
                                            {parseInt(form.glyhb) >=
                                                HEALTHY_GLYHB &&
                                                parseInt(form.glyhb) <
                                                    HIGH_GLYHB && (
                                                    <>
                                                        {
                                                            content.Summary
                                                                .DiabetesTestSummary
                                                                .RaisedHead
                                                        }
                                                        {
                                                            content.Summary
                                                                .DiabetesTestSummary
                                                                .Raised
                                                        }
                                                    </>
                                                )}
                                            {parseInt(form.glyhb) >=
                                                HIGH_GLYHB && (
                                                <>
                                                    {
                                                        content.Summary
                                                            .DiabetesTestSummary
                                                            .HighHead
                                                    }
                                                    {
                                                        content.Summary
                                                            .DiabetesTestSummary
                                                            .High
                                                    }
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {parseInt(form.AUDITScore) >= 0 &&
                                form.AUDITScore !== null && (
                                    <section
                                        className="risk-results"
                                        ref={this.alcoholRef}
                                    >
                                        <h2>
                                            {content.Summary.AlcoholScreening}
                                        </h2>
                                        <div className="risk-details">
                                            <Dial
                                                title={
                                                    content.Summary.AUDITCScore
                                                }
                                                targetValue={form.AUDITScore}
                                                unit=""
                                                veryHighValue={20}
                                                highValue={16}
                                                medValue={8}
                                                interpretedValue
                                                boxless
                                            />
                                            <div>
                                                <span className="section-summary">
                                                    {form.AUDITScore < 8 &&
                                                        content.Summary
                                                            .AlcoholScreeningSummary
                                                            .LowHead}
                                                    {form.AUDITScore >= 8 &&
                                                        form.AUDITScore < 16 &&
                                                        content.Summary
                                                            .AlcoholScreeningSummary
                                                            .MediumHead}
                                                    {form.AUDITScore >= 16 &&
                                                        form.AUDITScore < 20 &&
                                                        content.Summary
                                                            .AlcoholScreeningSummary
                                                            .HighHead}
                                                    {form.AUDITScore >= 20 &&
                                                        content.Summary
                                                            .AlcoholScreeningSummary
                                                            .DependencyHead}
                                                </span>
                                                {form.AUDITScore < 8 &&
                                                    content.Summary
                                                        .AlcoholScreeningSummary
                                                        .Low}
                                                {form.AUDITScore >= 8 &&
                                                    form.AUDITScore < 16 &&
                                                    content.Summary
                                                        .AlcoholScreeningSummary
                                                        .Medium}
                                                {form.AUDITScore >= 16 &&
                                                    form.AUDITScore < 20 &&
                                                    content.Summary
                                                        .AlcoholScreeningSummary
                                                        .High}
                                                {form.AUDITScore >= 20 &&
                                                    content.Summary
                                                        .AlcoholScreeningSummary
                                                        .Dependency}
                                            </div>
                                        </div>
                                        <div
                                            className="scroll-to-top"
                                            onClick={this.scrollToRef.bind(
                                                this,
                                                this.topRef
                                            )}
                                        >
                                            {content.Summary.ReturnToTop}
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                            />
                                        </div>
                                    </section>
                                )}
                            {form.totalCholesterol !== null && (
                                <section
                                    className="risk-results"
                                    ref={this.cholesterolRef}
                                >
                                    <h2>{content.Summary.CholesterolLevels}</h2>
                                    <div className="risk-details">
                                        <DialStatic
                                            title={
                                                content.Summary
                                                    .CholesterolLevels
                                            }
                                            targetValue={form.totalCholesterol}
                                            unit=""
                                            highValue={
                                                HEALTHY_CHOLESTEROL_CUTOFF
                                            }
                                            medValue={
                                                HEALTHY_CHOLESTEROL_CUTOFF
                                            }
                                            interpretedValue
                                            boxless
                                            alternativeLowRiskClass="healthy"
                                            alternativeMediumRiskClass="raised"
                                            alternativeHighRiskClass="raised"
                                        />
                                        <span className="section-summary">
                                            {form.totalCholesterol >
                                            HEALTHY_CHOLESTEROL_CUTOFF
                                                ? content.Summary
                                                      .CholesterolRiskSummary
                                                      .High.Header
                                                : content.Summary
                                                      .CholesterolRiskSummary
                                                      .Low.Header}
                                        </span>
                                        {form.totalCholesterol >=
                                        HEALTHY_CHOLESTEROL_CUTOFF
                                            ? content.Summary
                                                  .CholesterolRiskSummary.High
                                                  .Content
                                            : content.Summary
                                                  .CholesterolRiskSummary.Low
                                                  .Content}
                                    </div>
                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {form.GPAQScore !== null && (
                                <section
                                    className="risk-results"
                                    ref={this.activityRef}
                                >
                                    <h2>{content.Summary.ActivityLevel}</h2>
                                    <div className="risk-details">
                                        <DialStatic
                                            title={
                                                content.Summary.ActivityLevel
                                            }
                                            targetValue={form.GPAQScore}
                                            unit=""
                                            // These values are in reverse... higher == better
                                            veryHighValue={5}
                                            highValue={4}
                                            medValue={3}
                                            interpretedValue
                                            imageKey="gpaq"
                                            alternativeLowRiskClass="no-activity"
                                            alternativeMediumRiskClass="low-activity"
                                            alternativeHighRiskClass="med-activity"
                                            alternativeVeryHighRiskClass="high-activity"
                                            boxless
                                        />
                                        <span className="section-summary">
                                            {form.GPAQScore > 5 &&
                                                content.Summary
                                                    .ActivityRiskSummary.Active
                                                    .Headline}
                                            {form.GPAQScore === 4 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .ModeratelyActive.Headline}
                                            {form.GPAQScore === 3 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .ModeratelyInactive
                                                    .Headline}
                                            {form.GPAQScore === 2 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .Inactive.Headline}
                                        </span>
                                        <>
                                            {form.GPAQScore > 5 &&
                                                content.Summary
                                                    .ActivityRiskSummary.Active
                                                    .Content}
                                            {form.GPAQScore === 4 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .ModeratelyActive.Content}
                                            {form.GPAQScore === 3 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .ModeratelyInactive.Content}
                                            {form.GPAQScore === 2 &&
                                                content.Summary
                                                    .ActivityRiskSummary
                                                    .Inactive.Content}
                                        </>
                                    </div>

                                    <div
                                        className="scroll-to-top"
                                        onClick={this.scrollToRef.bind(
                                            this,
                                            this.topRef
                                        )}
                                    >
                                        {content.Summary.ReturnToTop}
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/prev-step.png`}
                                        />
                                    </div>
                                </section>
                            )}
                            {parseInt(form.smokingLevel) >= 3 && (
                                <section
                                    className="risk-results"
                                    ref={this.smokingRef}
                                >
                                    <h2>{content.Summary.SmokingRisk}</h2>
                                    {content.Summary.IncreasedSmokingRisk}
                                </section>
                            )}
                            <section
                                className="risk-results"
                                ref={this.answersRef}
                            >
                                <h2>{content.Summary.YourAnswers}</h2>

                                <ResultsListing form={form} />
                            </section>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default connect(
    (state) => ({
        form: state.form,
        risk: state.risk.value,
        heartAge: state.risk.heartAge,
        typicalScore: state.risk.typicalScore,
        includeDiabetes: state.form.includeDiabetes,
        token: state.auth.token
    }),
    {
        mapFormAndGetRisk,
        setAllFormValues,
        setBodyMassIndexAction,
        setDiabetesRiskScoreAction,
        setAUDITCScoreAction,
        setFullAUDITScoreAction,
        setGPAQScoreAction,
        setStatusAction,
        changeFormPartAction,
        completeFormPartChangeAction,
        checkMissingSectionsAction,
        checkMissingSectionsNotIncludingBPAction
    }
)(Summary);
