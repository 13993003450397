import React from "react";
import { Link } from "react-router-dom";

import content from "../../lookup/en/content";

import "./Footer.scss";

export const Footer = () => (
    <footer>
        <span className="powered-by">{content.Footer.PoweredByTickertest}</span>
        <img
            src={`${process.env.PUBLIC_URL}/assets/ce_marking.svg`}
            alt="CE mark"
            className="ce-mark"
        />
        <section className="terms">
            <Link to="/about">{content.Footer.About}</Link>
            <Link to="/faqs">{content.Footer.FAQs}</Link>
            <Link to="/terms">{content.Footer.Terms}</Link>
            <Link to="/privacy">{content.Footer.Disclaimers}</Link>
            <Link to="/cookies">{content.Footer.Cookies}</Link>
            <Link to="/contact">{content.Footer.Contact}</Link>
        </section>
    </footer>
);
