import React from "react";
import { connect } from "react-redux";

import "./HeartHealthAdvice.scss";
import content from "../../lookup/en/content";
import TableOfContent from "../../components/table-of-content/TableOfContent";
import { setContentsIndexAction } from "../../actions/header/headerActions";

const HEART_HEALTH_CONTENT = [
    {
        id: "saturates",
        header: content.HeartHealthAdvice.Saturates.Title,
        content: (
            <>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/steak-and-avocado.png`}
                    alt="Healthy heart diet advice"
                />
                <h3>{content.HeartHealthAdvice.Saturates.Title}</h3>
                {content.HeartHealthAdvice.Saturates.Content}
            </>
        )
    },
    {
        id: "reduce-fats",
        header: content.HeartHealthAdvice.ReduceFats.Title,
        content: (
            <div className="split-view">
                <section>
                    <h3>{content.HeartHealthAdvice.ReduceFats.Title}</h3>
                    {content.HeartHealthAdvice.ReduceFats.Content}
                </section>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/apple-face.png`}
                    alt="Reduce fats"
                />
            </div>
        )
    }
];

class HeartHealthAdvice extends React.Component {
    componentDidMount() {
        const component = this;

        window.onscroll = function () {
            component.changeContentsIndex();
        };
    }

    changeContentsIndex() {
        const { setContentsIndexAction, header } = this.props;
        const contentsIndex = header.contentsIndex;

        let nextElement = HEART_HEALTH_CONTENT[contentsIndex + 1];
        if (nextElement) {
            nextElement = document.getElementById(nextElement.id);
            if (nextElement) {
                const nextElementRect = nextElement.getBoundingClientRect();
                const nextElementTop = nextElementRect.top;

                if (nextElementTop < window.innerHeight) {
                    // has scrolled into view
                    setContentsIndexAction(contentsIndex + 1);
                }
            }
        }

        let currentElement = HEART_HEALTH_CONTENT[contentsIndex];
        if (currentElement) {
            currentElement = document.getElementById(currentElement.id);
            if (currentElement) {
                const currentElementRect =
                    currentElement.getBoundingClientRect();
                const currentElementTop = currentElementRect.top;

                if (currentElementTop > window.innerHeight) {
                    // we've scrolled back up
                    setContentsIndexAction(contentsIndex - 1);
                }
            }
        }
    }

    render() {
        return (
            <>
                <div className="heart-health-container">
                    <div className="heart-health-sidebar">
                        <TableOfContent
                            items={HEART_HEALTH_CONTENT}
                            activeIndex={this.props.header.contentsIndex}
                        />
                    </div>

                    <div className="heart-health-content">
                        <h2>{content.HeartHealthAdvice.Title}</h2>
                        <p className="read-length">
                            {content.HeartHealthAdvice.ReadingLength}
                        </p>
                        <p className="advice">
                            {content.HeartHealthAdvice.VisitYourGP}
                        </p>

                        {HEART_HEALTH_CONTENT.map((item) => (
                            <div
                                className="heart-health-content-item"
                                id={item.id}
                                key={item.id}
                            >
                                {item.content}
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default connect(
    (state) => ({
        header: state.header
    }),
    {
        setContentsIndexAction
    }
)(HeartHealthAdvice);
