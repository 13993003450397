import React from "react";

import "./PostcodeStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import content from "../../../lookup/en/content";

const POSTCODE_VALIDATION_WAIT_MS = 1000;

class PostcodeStep extends React.Component {
    timer = null;

    componentDidMount() {
        const { setPostcodeAction } = this.props;
        const defaultValue = localStorage.getItem("postalCode") || "";
        setPostcodeAction(defaultValue);

        if (defaultValue) {
            this.props.postcodeLookupAction(defaultValue);
        }
    }

    onKeyUp(e) {
        const { beginPostcodeValidationAction } = this.props;

        beginPostcodeValidationAction();
        let code = e.keyCode ? e.keyCode : e.which;
        if (code === 13 || code === 10) {
            e.target.blur();
        }

        const value = e.target.value;
        const validatePostcode = this.validatePostcode.bind(this);

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            validatePostcode(value);
        }, POSTCODE_VALIDATION_WAIT_MS);
    }

    onInputChange(e) {
        const value = e.target.value;
        this.validatePostcode(value);
    }

    validatePostcode(value) {
        const { setPostcodeAction, postcodeLookupAction, updateRisk } =
            this.props;
        setPostcodeAction(value);
        postcodeLookupAction(value);
        updateRisk("Postcode", value);
        localStorage.setItem("postalCode", value);
    }

    render() {
        const {
            activeStep,
            postalCode,
            postalCodeValidating,
            postalCodeInvalid
        } = this.props;

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={3}
                isValidating={postalCodeValidating}
                disableNavigation={postalCodeInvalid}
            >
                <SectionHeader>{content.Header.AboutYou}</SectionHeader>
                <section className="postcode-step">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/inspector.png`}
                        alt="Postcode lookup"
                        className="postcode-image"
                    />
                    <FieldCard
                        cardTitle={content.Steps.PostCode.Title}
                        helpTitle={content.Steps.PostCode.Title}
                        helpText={content.Steps.PostCode.HelpText}
                    >
                        <span>{content.Steps.PostCode.HelpTitle}</span>
                        <input
                            type="text"
                            className="postcode-input"
                            onKeyUp={this.onKeyUp.bind(this)}
                            defaultValue={postalCode}
                        />
                        {postalCodeValidating && (
                            <p className="validating-postcode">
                                {content.Steps.PostCode.Validating}
                            </p>
                        )}
                        {!postalCodeValidating && postalCodeInvalid && (
                            <p className="invalid-postcode">
                                {content.Steps.PostCode.ErrorHandling}
                            </p>
                        )}
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default PostcodeStep;
