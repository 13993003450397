import React from "react";

import content from "../../lookup/en/content";
import Button from "../button/Button";

export const WhatIsHealthCheck = () => (
    <article>
        <div className="content">
            <h2>{content.WhatIsHealthCheck.Title}</h2>
            <img
                src={`${process.env.PUBLIC_URL}/assets/man-jogging-2.png`}
                alt={content.WhatIsHealthCheck.Title}
            />
            {content.WhatIsHealthCheck.Content}
        </div>
    </article>
);
