import React, { Component } from "react";

import { getCookie, setCookie } from "../../util";

import content from "../../lookup/en/content";
import "./CookiePopup.scss";

class CookiePopup extends Component {
    state = {
        accepted: false
    };

    accept() {
        setCookie("cookiesAccepted", "true", 365);
        this.setState({
            accepted: true
        });
    }

    render() {
        document.body.classList.remove("show-cookie-overlay");
        if (getCookie("cookiesAccepted") === "true") return null;
        if (
            window.location.pathname !== "/privacy" &&
            window.location.pathname !== "/cookies" &&
            window.location.pathname !== "/terms"
        )
            document.body.classList.add("show-cookie-overlay");

        return (
            <div className="cookies-overlay">
                <div className="cookie-popup">
                    <div className="container">
                        <h4>{content.Cookies.Title}</h4>
                        {content.Cookies.Container}
                        <button onClick={this.accept.bind(this)}>
                            {content.Cookies.Acceptance}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookiePopup;
