import React, { Component } from "react";

import content from "../../lookup/en/content";
import Button from "../button/Button";

import "./Dial.scss";

class Dial extends Component {
    state = {
        animationValue: 0,
        displayValue: 0,
        secondaryAnimationValue: 0,
        riskClass: ""
    };

    animation = null;

    componentDidMount() {
        const {
            veryHighValue,
            highValue,
            medValue,
            lowerBound,
            targetValue,
            secondaryValue,
            secondaryHighValue,
            secondaryMedValue,
            secondaryLowerBound,
            interpretedValue,
            customInterpretedValue
        } = this.props;

        if (!interpretedValue && !customInterpretedValue)
            this.animation = setInterval(this.animate.bind(this), 10);

        let riskClass = "low-risk";
        if (!secondaryValue) {
            if (targetValue >= medValue) riskClass = "med-risk";
            if (targetValue >= highValue) riskClass = "high-risk";

            if (lowerBound) {
                if (targetValue < lowerBound) riskClass = "too-low";
            }
            if (veryHighValue) {
                if (targetValue >= veryHighValue) riskClass = "very-high-risk";
            }
        } else {
            if (targetValue > medValue || secondaryValue > secondaryMedValue)
                riskClass = "med-risk";
            if (
                targetValue >= highValue ||
                secondaryValue >= secondaryHighValue
            )
                riskClass = "high-risk";

            if (lowerBound) {
                if (
                    targetValue < lowerBound ||
                    secondaryValue < secondaryLowerBound
                )
                    riskClass = "too-low";
                if (veryHighValue) {
                    if (targetValue >= veryHighValue)
                        riskClass = "very-high-risk";
                }
            }
        }

        if (customInterpretedValue) {
            this.setState({
                displayValue: customInterpretedValue
            });
            riskClass += " custom-interpretation";
        } else if (interpretedValue) {
            this.setState({
                displayValue: content.Summary.RiskInterpretations[riskClass]
            });
        }

        if (lowerBound) riskClass += " with-lower-bound";
        if (veryHighValue) riskClass += " with-higher-bound";

        setTimeout(() => {
            this.setState({
                riskClass
            });
        }, 500);
    }

    animate() {
        const { targetValue, secondaryValue, unit, decimals } = this.props;
        const { animationValue, secondaryAnimationValue } = this.state;

        if (
            parseFloat(animationValue) >= parseFloat(targetValue) &&
            targetValue > 0
        ) {
            clearInterval(this.animation);
            this.setState({
                animationValue: targetValue,
                displayValue: targetValue + unit
            });

            if (secondaryValue) {
                this.setState({
                    secondaryAnimationValue: secondaryValue,
                    displayValue: `${targetValue}<span> / ${secondaryValue}</span>`
                });
            }
            return;
        }

        let increment = targetValue / 150;
        let newValue = parseFloat(animationValue) + increment;

        this.setState({
            animationValue: newValue,
            displayValue: newValue.toFixed(decimals) + unit
        });

        if (secondaryValue) {
            let secondaryIncrement = secondaryValue / 150;
            let newSecondaryValue =
                parseFloat(secondaryAnimationValue) + secondaryIncrement;

            this.setState({
                secondaryAnimationValue: newSecondaryValue,
                displayValue: `${newValue.toFixed(
                    decimals
                )}<span> / ${newSecondaryValue.toFixed(decimals)}</span>`
            });
        }
    }

    onClick() {
        this.props.onClick();
    }

    render() {
        const {
            title,
            lowerBound,
            veryHighValue,
            boxless,
            noBoundaries,
            disabled
        } = this.props;
        const { displayValue, riskClass } = this.state;

        let containerClass = "dial-container";
        if (boxless) containerClass += " boxless";
        if (noBoundaries) containerClass += " no-boundaries";
        if (disabled) containerClass += " disabled";

        return (
            <div className={containerClass}>
                <h3>{title}</h3>

                <div className={`dial ${riskClass}`}>
                    <div className="indicator"></div>
                    <div className="wheel">
                        {lowerBound && <div className="colour"></div>}
                        <div className="colour"></div>
                        <div className="colour"></div>
                        <div className="colour"></div>
                        {veryHighValue && <div className="colour"></div>}
                    </div>

                    <div
                        className="counter"
                        dangerouslySetInnerHTML={{ __html: displayValue }}
                    />

                    {!boxless && (
                        <Button onClick={this.onClick.bind(this)}>
                            {content.Summary.Details}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default Dial;
