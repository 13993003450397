import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./Checklist.scss";
import content from "../../lookup/en/content";
import Button from "../../components/button/Button";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { PAGE_IDS } from "../../lookup/api";

class Checklist extends React.Component {
    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.CHECKLIST);
    }

    render() {
        const { auth, includeDiabetes } = this.props;

        let link;
        if (
            !auth.userVerification.verified &&
            !localStorage.getItem("userVerification")
        ) {
            link = "/verification";
        } else {
            link = "/how-to-return";
        }

        return (
            <div className="checklist">
                <div className="content">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/checklist.png`}
                        alt={content.Checklist.Title}
                    />
                    <h2>{content.Checklist.Title}</h2>
                    <>{content.Checklist.Content(includeDiabetes)}</>
                    <p>{content.Checklist.Link}</p>
                    {/* <iframe
                        className="video"
                        src="https://www.youtube.com/embed/-6NLFDLp5vM"
                    ></iframe> */}
                    <Button href={link}>{content.Checklist.Return}</Button>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        includeDiabetes: state.form.includeDiabetes
    }),
    {
        trackPageViewAction
    }
)(Checklist);
