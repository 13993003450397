export default {
    latitude: 51.41450119018555,
    longitude: 0.5119556784629822,
    addresses: [
        "1 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "11 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "15 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "17 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "19 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "21 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "23 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "25 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "27 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "29 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "3 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "31 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "33 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "35 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "37 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "39 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "41 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "43 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "5 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "51 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "53 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "55 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "57 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "59 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "61 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "7 Glimmer Way, , , , Wainscott, Rochester, Kent",
        "9 Glimmer Way, , , , Wainscott, Rochester, Kent"
    ]
};
