import React from "react";

import "./NoIdea.scss";
import PromoModal from "../promo-modal/PromoModal";

class NoIdea extends React.Component {
    state = {
        promoModalOpen: false
    };

    toggleModal() {
        const { history, modalLinkHref, noModal } = this.props;
        this.setState({
            promoModalOpen: true
        });

        if (noModal) {
            setTimeout(() => {
                history.push(modalLinkHref);
            }, 650);
        }
    }

    closeModal() {
        this.setState({
            promoModalOpen: false
        });
    }

    render() {
        const {
            modalTitle,
            modalContent,
            modalLinkHref,
            modalLinkText,
            showInFrame,
            history,
            noModal
        } = this.props;

        return (
            <>
                {!noModal && (
                    <PromoModal
                        open={this.state.promoModalOpen}
                        closeModal={this.closeModal.bind(this)}
                        title={modalTitle}
                        content={modalContent}
                        linkHref={modalLinkHref}
                        linkText={modalLinkText}
                        showInFrame={showInFrame}
                        history={history}
                    />
                )}
                {noModal && showInFrame && (
                    <div
                        className={
                            this.state.promoModalOpen
                                ? "promo-modal-frame visible"
                                : "promo-modal-frame"
                        }
                    >
                        <iframe src={modalLinkHref} title={modalLinkText} />
                    </div>
                )}
                <div className="no-idea" onClick={this.toggleModal.bind(this)}>
                    <h4>No idea.</h4>
                    <p>Tell me where to get this information.</p>
                </div>
            </>
        );
    }
}

export default NoIdea;
