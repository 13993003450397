import React, { Component } from "react";

import FieldCard from "../../field-card/FieldCard";
import TestStep from "../../test-step/TestStep";
import RangeGroup from "../../range-group/RangeGroup";
import OptionToggle from "../../option-toggle/OptionToggle";

import content from "../../../lookup/en/content";
import {
    alcoholConsumptionOptions,
    alcoholUnitOptions,
    drinkingFrequencyOptions,
    binaryDrinkingOptions
} from "../../../lookup/en/formValues";

import "./AlcoholStep.scss";
import InfoModal from "../../info-modal/InfoModal";

class AlcoholStep extends Component {
    state = {
        initialAlcoholConsumptionValue: null,
        initialAlcoholUnitsValue: null,
        initialBingeSessionsValue: null,
        initialUnableToStop: null,
        initialFailedExpectations: null,
        initialMorningDrinking: null,
        initialDrinkingGuilt: null,
        initialBlackout: null,
        initialDrinkingInjury: null,
        initialDrinkingConcern: null,
        fullAudit: false,
        infoModalOpen: false
    };

    componentWillMount() {
        const {
            setAlcoholConsumptionAction,
            setAlcoholUnitsAction,
            setBingeSessionsAction,
            setAdditionalAUDITAction
        } = this.props;

        const defaultAlcoholConsumption =
            localStorage.getItem("alcoholConsumption") || 0;
        const defaultAlcoholUnits = localStorage.getItem("alcoholUnits") || 0;
        const defaultBingeSessions = localStorage.getItem("bingeSessions") || 0;
        const defaultUnableToStop = localStorage.getItem("unableToStop") || 0;
        const defaultFailedExpectations =
            localStorage.getItem("failedExpectations") || 0;
        const defaultMorningDrinking =
            localStorage.getItem("morningDrinking") || 0;
        const defaultDrinkingGuilt = localStorage.getItem("drinkingGuilt") || 0;
        const defaultBlackout = localStorage.getItem("blackout") || 0;
        const defaultDrinkingInjury =
            localStorage.getItem("drinkingInjury") || 0;
        const defaultDrinkingConcern =
            localStorage.getItem("drinkingConcern") || 0;

        setAlcoholConsumptionAction(defaultAlcoholConsumption);
        setAlcoholUnitsAction(defaultAlcoholUnits);
        setBingeSessionsAction(defaultBingeSessions);
        setAdditionalAUDITAction("unableToStop", defaultUnableToStop);
        setAdditionalAUDITAction(
            "failedExpectations",
            defaultFailedExpectations
        );
        setAdditionalAUDITAction("morningDrinking", defaultMorningDrinking);
        setAdditionalAUDITAction("drinkingGuilt", defaultDrinkingGuilt);
        setAdditionalAUDITAction("blackout", defaultBlackout);
        setAdditionalAUDITAction("drinkingInjury", defaultDrinkingInjury);
        setAdditionalAUDITAction("drinkingConcern", defaultDrinkingConcern);

        this.setState({
            initialAlcoholConsumptionValue: defaultAlcoholConsumption,
            initialAlcoholUnitsValue: defaultAlcoholUnits,
            initialBingeSessionsValue: defaultBingeSessions,
            initialUnableToStop: defaultUnableToStop,
            initialFailedExpectations: defaultFailedExpectations,
            initialMorningDrinking: defaultMorningDrinking,
            initialDrinkingGuilt: defaultDrinkingGuilt,
            initialBlackout: defaultBlackout,
            initialDrinkingInjury: defaultDrinkingInjury,
            initialDrinkingConcern: defaultDrinkingConcern
        });

        this.showAdditionalAuditQuestions();
    }

    onAlcoholConsumptionChange(value) {
        const { setAlcoholConsumptionAction, updateRisk } = this.props;

        setAlcoholConsumptionAction(value);
        updateRisk("AlcoholOften", value);
        localStorage.setItem("alcoholConsumption", value);
        this.showAdditionalAuditQuestions();
    }

    onAlcoholUnitsChange(value) {
        const { setAlcoholUnitsAction, updateRisk } = this.props;

        setAlcoholUnitsAction(value);
        updateRisk("AlcoholUnits", value);
        localStorage.setItem("alcoholUnits", value);
        this.showAdditionalAuditQuestions();
    }

    onBingeSessionsChange(value) {
        const { setBingeSessionsAction, updateRisk } = this.props;

        setBingeSessionsAction(value);
        updateRisk("AlcoholBinge", value);
        localStorage.setItem("bingeSessions", value);
        this.showAdditionalAuditQuestions();
    }

    onAdditionalAuditQuestionChange(key, value) {
        const { setAdditionalAUDITAction, updateRisk } = this.props;

        setAdditionalAUDITAction(key, value);
        updateRisk(key, value);
        localStorage.setItem(key, value);
    }

    showAdditionalAuditQuestions() {
        let alcoholConsumption =
            parseInt(localStorage.getItem("alcoholConsumption")) || 0;
        let alcoholUnits = parseInt(localStorage.getItem("alcoholUnits")) || 0;
        let bingeSessions =
            parseInt(localStorage.getItem("bingeSessions")) || 0;

        if (alcoholConsumption > 0) alcoholConsumption--;
        if (alcoholUnits > 0) alcoholUnits--;
        if (bingeSessions > 0) bingeSessions--;

        const fullAudit =
            alcoholConsumption + alcoholUnits + bingeSessions >= 5;

        if (!fullAudit) {
            this.onAdditionalAuditQuestionChange("unableToStop", 0);
            this.onAdditionalAuditQuestionChange("failedExpectations", 0);
            this.onAdditionalAuditQuestionChange("morningDrinking", 0);
            this.onAdditionalAuditQuestionChange("drinkingGuilt", 0);
            this.onAdditionalAuditQuestionChange("blackout", 0);
            this.onAdditionalAuditQuestionChange("drinkingInjury", 0);
            this.onAdditionalAuditQuestionChange("drinkingConcern", 0);
        }

        this.setState({
            fullAudit
        });
    }

    toggleInfoModal() {
        this.setState({
            infoModalOpen: !this.state.infoModalOpen
        });
    }
    render() {
        const {
            activeStep,
            alcoholConsumption,
            alcoholUnits,
            bingeSessions,
            unableToStop,
            failedExpectations,
            morningDrinking,
            drinkingGuilt,
            blackout,
            drinkingInjury,
            drinkingConcern
        } = this.props;

        const { fullAudit, infoModalOpen } = this.state;

        return (
            <TestStep activeStep={activeStep} stepIndex={9} alignTop>
                <section
                    className={
                        fullAudit
                            ? "alcohol-step expanded-step"
                            : "alcohol-step"
                    }
                >
                    <FieldCard cardTitle={content.Steps.Alcohol.Title}>
                        <p className="warning">
                            There may be more questions depending on your first
                            3 answers
                        </p>
                        <p>
                            <strong>{content.Steps.Alcohol.HowOften}</strong>
                        </p>

                        <div className="alcohol-dependency-items">
                            {alcoholConsumptionOptions.map((d) => (
                                <OptionToggle
                                    selected={alcoholConsumption === d.key}
                                    select={this.onAlcoholConsumptionChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="alcohol-dependency-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <InfoModal
                            title={content.Steps.Alcohol.HelpTitle}
                            content={content.Steps.Alcohol.HelpContent}
                            open={infoModalOpen}
                            closeModal={this.toggleInfoModal.bind(this)}
                        />
                        <p className="with-help">
                            <strong>{content.Steps.Alcohol.HowMuch}</strong>
                            <span
                                className="help"
                                onClick={this.toggleInfoModal.bind(this)}
                            >
                                ?
                            </span>
                        </p>

                        <div className="alcohol-dependency-items">
                            {alcoholUnitOptions.map((d) => (
                                <OptionToggle
                                    selected={alcoholUnits === d.key}
                                    select={this.onAlcoholUnitsChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="alcohol-dependency-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        <p>
                            <strong>
                                {content.Steps.Alcohol.BingeDrinking}
                            </strong>
                        </p>
                        <div className="alcohol-dependency-items">
                            {drinkingFrequencyOptions.map((d) => (
                                <OptionToggle
                                    selected={bingeSessions === d.key}
                                    select={this.onBingeSessionsChange.bind(
                                        this,
                                        d.key
                                    )}
                                    secondaryClass="alcohol-dependency-item"
                                    content={d.displayName}
                                    key={d.key}
                                />
                            ))}
                        </div>

                        {fullAudit && (
                            <>
                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.UnableToStop
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {drinkingFrequencyOptions.map((d) => (
                                        <OptionToggle
                                            selected={unableToStop === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "unableToStop",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps
                                                .FailedExpectations
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {drinkingFrequencyOptions.map((d) => (
                                        <OptionToggle
                                            selected={
                                                failedExpectations === d.key
                                            }
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "failedExpectations",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.MorningDrinking
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {drinkingFrequencyOptions.map((d) => (
                                        <OptionToggle
                                            selected={morningDrinking === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "morningDrinking",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.DrinkingGuilt
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {drinkingFrequencyOptions.map((d) => (
                                        <OptionToggle
                                            selected={drinkingGuilt === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "drinkingGuilt",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.Blackout
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {drinkingFrequencyOptions.map((d) => (
                                        <OptionToggle
                                            selected={blackout === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "blackout",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.DrinkingInjury
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {binaryDrinkingOptions.map((d) => (
                                        <OptionToggle
                                            selected={drinkingInjury === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "drinkingInjury",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>

                                <p>
                                    <strong>
                                        {
                                            content.Steps.Alcohol
                                                .AdditionalSteps.DrinkingConcern
                                        }
                                    </strong>
                                </p>

                                <div className="alcohol-dependency-items">
                                    {binaryDrinkingOptions.map((d) => (
                                        <OptionToggle
                                            selected={drinkingConcern === d.key}
                                            select={this.onAdditionalAuditQuestionChange.bind(
                                                this,
                                                "drinkingConcern",
                                                d.key
                                            )}
                                            secondaryClass="alcohol-dependency-item"
                                            content={d.displayName}
                                            key={d.key}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default AlcoholStep;
