import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    postalCode: "",
    postalCodeValidating: false,
    postalCodeInvalid: false,
    postalCodeValidated: false,
    includeDiabetes: false,
    remoteBloodPressure: null,
    remoteBloodTest: null,
    bookingLink: null,
    age: null,
    gender: null,
    ethnicity: null,
    heightUnit: "feet",
    heightInFeet: null,
    heightInInches: null,
    heightInCm: null,
    weightUnit: "stone",
    weightInKg: null,
    weightInStone: null,
    weightInLbs: null,
    smokingLevel: null,
    diabetesType: null,
    familyHistory: null,
    kidneyDisease: null,
    medicalConditions: [],
    noMedicalConditions: null,
    atrialFibrillationStatus: null,
    systolicBloodPressureReadings: [null, null, null],
    diastolicBloodPressureReadings: [null, null, null],
    bloodPressureInvalid: false,
    bloodPressureEmergency: false,
    bloodPressureLow: false,
    bloodPressureHigh: false,
    bloodPressureHighWarningRead: false,
    bloodPressureMoreReadingsRequested: false,
    bloodPressureHighWarningShow: false,
    bloodPressureLocation: null,
    pulse: null,
    cholesterolKnownStatus: null,
    totalCholesterol: null,
    hdlCholesterol: null,
    glyhb: null,
    diabetesInFamily: null,
    waistUnit: "inches",
    waistMeasurement: null,
    alcoholConsumption: null,
    alcoholUnits: null,
    bingeSessions: null,
    unableToStop: null,
    failedExpectations: null,
    morningDrinking: null,
    drinkingGuilt: null,
    blackout: null,
    drinkingInjury: null,
    drinkingConcern: null,
    activityHoursCycling: null,
    activityHoursWalking: null,
    activityHoursExercise: null,
    activityHoursHouseworkChildcare: null,
    activityHoursGardeningDIY: null,
    walkingPace: null,
    physicalWork: null,
    vegetables: null,
    foodTypes: null,
    weightClass: null,
    bodyMassIndex: null,
    diabetesRiskScore: null,
    AUDITCScore: null,
    AUDITScore: null,
    GPAQScore: null,
    formPart: null,
    formPartChanging: false,
    userStatus: null,
    missingSections: [],
    missingSectionsNotIncludingBP: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_ALL_FORM_VALUES:
            return {
                ...INITIAL_STATE,
                includeDiabetes: state.includeDiabetes,
                remoteBloodPressure: state.remoteBloodPressure,
                remoteBloodTest: state.remoteBloodTest,
                userStatus: state.userStatus
            };
        case actionTypes.SET_ALL_FORM_VALUES:
            return {
                ...state,
                ...action.formValues
            };
        case actionTypes.SET_INCLUDE_DIABETES:
            return {
                ...state,
                includeDiabetes: action.value
            };
        case actionTypes.SET_REMOTE_BLOOD_PRESSURE:
            return {
                ...state,
                remoteBloodPressure: action.value
            };
        case actionTypes.SET_BOOKING_LINK:
            return {
                ...state,
                bookingLink: action.value
            };
        case actionTypes.SET_REMOTE_BLOOD_TEST:
            return {
                ...state,
                remoteBloodTest: action.value
            };
        case actionTypes.SET_POSTCODE:
            return {
                ...state,
                postalCode: action.value
            };
        case actionTypes.BEGIN_POSTCODE_VALIDATION:
            return {
                ...state,
                postalCodeValidating: true
            };
        case actionTypes.SET_POSTCODE_VALID:
            return {
                ...state,
                postalCodeValidating: false,
                postalCodeValidated: true,
                postalCodeInvalid: false
            };
        case actionTypes.SET_POSTCODE_INVALID:
            return {
                ...state,
                postalCodeValidating: false,
                postalCodeValidated: true,
                postalCodeInvalid: true
            };
        case actionTypes.SET_AGE:
            return {
                ...state,
                age: action.value
            };
        case actionTypes.SET_GENDER:
            return {
                ...state,
                gender: action.value
            };
        case actionTypes.SET_ETHNICITY:
            return {
                ...state,
                ethnicity: action.value
            };
        case actionTypes.SET_HEIGHT_UNIT:
            return {
                ...state,
                heightUnit: action.value
            };
        case actionTypes.SET_HEIGHT_IN_FEET:
            return {
                ...state,
                heightInFeet: action.value
            };
        case actionTypes.SET_HEIGHT_IN_INCHES:
            return {
                ...state,
                heightInInches: action.value
            };
        case actionTypes.SET_HEIGHT_IN_CM:
            return {
                ...state,
                heightInCm: action.value
            };
        case actionTypes.SET_WEIGHT_UNIT:
            return {
                ...state,
                weightUnit: action.value
            };
        case actionTypes.SET_WEIGHT_IN_STONE:
            return {
                ...state,
                weightInStone: action.value
            };
        case actionTypes.SET_WEIGHT_IN_LBS:
            return {
                ...state,
                weightInLbs: action.value
            };
        case actionTypes.SET_WEIGHT_IN_KG:
            return {
                ...state,
                weightInKg: action.value
            };
        case actionTypes.SET_SMOKING_LEVEL:
            return {
                ...state,
                smokingLevel: action.value
            };
        case actionTypes.SET_DIABETES_TYPE:
            return {
                ...state,
                diabetesType: action.value
            };
        case actionTypes.SET_FAMILY_HISTORY:
            return {
                ...state,
                familyHistory: action.value
            };
        case actionTypes.SET_KIDNEY_DISEASE:
            return {
                ...state,
                kidneyDisease: action.value
            };
        case actionTypes.SET_MEDICAL_CONDITIONS:
            return {
                ...state,
                medicalConditions: action.value,
                noMedicalConditions: null
            };
        case actionTypes.SET_NO_MEDICAL_CONDITIONS:
            return {
                ...state,
                medicalConditions: [],
                noMedicalConditions: true
            };
        case actionTypes.SET_ATRIAL_FIBRILLATION_STEP:
            return {
                ...state,
                atrialFibrillationStatus: action.value
            };
        case actionTypes.SET_SYSTOLIC_BLOOD_PRESSURE_READINGS:
            return {
                ...state,
                systolicBloodPressureReadings: action.value
            };
        case actionTypes.SET_DIASTOLIC_BLOOD_PRESSURE_READINGS:
            return {
                ...state,
                diastolicBloodPressureReadings: action.value
            };
        case actionTypes.SET_BLOOD_PRESSURE_VALID:
            return {
                ...state,
                bloodPressureInvalid: false
            };
        case actionTypes.SET_BLOOD_PRESSURE_INVALID:
            return {
                ...state,
                bloodPressureInvalid: true
            };
        case actionTypes.SET_BLOOD_PRESSURE_EMERGENCY:
            return {
                ...state,
                bloodPressureLow: false,
                bloodPressureEmergency: true
            };
        case actionTypes.SET_BLOOD_PRESSURE_LOW:
            return {
                ...state,
                bloodPressureLow: true,
                bloodPressureHigh: false,
                bloodPressureEmergency: false
            };
        case actionTypes.SET_BLOOD_PRESSURE_HIGH:
            return {
                ...state,
                bloodPressureLow: false,
                bloodPressureHigh: true,
                bloodPressureEmergency: false
            };
        case actionTypes.SET_BLOOD_PRESSURE_NORMAL:
            return {
                ...state,
                bloodPressureLow: false,
                bloodPressureEmergency: false,
                bloodPressureHigh: false
            };
        case actionTypes.SET_BLOOD_PRESSURE_HIGH_WARNING_READ:
            return {
                ...state,
                bloodPressureHighWarningRead: true
            };
        case actionTypes.SET_BLOOD_PRESSURE_HIGH_WARNING_SHOW:
            return {
                ...state,
                bloodPressureHighWarningShow: true
            };
        case actionTypes.REQUEST_MORE_BP_READINGS:
            return {
                ...state,
                bloodPressureMoreReadingsRequested: action.value
            };
        case actionTypes.SET_BLOOD_PRESSURE_LOCATION:
            return {
                ...state,
                bloodPressureLocation: action.location
            };
        case actionTypes.SET_PULSE:
            return {
                ...state,
                pulse: action.value
            };
        case actionTypes.SET_CHOLESTEROL_STATUS:
            return {
                ...state,
                cholesterolKnownStatus: action.value
            };
        case actionTypes.SET_TOTAL_CHOLESTEROL_VALUE:
            return {
                ...state,
                totalCholesterol: action.value
            };
        case actionTypes.SET_HDL_CHOLESTEROL_VALUE:
            return {
                ...state,
                hdlCholesterol: action.value
            };
        case actionTypes.SET_DIABETES_IN_FAMILY:
            return {
                ...state,
                diabetesInFamily: action.value
            };
        case actionTypes.SET_WAIST_UNIT:
            return {
                ...state,
                waistUnit: action.value
            };
        case actionTypes.SET_WAIST_MEASUREMENT:
            return {
                ...state,
                waistMeasurement: action.value
            };
        case actionTypes.SET_ALCOHOL_CONSUMPTION_VALUE:
            return {
                ...state,
                alcoholConsumption: action.value
            };
        case actionTypes.SET_ALCOHOL_UNITS_VALUE:
            return {
                ...state,
                alcoholUnits: action.value
            };
        case actionTypes.SET_BINGE_DRINKING_SESSIONS:
            return {
                ...state,
                bingeSessions: action.value
            };
        case actionTypes.SET_ADDITIONAL_AUDIT_C_ACTION:
            return {
                ...state,
                [action.key]: action.value
            };
        case actionTypes.SET_ACTIVITY_HOURS_CYCLING:
            return {
                ...state,
                activityHoursCycling: action.value
            };
        case actionTypes.SET_ACTIVITY_HOURS_WALKING:
            return {
                ...state,
                activityHoursWalking: action.value
            };
        case actionTypes.SET_ACTIVITY_HOURS_EXERCISE:
            return {
                ...state,
                activityHoursExercise: action.value
            };
        case actionTypes.SET_ACTIVITY_HOURS_HOUSEWORK_CHILDCARE:
            return {
                ...state,
                activityHoursHouseworkChildcare: action.value
            };
        case actionTypes.SET_ACTIVITY_HOURS_GARDENING_DIY:
            return {
                ...state,
                activityHoursGardeningDIY: action.value
            };
        case actionTypes.SET_WALKING_PACE:
            return {
                ...state,
                walkingPace: action.value
            };
        case actionTypes.SET_PHYSICAL_WORK_DEFINITION:
            return {
                ...state,
                physicalWork: action.value
            };
        case actionTypes.SET_VEGETABLE_PORTIONS:
            return {
                ...state,
                vegetables: action.value
            };
        case actionTypes.SET_DIET_FOODS:
            return {
                ...state,
                foodTypes: action.value
            };
        case actionTypes.SET_WEIGHT_CLASS:
            return {
                ...state,
                weightClass: action.value
            };
        case actionTypes.SET_BODY_MASS_INDEX:
            return {
                ...state,
                bodyMassIndex: action.value
            };
        case actionTypes.SET_DIABETES_RISK_SCORE:
            return {
                ...state,
                diabetesRiskScore: action.value
            };
        case actionTypes.SET_AUDIT_C_SCORE:
            return {
                ...state,
                AUDITCScore: action.value
            };
        case actionTypes.SET_FULL_AUDIT_SCORE:
            return {
                ...state,
                AUDITScore: action.value
            };
        case actionTypes.SET_GPAQ_SCORE:
            return {
                ...state,
                GPAQScore: action.value
            };
        case actionTypes.CHANGE_FORM_PART:
            return {
                ...state,
                formPart: action.part,
                formPartChanging: true
            };
        case actionTypes.COMPLETE_FORM_PART_CHANGE:
            return {
                ...state,
                formPartChanging: false
            };
        case actionTypes.SET_STATUS_SUCCESS:
            return {
                ...state,
                userStatus: action.status
            };
        case actionTypes.SET_MISSING_FIELDS:
            return {
                ...state,
                missingSections: action.missingSections
            };
        case actionTypes.SET_MISSING_FIELDS_NOT_INCLUDING_BP:
            return {
                ...state,
                missingSectionsNotIncludingBP: action.missingSections
            };
        default:
            return state;
    }
};
