import React from "react";

import "./WelcomeDesktop.scss";
import Button from "../button/Button";
import content from "../../lookup/en/content";

const WelcomeDesktop = ({ userStatus }) => (
    <section className="welcome-desktop">
        <div className="content">
            <img
                src={`${process.env.PUBLIC_URL}/assets/intro_desktop.jpg`}
                className="logo"
                alt="NHS logo"
            />
            <Button href="/verification" isPrimary>
                {parseInt(userStatus) === 9
                    ? content.Home.TestButtonResults
                    : content.Home.TestButton}
            </Button>
        </div>
    </section>
);

export default WelcomeDesktop;
