import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Button from "../../components/button/Button";
import { confirmAddressAction } from "../../actions/address/addressActions";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import "./RequiredTestOrderPlaced.scss";
import content from "../../lookup/en/content";
import { PAGE_IDS } from "../../lookup/api";
import { setStatusAction } from "../../actions/form/formActions";

class RequiredTestOrderPlaced extends React.Component {
    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.BLOOD_TEST_ADDRESS_CONFIRM);
    }

    confirmAddress() {
        const { confirmAddressAction, token, setStatusAction } = this.props;

        setStatusAction(token, 6);
        confirmAddressAction();
    }

    render() {
        const { auth, address } = this.props;

        if (address.addressConfirmed) return <Redirect to="/test-summary" />;

        return (
            <div className="required-test-order-placed">
                <div className="order-confirmation">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/test-kit.png`}
                        alt={content.OrderPlaced.Title}
                    />
                    <h2>{content.OrderPlaced.Title}</h2>
                    <>{content.OrderPlaced.Content(address)}</>

                    <Button onClick={this.confirmAddress.bind(this)}>
                        {content.OrderPlaced.Next}
                    </Button>

                    <Button
                        href={`${process.env.PUBLIC_URL}/test-summary`}
                        greyed
                    >
                        {content.OrderPlaced.Back}
                    </Button>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        address: state.address,
        token: state.auth.token
    }),
    {
        confirmAddressAction,
        trackPageViewAction,
        setStatusAction
    }
)(RequiredTestOrderPlaced);
