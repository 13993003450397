import React from "react";

import "./LoadingSpinner.scss";

const LoadingSpinner = () => (
    <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default LoadingSpinner;
