import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    email: "",
    errors: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SEND_ENQUIRY_SUCCESS:
            return {
                ...state,
                email: action.email,
                errors: {}
            };
        case actionTypes.SEND_ENQUIRY_FAILURE:
            return {
                ...state,
                errors: action.errors
            };
        default:
            return state;
    }
};
