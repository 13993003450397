import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { clearRedirectAction } from "../../actions/auth/authActions";
import { setPreferenceAction } from "../../actions/preferences/preferencesActions";
import content from "../../lookup/en/content";
import "./Preferences.scss";

class Preferences extends Component {
    componentDidMount() {
        const { clearRedirectAction } = this.props;
        if (localStorage.getItem("userVerification")) {
            const userVerification = JSON.parse(
                localStorage.getItem("userVerification")
            );
            clearRedirectAction();
        }
    }

    componentDidUpdate() {
        const { preferences } = this.props;

        localStorage.setItem("preferences", JSON.stringify(preferences));
    }

    checkbox(field) {
        const { preferences, setPreferenceAction } = this.props;

        setPreferenceAction(field, !preferences[field]);
    }

    render() {
        const { preferences, auth } = this.props;

        return (
            <div className="preferences-container">
                <h2>{content.Header.Preferences}</h2>
                <form>
                    <label>{content.Preferences.PreferredLanguage}</label>
                    <select>
                        <option>English</option>
                    </select>

                    <fieldset
                        className="for-checkbox"
                        onClick={this.checkbox.bind(this, "mobilityIssues")}
                    >
                        <div
                            className={
                                preferences.mobilityIssues
                                    ? "checkbox checked"
                                    : "checkbox"
                            }
                        ></div>
                        <label>{content.Preferences.Mobility}</label>
                    </fieldset>

                    <fieldset
                        className="for-checkbox"
                        onClick={this.checkbox.bind(this, "visualImpairment")}
                    >
                        <div
                            className={
                                preferences.visualImpairment
                                    ? "checkbox checked"
                                    : "checkbox"
                            }
                        ></div>
                        <label>{content.Preferences.Visual}</label>
                    </fieldset>
                </form>

                <p>{content.Preferences.Change}</p>

                <Link
                    className="continue"
                    to={
                        auth.userVerification.verified
                            ? `${process.env.PUBLIC_URL}/test/1`
                            : `${process.env.PUBLIC_URL}/verification`
                    }
                >
                    <h3>{content.Steps.Next}</h3>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/next-step.png`}
                        alt={content.Steps.Next}
                    />
                </Link>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.auth,
        preferences: state.preferences
    }),
    {
        setPreferenceAction,
        clearRedirectAction
    }
)(Preferences);
