import React, { Component } from "react";

import TestStep from "../../test-step/TestStep";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";

import content from "../../../lookup/en/content";
import { waistMeasurements } from "../../../lookup/en/formValues";

import "./DiabetesStep.scss";

const MIN_WAIST_MEASUREMENT = 50;
const MAX_WAIST_MEASUREMENT = 250;

class DiabetesStep extends Component {
    state = {
        waistUnit: "inches",
        valid: true
    };

    componentWillMount() {
        const { setWaistMeasurementAction, setDiabetesInFamilyAction } =
            this.props;

        const defaultWaistUnit = localStorage.getItem("waistUnit") || "inches";
        const defaultWaistMeasurment =
            localStorage.getItem("waistMeasurement") || null;
        const defaultDiabetesInFamily =
            localStorage.getItem("diabetesInFamily") || null;

        this.setState({
            waistUnit: defaultWaistUnit
        });

        setDiabetesInFamilyAction(defaultDiabetesInFamily);
        setWaistMeasurementAction(defaultWaistMeasurment);
    }

    onUnitToggle() {
        const { setWaistUnitAction } = this.props;

        if (this.state.waistUnit === "cm") {
            this.setState({
                waistUnit: "inches"
            });
            localStorage.setItem("waistUnit", "inches");
            setWaistUnitAction("inches");
        } else {
            this.setState({
                waistUnit: "cm"
            });
            localStorage.setItem("waistUnit", "cm");
            setWaistUnitAction("cm");
        }
    }

    validateWaistMeasurement(value) {
        if (value < MIN_WAIST_MEASUREMENT || value > MAX_WAIST_MEASUREMENT) {
            return false;
        }
        return true;
    }

    onWaistMeasurementChange(value) {
        const { setWaistMeasurementAction, updateRisk } = this.props;

        setWaistMeasurementAction(value);
        updateRisk("Waist", value);
        localStorage.setItem("waistMeasurement", value);
    }

    onWaistMeasurementRawChange(e) {
        const { setWaistMeasurementAction, updateRisk } = this.props;
        const { waistUnit } = this.state;

        let value = e.target.value;
        if (waistUnit === "inches") value *= 2.54;

        if (e.target.value !== "" && !this.validateWaistMeasurement(value)) {
            this.setState({ valid: false });
            return;
        }
        this.setState({ valid: true });

        setWaistMeasurementAction(value.toFixed(2));
        updateRisk("Waist", value);
        localStorage.setItem("waistMeasurement", value);
    }

    onDiabetesInFamilyChange(value) {
        const { setDiabetesInFamilyAction, updateRisk } = this.props;

        setDiabetesInFamilyAction(value);
        updateRisk("DiabetesFamily", value);
        localStorage.setItem("diabetesInFamily", value);
    }

    render() {
        const { activeStep, waistMeasurement, diabetesInFamily } = this.props;
        const { waistUnit, valid } = this.state;

        const inchValue = Math.floor(waistMeasurement / 2.54);

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={8}
                disableNavigation={!valid}
            >
                <section className="diabetes-risk-step">
                    <FieldCard cardTitle={content.Steps.DiabetesRisk.Family}>
                        <div className="diabetes-family-items">
                            <OptionToggle
                                selected={parseInt(diabetesInFamily) === 1}
                                select={this.onDiabetesInFamilyChange.bind(
                                    this,
                                    1
                                )}
                                secondaryClass="diabetes-family-item"
                                content={content.Steps.DiabetesRisk.Yes}
                            />
                            <OptionToggle
                                selected={parseInt(diabetesInFamily) === 0}
                                select={this.onDiabetesInFamilyChange.bind(
                                    this,
                                    0
                                )}
                                secondaryClass="diabetes-family-item"
                                content={content.Steps.DiabetesRisk.No}
                            />
                        </div>
                    </FieldCard>

                    <img
                        src={`${process.env.PUBLIC_URL}/assets/waist-measurement.png`}
                        alt={content.Steps.DiabetesRisk.WaistMeasurement}
                    />
                    <FieldCard
                        cardTitle={content.Steps.DiabetesRisk.WaistMeasurement}
                        helpTitle={
                            content.Steps.DiabetesRisk.WaistMeasurementHelp
                                .Title
                        }
                        helpText={
                            <>
                                <iframe
                                    className="video"
                                    src="https://www.youtube.com/embed/X9yjbu4WaDc"
                                ></iframe>
                                {
                                    content.Steps.DiabetesRisk
                                        .WaistMeasurementHelp.Content
                                }
                            </>
                        }
                    >
                        <div className="waist-measurement-items">
                            <span
                                className={
                                    waistUnit === "cm"
                                        ? "unit-switcher on"
                                        : "unit-switcher off"
                                }
                                onClick={this.onUnitToggle.bind(this)}
                            >
                                {content.Steps.SwitchUnit}{" "}
                                {waistUnit === "inches"
                                    ? content.Steps.Height.Centimetres
                                    : content.Steps.Height.Inches}
                            </span>
                            <p>&nbsp;</p>

                            <div class="waist-measurement-changer">
                                <div>
                                    {waistUnit === "inches" && (
                                        <>
                                            <label>
                                                {content.Steps.Height.Inches}
                                            </label>
                                            <input
                                                type="number"
                                                name="inches"
                                                onBlur={this.onWaistMeasurementRawChange.bind(
                                                    this
                                                )}
                                                defaultValue={
                                                    inchValue > 0 ? inchValue : null
                                                }
                                            />
                                        </>
                                    )}
                                    {waistUnit === "cm" && (
                                        <>
                                            <label>
                                                {content.Steps.Height.Centimetres}
                                            </label>
                                            <input
                                                type="number"
                                                name="centimetres"
                                                onBlur={this.onWaistMeasurementRawChange.bind(
                                                    this
                                                )}
                                                defaultValue={
                                                    waistMeasurement > 0
                                                        ? waistMeasurement
                                                        : null
                                                }
                                            />
                                        </>
                                    )}
                                </div>

                                {!valid && (
                                    <p className="invalid-measurement">
                                        {
                                            content.Steps.DiabetesRisk
                                                .ErrorHandling
                                        }
                                    </p>
                                )}
                            </div>
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default DiabetesStep;
