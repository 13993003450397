import React, { Component } from "react";

import content from "../../lookup/en/content";
import Button from "../button/Button";

import "./DialStatic.scss";

class DialStatic extends Component {
    state = {
        riskClass: "",
        imageIndex: null
    };

    componentDidMount() {
        const {
            veryHighValue,
            highValue,
            medValue,
            lowerBound,
            targetValue,
            secondaryValue,
            secondaryHighValue,
            secondaryMedValue,
            secondaryLowerBound,
            alternativeTooLowRiskClass,
            alternativeLowRiskClass,
            alternativeMediumRiskClass,
            alternativeHighRiskClass,
            alternativeVeryHighRiskClass
        } = this.props;

        const TOO_LOW_CLASS = alternativeTooLowRiskClass
            ? alternativeTooLowRiskClass
            : "too-low";
        const LOW_RISK_CLASS = alternativeLowRiskClass
            ? alternativeLowRiskClass
            : "low-risk";
        const MED_RISK_CLASS = alternativeMediumRiskClass
            ? alternativeMediumRiskClass
            : "med-risk";
        const HIGH_RISK_CLASS = alternativeHighRiskClass
            ? alternativeHighRiskClass
            : "high-risk";
        const VERY_HIGH_RISK_CLASS = alternativeVeryHighRiskClass
            ? alternativeVeryHighRiskClass
            : "very-high-risk";

        let riskClass = LOW_RISK_CLASS;
        let imageIndex = 0;
        if (!secondaryValue) {
            if (targetValue >= medValue) {
                riskClass = MED_RISK_CLASS;
                imageIndex = 1;
            }
            if (targetValue >= highValue) {
                riskClass = HIGH_RISK_CLASS;
                imageIndex = 2;
            }

            if (lowerBound) {
                imageIndex++;
                if (targetValue < lowerBound) {
                    riskClass = TOO_LOW_CLASS;
                    imageIndex = 0;
                }
            }
            if (veryHighValue) {
                if (targetValue >= veryHighValue) {
                    imageIndex = 3;
                    riskClass = VERY_HIGH_RISK_CLASS;
                }
            }
        } else {
            if (targetValue > medValue || secondaryValue > secondaryMedValue) {
                imageIndex = 1;
                riskClass = MED_RISK_CLASS;
            }
            if (
                targetValue >= highValue ||
                secondaryValue >= secondaryHighValue
            ) {
                imageIndex = 2;
                riskClass = HIGH_RISK_CLASS;
            }

            if (lowerBound) {
                imageIndex++;

                if (
                    targetValue < lowerBound ||
                    secondaryValue < secondaryLowerBound
                ) {
                    imageIndex = 0;
                    riskClass = TOO_LOW_CLASS;
                }

                if (veryHighValue) {
                    if (targetValue >= veryHighValue) {
                        imageIndex = 3;
                        riskClass = VERY_HIGH_RISK_CLASS;
                    }
                }
            }
        }

        if (lowerBound) riskClass += " with-lower-bound";
        if (veryHighValue) riskClass += " with-higher-bound";

        this.setState({
            riskClass,
            imageIndex
        });
    }

    onClick() {
        this.props.onClick();
    }

    render() {
        const {
            title,
            prefix,
            disabled,
            targetValue,
            secondaryValue,
            interpretedValue,
            hiddenValue,
            boxless,
            noBoundaries,
            imageKey
        } = this.props;

        const { riskClass, imageIndex } = this.state;

        let containerClass = "static-dial-container";
        if (disabled) containerClass += " disabled";
        if (boxless) containerClass += " boxless";
        if (noBoundaries) containerClass += " no-boundaries";

        let displayValue = targetValue;
        if (secondaryValue) {
            displayValue = `${targetValue}<span> / ${secondaryValue}</span>`;
        }
        if (interpretedValue) {
            displayValue = content.Summary.RiskInterpretations[riskClass];
        }
        if (hiddenValue) {
            displayValue = "";
        }

        if (prefix) {
            displayValue = `<span>${prefix}</span>${displayValue}`;
        }

        return (
            <div className={containerClass}>
                <h3>{title}</h3>

                <div className={`dial ${riskClass}`}>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/dials/${imageKey}/${imageIndex}.jpg`}
                    />
                    <div
                        className="counter"
                        dangerouslySetInnerHTML={{ __html: displayValue }}
                    />

                    {!boxless && (
                        <Button onClick={this.onClick.bind(this)}>
                            {content.Summary.Details}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default DialStatic;
