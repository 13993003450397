import React, { Component } from "react";

import "./Risk.scss";
import content from "../../lookup/en/content";
import Tutorial from "../tutorial/Tutorial";

class Risk extends Component {
    state = {
        update: false
    };

    dismissTutorial() {
        localStorage.setItem("riskTutorial", true);
    }

    render() {
        const {
            riskValue,
            onSummary,
            setTutorialViewed,
            tutorials
        } = this.props;

        const riskTutorial = localStorage.getItem("riskTutorial");

        let riskRating = "";
        if (parseFloat(riskValue) > 0) riskRating = "low";
        if (parseFloat(riskValue) > 10) riskRating = "med";
        if (parseFloat(riskValue) > 20) riskRating = "high";

        return (
            <>
                <Tutorial
                    show={!riskTutorial}
                    elementReference=".risk-value"
                    position="bottom-right"
                    text={content.Tutorials.Risk}
                    dismiss={this.dismissTutorial.bind(this)}
                    setTutorialViewed={setTutorialViewed}
                    tutorials={tutorials}
                />
                <div className={onSummary ? "risk leaving" : "risk"}>
                    <div className="risk-container">
                        <p>
                            {riskValue > 0
                                ? content.Header.Risk
                                : content.Header.RiskWill}
                        </p>
                        <div className={`risk-value ${riskRating}`}>
                            {riskValue > 0 ? `${riskValue}%` : "-"}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Risk;
