import * as actionTypes from "../actionTypes";

export const setContentsIndexAction = (value) => ({
    type: actionTypes.SET_CONTENTS_INDEX,
    value
});

export const setTutorialViewedAction = (value) => ({
    type: actionTypes.SET_TUTORIAL_VIEWED,
    value
});
