import React from "react";

import Button from "../../components/button/Button";
import content from "../../lookup/en/content";

import "./NotFound.scss";

const NotFound = () => (
    <div className="not-found-container">
        <img src={`${process.env.PUBLIC_URL}/assets/nf404.png`} />
        <h1>{content.NotFound.Title}</h1>
        <p>{content.NotFound.Content}</p>
        <Button href={`${process.env.PUBLIC_URL}/`}>
            {content.NotFound.Back}
        </Button>
    </div>
);

export default NotFound;
