import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import header from "./header/headerReducer";
import form from "./form/formReducer";
import auth from "./auth/authReducer";
import risk from "./risk/riskReducer";
import address from "./address/addressReducer";
import pharmacies from "./pharmacies/pharmacyReducer";
import preferences from "./preferences/preferencesReducer";
import contact from "./contact/contactReducer";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        header,
        form,
        auth,
        risk,
        address,
        pharmacies,
        preferences,
        contact
    });
