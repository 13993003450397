import axios from "axios";

import * as actionTypes from "../actionTypes";
import mockAddresses from "../../mock/addresses";
import {
    setPostcodeValidAction,
    setPostcodeInvalidAction,
    setStatusAction,
    setPostcodeAction
} from "../form/formActions";

import { API_BASE } from "../../lookup/api";

const POSTCODE_LOOKUP_API_URL = "https://api.getaddress.io/find/";
const POSTCODE_LOOKUP_API_KEY = "?api-key=Psfr4oK0mkWfWQx0pQGWrw25969";
const BLOOD_TEST_ORDER_URL = `${API_BASE}/btrequest.ashx`;

export const setAddressesAction = (addresses) => ({
    type: actionTypes.SET_LOOKUP_ADDRESSES,
    addresses
});

export const clearAddressesAction = () => ({
    type: actionTypes.CLEAR_LOOKUP_ADDRESSES
});

export const chooseAddressAction = (address) => ({
    type: actionTypes.CHOOSE_ADDRESS,
    address
});

export const setPersonalDetailsAction = (key, value) => ({
    type: actionTypes.SET_PERSONAL_DETAILS,
    key,
    value
});

export const setAddressComponentAction = (key, value) => ({
    type: actionTypes.SET_ADDRESS_COMPONENT,
    key,
    value
});

export const setLatLngAction = (latitude, longitude) => ({
    type: actionTypes.SET_LAT_LNG,
    latitude,
    longitude
});

export const setAddressFormErrorsAction = (errors) => ({
    type: actionTypes.SET_ADDRESS_FORM_ERRORS,
    errors
});

export const orderPlaceSuccessAction = () => ({
    type: actionTypes.CHOLESTEROL_TEST_ORDER_PLACE_SUCCESS
});

export const bpKitLoanPlaceSuccessAction = () => ({
    type: actionTypes.BP_KIT_LOAN_ORDER_PLACE_SUCCESS
});

export const beginPostcodeValidationAction = () => ({
    type: actionTypes.BEGIN_POSTCODE_VALIDATION
});

export const postcodeLookupAction = (postalCode, callback = () => {}) => {
    const collator = new Intl.Collator("en", {
        numeric: true,
        sensitivity: "base"
    });

    if (process.env.REACT_APP_MOCK_API) {
        return (dispatch) => {
            dispatch(
                setLatLngAction(
                    parseFloat(mockAddresses.latitude),
                    parseFloat(mockAddresses.longitude)
                )
            );
            dispatch(
                setAddressesAction(
                    mockAddresses.addresses.sort(collator.compare)
                )
            );
            dispatch(setPostcodeValidAction(postalCode));
            callback(mockAddresses);
        };
    }

    return (dispatch) => {
        dispatch(beginPostcodeValidationAction());

        return axios
            .get(
                `${POSTCODE_LOOKUP_API_URL}${postalCode}${POSTCODE_LOOKUP_API_KEY}`
            )
            .then(({ data }) => {
                dispatch(
                    setLatLngAction(
                        parseFloat(data.latitude),
                        parseFloat(data.longitude)
                    )
                );
                dispatch(
                    setAddressesAction(data.addresses.sort(collator.compare))
                );
                if (data.addresses.length > 0) {
                    dispatch(setPostcodeValidAction(postalCode));
                }
                callback(data);
            })
            .catch((error) => {
                if (postalCode === "") {
                    dispatch(setPostcodeValidAction(""));
                } else {
                    dispatch(setPostcodeInvalidAction());
                }
            });
    };
};

export const submitAddressForm = (token, formValues, type) => {
    let errors = {};
    Object.keys(formValues.personalDetails).forEach((key) => {
        if (formValues.personalDetails[key] === null) errors[key] = "required";
    });
    Object.keys(formValues.selectedAddress).forEach((key) => {
        if (formValues.selectedAddress[key] === null && key !== "line2")
            errors[key] = "required";
    });

    if (Object.keys(errors).length > 0) {
        return (dispatch) => dispatch(setAddressFormErrorsAction(errors));
    }

    if (process.env.REACT_APP_MOCK_API) {
        if (type == "bloodTest") {
            return (dispatch) => dispatch(orderPlaceSuccessAction());
        } else if (type == "bpKit") {
            return (dispatch) => dispatch(bpKitLoanPlaceSuccessAction());
        }
    }

    return (dispatch) => dispatch(addressValidatedAction());
};

export const addressValidatedAction = () => ({
    type: actionTypes.ADDRESS_VALIDATED
});

export const confirmAddressAction = (type = "bloodTest") => {
    return (dispatch, getState) => {
        const token = localStorage.getItem("token");
        const formValues = getState().address;
        const bmiValues = getState().form;

        let form = {
            Token: token,
            titleid: formValues.personalDetails.titleID,
            firstname: formValues.personalDetails.firstName,
            surname: formValues.personalDetails.lastName,
            mobile: formValues.personalDetails.mobile,
            dob: formValues.personalDetails.dob,
            email: formValues.personalDetails.email,
            genderid: formValues.personalDetails.gender,
            Paddressline1: formValues.selectedAddress.line1,
            Paddressline2: formValues.selectedAddress.line2,
            Pcity: formValues.selectedAddress.town,
            Ppostcode: formValues.selectedAddress.postalCode
            // height: bmiValues.heightInCm,
            // weight: bmiValues.weightInKg
        };

        if (formValues.selectedAddress.line3) {
            form.Paddressline1 = `${formValues.selectedAddress.line1}, ${formValues.selectedAddress.line2}`;
            form.Paddressline2 = formValues.selectedAddress.line3;

            if (formValues.selectedAddress.line4) {
                form.Paddressline2 += `, ${formValues.selectedAddress.line4}`;
            }
        }

        Object.keys(form).forEach((key) => {
            if (form[key] === null) {
                delete form[key];
            }
        });

        return axios
            .post(BLOOD_TEST_ORDER_URL, JSON.stringify(form), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(`Error: ${data.ErrorMessage}`);
                } else {
                    dispatch(setStatusAction(token, 6));
                    if (type == "bloodTest") {
                        dispatch(orderPlaceSuccessAction());
                    } else if (type == "bpKit") {
                        dispatch(bpKitLoanPlaceSuccessAction());
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};
