import React from "react";

import "./CholesterolStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import OptionToggle from "../../option-toggle/OptionToggle";
import NoIdea from "../../no-idea/NoIdea";
import PromoModal from "../../promo-modal/PromoModal";
import RangeGroup from "../../range-group/RangeGroup";
import content from "../../../lookup/en/content";
import { setTotalCholesterolValueAction } from "../../../actions/form/formActions";

const CHOLESTEROL_MODAL_LINK_HREF = `${process.env.PUBLIC_URL}/free-cholesterol-test`;

const MIN_CHOLESTEROL_VALUE = 0.0;
const MAX_CHOLESTEROL_VALUE = 15.0;
const MIN_HDL_CHOLESTEROL_VALUE = 0.0;
const MAX_HDL_CHOLESTEROL_VALUE = 5.0;

class CholesterolStep extends React.Component {
    state = {
        cholesterolKnownStatus: null,
        promoModalOpen: false,
        initialTotalCholesterolValue: null,
        initialHDLCholesterolValue: null
    };

    cholesterolRangeAnimate = null;
    zeroInputs = 0;

    componentWillMount() {
        const {
            setCholesterolStatusAction,
            setTotalCholesterolValueAction,
            setHDLCholesterolValueAction
        } = this.props;
        const defaultCholesterolStatus =
            localStorage.getItem("cholesterolKnownStatus") || null;
        setCholesterolStatusAction(defaultCholesterolStatus);

        const defaultTotalCholesterolValue =
            localStorage.getItem("totalCholesterolMMH") ||
            localStorage.getItem("totalCholesterol") ||
            MIN_CHOLESTEROL_VALUE;
        setTotalCholesterolValueAction(
            parseFloat(defaultTotalCholesterolValue)
        );

        const defaultHDLCholesterolValue =
            localStorage.getItem("hdlCholesterolMMH") ||
            localStorage.getItem("hdlCholesterol") ||
            MIN_HDL_CHOLESTEROL_VALUE;
        setHDLCholesterolValueAction(defaultHDLCholesterolValue);

        this.setState({
            cholesterolKnownStatus: defaultCholesterolStatus,
            initialTotalCholesterolValue: defaultTotalCholesterolValue,
            initialHDLCholesterolValue: defaultHDLCholesterolValue
        });
    }

    toggleModal() {
        this.setState({
            promoModalOpen: !this.state.promoModalOpen
        });
    }

    onCholesterolStatusChange() {
        const { setCholesterolStatusAction, cholesterolStatus } = this.props;
        let value = false;
        if (cholesterolStatus === "false" || cholesterolStatus === false) {
            value = null;
        } else {
            this.zeroInputs = 0;
            this.cholesterolRangeAnimate = setInterval(() => {
                let inputs = document.querySelectorAll(
                    ".cholesterol-items input[type=range]"
                );
                inputs.forEach((input) => {
                    if (input.value > 0) {
                        input.value -= 0.1;
                        const change = new Event("change", {
                            bubbles: true
                        });
                        input.dispatchEvent(change);

                        if (input.value <= 0) {
                            this.zeroInputs++;
                            input.value = 0;
                            input.dispatchEvent(change);

                            if (this.zeroInputs === inputs.length) {
                                this.pushTotalCholesterolToState(0);
                                this.pushHDLCholesterolToState(0);

                                clearInterval(this.cholesterolRangeAnimate);
                            }
                        }
                    }
                });
            }, 10);
        }

        setCholesterolStatusAction(value);
        localStorage.setItem("cholesterolKnownStatus", value);
    }

    returnToSelection() {
        const { setCholesterolStatusAction } = this.props;
        setCholesterolStatusAction(null);
        localStorage.removeItem("cholesterolKnownStatus");
    }

    pushTotalCholesterolToState(value) {
        const {
            setTotalCholesterolValueAction,
            setCholesterolStatusAction,
            updateRisk
        } = this.props;

        const parsedValue = parseFloat(value).toFixed(2);

        if (parsedValue !== 0) {
            setCholesterolStatusAction(parsedValue);
            localStorage.setItem("cholesterolKnownStatus", parsedValue);
        }

        setTotalCholesterolValueAction(parsedValue);
        updateRisk("Cholesterol", parsedValue);
        localStorage.setItem("totalCholesterol", parsedValue);
    }

    pushHDLCholesterolToState(value) {
        const {
            setHDLCholesterolValueAction,
            setCholesterolStatusAction,
            updateRisk
        } = this.props;

        const parsedValue = parseFloat(value).toFixed(2);

        if (parsedValue !== 0) {
            setCholesterolStatusAction(parsedValue);
            localStorage.setItem("cholesterolKnownStatus", parsedValue);
        }

        setHDLCholesterolValueAction(parsedValue);
        updateRisk("Hdl", parsedValue);
        localStorage.setItem("hdlCholesterol", parsedValue);
    }

    render() {
        const {
            activeStep,
            history,
            cholesterolStatus,
            totalCholesterol,
            hdlCholesterol
        } = this.props;

        return (
            <TestStep activeStep={activeStep} stepIndex={9}>
                <SectionHeader isMedical>
                    {content.Header.MedicalInformation}
                </SectionHeader>
                <section className="cholesterol-step">
                    <FieldCard
                        cardTitle={content.Steps.Cholesterol.Title}
                        helpTitle={content.Steps.Cholesterol.HelpTitle}
                        helpText={content.Steps.Cholesterol.HelpText}
                    >
                        <div className="cholesterol-status">
                            <p>
                                <strong>
                                    {content.Steps.Cholesterol.Subtitle}
                                </strong>
                            </p>
                            <div className="cholesterol-items">
                                <p>{content.Steps.Cholesterol.Total}</p>
                                <RangeGroup
                                    min={MIN_CHOLESTEROL_VALUE}
                                    max={MAX_CHOLESTEROL_VALUE}
                                    onChange={this.pushTotalCholesterolToState.bind(
                                        this
                                    )}
                                    fixedPoints={2}
                                    step={0.01}
                                    defaultValue={totalCholesterol}
                                    initialValue={
                                        this.state.initialTotalCholesterolValue
                                    }
                                    idKey="total_cholesterol"
                                    small
                                />
                                <p>{content.Steps.Cholesterol.HDL}</p>
                                <RangeGroup
                                    min={MIN_HDL_CHOLESTEROL_VALUE}
                                    max={MAX_HDL_CHOLESTEROL_VALUE}
                                    onChange={this.pushHDLCholesterolToState.bind(
                                        this
                                    )}
                                    step={0.01}
                                    fixedPoints={2}
                                    defaultValue={hdlCholesterol}
                                    initialValue={
                                        this.state.initialHDLCholesterolValue
                                    }
                                    idKey="hdl_cholesterol"
                                    small
                                />
                                <OptionToggle
                                    selected={
                                        cholesterolStatus === "false" ||
                                        cholesterolStatus === false
                                    }
                                    select={this.onCholesterolStatusChange.bind(
                                        this
                                    )}
                                    secondaryClass="cholesterol-item"
                                    content={content.Steps.Cholesterol.No}
                                />
                                <div
                                    className={
                                        this.state.promoModalOpen
                                            ? "promo-modal-frame visible"
                                            : "promo-modal-frame"
                                    }
                                >
                                    <iframe
                                        src={CHOLESTEROL_MODAL_LINK_HREF}
                                        title={
                                            content.Steps.Cholesterol.Modal
                                                .Title
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="clearfix" />
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default CholesterolStep;
