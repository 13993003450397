import React, { Component } from "react";
import { connect } from "react-redux";

import { sendEnquiryAction } from "../../actions/contact/contactActions";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import Button from "../../components/button/Button";
import content from "../../lookup/en/content";

import "./Contact.scss";
import { PAGE_IDS } from "../../lookup/api";

class Contact extends Component {
    state = {
        email: "",
        message: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.CONTACT_US);
    }

    sendEnquiry() {
        const { sendEnquiryAction } = this.props;
        sendEnquiryAction(this.state.email, this.state.message);
    }

    onChange(key, event) {
        this.setState({
            [key]: event.target.value
        });
    }

    render() {
        const { contact } = this.props;

        return (
            <div className="contact-container">
                <h1>{content.Contact.Title}</h1>
                <p>{content.Contact.Content}</p>
                {contact.email !== "" && <p>{content.Contact.ThankYou}</p>}
                {contact.email === "" && (
                    <form>
                        <label>{content.Contact.Email}</label>
                        {contact.errors.email === "invalid" && (
                            <span className="error">
                                {content.Contact.EmailInvalid}
                            </span>
                        )}
                        <input
                            type="text"
                            name="Email"
                            onChange={this.onChange.bind(this, "email")}
                        />
                        <label>{content.Contact.Message}</label>
                        {contact.errors.message === "invalid" && (
                            <span className="error">
                                {content.Contact.MessageInvalid}
                            </span>
                        )}
                        <textarea
                            name="message"
                            onChange={this.onChange.bind(this, "message")}
                        ></textarea>
                        <Button isPrimary onClick={this.sendEnquiry.bind(this)}>
                            {content.Contact.Send}
                        </Button>
                    </form>
                )}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        contact: state.contact
    }),
    {
        sendEnquiryAction,
        trackPageViewAction
    }
)(Contact);
