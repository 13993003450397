import React from "react";

import content from "../../lookup/en/content";

import "./Inactive.scss";

const Inactive = () => (
    <div className="inactive-container">
        <img src={`${process.env.PUBLIC_URL}/assets/nf404.png`} />
        <h1>{content.Inactive.Title}</h1>
        <p>{content.Inactive.Content}</p>
    </div>
);

export default Inactive;
