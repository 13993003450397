import React from "react";

import "./WelcomeMobile.scss";
import Button from "../button/Button";
import content from "../../lookup/en/content";

const WelcomeMobile = ({ userStatus }) => (
    <section className="welcome-mobile">
        <img
            src={`${process.env.PUBLIC_URL}/assets/intro_mobile.jpg`}
            className="hero"
            alt="NHS Health Check Helping your prevent diabetes, heart disease, kidney disease, stroke and dementia"
        />
        <Button href="/verification" isPrimary>
            {parseInt(userStatus) === 9
                ? content.Home.TestButtonResults
                : content.Home.TestButton}
        </Button>
    </section>
);

export default WelcomeMobile;
