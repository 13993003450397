import React, { Component } from "react";
import axios from "axios";

import "./FAQs.scss";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { connect } from "react-redux";
import { PAGE_IDS } from "../../lookup/api";

class FAQs extends Component {
    state = {
        content: ""
    };

    componentWillMount() {
        const { trackPageViewAction } = this.props;

        trackPageViewAction(PAGE_IDS.FAQs);

        axios.get(`${process.env.PUBLIC_URL}/faqs.html`).then(({ data }) => {
            this.setState({
                content: data
            });

            if (window.location.hash) {
                setTimeout(() => {
                    const el = document.querySelector(window.location.hash);
                    document.getElementById("root").scrollTo(0, el.offsetTop);
                }, 100);
            }
        });
    }

    render() {
        return (
            <div className="faqs-container">
                <h1>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/confused-man.png`}
                    />
                    <br />
                    Frequently Asked Questions
                </h1>
                <section
                    dangerouslySetInnerHTML={{
                        __html: this.state.content
                    }}
                />
            </div>
        );
    }
}

export default connect((state) => ({}), {
    trackPageViewAction
})(FAQs);
