import React from "react";

import "./HeightWeightStep.scss";
import TestStep from "../../test-step/TestStep";
import SectionHeader from "../../section-header/SectionHeader";
import FieldCard from "../../field-card/FieldCard";
import content from "../../../lookup/en/content";

import {
    convertCmToFeetAndInches,
    convertFeetAndInchesToCm,
    convertKgToStoneAndLbs,
    convertStoneAndLbsToKg
} from "../../../util";

const MAX_INCHES = 11;
const MAX_FEET = 12;
const MIN_CM = 80;
const MAX_CM = 220;

const MAX_LBS = 13;
const MAX_STONE = 32;
const MAX_KG = 209;

class HeightWeightStep extends React.Component {
    state = {
        valid: true
    };

    componentWillMount() {
        const {
            setHeightUnitAction,
            setHeightInFeetAction,
            setHeightInInchesAction,
            setHeightInCmAction,
            setWeightUnitAction,
            setWeightInStoneAction,
            setWeightInLbsAction,
            setWeightInKgAction
        } = this.props;

        const defaultHeightUnit = localStorage.getItem("heightUnit") || "feet";
        const defaultHeightInCm = localStorage.getItem("heightInCm");
        let defaultHeightInFeet = localStorage.getItem("heightInFeet");
        let defaultHeightInInches = localStorage.getItem("heightInInches");

        setHeightUnitAction(defaultHeightUnit);
        setHeightInCmAction(defaultHeightInCm);

        if (!defaultHeightInFeet && defaultHeightInCm) {
            const feetAndInchesValue = convertCmToFeetAndInches(
                defaultHeightInCm
            );
            defaultHeightInFeet = feetAndInchesValue.feet;
            defaultHeightInInches = feetAndInchesValue.inches;
        }

        setHeightInFeetAction(defaultHeightInFeet);
        setHeightInInchesAction(defaultHeightInInches);

        const defaultWeightUnit = localStorage.getItem("weightUnit") || "stone";
        const defaultWeightInKg = localStorage.getItem("weightInKg");
        let defaultWeightInStone = localStorage.getItem("weightInStone");
        let defaultWeightInLbs = localStorage.getItem("weightInLbs");

        if (!defaultWeightInStone && defaultWeightInKg) {
            const stoneAndLbsValue = convertKgToStoneAndLbs(defaultWeightInKg);
            defaultWeightInStone = stoneAndLbsValue.stone;
            defaultWeightInLbs = stoneAndLbsValue.lbs;
        }

        setWeightUnitAction(defaultWeightUnit);
        setWeightInKgAction(defaultWeightInKg);
        setWeightInStoneAction(defaultWeightInStone);
        setWeightInLbsAction(defaultWeightInLbs);
    }

    validateHeight(value) {
        if (value < MIN_CM || value > MAX_CM) {
            return false;
        }
        return true;
    }

    changeHeight(event) {
        const {
            heightInFeet,
            heightInInches,
            setHeightInCmAction,
            setHeightInFeetAction,
            setHeightInInchesAction,
            updateRisk
        } = this.props;

        const input = event.target;
        let value = input.value;
        const unit = input.attributes.name.value;
        let cmValue; // API accepts CM

        if(unit === "feet") {
            cmValue = convertFeetAndInchesToCm(value, heightInInches);
            setHeightInFeetAction(value);
            localStorage.setItem("heightInFeet", value);
        } else if(unit === "inches") {
            cmValue = convertFeetAndInchesToCm(heightInFeet, value);
            setHeightInInchesAction(value);
            localStorage.setItem("heightInInches", value);
        } else {
            cmValue = value;
        }

        if (
            ((unit !== "cm" && cmValue.toString() !== "0.00") || (unit === "cm" && cmValue !== "")) && !this.validateHeight(cmValue)) {
            this.setState({ valid: false });
            return;
        }
        this.setState({ valid: true });
        setHeightInCmAction(cmValue);
        localStorage.setItem("heightInCm", cmValue);

        updateRisk("Height", cmValue);
    }

    changeHeightUnit(unit) {
        const { setHeightUnitAction } = this.props;

        setHeightUnitAction(unit);
        localStorage.setItem("heightUnit", unit);
    }

    changeWeight(event) {
        const {
            weightInStone,
            weightInLbs,
            setWeightInKgAction,
            setWeightInStoneAction,
            setWeightInLbsAction,
            updateRisk
        } = this.props;

        const input = event.target;
        let value = input.value;
        let kgValue;

        if (input.attributes.name.value === "kg") {
            if (input.value > MAX_KG) value = MAX_KG;
            setWeightInKgAction(value);
            localStorage.setItem("weightInKg", value);
            kgValue = value;

            const stoneAndLbsValue = convertKgToStoneAndLbs(value);
            setWeightInStoneAction(stoneAndLbsValue.stone);
            setWeightInLbsAction(stoneAndLbsValue.lbs);

            localStorage.setItem("weightInStone", stoneAndLbsValue.stone);
            localStorage.setItem("weightInLbs", stoneAndLbsValue.lbs);
        } else if (input.attributes.name.value === "stone") {
            if (input.value > MAX_STONE) value = MAX_STONE;
            setWeightInStoneAction(value);
            localStorage.setItem("weightInStone", value);

            kgValue = convertStoneAndLbsToKg(value, weightInLbs);
            setWeightInKgAction(kgValue);
            localStorage.setItem("weightInKg", kgValue);
        } else if (input.attributes.name.value === "lbs") {
            if (input.value > MAX_LBS) value = MAX_LBS;
            setWeightInLbsAction(value);
            localStorage.setItem("weightInLbs", value);

            kgValue = convertStoneAndLbsToKg(weightInStone, value);
            setWeightInKgAction(kgValue);
            localStorage.setItem("weightInKg", kgValue);
        }

        updateRisk("Weight", kgValue);
        input.value = value;
    }

    changeWeightUnit(unit) {
        const { setWeightUnitAction } = this.props;

        setWeightUnitAction(unit);
        localStorage.setItem("weightUnit", unit);
    }

    render() {
        const {
            activeStep,
            heightUnit,
            heightInFeet,
            heightInInches,
            heightInCm,
            weightUnit,
            weightInStone,
            weightInLbs,
            weightInKg
        } = this.props;

        const { valid } = this.state;

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={2}
                disableNavigation={!valid}
            >
                <SectionHeader>{content.Header.AboutYou}</SectionHeader>
                <section className="height-weight-step">
                    <FieldCard
                        cardTitle={content.Steps.Height.Title}
                        defaultUnit={content.Steps.Height.Feet}
                        alternativeUnit={content.Steps.Height.Centimetres}
                        currentUnit={heightUnit}
                        changeUnit={this.changeHeightUnit.bind(this)}
                    >
                        <div className="height-weight-changer">
                            {heightUnit === "feet" && (
                                <>
                                    <label className="height-feet">
                                        {content.Steps.Height.Feet}
                                    </label>
                                    <input
                                        type="number"
                                        name="feet"
                                        className="height-feet"
                                        onBlur={this.changeHeight.bind(this)}
                                        defaultValue={heightInFeet}
                                    />
                                    <label className="height-inches">
                                        {content.Steps.Height.Inches}
                                    </label>
                                    <input
                                        type="number"
                                        name="inches"
                                        className="height-inches"
                                        onBlur={this.changeHeight.bind(this)}
                                        defaultValue={heightInInches}
                                    />
                                </>
                            )}
                            {heightUnit === "cm" && (
                                <>
                                    <label className="height-cm">
                                        {content.Steps.Height.Centimetres}
                                    </label>
                                    <input
                                        type="number"
                                        name="cm"
                                        className="height-cm"
                                        onBlur={this.changeHeight.bind(this)}
                                        defaultValue={heightInCm}
                                    />
                                </>
                            )}
                            {!valid && (
                                <p className="invalid-measurement">
                                    {
                                        content.Steps.Height
                                            .ErrorHandling
                                    }
                                </p>
                            )}
                        </div>
                    </FieldCard>
                    <FieldCard
                        cardTitle={content.Steps.Weight.Title}
                        defaultUnit="stone"
                        alternativeUnit="kg"
                        currentUnit={weightUnit}
                        changeUnit={this.changeWeightUnit.bind(this)}
                    >
                        <div className="height-weight-changer">
                            {weightUnit === "stone" && (
                                <>
                                    <label className="weight-stone">
                                        {content.Steps.Weight.Stone}
                                    </label>
                                    <input
                                        type="number"
                                        name="stone"
                                        className="weight-stone"
                                        onBlur={this.changeWeight.bind(this)}
                                        defaultValue={weightInStone}
                                    />
                                    <label className="weight-lbs">
                                        {content.Steps.Weight.Pounds}
                                    </label>
                                    <input
                                        type="number"
                                        name="lbs"
                                        className="weight-lbs"
                                        onBlur={this.changeWeight.bind(this)}
                                        defaultValue={weightInLbs}
                                    />
                                </>
                            )}
                            {weightUnit === "kg" && (
                                <>
                                    <label className="weight-kg">
                                        {content.Steps.Weight.Kilograms}
                                    </label>
                                    <input
                                        type="number"
                                        name="kg"
                                        className="weight-kg"
                                        onBlur={this.changeWeight.bind(this)}
                                        defaultValue={weightInKg}
                                    />
                                </>
                            )}
                        </div>
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default HeightWeightStep;
