import React from "react";
import { Link } from "react-router-dom";
import { titles } from "./formValues";

export default {
    Header: {
        Login: "Log in",
        Risk: "Your chance of having a heart attack or stroke within the next 10 years is:",
        RiskWill:
            "Your chance of having a heart attack or stroke within the next 10 years will appear in the circle.",
        AboutYou: "About you",
        MedicalInformation: "Your medical information",
        Back: "Back",
        Close: "Close",
        Home: "Home",
        DoTheTestNow: "Do the test now",
        TableOfContent: "Table of content",
        Preferences: "My preferences",
        SessionTimeout: "You have been logged out due to inactivity.",
        SessionInvalid: "You have been logged out as a security precaution."
    },
    Footer: {
        PoweredByTickertest: "Digital NHS Health Check by Howoco",
        About: "About",
        Accessibility: "Accessibility",
        Terms: "Terms and conditions",
        Disclaimers: "Data and privacy statement",
        FAQs: "Frequently asked questions",
        Cookies: "Cookie policy",
        Help: "Help and support",
        Contact: "Contact us"
    },
    Home: {
        Subtitle: "Your online health check",
        TestButton: "Do the NHS Health Check now",
        TestButtonResults: "View your results",
        AboutButton: "What is an NHS Health Check?",
        HelpButton: "How can it help you?",
        AdviceButton: "Healthy living advice",
        About: {
            Title: "What is this?",
            Intro: (
                <>
                    <p>
                        THis is a Digital NHS Health Check for adults in England
                        aged <strong>40 to 74</strong> that you can do from
                        home. It's designed to spot early signs of stroke,
                        kidney disease, heart disease or type 2 diabetes. As we
                        get older, we have a higher risk of developing one of
                        these conditions. An NHS Health Check helps find ways to
                        lower this risk.
                    </p>
                </>
            ),
            TooBusyTitle: "Too busy to get tested?",
            TooBusyContent:
                "Those of us leading a busy life are least aware of chronic health conditions until we feel ill and need to visit the GP. That's because these conditions often give us no warnings.",
            HiddenDangersTitle: "Hidden dangers",
            HiddenDangersContent: (
                <>
                    <p>
                        For example,Heart disease kills 167,000 people in the UK
                        yearly and is one of the leading factors of COVID-19
                        complications. If you haven't been tested for heart risk
                        before, you're not alone - neither has over half of the
                        people in the UK in the at-risk range.
                    </p>
                    <p>Daft, isn't it?</p>
                </>
            ),
            Part1ExplanationTitle: "What is your score?",
            Part1ExplanationContent: (
                <>
                    <p>
                        In <strong>Part 1</strong> of the Health Check, we
                        assess your heart risk level from a questionnaire. Each
                        step will recalibrate your score in percentage - the
                        more accurately you can answer the questions the better
                        it can calculate your risk level.
                    </p>
                </>
            ),
            Part2ExplanationTitle: "For a more complete picture",
            Part2ExplanationContent: (
                <>
                    <p>
                        <strong>Part 2</strong> of the Health Check focuses on
                        your alcohol consumption, diet and physical activity
                        level.
                    </p>
                </>
            ),
            GuidanceTitle: "We'll hold your hand",
            GuidanceContent: (
                <>
                    <p>
                        This Health Check will help you get hold of more
                        difficult information such as cholesterol level, blood
                        glucose and even your blood pressure. After the test we
                        will show you a summary of your results which (if you
                        agree) will be transferred to your patient record for
                        your doctor to see.
                    </p>
                    <p>
                        If any of the results give you cause for concern please
                        see your doctor.
                    </p>
                </>
            )
        },
        DoTheTest: (
            <>
                Do a Health Check -<br /> you'll feel better either way.
            </>
        )
    },
    Instructions: {
        Title: "Instructions for taking blood samples",
        Content: (
            <p>
                Please watch the video below for instructions on the best way to
                provide your blood sample.
            </p>
        )
    },
    WhatIsHealthCheck: {
        Title: "What is an NHS Health Check?",
        Content: (
            <>
                <p>
                    Even if you're feeling well, it's worth having your FREE NHS
                    Health Check now. Everyone is at risk of developing heart
                    disease, stroke, type 2 diabetes, kidney disease and some
                    forms of dementia. The good news is that these conditions
                    can often be prevented. The NHS Health Check can help you to
                    stay healthy and well by supporting you to make changes and
                    access treatments that will reduce your chance of developing
                    these diseases.
                </p>
                <p>
                    For more information on the NHS Health Check visit the{" "}
                    <a
                        href="https://www.nhs.uk/conditions/nhs-health-check/what-is-an-nhs-health-check-new/"
                        target="_blank"
                    >
                        NHS website
                    </a>
                    .
                </p>
            </>
        ),
        ImprovingTitle: "How can I improve my test results?",
        ImprovingContent: (
            <p>
                Once you've completed your NHS Health Check, you will see a
                summary of the questions you have answered. You'll be given
                advice to help lower your risk of a stroke, kidney disease,
                heart disease, diabetes or dementia, and maintain or improve
                your health.
            </p>
        )
    },
    HeartHealthAdvice: {
        Title: "Heart health advice",
        ReadingLength: "12 minute read",
        VisitYourGP: (
            <>
                If you are <strong>concerned about your heart health</strong>
                <br /> we recommend that you <strong>visit your GP</strong> to
                discuss.
            </>
        ),
        Saturates: {
            Title: "Saturated vs Unsaturated Fats",
            Content: (
                <>
                    <p>
                        A low-fat, high-fibre diet is recommended, which should
                        include plenty of fresh fruit and vegetables (5 portions
                        a day) and whole grains.
                    </p>
                    <p>
                        You should limit the amount of salt you eat to no more
                        than 6g (0.2oz) a day as too much salt will increase
                        your blood pressure. 6g of salt is about 1 teaspoonful.
                    </p>
                    <p>
                        Some fat in the diet is essential, but on average people
                        in the UK eat too much saturated fat.
                    </p>
                    <p>
                        It's important to get most of your fat from unsaturated
                        oils and spreads.
                    </p>
                    <p>
                        Swapping to unsaturated fats can help lower cholesterol.
                        Eating a healthy diet and doing regular exercise can
                        help lower the level of cholesterol in your blood.
                    </p>
                    <p>
                        It's important to keep your cholesterol in check because
                        high cholesterol levels increase your risk of heart
                        disease and stroke.
                    </p>
                    <p>
                        Remember that all types of fat are high in energy and
                        should be eaten in small amounts.
                    </p>
                    <p>
                        There are 2 types of fat: saturated and unsaturated. You
                        should avoid food containing saturated fats, because
                        these will increase the levels of bad cholesterol in
                        your blood. A balanced diet should still include
                        unsaturated fats, which have been shown to increase
                        levels of good cholesterol and help reduce any blockage
                        in your arteries.
                    </p>
                </>
            )
        },
        ReduceFats: {
            Title: "Reduce total fat",
            Content: (
                <>
                    <p>
                        Reducing the total amount of fat in your diet can also
                        help reduce your risk of heart disease.
                    </p>
                    <p>Instead of roasting or frying, consider:</p>
                    <ul>
                        <li>Grilling</li>
                        <li>Steaming</li>
                        <li>Poaching</li>
                        <li>Boiling</li>
                        <li>Microwaving</li>
                    </ul>
                    <p>
                        Choose lean cuts of meat and go for lower-fat varieties
                        of dairy products and spreads, or eat a smaller amount
                        of full-fat varieties.
                    </p>
                </>
            )
        }
    },
    HealthyLivingAdvice: {
        Title: "Healthy living advice",
        ReadingLength: "7 minute read",
        StopSmoking: {
            Title: "Stop smoking",
            Content: (
                <>
                    <p>
                        Stopping smoking is one of the best things you'll ever
                        do for your health.
                    </p>
                    <p>
                        When you stop, you give your lungs the chance to repair
                        and you'll be able to breathe easier. There are lots of
                        other benefits too - and they start almost immediately.
                    </p>
                    <p>
                        <a href="https://www.nhs.uk/better-health/quit-smoking/">
                            https://www.nhs.uk/better-health/quit-smoking/
                        </a>
                    </p>
                </>
            )
        },
        Alcohol: {
            Title: "Alcohol",
            Content: (
                <>
                    <p>
                        Realising you have a problem with alcohol is the first
                        big step to getting help.
                    </p>
                    <p>You may need help if:</p>
                    <ul>
                        <li>you often feel the need to have a drink</li>
                        <li>you get into trouble because of your drinking</li>
                        <li>
                            other people warn you about how much you're drinking
                        </li>
                        <li>you think your drinking is causing you problems</li>
                    </ul>
                    <p>
                        <a href="https://www.nhs.uk/live-well/alcohol-support/">
                            https://www.nhs.uk/live-well/alcohol-support/
                        </a>
                    </p>
                </>
            )
        },
        PhysicalActivity: {
            Title: "Physical activity",
            Content: (
                <>
                    <p>
                        No matter how much you do, physical activity is good for
                        your body and mind. Adults should aim to be active every
                        day. Some is good - more is better still.
                    </p>
                    <p>
                        <a href="https://www.nhs.uk/better-health/get-active/">
                            https://www.nhs.uk/better-health/get-active/
                        </a>
                    </p>
                </>
            )
        },
        WeightManagement: {
            Title: "Weight management",
            Content: (
                <>
                    <p>
                        If you're overweight, losing weight has many health
                        benefits. Making small, simple changes to what and how
                        much you are eating and drinking can really help you
                        lose the pounds.
                    </p>
                    <p>
                        <a href="https://www.nhs.uk/better-health/lose-weight/">
                            https://www.nhs.uk/better-health/lose-weight/
                        </a>
                    </p>
                </>
            )
        },
        Diabetes: {
            Title: "Diabetes",
            Content: (
                <>
                    <p>
                        A healthy diet and keeping active will help you manage
                        your blood sugar level.
                    </p>
                    <p>
                        It'll also help you control your weight and generally
                        feel better.
                    </p>
                    <p>
                        <a href="https://www.nhs.uk/conditions/type-2-diabetes/food-and-keeping-active/">
                            https://www.nhs.uk/conditions/type-2-diabetes/food-and-keeping-active/
                        </a>
                    </p>
                </>
            )
        },
        Dementia: {
            Title: "Dementia",
            Content: (
                <>
                    <p>Most older people do not get dementia.</p>
                    <p>
                        But those people who are affected are usually over 65.
                        It cannot be cured, but you can reduce your chances of
                        getting dementia. Even if someone has dementia, they can
                        take action to lessen the symptoms.
                    </p>
                    <p>
                        <a href="https://www.nhs.uk/conditions/dementia/">
                            https://www.nhs.uk/conditions/dementia/
                        </a>
                    </p>
                </>
            )
        }
    },
    Verification: {
        Title: "Sign in",
        Header: "Please enter your details here so we can confirm who you are",
        Failed: "We're sorry, but we couldn't verify your identity. Please try again",
        Surname: "Your surname",
        DOB: "Your date of birth",
        ActiveSession: (name) =>
            `There is an active session under the name ${name}. Would you like to continue?`,
        Yes: "Yes",
        No: "No",
        PIN: "PIN",
        PINConfirm: "Confirm PIN",
        InvalidKey:
            "NHS Health Check is by invitation only, contact your GP for more details. If you have an invite be sure to follow the link in your text message.",
        AddPINTitle: "Optional PIN Security",
        AddPIN: (
            <>
                <p>
                    If you would like you can further secure your data with a
                    4-digit PIN.
                </p>
                <p>
                    <strong>This is optional</strong>, but you may want to do
                    this when using a shared computer or device. if you do not
                    want to set a PIN leave the boxes blank.
                </p>
            </>
        ),
        InvalidPIN:
            "Your PIN should be 4 numeric characters. Please ensure that both fields match.",
        NoConsent:
            "Please indicate that you accept the terms and conditions before continuing.",
        ContactDetails: {
            Title: "Contact details",
            Description: (
                <>
                    <p>
                        In order to help us communicate with you regarding this
                        health check please provide your contact details below.
                        We will only contact you for the purposes of this health
                        check and we will strictly follow our data privacy laws
                        as set out in our <a href="/privacy">data and privacy
                        statement</a>. We will <strong>NOT</strong> be passing
                        on your details to any third party.
                    </p>
                </>
            ),
            Telephone: "Telephone (optional)",
            Email: "Email (optional)",
            TelephoneError: "Telephone number is invalid",
            EmailError: "Email address is invalid"
        }
    },
    Preferences: {
        PreferredLanguage: "Preferred Language",
        Visual: "I am visually impaired",
        Mobility: "I have mobility issues",
        Representative:
            "I have permission to complete this on the patient's behalf",
        Consent: (
            <>
                I have seen and accept the{" "}
                <a href={`${process.env.PUBLIC_URL}/terms`} target="_blank">
                    terms and conditions
                </a>{" "}
                and understand that a record of my data will be processed and
                held by Howoco and shared with my GP.
            </>
        ),
        Change: "You can change these preferences at any time by selecting My Preferences in the main menu."
    },
    Checklist: {
        Title: "Checklist",
        Content: (diabetes) => (
            <>
                <p>
                    To get the best (most accurate) outcome from this NHS Health
                    Check you should try and complete all the steps including:
                </p>
                <ul>
                    <li>
                        <strong>All the questions</strong> that appear on screen
                    </li>

                    <li>
                        <strong>Blood test for cholesterol</strong> - once you
                        have completed the health check questions, you will
                        receive an easy-to-use test kit in the post to collect
                        your blood sample. Alternatively, you may receive a call
                        from your GP to make an appointment to attend your
                        surgery. The test will be performed for you there.
                    </li>
                    <li>
                        <strong>Blood pressure measurement</strong> - if you
                        have received a home blood test kit in the post, you
                        will need to obtain your own blood pressure readings.
                        This can be done at your local pharmacy / GP (we’ll show
                        you where). Please follow the on-screen instructions
                        when you receive the blood pressure measurements.
                        Alternatively, you may receive a call from your GP to
                        make an appointment to attend your surgery. The test
                        will be performed for you there.
                    </li>
                </ul>
                {/* <p>
                    <strong>Please note:</strong> we only have a limited number
                    of home test kits for testing cholesterol levels and these
                    will be sent on a first come first served basis once you
                    have completed all questions. When we have no further kits
                    you will be contacted by your GP to make an appointment for
                    your Bloods and BP. The home tests and the GP tests will
                    both detect the cholesterol levels and will both enable you
                    to have a full Health Check.
                </p> */}
            </>
        ),
        Return: "Next"
    },
    HowToReturn: {
        Title: "It's OK to leave and come back to it later",
        Content: (
            <>
                <p>
                    You may not have all the answers at hand to complete your
                    NHS Health Check. For example, you may have to visit a
                    chemist near you to get a blood pressure reading.
                </p>
                <p>
                    It's OK to leave the NHS Health Check at any point and come
                    back to it later, on any device. We've captured your answers
                    so you won't have to start over again.
                </p>
                <h4>How to log in again</h4>
                <p>
                    Just remember when logging in again,{" "}
                    <strong>use the unique link from the text message</strong>{" "}
                    your GP sent you, and follow the screen security procedures.
                </p>
            </>
        ),
        Return: "Next"
    },
    WhatHappens: {
        Title: "What happens at the end of the test",
        Content: (
            <>
                <p>
                    When you finish the test your answers will be automatically
                    sent back to your GP to update your patient record. Your GP
                    will contact you if a follow up appointment is required. If
                    you have any immediate concerns please contact{" "}
                    <a href="https://111.nhs.uk/" target="_blank">
                        NHS 111 online
                    </a>
                    .
                </p>
            </>
        ),
        Return: "Begin Test"
    },
    Steps: {
        Summary: "Finish",
        SummaryDescription:
            "A summary of your answers to the NHS Health Check will be shown on the next screen",
        SummaryContinue: "Continue",
        SummaryReturn: "Return to summary",
        PleaseSelect: "Please select",
        Next: "Next",
        NextStepIfUnknown: "Go to next section if you don't know the answer",
        LeaveBlankIfUnknown: "Leave blank if unknown",
        SwitchUnit: "Switch to",
        Back: "Back",
        Dividers: {
            PartOne: {
                Number: "Part 1",
                Title: "Your heart health"
            },
            PartTwo: {
                Number: "Part 2",
                Title: "Lifestyle"
            },
            Summary: {
                Title: "Generating your summary..."
            }
        },
        PostCode: {
            Title: "Your UK postcode",
            Validating: "Validating your postcode...",
            ErrorHandling:
                "Sorry, that postal code seems to be invalid. If you are unsure of your postcode, please delete the postcode and click next.",
            HelpTitle: "Please enter postcode of where you live",
            HelpText: "We need this to calculate your heart health risk."
        },
        Age: {
            Title: "Your age"
        },
        Gender: {
            Title: "Your sex at birth",
            Male: "Male",
            Female: "Female",
            HelpTitle: "Your sex at birth",
            HelpText:
                "We need to know the sex you were born with, rather than the gender you identify with, because males and females have very different risks of Heart Disease. You can choose not to answer this question, but the results of the test will be much less accurate."
        },
        Ethnicity: {
            Title: "Your ethnic origin",
            HelpTitle: "What is ethnic origin?",
            HelpText: (
                <>
                    <p>
                        A person's ethnic origin or ethnicity is the definition
                        of their particular race of people. It can also be
                        described as your racial origin. We need this
                        information because your ethnicity can increase your
                        risk of developing heart and circulatory diseases. If
                        you're South Asian, African, or African Caribbean in the
                        UK, your risk of developing some heart and circulatory
                        diseases can be higher than white Europeans.
                    </p>
                </>
            )
        },
        Height: {
            Title: "Your height",
            Feet: "feet",
            Inches: "inches",
            Centimetres: "cm",
            ErrorHandling: "Sorry, but there seems to be something wrong with your height measurement. Please verify it before continuing",
        },
        Weight: {
            Title: "Your weight",
            Stone: "stone",
            Pounds: "lbs",
            Kilograms: "kg"
        },
        Smoking: {
            Title: "Do you smoke?",
            HelpTitle: "Smoking",
            HelpText: (
                <>
                    <p>
                        Values are based on the number of cigarettes
                        (Cigars/pipe/bidis or other forms of tobacco) you smoke
                        in a day.
                    </p>
                </>
            )
        },
        Diabetes: {
            Title: "Do you have diabetes?",
            HelpTitle: "What is diabetes?",
            HelpText: (
                <>
                    <p>
                        Diabetes is a disease in which your blood glucose, or
                        blood sugar, levels are too high.
                    </p>
                    <p>There are 2 main types of diabetes:</p>
                    <ul>
                        <li>
                            <a
                                href="https://www.nhs.uk/conditions/type-1-diabetes/"
                                target="_blank"
                            >
                                type 1 diabetes
                            </a>
                             - where the body's immune system attacks and
                            destroys the cells that produce insulin
                        </li>
                        <li>
                            <a
                                href="https://www.nhs.uk/conditions/type-2-diabetes/"
                                target="_blank"
                            >
                                type 2 diabetes
                            </a>{" "}
                            - where the body does not produce enough insulin, or
                            the body's cells do not react to insulin
                        </li>
                    </ul>
                    <p>
                        Type 2 diabetes is far more common than type 1. In the
                        UK, around 90% of all adults with diabetes have type 2.
                    </p>
                </>
            )
        },
        FamilyHistory: {
            Title: "Have your parents, brothers or sisters, had angina or heart attack when under the age of 60?",
            Yes: "Yes",
            No: "No/Don't know",
            HelpTitle: "What is angina?",
            HelpText:
                "Angina is chest pain caused by reduced blood flow to the heart muscles. It's not usually life threatening, but it's a warning sign that you could be at risk of a heart attack or stroke. With treatment and healthy lifestyle changes, it's possible to control angina and reduce the risk of these more serious problems."
        },
        KidneyDisease: {
            Title: "Do you have chronic kidney disease?",
            Description:
                "Please select 'None' if you haven't been diagnosed by your doctor",
            HelpTitle: "What is chronic kidney disease?",
            HelpText: (
                <>
                    <p>
                        Chronic kidney disease (CKD) means your kidneys are
                        damaged and can't filter blood the way they should. The
                        disease is called “chronic” because the damage to your
                        kidneys happens slowly over a long period of time. This
                        damage can cause wastes to build up in your body. CKD
                        can also cause other health problems.
                    </p>
                    <p>
                        Unless you doctor has told you that you have this please
                        answer no.
                    </p>
                </>
            ),
            Yes: "Stage 3, 4 or 5",
            No: "No"
        },
        MedicalConditions: {
            Title: "Do any of these apply to you?",
            No: "No",
            Yes: "Yes",
            None: "None"
        },
        AtrialFibrillation: {
            Title: "Do you have atrial fibrillation?",
            Description:
                "Atrial fibrillation is an abnormal heart rhythm. Find out more by clicking on the help icon.",
            HelpTitle: "What is atrial fibrillation?",
            HelpText: (
                <>
                    <p>
                        Atrial fibrillation is a heart condition that causes an
                        irregular and often abnormally fast heart rate.
                    </p>
                    <p>
                        Only answer yes to this if your doctor has diagnosed you
                        with AF.
                    </p>
                </>
            ),
            Modal: {
                Title: "Get an atrial fibrillation test",
                Content: (
                    <p>
                        <strong>Fibricheck</strong> is a FREE smartphone app
                        which you can download to check your atrial
                        fibrillation. The app checks your heart rhythm in just
                        60 seconds by you holding your finger over the camera on
                        your phone. It'll give you a quick, easy and reliable
                        reading. Once you're done, just select 'YES' or 'NO'
                        here.
                    </p>
                ),
                ButtonText: "Download Fibricheck app"
            },
            No: "No",
            Yes: "Yes"
        },
        BloodPressure: {
            Title: "Blood pressure",
            Reading: "Blood pressure reading:",
            OrdinalReadings: [
                "First reading:",
                "Second reading:",
                "Third reading:"
            ],
            Current: "Current:",
            HelpTitle: "What is blood pressure?",
            Systolic: "Systolic",
            Diastolic: "Diastolic",
            CompleteQuestionnaire: (
                <>
                    <p>
                        Please complete the rest of the questionnaire before
                        returning to this section.
                    </p>
                    <p>
                        Pages that need your answers are shown as grey dots.
                        Green dots are finished pages.
                    </p>
                </>
            ),
            ErrorHandling:
                "Sorry, but there seems to be something wrong with your blood pressure readings. Please verify them before continuing",
            HelpText: (
                <>
                    <p>
                        Blood pressure indicates how much pressure your blood is
                        exerting against your artery walls.
                    </p>
                    <p>
                        The first number (systolic) is the pressure when the
                        heart beats. The second number (diastolic) is the
                        pressure when the heart relaxes.{" "}
                    </p>
                    <p>
                        To get this figure while you are taking your BP calm
                        down, sit down and breath normally for 5 minutes before
                        you take a reading.
                    </p>
                </>
            ),
            MoreReadingsRequested: (
                <>
                    <p>
                        <strong>
                            Please complete the remaining reading(s)
                        </strong>{" "}
                        so we can arrive at a more accurate result.
                    </p>
                    <p>
                        If you don't have any other readings to enter, please
                        press "continue" below.
                    </p>
                </>
            ),
            EmergencyText: {
                Title: "Warning",
                Content: (
                    <>
                        <p>
                            <strong>
                                You have indicated a blood pressure that is
                                extremely high.
                            </strong>
                        </p>
                        <p>
                            Call your GP practice about your readings today (or
                            111 if practice is closed), they will advise you
                            what to do.
                        </p>
                    </>
                )
            },
            HighReadingText: {
                Title: "Warning",
                Content: (
                    <>
                        <p>
                            <strong>
                                You have entered a reading that indicates a high
                                blood pressure.
                            </strong>
                        </p>
                        <p>
                            Call your GP practice in the next few days to make a
                            routine appointment for a blood pressure check.
                        </p>
                    </>
                )
            },
            LowReadingText: {
                Title: "Warning",
                Content: (
                    <>
                        <p>
                            <strong>
                                You have entered a reading that indicates a low
                                blood pressure.
                            </strong>
                        </p>
                        <p>
                            Call your GP practice in the next few days to make a
                            routine appointment for a blood pressure check.
                        </p>
                    </>
                )
            },
            Unit: "mmHG",
            PreviousReadings: "Last two readings:",
            RestingPulse: "Your pulse at rest",
            Modal: {
                Title: "Get a blood pressure test",
                Content: (
                    <>
                        <p>
                            Your GP will give you a blood pressure test if you
                            request it, some surgeries also have a self BP
                            machine that is free to use without an appointment.
                        </p>
                        <p>
                            Alternatively, there are many places on the high
                            street such as Boots, Asda and Tesco chemists that
                            offer free blood pressure tests.
                        </p>
                    </>
                ),
                ButtonText: "Find a FREE test near you"
            },
            Remote: (
                <p>
                    Please enter <strong>up to 3</strong> readings below. 3
                    readings will help us arrive at the most accurate result.
                </p>
            ),
            GP: (
                <p>
                    Your blood pressure will be taken by your GP. If you have
                    already had your readings taken, they will appear below.
                </p>
            ),
            GPWithBloods: (
                <p>
                    Now that you have answered all the questions you will be
                    contacted by your GP to make an appointment for your Bloods
                    and BP.
                </p>
            ),
            BookingLink: "Book an appointment",
            Location: {
                Title: "Blood pressure measured at",
                Home: "Test was done at home",
                HomeAdvice:
                    "Please perform the blood pressure test three times and input the average score.",
                Pharmacy: "Test was done at pharmacy / GP",
                PharmacyAdvice: "Please tell us where:"
            }
        },
        Glyhb: {
            Results: "HbA1C (mmol/mol)",
            Unit: "mmol/mol"
        },
        Cholesterol: {
            Title: "Cholesterol levels",
            Subtitle: "My cholesterol levels are",
            DoYouKnow: "Do you know your cholesterol levels?",
            Yes: "Yes",
            No: "I don't know my cholesterol levels",
            HelpTitle: "How are your cholesterol levels calculated?",
            HelpText: (
                <>
                    <p>
                        Your cholesterol ratio is calculated by dividing your
                        total cholesterol by your HDL number. For instance, if
                        your total cholesterol is 180 and your HDL is 82, your
                        cholesterol ratio is 2.2.
                    </p>
                    <p>
                        But don't worry, we do that calculation for you - just
                        enter the two figures we ask for or let us update the
                        numbers from your free blood test.
                    </p>
                </>
            ),
            Modal: {
                Title: "Get a FREE cholesterol test",
                Content: (
                    <>
                        <p>
                            From the information you've given us so far we
                            recommend that you order our Healthy Heart blood
                            test to be sent to you at home. This will tell us
                            exactly what your cholesterol level is.
                        </p>
                        <p>
                            <strong>
                                This test is FREE, paid for by the NHS.
                            </strong>
                        </p>
                    </>
                ),
                ButtonText: "Get your FREE blood test"
            },
            Total: "Total cholesterol levels (mmol/L)",
            HDL: "HDL cholesterol (mmol/L)",
            Unit: "mmol/L"
        },
        DiabetesRisk: {
            Title: "Diabetes",
            Family: "Do you have a parent, brother, sister and/or own child with diabetes?",
            Yes: "Yes",
            No: "No/Don't know",
            WaistMeasurement: "What is your waist measurement?",
            WaistMeasurementHelp: {
                Title: "How to measure your waist",
                Content: (
                    <>
                        <ol>
                            <li>
                                Find the bottom of your ribs and the top of your
                                hips.
                            </li>
                            <li>
                                Place a tape measure around your middle at a
                                point halfway between them (just above the belly
                                button).
                            </li>
                            <li>
                                Make sure it's pulled tight, but isn't digging
                                into your skin.
                            </li>
                            <li>
                                Breathe out naturally and take your measurement.
                            </li>
                            <li>
                                Take your measurement again, just to be sure.
                            </li>
                        </ol>
                    </>
                )
            },
            ErrorHandling: "Sorry, but there seems to be something wrong with your measurements. Please verify them before continuing",
        },
        Alcohol: {
            Title: "Your alcohol consumption",
            HowOften: "How often do you have a drink containing alcohol?",
            HowMuch:
                "How many units of alcohol do you drink on a typical day when you are drinking?",
            BingeDrinking:
                "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
            AdditionalSteps: {
                UnableToStop:
                    "How often during the last year have you found that you were not able to stop drinking once you had started?",
                FailedExpectations:
                    "How often during the last year have you failed to do what was normally expected from you because of your drinking?",
                MorningDrinking:
                    "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
                DrinkingGuilt:
                    "How often during the last year have you had a feeling of guilt or remorse after drinking?",
                Blackout:
                    "How often during the last year have you been unable to remember what heppend the night before because you had been drinking?",
                DrinkingInjury:
                    "Have you or somebody else been injured as a result of your drinking?",
                DrinkingConcern:
                    "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested you cut down?"
            },
            HelpTitle: "How much is 'one unit'?",
            HelpContent: (
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Pint.png`}
                                    alt="1/2 pint"
                                />
                            </td>
                            <td>
                                <p>
                                    <strong>One unit</strong>
                                </p>
                                <p>
                                    1/2 pint of ordinary strength beer lager or
                                    cider
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Wine.png`}
                                    alt="1 small glass of wine"
                                />
                            </td>
                            <td>
                                <p>
                                    <strong>One unit</strong>
                                </p>
                                <p>1 small glass of wine</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Spirits.png`}
                                    alt="1 single measure of spirits"
                                />
                            </td>
                            <td>
                                <p>
                                    <strong>One unit</strong>
                                </p>
                                <p>1 single measure of spirits</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Sherry.png`}
                                    alt="1 small glass of sherry"
                                />
                            </td>
                            <td>
                                <p>
                                    <strong>One unit</strong>
                                </p>
                                <p>1 small glass of sherry</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Aperitif.png`}
                                    alt="1 single measure of aperitifs"
                                />
                            </td>
                            <td>
                                <p>
                                    <strong>One unit</strong>
                                </p>
                                <p>1 single measure of aperitifs</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        },
        PhysicalActivity: {
            Title: "Physical activity",
            HoursCycling: (
                <>
                    <strong>How many hours per week do you cycle?</strong>{" "}
                    <span>
                        (Including cycling to work and during leisure time)
                    </span>
                </>
            ),
            HoursWalking: (
                <>
                    <strong>How many hours per week do you walk?</strong>{" "}
                    <span>
                        (Including walking to work, shopping, for pleasure,
                        etc.)
                    </span>
                </>
            ),
            HoursExercise: (
                <>
                    <strong>
                        How many hours per week do you do physical exercise?
                    </strong>{" "}
                    <span>
                        (swimming, jogging, gym workout, tennis, football etc.)
                    </span>
                </>
            ),
            HoursHouseworkChildcare: (
                <>
                    <strong>
                        How many hours per week do you do housework and/or
                        childcare?
                    </strong>
                </>
            ),
            HoursGardeningDIY: (
                <>
                    <strong>
                        How many hours per week do you do gardening and/or DIY?
                    </strong>
                </>
            ),
            WalkingPace: (
                <>
                    <strong>
                        How would you describe your usual walking pace?
                    </strong>
                </>
            ),
            PhysicalWork: "Please select one box which best describes you:",
            PhysicalWorkDescription: "How physical is your work?",
            Weekly: "During the last week, how many hours did you spend on each of the following activities?",
            WeeklyDisclaimer:
                "Please answer whether you are in employment or not.",
            Exercise:
                "Physical exercise e.g. swimming, jogging, aerobics, sports etc.",
            Cycling: "Cycling",
            Walking: "Walking",
            Housework: "Housework/Childcare",
            GardeningDIY: "Gardening/DIY"
        },
        Diet: {
            Title: "Diet",
            Vegetables:
                "Roughly how many portions of fruit and vegetables do you eat per day?",
            Foods: "My diet is made up mostly of:",
            WeightClass: "Do you consider yourself to be:"
        },
        Dementia: {
            Title: "Dementia awareness",
            Content: (
                <>
                    <p>
                        Most older people do not get dementia. But those people
                        who are affected are usually over 65.
                    </p>
                    <p>
                        It cannot be cured, but you can reduce your chances of
                        getting dementia.{" "}
                        <a
                            href="https://www.nhs.uk/conditions/dementia/"
                            target="_blank"
                        >
                            Click here
                        </a>{" "}
                        for some NHS resources if you have dementia concerns.
                    </p>
                    <p>
                        And remember:
                        <br />
                        <strong>
                            What's good for your heart is good for your brain.
                        </strong>
                        That means if you can make some of the suggested changes
                        that we share with you at the end of this NHS Health
                        Check, for example, getting more active or reducing the
                        amount of alcohol you drink this will help to improve
                        your brain health as well as your heart health.
                    </p>
                </>
            )
        }
    },
    Summary: {
        Title: "Summary",
        Intro: {
            Header: "You've completed the Health Check",
            HeaderIncomplete:
                "Please send your blood test back to get a more accurate summary.*",
            Content: (
                <p>
                    *Please allow up to 7 days for your GP to receive your
                    results.
                </p>
            )
        },
        YourAnswers: "Your NHS Health Check answers",
        HeartHealth: {
            Title: "Heart health",
            HeartAge: {
                Title: "Heart age",
                YourAge: "Your age",
                HeartAge: "Heart age"
            },
            RelativeRisk: {
                Title: "How you compare",
                Description:
                    "Your score compared to a healthy person with the same age, sex and ethnicity"
            },
            QRisk: {
                Title: "Qrisk score",
                Explanation: (risk) => (
                    <>
                        This means that out of <strong>100</strong> people with
                        the same risk factors as you, <strong>{risk}</strong>{" "}
                        are likely to have a heart attack or stroke within the
                        next 10 years.
                    </>
                )
            }
        },
        Back: "Back to test",
        SendResultsToGP: "Send results to my GP",
        GPDisclaimer:
            "Please note you won't be able to alter your answers once you send the results to your GP.",
        MissingValues: {
            Title: "Your health check is incomplete",
            TitleComplete: "Well done, you've completed all the questions",
            TitleError: "Incomplete Test Results",
            Intro: "You'll get your summary report after you've completed these. Scroll down to find a summary of the information you've given us.",
            IntroComplete: (
                <>
                    <p>
                        We will contact you to{" "}
                        <strong>make an appointment with your GP</strong> to
                        record your blood pressure and take a blood test in
                        order to complete the Health Check. We'll have your
                        results here a few days after your appointment.
                    </p>
                    <p>
                        If you have already scheduled an appointment, kindly
                        disregard this message.
                    </p>
                    <p>
                        Scroll down to view a summary of the information you
                        have given us.
                    </p>
                </>
            ),
            IntroCompleteRemote: (
                <>
                    <p>
                        Please{" "}
                        <strong>
                            complete the blood test you have been sent
                        </strong>{" "}
                        and post that back to us for processing to complete your
                        NHS Health Check. We will send you a text message when
                        the blood sample has been tested so you can log in and
                        see your full summary results.
                    </p>
                    <p>
                        Scroll down to view a summary of the information you
                        have given us.
                    </p>
                </>
            ),
            IntroProcessing: (
                <p>
                    Your blood sample is currently being processed. Once the
                    results are ready{" "}
                    <strong>we will send you a text message</strong> with a link
                    to see your NHS Health Check Summary.
                </p>
            ),
            IntroError: (diabetes) => (
                <div className="error-instructions">
                    <p>
                        Unfortunately, we were not able to complete your blood
                        test. This can happen for a number of reasons and is not
                        a cause for concern. The reasons include:
                    </p>
                    <ul>
                        <li>
                            Haemolysis - this is where the red blood cells in
                            the blood collection start to break down and this
                            impacts on our ability to generate results for some
                            tests. This can happen when squeezing your finger
                            too hard rather than massaging the side of your
                            hand.
                        </li>
                        <li>
                            Insufficient volume - where you have not supplied
                            enough blood to perform the testing.
                        </li>
                        <li>
                            Late return - where the period between when the
                            blood sample was collected and receipt in the
                            laboratory is too great.
                        </li>
                    </ul>
                    <p>
                        You can still complete your NHS Health Check by
                        contacting your GP to book a cholesterol test . Just
                        make sure to let them know that you are completing a
                        digital NHS Health Check.
                    </p>
                    <p>
                        Once you've had a blood test arranged by your GP you
                        will need the results for your total cholesterol and
                        high density lipoprotein cholesterol. You can get your
                        results by either calling your GP to ask for them or by
                        logging into the NHS App: NHS website.
                    </p>
                    <p>
                        Once you have your cholesterol results, please follow
                        these steps so that you can then complete your digital
                        NHS Health Check:
                    </p>
                    <ol>
                        <li>
                            Go to the the Digital Health Check website using the
                            original link and log in with your surname and DOB.
                        </li>
                        <li>
                            Open the chat function (bottom right hand corner of
                            your screen), this is a secure way that you can
                            share information with us.
                        </li>
                        <li>
                            Tell them us that you need to add your cholesterol
                            tests {diabetes && <>(and HbA1C)</>} to your digital
                            NHS Health Check.
                        </li>
                        <li>You may be asked to verify your identity.</li>
                        <li>
                            Tell them us the values for your total cholesterol
                            /and high density lipoprotein cholesterol{" "}
                            {diabetes && <>(and Diabetes)</>}.
                        </li>
                        <li>
                            Please give us a moment, We will add the information
                            to your digital NHS Health Check so that you can
                            then see your summary results.
                        </li>
                        <li>
                            We will tell you on the chat when to log back in to
                            see your results.
                        </li>
                    </ol>
                    <p>
                        If the chat is not available - please come back during
                        hours of operation Mon-Fri 9.00-5.00.
                    </p>
                </div>
            ),
            CholesterolTest: "Blood test status",
            CholesterolTestStatus: {
                OrderReceived: "Order received",
                KitSent: "Kit sent",
                SampleReceived: "Lab received blood sample & being processed",
                Complete: "Complete: results available",
                Error: "Sorry, there was a problem with your test results."
            },
            CholesterolTestExplanation:
                "*If you have posted your test kit back already, please wait for a few days before Health Check results can be generated."
        },
        ShowEstimatedResults: "Show estimated* summary results",
        Alert: "ALERT",
        AlertText:
            "Please be aware that your incomplete NHS Health Check results will be sent to your GP if you go ahead here, and you won't be able to go back to complete.",
        CompleteAlertText:
            "Please be aware that your NHS Health Check results will be sent to your GP if you go ahead here, and you won't be able to back to change them.",
        AlertConfirm: (word) => <>To confirm type "{word}" below.</>,
        Thanks: "Thanks for completing the health check.",
        ShowSummary: "Show summary",
        ShowSummaryAnyway: "Show summary anyway",
        WhyEstimated:
            "*Your score has been calculated using estimated data, as some information was left blank.",
        HeartHealthScore: "Heart disease risk",
        BMI: "Body Mass Index",
        BloodPressure: "Blood pressure",
        DiabetesRisk: "Diabetes risk",
        DiabetesBTWarning:
            "Your blood test indicates that your blood sugar is raised",
        AlcoholScreening: "Alcohol",
        CholesterolLevels: "Cholesterol levels",
        ActivityLevel: "Activity level",
        Details: "Details",
        RiskRange:
            "Your chance of having a heart attack or stroke within the next 10 years is:",
        RiskExplanation:
            "Your chance is an estimate of how likely you are to have a heart attack or stroke in the next ten years. If you have a 10% risk, it means that if there were 100 people with the same risk as you, we'd expect 10 of them to have a heart attack or stroke in the next ten years.",
        Instructions: <>Good so far but we need some more info from you.</>,
        InstructionHighRisk:
            "Your risk score is high, therefore we strongly advise you to consult your doctor to discuss these results as soon as possible.",
        Improvements: {
            Title: (
                <>
                    You can <strong>improve the accuracy</strong> of this test
                    result by doing tests for:
                </>
            ),
            Cholesterol: "Cholesterol/HDL Ratio",
            AtrialFibrillation: "Atrial fibrillation",
            BloodPressure: "Systolic blood pressure"
        },
        FreeCholesterolTest: {
            Offer: (
                <>
                    We would like to offer you a <strong>FREE</strong>{" "}
                    Cholesterol blood test which you can do at home
                </>
            ),
            ButtonText: "Get your FREE blood test"
        },
        ReturnToTop: "Back to top",
        FreeBloodTest: {
            Offer: (
                <>
                    Your results indicate that you're in the at-risk group. We'd
                    like to post you a <strong>FREE at-home blood test</strong>{" "}
                    for more accurate readings.
                </>
            ),
            ButtonText: "Order test kit"
        },
        CompleteTest: "Complete Digital Health Check",
        RiskInterpretations: {
            healthy: "Healthy",
            raised: "Raised",
            raised_med: "Raised",
            "no-activity with-higher-bound": "Inactive",
            "low-activity with-higher-bound": "Moderately inactive",
            "med-activity with-higher-bound": "Moderately active",
            "high-activity with-higher-bound": "Active",
            "low-risk": "Low",
            "low-risk with-higher-bound": "Low",
            "med-risk": "Med",
            "increased with-higher-bound": "Increased",
            "high-risk": "High",
            "high-risk with-higher-bound": "High",
            "very-high-risk": "Very High",
            "very-high-risk with-higher-bound": "Very High"
        },
        HeartHealthScoreDetails: "Heart health score details",
        HeartHealthHeartAgeDetails: "Heart age",
        HeartHealthHeartAgeSummary: (
            <>
                <h4>What is heart age?</h4>
                <p>
                    Your indicated Heart Age test gives you an idea of what your
                    heart age is compared to a person of the same age, sex (at
                    birth) and ethnicity who has no additional risk factors that
                    could affect their heart.
                </p>
                <h4>Heart Age tool</h4>
                <a
                    href="https://www.nhs.uk/conditions/nhs-health-check/check-your-heart-age-tool/"
                    target="_blank"
                >
                    What is your heart age tool?
                </a>
                <p>This tool will help you to see:</p>
                <ul>
                    <li>
                        The number of extra years you can give your heart age by
                        making some healthy lifestyle changes
                    </li>
                    <li>
                        The importance of blood pressure and cholesterol levels
                        in estimating your heart age
                    </li>
                    <li>
                        How to improve your heart age by eating better and
                        moving more
                    </li>
                </ul>
                <p>
                    This test is suitable for anyone aged 30 to 95. It is not
                    suitable for anyone with a condition that affects their
                    heart or blood vessels (a cardiovascular disease).
                </p>
            </>
        ),
        HeartHealthRelativeRiskDetails: "How you compare",
        HeartHealthRelativeRiskSummary: "",
        HeartHealthQRiskScoreDetails: "Qrisk",
        HeartHealthWhatIsQRisk: (
            <>
                <h4>What is Qrisk?</h4>
                <p>
                    Your chance of having a heart attack or stroke in the next
                    10-years is estimated using QRISK3.
                </p>
                <p>
                    QRISK3 is a mathematical calculation developed by
                    researchers using data from hundreds of thousands of people
                    in England. QRISK estimates your chance of a heart attack or
                    stroke in the next 10-years. It uses the information you've
                    provided through your NHS Health Check - age, blood
                    pressure, smoking status, cholesterol, body mass index,
                    ethnicity and family history to do this.
                </p>
            </>
        ),
        HeartHealthQRiskScoreSummary: {
            Neutral: (risk) => {
                return (
                    <>
                        <h4>Your results</h4>
                        <p>
                            Your chance of having a heart attack or stroke
                            within the next 10 years is: {risk}%
                        </p>
                        <p>
                            In other words, in a crowd of 100 people with the
                            same risk factors as you,{" "}
                            {Math.round(parseFloat(risk))} are likely to have a
                            heart attack or stroke within the next 10 years.
                        </p>
                        <p>
                            The good news is that you can reduce this risk.
                            Making small changes to become more active, drink
                            less alcohol, lose weight and stop smoking can make
                            a difference. The first step is to look at the rest
                            of your NHS Health Check results to identify what
                            you might want to focus on and to plan some changes
                            that work for you.
                        </p>
                        <p>
                            As your QRISK score is over 10% you might also need
                            medicine such as a Statin, speak to your GP about
                            this.
                        </p>
                        <p>
                            Statins are really effective at helping to reduce
                            the chance of a heart attack or stroke.
                        </p>
                        <a href="https://www.nhs.uk/conditions/statins/">
                            Find out more about statins
                        </a>
                    </>
                );
            },
            Low: (risk) => {
                return (
                    <>
                        <h4>Your results</h4>
                        <p>
                            The good news is that your chance of having a heart
                            attack or stroke in the next 10 years is currently
                            low.
                        </p>
                        <p>
                            Almost everyone can reduce their score – the lower
                            the better. By keeping up the good work and making
                            more healthy choices you can keep your chance of a
                            heart attack or stroke low over the next 10 years
                            and beyond.{" "}
                        </p>
                        <p>
                            The first step is to look at the rest of your NHS
                            Health Check results to find out what else you might
                            do to keep your heart healthy.{" "}
                        </p>
                    </>
                );
            },
            Medium: (risk) => {
                return (
                    <>
                        <p>
                            Your risk is moderate - you have a {risk}% chance of
                            a heart or circulation problem in the next 10 years.
                            This means that if there were 100 people with the
                            same risk as you, we'd expect {Math.floor(risk)} of
                            them to have a heart attack or stroke in the next 10
                            years.
                        </p>
                        <p>
                            You have a chance to lower this risk score by making
                            changes to your lifestyle and some of your habits.
                            Stop smoking if you do. Keep to a healthy weight.
                            Drink less alcohol. Keep active and eat healthily.
                            To support you in lowering your risk score here are
                            some NHS resources to look at.{" "}
                            <a
                                href="https://www.nhs.uk/better-health/"
                                target="_blank"
                            >
                                https://www.nhs.uk/better-health/
                            </a>
                        </p>
                    </>
                );
            },
            High: (risk) => {
                return (
                    <>
                        <p>
                            Your risk is high - you have a {risk}% chance of a
                            heart or circulation problem in the next 10 years.
                            This means that if there were 100 people with the
                            same risk as you, we'd expect 
                            {Math.floor(risk)} of them to have a heart attack or
                            stroke in the next 10 years.
                        </p>
                        <p>
                            Because your risk score is high you need to see your
                            family doctor at the earliest opportunity. The good
                            news is that you've found out in time to do
                            something about it.
                        </p>
                    </>
                );
            }
        },
        BMISummary: {
            UnderweightHead: "Your result suggests you are underweight",
            HealthyHead: "Your result suggests you are a healthy weight",
            OverweightHead: "Your result suggests you are overweight",
            ObeseHead: "Your result suggests you are obese",
            Explanation: (
                <>
                    <h4>What is Body Mass Index (BMI)?</h4>
                    <p>
                        Body mass index is a measure used to understand if you
                        are a healthy weight for your height. It is calculated
                        by dividing your weight in kilograms by your height in
                        metres squared.
                    </p>

                    <a
                        href="https://www.nhs.uk/common-health-questions/lifestyle/what-is-the-body-mass-index-bmi/"
                        target="_blank"
                    >
                        Find out more about BMI
                    </a>
                </>
            ),
            Healthy: (
                <>
                    <p>Your body mass index is in the healthy weight range. </p>
                    <p>
                        Being a healthy weight can help prevent a heart attack,
                        stroke and some types of dementia. Try to maintain this
                        weight, you can find helpful tips on the NHS website.
                    </p>

                    <a
                        href="https://www.nhs.uk/live-well/eat-well/food-types/"
                        target="_blank"
                    >
                        Maintaining a healthy weight
                    </a>
                </>
            ),
            Underweight: (
                <>
                    <p>Your body mass index is in the underweight range.</p>
                    <p>
                        Being underweight could be a sign you're not eating
                        enough or you may be ill. If you're underweight tell a
                        GP or nurse. They can give you help and advice. You can
                        also go to the NHS website for more information.
                    </p>
                </>
            ),
            Overweight: (
                <>
                    <p>Your body mass index is in the overweight range.</p>
                    <p>
                        Gradually losing weight can help you reduce your chance
                        of a heart attack, stroke and some forms of dementia.
                        Making small simple changes to what and how much you are
                        eating, drinking and exercising can really help you lose
                        the pounds or stop you from gaining more weight.{" "}
                    </p>
                    <p>
                        For help and support with getting started on your weight
                        management journey you download the free NHS Weight Loss
                        Plan.
                    </p>

                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        Lose Weight - Better Health - NHS
                    </a>
                    <a
                        href="https://www.nhs.uk/live-well/exercise/running-and-aerobic-exercises/get-running-with-couch-to-5k/"
                        target="_blank"
                    >
                        Get running with Couch to 5K - NHS
                    </a>
                    <a
                        href="https://www.nhs.uk/live-well/eat-well/food-guidelines-and-food-labels/the-eatwell-guide/"
                        target="_blank"
                    >
                        The Eatwell Guide - NHS
                    </a>
                    <a
                        href="https://blackcountrymoving.activityfinder.net/"
                        target="_blank"
                    >
                        Find local activities | Active Black Country
                    </a>
                    <a
                        href="https://www.wolverhampton.gov.uk/health-and-social-care/health-and-wellbeing/fit-fiver#:~:text=Fit%20for%20a%20Fiver%20is%20an%20exercise%20programme,3%20months%20access%20to%20public%20swimming%20for%20%C2%A35."
                        target="_blank"
                    >
                        Fit for a Fiver | City Of Wolverhampton Council
                    </a>
                    <a
                        href="https://uat.ramblers.org.uk/go-walking/wellbeing-walks-groups/ramblers-wellbeing-walks-wolverhampton"
                        target="_blank"
                    >
                        Ramblers Wellbeing Walks - Wolverhampton
                    </a>
                    <a href="https://www.wvactive.com/" target="_blank">
                        WV Active | Gyms, pools and fitness classes in
                        Wolverhampton
                    </a>
                    <a
                        href="https://www.wolverhampton.gov.uk/community/community-centres/bob-jones"
                        target="_blank"
                    >
                        Bob Jones Community Hub | City Of Wolverhampton Council
                    </a>
                </>
            ),
            Obese: (
                <>
                    <p>Your body mass index is in the obese range.</p>
                    <p>
                        Gradually losing weight can help you reduce your chance
                        of a heart attack, stroke and some forms of dementia.
                        Making small simple changes to what and how much you are
                        eating, drinking and exercising can really help you lose
                        the pounds or stop you from gaining more weight.{" "}
                    </p>
                    <p>
                        For help and support with getting started on your weight
                        management journey you can download the free NHS Weight
                        Loss Plan.
                    </p>

                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        Lose Weight - Better Health - NHS
                    </a>
                    <a
                        href="https://www.nhs.uk/live-well/exercise/running-and-aerobic-exercises/get-running-with-couch-to-5k/"
                        target="_blank"
                    >
                        Get running with Couch to 5K - NHS
                    </a>
                    <a
                        href="https://www.nhs.uk/live-well/eat-well/food-guidelines-and-food-labels/the-eatwell-guide/"
                        target="_blank"
                    >
                        The Eatwell Guide - NHS
                    </a>
                    <a
                        href="https://blackcountrymoving.activityfinder.net/"
                        target="_blank"
                    >
                        Find local activities | Active Black Country
                    </a>
                    <a
                        href="https://www.wolverhampton.gov.uk/health-and-social-care/health-and-wellbeing/fit-fiver#:~:text=Fit%20for%20a%20Fiver%20is%20an%20exercise%20programme,3%20months%20access%20to%20public%20swimming%20for%20%C2%A35."
                        target="_blank"
                    >
                        Fit for a Fiver | City Of Wolverhampton Council
                    </a>
                    <a
                        href="https://uat.ramblers.org.uk/go-walking/wellbeing-walks-groups/ramblers-wellbeing-walks-wolverhampton"
                        target="_blank"
                    >
                        Ramblers Wellbeing Walks - Wolverhampton
                    </a>
                    <a href="https://www.wvactive.com/" target="_blank">
                        WV Active | Gyms, pools and fitness classes in
                        Wolverhampton
                    </a>
                    <a
                        href="https://www.wolverhampton.gov.uk/community/community-centres/bob-jones"
                        target="_blank"
                    >
                        Bob Jones Community Hub | City Of Wolverhampton Council
                    </a>
                </>
            ),
            WaistSize: (
                <>
                    <p>
                        The BMI calculator will give you a personal calorie
                        allowance to help you achieve a healthy weight safely.
                    </p>
                    <a
                        href="https://www.nhs.uk/live-well/healthy-weight/bmi-calculator/"
                        target="_blank"
                    >
                        BMI Calculator
                    </a>
                    <h4>Why waist size also matters</h4>
                    <p>
                        Measuring your waist is a good way to check you're not
                        carrying too much fat around your stomach, which can
                        raise your risk of heart disease, type 2 diabetes and
                        stroke.
                    </p>
                    <p>
                        You can have a healthy BMI and still have excess tummy
                        fat, meaning you're still at risk of developing these
                        conditions.
                    </p>
                </>
            )
        },
        BloodPressureSummary: {
            LowHead: "Your blood pressure result is low",
            NormalHead: "Your blood pressure result is normal",
            PreHighHead: "Your blood pressure result is high",
            HighHead: "Your blood pressure result is very high",
            Explanation: (
                <>
                    <h4>What is blood pressure?</h4>
                    <p>
                        Blood pressure is measured in millimetres of mercury
                        (mmHG) and given as 2 figures:
                    </p>
                    <p>
                        Systolic pressure - is the pressure when your heart
                        beats.
                    </p>
                    <p>
                        Diastolic pressure - is the pressure when your heart
                        rests.{" "}
                    </p>
                    <p>
                        If your blood pressure is 140 over 90 or 140/90 mmHg
                        then it means you have a systolic pressure of 140 mmHg
                        and a diastolic pressure of 90 mmHg.
                    </p>
                </>
            ),
            Archive: {
                Low: (
                    <>
                        <p>Your blood pressure is low.</p>
                        <p>
                            <a
                                href="https://www.nhs.uk/conditions/low-blood-pressure-hypotension/"
                                target="_blank"
                            >
                                Low blood pressure (hypotension)
                            </a>
                             isn't usually as serious, although it can cause 
                            <a
                                href="https://www.nhs.uk/conditions/dizziness/"
                                target="_blank"
                            >
                                dizziness
                            </a>
                             and 
                            <a
                                href="https://www.nhs.uk/conditions/fainting/"
                                target="_blank"
                            >
                                fainting
                            </a>{" "}
                            in some people.
                        </p>
                    </>
                ),
                Normal: (
                    <>
                        <p>Your blood pressure is normal.</p>
                        <p>
                            That's great. Well done! To help you keep it in that
                            range here are some resources and advice. These
                            lifestyle changes can help prevent high blood
                            pressure:
                        </p>
                        <ul>
                            <li>
                                reduce the amount of salt you eat and have a
                                generally healthy diet
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/alcohol-support/tips-on-cutting-down-alcohol/"
                                    target="_blank"
                                >
                                    cut back on alcohol
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/healthy-weight/start-the-nhs-weight-loss-plan/"
                                    target="_blank"
                                >
                                    lose weight
                                </a>
                                 if you're overweight
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/exercise/free-fitness-ideas/"
                                    target="_blank"
                                >
                                    exercise
                                </a>
                                 regularly
                            </li>
                            <li>cut down on caffeine</li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/quit-smoking/10-self-help-tips-to-stop-smoking/"
                                    target="_blank"
                                >
                                    stop smoking
                                </a>
                            </li>
                        </ul>
                    </>
                ),
                PreHigh: (
                    <>
                        <p>Your blood pressure is a bit too high.</p>
                        <p>
                            Your blood pressure readings are higher than they
                            ideally should be. To help you get it down here are
                            some resources and advice. These lifestyle changes
                            can help prevent high blood pressure:
                        </p>
                        <ul>
                            <li>
                                reduce the amount of salt you eat and have a
                                generally healthy diet
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/alcohol-support/tips-on-cutting-down-alcohol/"
                                    target="_blank"
                                >
                                    cut back on alcohol
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/healthy-weight/start-the-nhs-weight-loss-plan/"
                                    target="_blank"
                                >
                                    lose weight
                                </a>
                                 if you're overweight
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/exercise/free-fitness-ideas/"
                                    target="_blank"
                                >
                                    exercise
                                </a>
                                 regularly
                            </li>
                            <li>cut down on caffeine</li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/quit-smoking/10-self-help-tips-to-stop-smoking/"
                                    target="_blank"
                                >
                                    stop smoking
                                </a>
                            </li>
                        </ul>
                    </>
                ),
                High: (
                    <>
                        <p>Your blood pressure readings are high.</p>
                        <p>
                            <a
                                href="https://www.nhs.uk/conditions/high-blood-pressure-hypertension/"
                                target="_blank"
                            >
                                High blood pressure
                            </a>
                             is often related to unhealthy lifestyle habits,
                            such as smoking, drinking too much alcohol, being
                            overweight and not exercising enough.
                        </p>
                        <p>
                            Left untreated, high blood pressure can increase
                            your risk of developing a number of serious
                            long-term health conditions, such as coronary heart
                            disease and kidney disease.
                        </p>
                        <p>
                            You need to see your doctor to discuss it as soon as
                            possible. Here are some lifestyle changes and advice
                            to help get your blood pressure down. These
                            lifestyle changes can help prevent high blood
                            pressure:
                        </p>
                        <ul>
                            <li>
                                reduce the amount of salt you eat and have a
                                generally healthy diet
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/alcohol-support/tips-on-cutting-down-alcohol/"
                                    target="_blank"
                                >
                                    cut back on alcohol
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/healthy-weight/start-the-nhs-weight-loss-plan/"
                                    target="_blank"
                                >
                                    lose weight
                                </a>
                                 if you're overweight
                            </li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/exercise/free-fitness-ideas/"
                                    target="_blank"
                                >
                                    exercise
                                </a>
                                 regularly
                            </li>
                            <li>cut down on caffeine</li>
                            <li>
                                <a
                                    href="https://www.nhs.uk/live-well/quit-smoking/10-self-help-tips-to-stop-smoking/"
                                    target="_blank"
                                >
                                    stop smoking
                                </a>
                            </li>
                        </ul>
                    </>
                )
            },
            Low: (
                <>
                    <p>
                        Low blood pressure is usually not a problem. It can be
                        caused by illness, a health condition or by taking some
                        medicines.
                    </p>

                    <p>
                        But if your low blood pressure is causing any of the
                        following symptoms, see a GP:
                    </p>

                    <ul>
                        <li>chest pain</li>
                        <li>sweating</li>
                        <li>shortness of breath</li>
                        <li>unsteadiness</li>
                        <li>dizziness or feeling lightheaded</li>
                        <li>fainting</li>
                    </ul>

                    <a href="https://www.nhs.uk/conditions/low-blood-pressure-hypotension/">
                        Find out more about low blood pressure
                    </a>
                </>
            ),
            Normal: (
                <>
                    <p>
                        Your blood pressure is healthy, which is good, because
                        high blood pressure can cause serious health problems
                        including heart attack or stroke.
                    </p>

                    <p>
                        You can continue to prevent high blood pressure by
                        sticking to a healthy lifestyle, such as:
                    </p>

                    <ul>
                        <li>keeping to a healthy weight</li>
                        <li>increasing your physical activity</li>
                        <li>eating lots of fresh fruit and vegetables</li>
                        <li>cutting down on salt in your diet</li>
                        <li>drinking less alcohol and caffeine</li>
                        <li>quitting smoking</li>
                    </ul>

                    <p>
                        You might want to take a look at your other NHS Health
                        Check results to see if you could benefit from making
                        changes that will help keep your blood pressure healthy.{" "}
                    </p>

                    <a href="https://www.nhs.uk/conditions/high-blood-pressure-hypertension/prevention/">
                        Find out more about maintaining a healthy blood pressure
                    </a>
                </>
            ),
            PreHigh: (
                <>
                    <p>
                        High blood pressure is sometimes called the "silent
                        killer" because it rarely causes symptoms but left
                        untreated, it can cause a heart attack or stroke.
                    </p>

                    <p>
                        The first step is to get your blood pressure checked
                        again by a nurse or your GP. They will be able to let
                        you know whether you need to take medicine for high
                        blood pressure.
                    </p>

                    <ul>
                        <li>
                            You can help to lower your blood pressure by making
                            small changes, such as:{" "}
                        </li>
                        <li>losing weight (if you're overweight)</li>
                        <li>exercising regularly</li>
                        <li>eating lots of fresh fruit and vegetables</li>
                        <li>cutting down on salt in your diet</li>
                        <li>drinking less alcohol and caffeine</li>
                        <li>quitting smoking</li>
                    </ul>

                    <a href="https://www.nhs.uk/conditions/high-blood-pressure-hypertension/prevention/">
                        Find out how a healthy lifestyle can prevent high blood
                        pressure
                    </a>
                </>
            ),

            High: (
                <p>
                    We've let your GP know that you need a follow up blood
                    pressure check. They should be in touch. If you've not heard
                    from your practice within 24 hours then please do get in
                    contact with them.
                </p>
            )
        },
        DiabetesRiskSummary: {
            LowHead: "You are within the normal range",
            MediumHead: "You have an increased risk of diabetes",
            HighHead: "You are at moderate risk of diabetes",
            VeryHighHead: "You are at high risk of diabetes",
            Diabetes: (
                <>
                    <h4>Types of diabetes</h4>
                    <p>
                        Diabetes is a lifelong condition that causes a person's
                        blood sugar level to become too high.
                    </p>
                    <p>There are 2 main types of diabetes:</p>
                    <p>
                        <strong>Type 1 diabetes</strong> - where the body's
                        immune system attacks and destroys the cells that
                        produce insulin
                    </p>
                    <p>
                        <strong>Type 2 diabetes</strong> – where the body does
                        not produce enough insulin, or the body's cells do not
                        react to insulin
                    </p>
                    <p>
                        Type 2 diabetes is far more common than type 1. In the
                        UK, around 90% of all adults with diabetes have type 2.
                    </p>
                    <h4>Risk levels</h4>
                    <p>
                        You can't change your age or your genes. But if your
                        risk is partly due to your lifestyle, a few small
                        changes can make a big difference.
                    </p>
                    <p>
                        For Type 2 diabetes, use this tool if you would like to
                        know how to reduce your diabetes risk:
                    </p>
                    <a href="https://riskscore.diabetes.org.uk/start">
                        Diabetes UK: Type 2 diabetes - know your risk
                    </a>
                </>
            ),
            Low: (score) => (
                <>
                    <p>
                        Your result shows that your chance of developing type II
                        diabetes is low.{" "}
                    </p>

                    <p>
                        As you get older your chance of developing type II
                        diabetes may increase. You can help keep your risk low
                        by staying active and maintaining a healthy weight. You
                        can help keep your risk low by:
                    </p>
                    <ol>
                        <li>keeping to a healthy weight</li>
                        <li>increasing your physical activity</li>
                        <li>eating lots of fresh fruit and vegetables</li>
                        <li>drinking less alcohol </li>
                    </ol>

                    <p>
                        {" "}
                        Take a look at your other NHS Health Check results to
                        see if you could benefit from making changes that will
                        help reduce your chance of developing type II diabetes.
                    </p>
                </>
            ),
            Medium: (score) => (
                <>
                    <p>
                        Your result shows that have an increased chance of
                        developing type II diabetes.{" "}
                    </p>

                    <p>
                        As you get older your chance of developing type II
                        diabetes may increase. You can help reduce your risk by:
                    </p>
                    <ol>
                        <li>keeping to a healthy weight</li>
                        <li>increasing your physical activity</li>
                        <li>eating lots of fresh fruit and vegetables</li>
                        <li>drinking less alcohol </li>
                    </ol>

                    <p>
                        {" "}
                        Take a look at your other NHS Health Check results to
                        see if you could benefit from making changes that will
                        help reduce your chance of developing type II diabetes.
                    </p>
                </>
            ),
            High: (score) => (
                <>
                    <p>
                        Your result shows that your chance of developing type II
                        diabetes is high.
                    </p>
                    <p>
                        We've let your GP know that they need to speak to you.
                        If you don't hear from them in the next few days, then
                        do get in touch with them.{" "}
                    </p>
                    <p>
                        The good news is that you can take action to prevent
                        type II diabetes by being a healthy weight, eating
                        healthy, drinking less alcohol and exercising.
                    </p>
                    <p>
                        For support with weight loss and getting more active go
                        to the NHS diabetes prevention programme. You could also
                        download the free NHS Weight Loss Plan, Couch to 5K,
                        Active 10 app or drink free days app.
                    </p>

                    <a
                        href="http://www.preventing-diabetes.co.uk/"
                        target="_blank"
                    >
                        Preventing Diabetes
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        NHS Weight Loss Plan
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Couch to 5K
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Active 10 app
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/drink-less/"
                        target="_blank"
                    >
                        Drink Free Days
                    </a>
                </>
            ),
            VeryHigh: (score) => (
                <>
                    <p>
                        Your result shows that your chance of developing type II
                        diabetes is very high.
                    </p>
                    <p>
                        We've let your GP know that they need to speak to you.
                        If you don't hear from them in the next few days, then
                        do get in touch with them.{" "}
                    </p>
                    <p>
                        The good news is that you can take action to prevent
                        type II diabetes by being a healthy weight, eating
                        healthy, drinking less alcohol and exercising.
                    </p>
                    <p>
                        For support with weight loss and getting more active go
                        to the NHS diabetes prevention programme. You could also
                        download the free NHS Weight Loss Plan, Couch to 5K,
                        Active 10 app or drink free days app.
                    </p>
                    <a
                        href="http://www.preventing-diabetes.co.uk/"
                        target="_blank"
                    >
                        Preventing Diabetes
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        NHS Weight Loss Plan
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Couch to 5K
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Active 10 app
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/drink-less/"
                        target="_blank"
                    >
                        Drink Free Days
                    </a>
                </>
            ),
            YouHaveDiabetes: (
                <>
                    <p>
                        You have stated you have been diagnosed with Diabetes
                        type 1 or type 2. You need to see your doctor to discuss
                        this as soon as possible if they are not already aware
                        of it.
                    </p>
                    <p>
                        A healthy diet and keeping active will help you manage
                        your blood sugar level. It'll also help you control your
                        weight and you'll generally feel better.{" "}
                        <a
                            href="https://www.nhs.uk/conditions/type-2-diabetes/food-and-keeping-active/"
                            target="_blank"
                        >
                            https://www.nhs.uk/conditions/type-2-diabetes/food-and-keeping-active/
                        </a>
                    </p>
                </>
            )
        },
        DiabetesTestSummary: {
            HealthyHead: "Your blood sugar result is in the healthy range.",
            RaisedHead: "Your result shows that your blood sugar is raised.",
            HighHead: "Your result shows that your blood sugar is high.",
            Healthy: (
                <>
                    <p>
                        This is good news as high levels of blood sugar can be a
                        sign of type 2 diabetes.{" "}
                    </p>
                    <p>
                        You can help to keep your blood sugar in the healthy
                        range by:
                    </p>
                    <ul>
                        <li>keeping to a healthy weight</li>
                        <li>increasing your physical activity</li>
                        <li>eating lots of fresh fruit and vegetables</li>
                        <li>drinking less alcohol </li>
                    </ul>

                    <p>
                        Take a look at your other NHS Health Check results to
                        see if you could benefit from making new changes that
                        will help keep your blood sugar healthy.{" "}
                    </p>
                </>
            ),
            Raised: (
                <>
                    <p>
                        Raised blood sugar is sometimes known as pre-diabetes.
                        It means that your chance of developing type 2 diabetes
                        is increased.
                    </p>
                    <p>
                        The good news is that by being a healthy weight, eating
                        healthy and exercising you can reduce your blood sugar
                        levels and prevent type 2 diabetes.{" "}
                    </p>
                    <p>
                        For support with weight loss and getting more active go
                        to the NHS diabetes prevention programme. You could also
                        download the free NHS Weight Loss Plan, Couch to 5K,
                        Active 10 app or drink free days app.
                    </p>

                    <a
                        href="http://www.preventing-diabetes.co.uk/"
                        target="_blank"
                    >
                        Preventing Diabetes
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        NHS Weight Loss Plan
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Couch to 5K
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Active 10 app
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/drink-less/"
                        target="_blank"
                    >
                        Drink Free Days
                    </a>
                </>
            ),
            High: (
                <>
                    <p>
                        You could have type 2 diabetes and may need medicine to
                        help manage it.{" "}
                    </p>
                    <p>
                        We've let your GP know that they need to speak to you.
                        If you don't hear from them in the next few days, then
                        do get in touch with them. For more information on
                        pre-diabetes go to the NHS website.{" "}
                    </p>
                    <p>
                        In the meantime there are changes that you can make
                        which will help to manage your blood sugar levels, such
                        as keeping to a healthy weight, increasing your physical
                        activity, eating lots of fresh fruit and vegetables and
                        drinking less alcohol.{" "}
                    </p>
                    <p>
                        For support with weight loss and getting more active go
                        to the NHS diabetes prevention programme. You could also
                        download the free NHS Weight Loss Plan, Couch to 5K,
                        Active 10 app or drink free days app.
                    </p>
                    <a
                        href="http://www.preventing-diabetes.co.uk/"
                        target="_blank"
                    >
                        Preventing Diabetes
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/lose-weight/"
                        target="_blank"
                    >
                        NHS Weight Loss Plan
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Couch to 5K
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/get-active/"
                        target="_blank"
                    >
                        Active 10 app
                    </a>
                    <a
                        href="https://www.nhs.uk/better-health/drink-less/"
                        target="_blank"
                    >
                        Drink Free Days
                    </a>
                </>
            )
        },
        AlcoholScreeningSummary: {
            LowHead: "",
            MediumHead: "",
            HighHead: "",
            DependencyHead: "",
            Low: (
                <>
                    <p>
                        Your results shows that your chance of illness from
                        drinking alcohol is low.
                    </p>
                    <p>
                        However, for some people even small amounts of alcohol
                        can be harmful, for example, if you are pregnant, have a
                        long-term health condition or operate heavy machinery.
                    </p>
                    <p>
                        If you are regularly drinking as many as 14 units a week
                        try spreading your drinking over 3 days or more and
                        having a couple of alcohol free days each week.{" "}
                    </p>
                </>
            ),
            Medium: (
                <>
                    <p>
                        Your result shows that you have an increased chance of
                        illness from alcohol.{" "}
                    </p>
                    <p>
                        This means you have an increased chance of an
                        alcohol-related illness such as stroke, high blood
                        pressure or liver disease.{" "}
                    </p>
                    <p>
                        You can reduce this risk by trying to cut down to fewer
                        than 15 units per week.
                    </p>
                    <p>
                        You can find some great tips on how to reduce the amount
                        of alcohol you drink on the Better Health website. The
                        drink free days app could also help you cut down on
                        alcohol.
                    </p>

                    <a
                        href="https://www.nhs.uk/better-health/drink-less/"
                        target="_blank"
                    >
                        Better Health Website and Drink Free Days app
                    </a>
                </>
            ),
            High: (
                <>
                    <p>
                        Your result shows that your drinking may already be
                        damaging your health.{" "}
                    </p>
                    <p>
                        This means you have a high chance of an alcohol-related
                        illness such as stroke, high blood pressure or liver
                        disease.{" "}
                    </p>
                    <p>
                        You can reduce this risk by trying to cut down to fewer
                        than 15 units per week.
                    </p>
                    <p>
                        You can find some great tips on how to reduce the amount
                        of alcohol you drink on the Better Health website. Use
                        the drink free days app to help you cut down on alcohol.
                    </p>
                    <p>
                        You might also want to think about free local support
                        groups and other alcohol counselling that might help you
                        cut down.
                    </p>

                    <a
                        href="http://www.recoverynearyou.org.uk/help-quit-alcohol-or-drugs/#alcohol1"
                        target="_blank"
                    >
                        Wolverhampton Substance Misuse Service
                    </a>

                    <a
                        href="https://www.gov.uk/government/publications/delivering-better-oral-health-an-evidence-based-toolkit-for-prevention/chapter-12-alcohol"
                        target="_blank"
                    >
                        Alcohol - GOV.UK
                    </a>

                    <a
                        href="https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/"
                        target="_blank"
                    >
                        Alcohol units - NHS
                    </a>

                    <a
                        href="https://www.nhs.uk/live-well/alcohol-advice/"
                        target="_blank"
                    >
                        Alcohol advice - NHS
                    </a>
                </>
            ),
            Dependency: (
                <>
                    <p>
                        Your result shows that your drinking might be becoming a
                        bit of a problem.{" "}
                    </p>
                    <p>
                        Drinking in a dependent way can make it difficult to cut
                        down or stop drinking without support. A good place to
                        start is by talking to your GP or your local alcohol
                        treatment service: Find alcohol support services in your
                        area.{" "}
                    </p>
                    <p>
                        You might also want to think about free local support
                        groups and other alcohol counselling that might support
                        you.{" "}
                    </p>
                    <p>
                        It can be dangerous for some dependent drinkers to stop
                        drinking suddenly. If you experience shaking, dizziness,
                        sweating or anxiety when you stop drinking, seek medical
                        advice before you stop completely.{" "}
                    </p>

                    <a
                        href="http://www.recoverynearyou.org.uk/help-quit-alcohol-or-drugs/#alcohol1"
                        target="_blank"
                    >
                        Wolverhampton Substance Misuse Service
                    </a>

                    <a
                        href="https://www.gov.uk/government/publications/delivering-better-oral-health-an-evidence-based-toolkit-for-prevention/chapter-12-alcohol"
                        target="_blank"
                    >
                        Alcohol - GOV.UK
                    </a>

                    <a
                        href="https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/"
                        target="_blank"
                    >
                        Alcohol units - NHS
                    </a>

                    <a
                        href="https://www.nhs.uk/live-well/alcohol-advice/"
                        target="_blank"
                    >
                        Alcohol advice - NHS
                    </a>
                </>
            )
        },
        CholesterolRiskSummary: {
            Low: {
                Header: "Your cholesterol results are in the healthy range",
                Content: (
                    <>
                        <p>
                            Cholesterol levels are affected by what you eat and
                            drink as well as exercise and smoking. You can help
                            to keep your cholesterol at a healthy level by
                            continuing to make healthy choices.{" "}
                        </p>
                        <p>
                            Take a look at the rest of your NHS Health Check
                            results to find out what else you might do to keep
                            your cholesterol healthy.
                        </p>
                    </>
                )
            },
            High: {
                Header: "Your cholesterol levels are raised",
                Content: (
                    <>
                        <p>
                            Cholesterol levels are affected by what you eat and
                            drink as well as exercise and smoking. You can
                            improve your cholesterol by making healthy changes.{" "}
                        </p>
                        <p>
                            You might also need medicine such as a Statin, speak
                            to your GP about this.{" "}
                        </p>
                        <p>
                            Statins are really effective at helping to reduce
                            the chance of a heart attack or stroke.{" "}
                        </p>

                        <a href="https://www.bhf.org.uk/informationsupport/risk-factors/high-cholesterol">
                            High cholesterol - Causes, Symptoms and Treatment |
                            BHF
                        </a>

                        <a href="https://www.heartuk.org.uk/downloads/health-professionals/publications/understanding-cholesterol.pdf">
                            Understanding Cholesterol
                        </a>

                        <a href="https://patient.info/heart-health/high-cholesterol">
                            High Cholesterol | How to lower cholesterol levels |
                            Patient
                        </a>
                    </>
                )
            }
        },
        ActivityRiskSummary: {
            Active: {
                Headline: "Your result is classified as active.",
                Content: (
                    <>
                        <p>
                            Your current levels of physical activity are helping
                            to reduce your chances of a heart attack or stroke.
                            Keep it up!{" "}
                        </p>
                        <p>
                            You can find out more about recommended levels of
                            physical activity and different types of exercise
                            that can help you to stay health and well by going
                            to nhs.uk.{" "}
                        </p>

                        <a
                            href="https://www.nhs.uk/live-well/exercise/exercise-guidelines/physical-activity-guidelines-for-adults-aged-19-to-64/"
                            target="_blank"
                        >
                            Physical activity guidelines for adults
                        </a>

                        <a
                            href="http://www.nhs.uk/better-health/get-active"
                            target="_blank"
                        >
                            Better Health
                        </a>
                    </>
                )
            },
            ModeratelyActive: {
                Headline: "Your result is classified as moderately active.",
                Content: (
                    <>
                        <p>
                            Your current levels of physical activity are helping
                            to reduce your chances of a heart attack or stroke.
                            Some is good – more is better still.
                        </p>
                        <p>
                            Adults should aim to be active every day. For more
                            information on opportunities to get active go to
                            Better Health or download the Couch to 5K or Active
                            10 app.
                        </p>

                        <a
                            href="https://www.nhs.uk/live-well/exercise/exercise-guidelines/physical-activity-guidelines-for-adults-aged-19-to-64/"
                            target="_blank"
                        >
                            Physical activity guidelines for adults
                        </a>

                        <a
                            href="http://www.nhs.uk/better-health/get-active"
                            target="_blank"
                        >
                            Better Health
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Couch to 5K
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Active 10 app
                        </a>
                    </>
                )
            },
            ModeratelyInactive: {
                Headline: "Your result is classified as moderately inactive.",
                Content: (
                    <>
                        <p>
                            No matter how much you do, physical activity is good
                            for your body and mind. Doing some physical activity
                            is good – more is better and every minute counts.
                            Adults should aim to be active every day.{" "}
                        </p>
                        <p>
                            Small steps like having 10-minute activity breaks to
                            reduce the amount of time you spend sitting can
                            help. For more information on opportunities to get
                            active go to Better Health or download the Couch to
                            5K or Active 10 app.
                        </p>
                        <p>
                            You can find out more about recommended levels of
                            physical activity and different types of exercise
                            that can help you to stay healthy and well by going
                            to Physical activity guidelines for adults aged 19
                            to 64 - NHS (www.nhs.uk).{" "}
                        </p>

                        <a
                            href="https://www.nhs.uk/live-well/exercise/exercise-guidelines/physical-activity-guidelines-for-adults-aged-19-to-64/"
                            target="_blank"
                        >
                            Physical activity guidelines for adults
                        </a>

                        <a
                            href="http://www.nhs.uk/better-health/get-active"
                            target="_blank"
                        >
                            Better Health
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Couch to 5K
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Active 10 app
                        </a>
                    </>
                )
            },
            Inactive: {
                Headline: "Your result is classified as inactive.",
                Content: (
                    <>
                        <p>
                            No matter how much you do, physical activity is good
                            for your body and mind. Doing some physical activity
                            is good – more is better and every minute counts.
                            Adults should aim to be active every day.{" "}
                        </p>
                        <p>
                            Small steps like having 10-minute activity breaks to
                            reduce the amount of time you spend sitting can
                            help. For more information on opportunities to get
                            active go to Better Health or download the Couch to
                            5K or Active 10 app.
                        </p>
                        <p>
                            You can find out more about recommended levels of
                            physical activity and different types of exercise
                            that can help you to stay healthy and well by going
                            to Physical activity guidelines for adults aged 19
                            to 64 - NHS (www.nhs.uk).{" "}
                        </p>

                        <a
                            href="https://www.nhs.uk/live-well/exercise/exercise-guidelines/physical-activity-guidelines-for-adults-aged-19-to-64/"
                            target="_blank"
                        >
                            Physical activity guidelines for adults
                        </a>

                        <a
                            href="http://www.nhs.uk/better-health/get-active"
                            target="_blank"
                        >
                            Better Health
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Couch to 5K
                        </a>
                        <a
                            href="https://www.nhs.uk/better-health/get-active/"
                            target="_blank"
                        >
                            Active 10 app
                        </a>
                    </>
                )
            },
            Other: {
                Headline: (level) => `Your activity level is ${level}`,
                Content:
                    "You should try and increase very gradually the amount of activity you do each week."
            }
        },
        SmokingRisk: "Smoking risk",
        IncreasedSmokingRisk: (
            <>
                <h4>
                    You have indicated that you smoke more than 10 cigarettes a
                    day
                </h4>
                <p>
                    Stopping smoking is one of the best things you will ever do
                    for your health.{" "}
                </p>
                <p>To get advice on stopping smoking you can:</p>
                <ul>
                    <li>call the NHS Smoking Helpline on 0300 123 1044 </li>
                    <li>go to nhs.uk/smokefree or </li>
                    <li>download the free NHS quit smoking app </li>
                </ul>
                <a
                    href="https://www.nhs.uk/better-health/quit-smoking/personal-quit-plan/"
                    target="_blank"
                >
                    Free Personal Quit Plan - Quit Smoking - NHS
                </a>
                <a
                    href="https://www.nhs.uk/better-health/quit-smoking/"
                    target="_blank"
                >
                    NHS Quit Smoking App
                </a>
            </>
        )
    },
    BloodPressureTests: {
        Title: (
            <>
                Find a <strong>FREE</strong> blood pressure test near me
            </>
        ),
        Kilometres: "km",
        Hours: "Hours",
        InputText: "Enter a postcode here",
        ButtonText: "Go",
        Content: (
            <>
                <p>
                    High blood pressure (hypertension) can increase your risk of
                    developing serious problems, such as heart attacks and
                    strokes, if it's not treated. Having this quick test is the
                    only way to find out what your blood pressure is - and it
                    could save your life.
                </p>
                <p>
                    If you have a BP meter you can do this at home. If not we
                    have provided a look up service of your nearest GP surgery
                    or pharmacy where you can get your BP measured.
                </p>
            </>
        ),
        Chat: "I have mobility or other issues that prevent me going out. Please send a loan unit to my home address."
    },
    FreeBloodTest: {
        Title: "Blood test",
        RemoteTestIntro: (
            <>
                As part of a complete NHS Health Check we will need to test your
                blood for cholesterol levels. This will be done at your GP.
            </>
        ),
        RequiredIntro: (
            <>
                <p>
                    As part of a complete NHS Health Check we will need to test
                    your blood for cholesterol levels. A FREE blood test kit
                    will be sent to the address you enter below with
                    instructions on how to provide a blood sample. You should
                    then post the sample back as soon as possible in the
                    pre-paid envelope included.
                </p>
                {/* <p>
                    <em>
                        Please note: you will only receive your blood test kit
                        once you have answered the health questions on the
                        following pages.
                    </em>
                </p> */}
            </>
        ),
        RequiredIntroDiabetes: (
            <>
                <p>
                    As part of a complete NHS Health Check we will need to test
                    your blood for cholesterol levels. You may also be required
                    to have a test for diabetes so please input your height and
                    weight accurately. A FREE blood test kit will be sent to the
                    address you enter below with instructions on how to provide
                    a blood sample. You should then post the sample back as soon
                    as possible in the pre-paid envelope included.
                </p>
                {/* <p>
                    <em>
                        Please note: you will only receive your blood test kit
                        once you have answered the health questions on the
                        following pages.
                    </em>
                </p> */}
            </>
        ),
        More: "More about the blood test",
        ModalTitle: "About the blood test",
        Intro: (
            <>
                Your results indicate that you're in the at-risk group. We'd
                like to post you a <span>FREE at-home blood test</span> for more
                accurate readings.
            </>
        ),
        Address: "Delivery address for the test kit",
        RemoteContent: (
            <>
                <p>
                    High cholesterol usually causes no signs or symptoms. A
                    complete cholesterol test is done to determine whether your
                    cholesterol is high and to estimate your risk of heart
                    attacks and other forms of heart disease and diseases of the
                    blood vessels.
                </p>
                <p>
                    If your BMI (Body mass index) is high, you may also be
                    offered a test for Diabetes.
                </p>
                <p>
                    Your blood will be analysed by an NHS lab providing the same
                    accuracy of results as the service supplied by your GP.
                </p>
            </>
        ),
        Content: (
            <>
                <p>
                    High cholesterol usually causes no signs or symptoms. A
                    complete cholesterol test is done to determine whether your
                    cholesterol is high and to estimate your risk of heart
                    attacks and other forms of heart disease and diseases of the
                    blood vessels.
                </p>
                <p>
                    If your BMI (Body mass index) is high, you may also be
                    offered a test for Diabetes, full instructions will be
                    provided in the blood test kit we post to you.
                </p>
                <p>
                    Simply fill out your address details and we will send you a
                    pinprick test kit for you to blood test at home.
                </p>
                <p>
                    Your blood will be analysed by an NHS lab providing the same
                    accuracy of results as the service supplied by your GP.
                </p>

                <p>
                    Return the kit to the lab and within a couple of days you
                    will receive an email to say your results are on the Digital
                    Health Check website.
                </p>
            </>
        ),
        WhyBMI: "Why are we asking about your height and weight?",
        WhyBMIContent:
            "If your BMI indicates you are potentially at risk, we will also test your blood for diabetes.",
        Form: {
            Title: "Title",
            FirstName: "First name",
            LastName: "Surname",
            Email: "Email address",
            Telephone: "Mobile",
            PostCode: "Your postcode",
            PostCodeLookupButton: "Look up",
            CantFind: "Can't find your address?",
            Height: "Height",
            Weight: "Weight",
            Address: {
                Title: "Address",
                Edit: "Edit",
                Line1: "Line 1",
                Line2: "Line 2",
                Locality: "Locality",
                Town: "Town",
                County: "County"
            },
            Submit: "Submit",
            StartTest: "Start Test"
        }
    },
    BloodPressureKit: {
        Intro: (
            <>
                We can offer you a loan kit so that you can measure your blood
                pressure at home if you are unable to get to a pharmacy.
            </>
        ),
        Form: {
            Title: "Title",
            FirstName: "First name",
            LastName: "Surname",
            Email: "Email address",
            Telephone: "Mobile",
            PostCode: "Your postcode",
            PostCodeLookupButton: "Look up",
            CantFind: "Can't find your address?",
            Address: {
                Title: "Address",
                Edit: "Edit",
                Line1: "Line 1",
                Line2: "Line 2",
                Locality: "Locality",
                Town: "Town",
                County: "County"
            },
            Submit: "Order blood pressure kit"
        }
    },
    FreeCholesterolTest: {
        Title: (
            <>
                Get a <strong>FREE</strong> cholesterol test
            </>
        ),
        Content: (
            <>
                <p>
                    In order to accurately assess your risk, we need you to
                    order a <strong>Healthy Heart home testing kit</strong>. The
                    kit, a simple blood test, will be posted to you and it will
                    contain all the instructions you need to complete the test.
                </p>
                <p>
                    Once completed, post it back to us and we will process the
                    test at one of our NHS labs. When the results are ready we
                    will send you an email and/or text so you can view your
                    results online, and input them here for a complete picture
                    of your heart health.
                </p>

                <div className="test-details">
                    <h4>More about our Healthy Heart test</h4>
                    <p>
                        There are a range of factors that can affect our risk of
                        developing cardiovascular disease. Two of the major risk
                        factors are the amount of cholesterol and sugar
                        (glucose) in our blood.
                    </p>
                    <p>
                        Our tests for <strong>diabetes</strong> and{" "}
                        <strong>total cholesterol</strong> will help give you a
                        better understanding of your cardiovascular risk and
                        what steps could be taken to help lower your risk.{" "}
                    </p>
                    <p>
                        High levels of cholesterol can cause narrowing of blood
                        vessels, increasing our risk of cardiovascular disease.
                        High blood sugar (glucose) can indicate that you may
                        have diabetes or be at high risk of diabetes. The high
                        sugar levels cause damage to blood vessels and this can
                        make cardiovascular disease more likely to occur.
                    </p>
                    <p>
                        Importantly, it is possible to lower the amount of
                        cholesterol and sugar in our blood, and this can reduce
                        the risk of cardiovascular disease.
                    </p>
                    <p>
                        Our <strong>Heart Health</strong> test are confidential,
                        and is conducted by an NHS lab. It is designed to be
                        able to be carried out in the comfort of your own home.
                    </p>
                    <p>
                        For the best results the test should be returned in the
                        pre-paid package provided as soon as they are carried
                        out.
                    </p>
                </div>

                <p>
                    Please complete the form below to order your FREE test.
                    Please fill in all the boxes.
                </p>
            </>
        ),
        TestName: "The NHS Digital Health Check",
        Form: {
            Title: "Title",
            FirstName: "First name",
            LastName: "Surname",
            Email: "Email address",
            Telephone: "Mobile",
            DOB: "Date of birth",
            Gender: "Gender",
            PostCode: "Your postcode",
            PostCodeLookupButton: "Look up",
            CantFind: "Can't find your address?",
            Address: {
                Title: "Address",
                Edit: "Edit",
                Line1: "Line 1",
                Line2: "Line 2",
                Locality: "Locality",
                Town: "Town",
                County: "County"
            },
            Submit: "Order test kit",
            Disclaimer:
                "*Test kit will be delivered by Royal Mail post within xx days. Please contact us if you do not receive the test kit."
        }
    },
    OrderPlaced: {
        Title: "Thanks, we've got your address",
        Content: (address) => (
            <>
                <p>
                    Please confirm your address is correct and we'll send you
                    the blood test kit ASAP to the address below and it should
                    be with you within 72 hours.
                </p>
                <p>
                    {
                        titles.find(
                            (title) =>
                                title.value ===
                                parseInt(address.personalDetails.titleID)
                        )?.text
                    }{" "}
                    {address.personalDetails.firstName}{" "}
                    {address.personalDetails.lastName}
                    <br />
                    {address.selectedAddress.line1}
                    <br />
                    {address.selectedAddress.line2}
                    {address.selectedAddress.line2 && <br />}
                    {address.selectedAddress.line3}
                    {address.selectedAddress.line3 && <br />}
                    {address.selectedAddress.line4}
                    {address.selectedAddress.line4 && <br />}
                    {address.selectedAddress.locality}
                    {address.selectedAddress.locality && <br />}
                    {address.selectedAddress.town}
                    {address.selectedAddress.town && <br />}
                    {address.selectedAddress.county}
                    {address.selectedAddress.county && <br />}
                    {address.selectedAddress.postalCode}
                </p>
            </>
        ),
        Next: "Finish",
        Back: "Back"
    },
    Errors: {
        titleID: { required: "This field is required" },
        firstName: { required: "This field is required" },
        lastName: { required: "This field is required" },
        title: { required: "This field is required" },
        email: {
            required: "This field is required",
            invalid: "This is not a valid email address"
        },
        mobile: {
            required: "This field is required",
            invalid: "This is not a valid mobile number"
        },
        dob: { required: "This field is required" },
        postalCode: { required: "Please choose an address" },
        gender: { required: "This field is required" },
        line1: { required: "This field is required" },
        locality: { required: "This field is required" },
        town: { required: "This field is required" },
        county: { required: "This field is required" }
    },
    StepTitles: [
        "Ethnicity",
        "Height and weight",
        "UK postcode",
        "Age and sex",
        "Dementia",
        "Smoking",
        "Medical conditions",
        "Diabetes and Waist",
        "Alcohol use",
        "Physical activity",
        "Blood pressure",
        "Blood pressure",
        ""
    ],
    Cookies: {
        Title: "Cookies",
        Container: (
            <>
                <p>
                    We use cookies on our site to personalise content, provide
                    features and analyse our traffic. By clicking 'I accept',
                    you consent to the use of this technology across the
                    website.
                    <Link to={`${process.env.PUBLIC_URL}/privacy`}>
                        Privacy Statement
                    </Link>
                    |
                    <Link to={`${process.env.PUBLIC_URL}/cookies`}>
                        Cookie Policy
                    </Link>
                </p>
            </>
        ),
        Acceptance: "I accept"
    },
    Contact: {
        Title: "Contact us",
        Content:
            "You can contact us using the chat function here on the site. Alternatively you can email us. Simply fill in the boxes below and we'll get back to you.",
        Email: "Your email",
        EmailInvalid: "Please enter a valid email",
        Message: "Your message",
        MessageInvalid: "Please enter a message",
        Send: "Send",
        ThankYou:
            "Thank you for your message. Someone will be in touch within one working day. Many thanks for your patience."
    },
    Tutorials: {
        Dismiss: "OK",
        Risk: "Your risk will change as you enter your details",
        Next: "Use this button to move forward through the steps",
        Steps: "Use these buttons to navigate through the test",
        Slider: "Use the slider to change values",
        Help: "Click this button to get further help"
    },
    Inactive: {
        Title: "Sorry...",
        Content: (
            <>
                We are sorry but the deadline for completing your digital NHS
                Health Check has now passed. If you are interested in receiving
                an NHS Health Check please contact your surgery.
            </>
        )
    },
    NotFound: {
        Title: "Oops! Page not found",
        Content: "Error 404 - The page you have requested cannot be found.",
        Back: "Back to Home"
    },
    Questionnaire: {
        TitleForPostTest:
            "Once you have completed your NHS Health Check you will receive a text message inviting you to provide feedback to be in with a chance of winning a £50 retail voucher",
        Title: "Provide feedback to be in with a chance of winning a £50 retail voucher",
        TitleSmall: "Chance of winning a prize for your feedback",
        Button: "Go"
    }
};
