import axios from "axios";

import * as actionTypes from "../actionTypes";
import {
    clearAllFormValuesAction,
    setIncludeDiabetesAction,
    setRemoteBloodPressureAction,
    setRemoteBloodTestAction,
    setStatusAction,
    setStatusSuccessAction
} from "../form/formActions";
import { setRiskAction } from "../risk/riskActions";

import { API_BASE } from "../../lookup/api";

const VERIFY_USER_URL = `${API_BASE}/userauth.ashx`;
const TOKEN_REFRESH_URL = `${API_BASE}/refreshtoken.ashx`;
const CHECK_KEY_URL = `${API_BASE}/checkkey.ashx`;
const SET_PIN_URL = `${API_BASE}/setpin.ashx`;
const SET_CONTACT_DETAILS_URL = `${API_BASE}/savecontactdetails.ashx`;

export const setTokenAction = (value) => {
    localStorage.setItem("token", value);
    return {
        type: actionTypes.SET_TOKEN,
        value
    };
};

export const setUserKeyAction = (value) => {
    localStorage.setItem("userKey", value);
    if (value === null) localStorage.removeItem("userKey");
    return {
        type: actionTypes.SET_USER_KEY,
        value
    };
};

export const invalidateTokenAction = () => ({
    type: actionTypes.INVALIDATE_TOKEN
});

export const showAuthMessageAction = (message) => ({
    type: actionTypes.SHOW_AUTH_MESSAGE,
    message
});

export const verifyUserSuccessAction = (userVerification, redirect) => ({
    type: actionTypes.VERIFY_USER_SUCCESS,
    userVerification,
    redirect
});

export const clearRedirectAction = () => ({
    type: actionTypes.CLEAR_REDIRECT
});

export const verifyUserFailedAction = (userVerification) => ({
    type: actionTypes.VERIFY_USER_FAILED,
    userVerification
});

export const clearVerificationAction = () => {
    localStorage.removeItem("userVerification");
    return {
        type: actionTypes.CLEAR_VERIFICATION
    };
};

export const setPinRequiredAction = (pinRequired) => {
    localStorage.setItem("pinRequired", pinRequired);
    return {
        type: actionTypes.SET_PIN_REQUIRED,
        pinRequired
    };
};

export const setPinAction = (pin, token) => {
    if (process.env.REACT_APP_MOCK_API) {
        return {
            type: actionTypes.SET_PIN_SUCCESS
        };
    }
    return (dispatch) => {
        return axios
            .post(
                SET_PIN_URL,
                JSON.stringify({
                    Token: token,
                    Pin: pin
                }),
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                }
            )
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(data.ErrorMessage);
                    dispatch(setPinInvalidAction());
                } else {
                    dispatch(setPinSuccessAction());
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const setPinInvalidAction = () => ({
    type: actionTypes.SET_PIN_INVALID
});

export const setPinValidAction = () => ({
    type: actionTypes.SET_PIN_VALID
});

export const setPinSuccessAction = () => ({
    type: actionTypes.SET_PIN_SUCCESS
});

export const setNoPinAction = () => ({
    type: actionTypes.SET_NO_PIN
});

export const setUserInactiveAction = () => ({
    type: actionTypes.SET_USER_INACTIVE
});

export const checkKeyAction = (key) => {
    if (process.env.REACT_APP_MOCK_API) {
        if (process.env.REACT_APP_MOCK_PIN) {
            return (dispatch) => {
                dispatch(setPinRequiredAction(true));
            };
        } else {
            return (dispatch) => {
                dispatch(setPinRequiredAction(false));
            };
        }
    }

    return (dispatch) => {
        return axios
            .post(
                CHECK_KEY_URL,
                JSON.stringify({
                    UserKey: key
                }),
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                }
            )
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(data.ErrorMessage);
                } else if (data.ValidKey) {
                    dispatch(setStatusSuccessAction(data.stateID));
                    dispatch(setPinRequiredAction(data.HasPin));
                    dispatch(setIncludeDiabetesAction(data.IncludeDiabetes));
                } else if (!data.ValidKey) {
                    dispatch(setUserKeyAction(null));
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const verifyUserAction = (userVerification, redirect = false) => {
    if (!userVerification.consent) {
        return {
            type: actionTypes.VERIFY_USER_FAILED,
            userVerification
        };
    }

    if (
        process.env.REACT_APP_MOCK_PATIENT_NAME &&
        process.env.REACT_APP_MOCK_PATIENT_DOB
    ) {
        if (
            userVerification.surname !==
                process.env.REACT_APP_MOCK_PATIENT_NAME ||
            `${userVerification.dayOfBirth}/${userVerification.monthOfBirth}/${userVerification.yearOfBirth}` !==
                process.env.REACT_APP_MOCK_PATIENT_DOB
        ) {
            return {
                type: actionTypes.VERIFY_USER_FAILED,
                userVerification
            };
        } else {
            localStorage.setItem(
                "userVerification",
                JSON.stringify(userVerification)
            );
            return (dispatch) => {
                dispatch(verifyUserSuccessAction(userVerification, redirect));
                dispatch(setTokenAction("token"));
            };
        }
    }

    return (dispatch) => {
        return axios
            .post(
                VERIFY_USER_URL,
                JSON.stringify({
                    Surname: userVerification.surname,
                    DOB: `${userVerification.yearOfBirth}-${userVerification.monthOfBirth}-${userVerification.dayOfBirth}`,
                    UserKey: localStorage.getItem("userKey"),
                    ConsentProvided: userVerification.consent,
                    Pin: userVerification.PIN
                }),
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                }
            )
            .then(({ data }) => {
                if (!data.Success) {
                    dispatch(invalidateTokenAction());
                    dispatch(verifyUserFailedAction(userVerification));
                } else {
                    if (!data.ActiveUser) {
                        dispatch(setUserInactiveAction());
                    } else {
                        localStorage.setItem(
                            "userVerification",
                            JSON.stringify(userVerification)
                        );

                        const storedValues = data;

                        if (storedValues.Postcode)
                            localStorage.setItem(
                                "postalCode",
                                storedValues.Postcode
                            );
                        if (storedValues.Age)
                            localStorage.setItem("age", storedValues.Age);
                        if (storedValues.Sex)
                            localStorage.setItem("gender", storedValues.Sex);
                        if (storedValues.EthnicBackground)
                            localStorage.setItem(
                                "ethnicity",
                                storedValues.EthnicBackground
                            );
                        if (storedValues.Height)
                            localStorage.setItem(
                                "heightInCm",
                                storedValues.Height
                            );
                        if (storedValues.Weight)
                            localStorage.setItem(
                                "weightInKg",
                                storedValues.Weight
                            );
                        if (storedValues.HeightUnit)
                            localStorage.setItem(
                                "heightUnit",
                                storedValues.HeightUnit
                            );
                        if (storedValues.WeightUnit)
                            localStorage.setItem(
                                "weightUnit",
                                storedValues.WeightUnit
                            );
                        if (storedValues.SmokingStatus)
                            localStorage.setItem(
                                "smokingLevel",
                                storedValues.SmokingStatus
                            );
                        if (storedValues.DiabetesStatus)
                            localStorage.setItem(
                                "diabetesType",
                                storedValues.DiabetesStatus
                            );
                        if (storedValues.FamilyHeart)
                            localStorage.setItem(
                                "familyHistory",
                                storedValues.FamilyHeart
                            );
                        if (storedValues.KidneyDisease)
                            localStorage.setItem(
                                "kidneyDisease",
                                storedValues.KidneyDisease
                            );

                        const medicalConditions = [];
                        if (
                            storedValues.Migraines &&
                            storedValues.Migraines !== "False"
                        )
                            medicalConditions.push("Migraines");
                        if (
                            storedValues.BloodPressureTreatment &&
                            storedValues.BloodPressureTreatment !== "False"
                        )
                            medicalConditions.push("Bloodpressuretreatment");
                        if (
                            storedValues.Arthritis &&
                            storedValues.Arthritis !== "False"
                        )
                            medicalConditions.push("Arthritis");
                        if (
                            storedValues.Lupus &&
                            storedValues.Lupus !== "False"
                        )
                            medicalConditions.push("Lupus");
                        if (
                            storedValues.AntiPsychotic &&
                            storedValues.AntiPsychotic !== "False"
                        )
                            medicalConditions.push("Antipsychotic");
                        if (
                            storedValues.SteroidTablets &&
                            storedValues.SteroidTablets !== "False"
                        )
                            medicalConditions.push("Steroidtablets");
                        if (
                            storedValues.ErectileDisfunction &&
                            storedValues.ErectileDisfunction !== "False"
                        )
                            medicalConditions.push("Erectiledisfunction");
                        if (
                            storedValues.MentalIllness &&
                            storedValues.MentalIllness !== "False"
                        )
                            medicalConditions.push("Mentalillness");
                        localStorage.setItem(
                            "medicalConditions",
                            JSON.stringify(medicalConditions)
                        );
                        if (storedValues.NoAilments)
                            localStorage.setItem(
                                "noMedicalConditions",
                                storedValues.NoAilments
                            );

                        if (storedValues.AF)
                            localStorage.setItem(
                                "atrialFibrillationStatus",
                                storedValues.AF
                            );

                        if (storedValues.Systolicbp1) {
                            let systolicValues = [
                                storedValues.Systolicbp1,
                                storedValues.Systolicbp2,
                                storedValues.Systolicbp3
                            ];
                            localStorage.setItem(
                                "systolicBloodPressureReadings",
                                systolicValues
                            );
                        }

                        if (storedValues.DiastolicBP1) {
                            let diastolicValues = [
                                storedValues.DiastolicBP1,
                                storedValues.DiastolicBP2,
                                storedValues.DiastolicBP3
                            ];
                            localStorage.setItem(
                                "diastolicBloodPressureReadings",
                                diastolicValues
                            );
                        }

                        if (storedValues.bloodPressureLocation) {
                            localStorage.setItem(
                                "bloodPressureLocation",
                                storedValues.bloodPressureLocation
                            );
                        }

                        if (storedValues.Glyhb)
                            localStorage.setItem("glyhb", storedValues.Glyhb);

                        if (storedValues.Hdl)
                            localStorage.setItem(
                                "hdlCholesterol",
                                parseFloat(storedValues.Hdl).toFixed(2)
                            );

                        if (storedValues.Cholesterol)
                            localStorage.setItem(
                                "totalCholesterol",
                                parseFloat(storedValues.Cholesterol).toFixed(2)
                            );
                        if (storedValues.DiabetesFamily)
                            localStorage.setItem(
                                "diabetesInFamily",
                                storedValues.DiabetesFamily
                            );
                        if (storedValues.Waist)
                            localStorage.setItem(
                                "waistMeasurement",
                                storedValues.Waist
                            );
                        if (storedValues.AlcoholOften)
                            localStorage.setItem(
                                "alcoholConsumption",
                                storedValues.AlcoholOften
                            );
                        if (storedValues.AlcoholUnits)
                            localStorage.setItem(
                                "alcoholUnits",
                                storedValues.AlcoholUnits
                            );
                        if (storedValues.AlcoholBinge)
                            localStorage.setItem(
                                "bingeSessions",
                                storedValues.AlcoholBinge
                            );

                        if (storedValues.unableToStop)
                            localStorage.setItem(
                                "unableToStop",
                                storedValues.unableToStop
                            );
                        if (storedValues.failedExpectations)
                            localStorage.setItem(
                                "failedExpectations",
                                storedValues.failedExpectations
                            );
                        if (storedValues.morningDrinking)
                            localStorage.setItem(
                                "morningDrinking",
                                storedValues.morningDrinking
                            );
                        if (storedValues.drinkingGuilt)
                            localStorage.setItem(
                                "drinkingGuilt",
                                storedValues.drinkingGuilt
                            );
                        if (storedValues.blackout)
                            localStorage.setItem(
                                "blackout",
                                storedValues.blackout
                            );
                        if (storedValues.drinkingInjury)
                            localStorage.setItem(
                                "drinkingInjury",
                                storedValues.drinkingInjury
                            );
                        if (storedValues.drinkingConcern)
                            localStorage.setItem(
                                "drinkingConcern",
                                storedValues.drinkingConcern
                            );

                        if (storedValues.PhysicalActivityHoursCycling)
                            localStorage.setItem(
                                "activityHoursCycling",
                                storedValues.PhysicalActivityHoursCycling
                            );
                        if (storedValues.PhysicalActivityHoursWalking)
                            localStorage.setItem(
                                "activityHoursWalking",
                                storedValues.PhysicalActivityHoursWalking
                            );
                        if (storedValues.PhysicalActivityHours)
                            localStorage.setItem(
                                "activityHoursExercise",
                                storedValues.PhysicalActivityHours
                            );
                        if (
                            storedValues.PhysicalActivityHoursHouseworkChildcare
                        )
                            localStorage.setItem(
                                "activityHoursHouseworkChildcare",
                                storedValues.PhysicalActivityHoursHouseworkChildcare
                            );
                        if (storedValues.PhysicalActivityHoursGardeningDIY)
                            localStorage.setItem(
                                "activityHoursGardeningDIY",
                                storedValues.PhysicalActivityHoursGardeningDIY
                            );
                        if (storedValues.PhysicalActivityWork)
                            localStorage.setItem(
                                "physicalWork",
                                storedValues.PhysicalActivityWork
                            );
                        if (storedValues.WalkingPace)
                            localStorage.setItem(
                                "walkingPace",
                                storedValues.WalkingPace
                            );

                        let statusId = storedValues.StateID;
                        if (!statusId) statusId = 1;

                        localStorage.setItem("userStatus", statusId);
                        localStorage.setItem(
                            "userMMHStatus",
                            storedValues.MMHStateID
                        );

                        dispatch(
                            setRiskAction(parseFloat(data.Risk).toFixed(1))
                        );
                        dispatch(setTokenAction(data.Tokenstring));
                        dispatch(
                            verifyUserSuccessAction(userVerification, redirect)
                        );

                        dispatch(setStatusAction(data.Tokenstring, statusId));
                        dispatch(
                            setContactDetailsAction({
                                email: data.Email,
                                telephone: data.Mobile
                            })
                        );
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const refreshTokenAction = (token) => {
    if (process.env.REACT_APP_MOCK_API) {
        if (token === process.env.REACT_APP_MOCK_TOKEN_FAIL)
            return (dispatch) => {
                dispatch(invalidateTokenAction());
                dispatch(clearAllFormValuesAction());
            };
        else return (dispatch) => dispatch(setTokenAction(token));
    }

    return (dispatch) => {
        return axios
            .post(
                TOKEN_REFRESH_URL,
                JSON.stringify({
                    Token: token
                }),
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                }
            )
            .then(({ data }) => {
                if (data.Error < 0) {
                    dispatch(invalidateTokenAction());
                    dispatch(clearAllFormValuesAction());
                    window.location = `${process.env.PUBLIC_URL}/?timeout=true`;
                } else {
                    dispatch(setTokenAction(token));
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const setContactDetailsAction = (contactDetails) => ({
    type: actionTypes.SET_CONTACT_DETAILS,
    contactDetails
});

export const saveContactDetailsAction = (contactDetails, token) => {
    return (dispatch) => {
        return axios
            .post(
                SET_CONTACT_DETAILS_URL,
                JSON.stringify({
                    Token: token,
                    Email: contactDetails.email,
                    Mobile: contactDetails.telephone
                }),
                {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                }
            )
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(data.ErrorMessage);
                    dispatch(setContactDetailsErrorAction());
                } else {
                    dispatch(
                        setContactDetailsSuccessAction({
                            email: data.Email,
                            telephone: data.Mobile
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};

export const setContactDetailsSuccessAction = (contactDetails) => ({
    type: actionTypes.SET_CONTACT_DETAILS_SUCCESS,
    contactDetails: {
        email: contactDetails.email,
        telephone: contactDetails.telephone
    }
});

export const setContactDetailsErrorAction = () => ({
    type: actionTypes.SET_CONTACT_DETAILS_ERROR
});

export const setNoContactDetailsAction = () => ({
    type: actionTypes.SET_NO_CONTACT_DETAILS
});
