import React from "react";

export const ethnicityMap = [
    { id: 1, name: "White", displayName: "White" },
    { id: 2, name: "Indian", displayName: "Indian" },
    { id: 3, name: "Pakistani", displayName: "Pakistani" },
    {
        id: 4,
        name: "Bangladeshi",
        displayName: "Bangladeshi",
        hyphenated: "Bangla-deshi"
    },
    { id: 5, name: "Other Asian", displayName: "Other Asian" },
    { id: 6, name: "Black Caribbean", displayName: "Black Caribbean" },
    { id: 7, name: "Black African", displayName: "Black African" },
    { id: 8, name: "Chinese", displayName: "Chinese" },
    {
        id: 9,
        name: "Other Ethnic Group",
        displayName: "Other Ethnic Group"
    }
];

export const smokingLevels = [
    { id: 0, description: "Non smoker" },
    {
        id: 1,
        description: "Ex-smoker"
    },
    { id: 2, description: "Light smoker <10 a day" },
    { id: 3, description: "Moderate smoker 10‑19 a day" },
    { id: 4, description: "Heavy smoker 20+ a day" }
];

export const diabetesTypes = [
    { id: 0, type: "No" },
    { id: 1, type: "Type 1" },
    { id: 2, type: "Type 2" }
];

export const relevantMedicalConditions = [
    {
        key: "Migraines",
        description: "Migraines",
        displayName: "Migraines",
        helpText: (
            <>
                <p>
                    A migraine is a splitting headache that just seems to set in
                    apparently due to no reason at all. Typically, the headaches
                    affect one half of the head, are pulsating in nature, and
                    last from a few hours to 3 days.
                </p>
                <p>
                    If you only have headaches and your doctor has not diagnosed
                    Migraines, then answer no.
                </p>
            </>
        )
    },
    /* {
        key: "Bloodpressuretreatment",
        description: "On blood pressure treatment",
        displayName: "On blood pressure treatment",
        helpText:
            "If you are taking medicine prescribed by your doctor to lower your blood pressure answer yes."
    }, */
    {
        key: "Arthritis",
        description: "Rheumatoid arthritis",
        displayName: "Rheumatoid arthritis",
        helpText:
            "Rheumatoid arthritis (RA) causes joint inflammation and pain. It happens when the immune system doesn’t work properly and attacks lining of the joints (called the synovium). The disease commonly affects the hands, knees or ankles, and usually the same joint on both sides of the body."
    },
    {
        key: "Lupus",
        description: "Lupus",
        displayName: "Lupus",
        helpText: "Also known as systemic lupus erythematosus (SLE)."
    },
    {
        key: "Mentalillness",
        description: "Severe mental illness",
        displayName: "Severe mental illness",
        helpText:
            "This includes schizophrenia, bipolar disorder and moderate/severe depression."
    },
    {
        key: "Antipsychotic",
        description: "On atypical anti-psychotic medication?",
        displayName: "On atypical anti-psychotic medication?",
        helpText:
            "Various atypical antipsychotic agents are available in the UK: amisulpride, aripiprazole, clozapine, olanzapine, quetiapine, risperidone, sertindole and zotepine."
    },
    {
        key: "Steroidtablets",
        description: "Are you on regular steroid tablets?",
        displayName: "On regular steroid tablets",
        helpText:
            "Steroid tablets, also called corticosteroid tablets, are a type of anti-inflammatory medicine used to treat a range of conditions. These may include but are not limited to the following prednisolone, betamethasone, dexamethasone."
    },
    {
        key: "Erectiledisfunction",
        description: "Diagnosis or treatment for erectile disfunction?",
        displayName: "Diagnosis or treatment for erectile disfunction?",
        helpText:
            "Only answer yes to this if your doctor has diagnosed you with erectile dysfunction and/or you are having treatment for it."
    }
];

export const alcoholConsumptionOptions = [
    {
        key: "1",
        displayName: "Never"
    },
    {
        key: "2",
        displayName: "Monthly or less"
    },
    {
        key: "3",
        displayName: "2 to 4 times per month"
    },
    {
        key: "4",
        displayName: "2 to 3 times per week"
    },
    {
        key: "5",
        displayName: "4 or more times per week"
    }
];

export const alcoholUnitOptions = [
    {
        key: "1",
        displayName: "0 to 2"
    },
    {
        key: "2",
        displayName: "3 to 4"
    },
    {
        key: "3",
        displayName: "5 to 6"
    },
    {
        key: "4",
        displayName: "7 to 9"
    },
    {
        key: "5",
        displayName: "10 or more"
    }
];

export const drinkingFrequencyOptions = [
    {
        key: "1",
        displayName: "Never"
    },
    {
        key: "2",
        displayName: "Less than monthly"
    },
    {
        key: "3",
        displayName: "Monthly"
    },
    {
        key: "4",
        displayName: "Weekly"
    },
    {
        key: "5",
        displayName: "Daily or almost daily"
    }
];

export const binaryDrinkingOptions = [
    {
        key: "1",
        displayName: "No"
    },
    {
        key: "2",
        displayName: "Yes, but not in the last year"
    },
    {
        key: "3",
        displayName: "Yes, during the last year"
    }
];

export const exerciseOptions = [
    "Please select",
    "None",
    "1 hour or less",
    "Between 1 and 3 hours",
    "3 hours or more"
];

export const newexerciseOptions = [
    {
        key: "1",
        displayName: "None"
    },
    {
        key: "2",
        displayName: "Some but less than one hour"
    },
    {
        key: "3",
        displayName: "1 to 3 hours"
    },
    {
        key: "4",
        displayName: "3 or more hours"
    }
];

export const newwalkingPaceOptions = [
    {
        key: "1",
        displayName: "Slow (less than 3mph)"
    },
    {
        key: "2",
        displayName: "Average"
    },
    {
        key: "3",
        displayName: "Brisk"
    },
    {
        key: "4",
        displayName: "Fast (over 4mph)"
    }
];

export const walkingPaceOptions = [
    "Please select",
    "Slow (less than 3mph)",
    "Brisk",
    "Average",
    "Fast (over 4mph)"
];

export const physicalWorkDefinitions = [
    {
        key: "1",
        displayName: "Unemployed / retired"
    },
    {
        key: "2",
        displayName: "Sit down / office work"
    },
    {
        key: "3",
        displayName: "Stand at work a lot"
    },
    {
        key: "4",
        displayName: "Work involves definite physical effort"
    },
    {
        key: "5",
        displayName: "Work involves vigourous physical effort"
    }
];

export const waistMeasurements = [
    {
        value: 1,
        displayNames: {
            inches: 'Less than 35.5"',
            cm: "Less than 90cm"
        }
    },
    {
        value: 2,
        displayNames: {
            inches: '35.5" - 39.3"',
            cm: "90cm - 100cm"
        }
    },
    {
        value: 3,
        displayNames: {
            inches: '39.4" - 43.3"',
            cm: "101cm - 110cm"
        }
    },
    {
        value: 4,
        displayNames: {
            inches: '43.4" or above',
            cm: "111cm or above"
        }
    }
];

export const vegPortions = ["None", "1", "2", "3", "4", "5 or more"];

export const foodTypeOptions = [
    {
        value: 1,
        displayName: "1. Fruit, veg & lean protein"
    },
    {
        value: 2,
        displayName: "2. Burgers, chips & fried foods"
    },
    {
        value: 3,
        displayName: "3. Cakes, cookies & pastries"
    },
    {
        value: 4,
        displayName: "4. I'm vegetarian"
    },
    {
        value: 5,
        displayName: "A mixture of 1, 2 and 3"
    }
];

export const weightCategories = [
    "Underweight",
    "Normal weight",
    "Overweight",
    "Obese"
];

export const GOOGLE_MAPS_API_KEY = "AIzaSyAkIsvn7JFVBFC-OIycQSeC-YutcPJIeT4";

export const titles = [
    {
        value: 1,
        text: "Mr"
    },
    {
        value: 2,
        text: "Ms"
    },
    {
        value: 3,
        text: "Mrs"
    },
    {
        value: 4,
        text: "Miss"
    },
    {
        value: 5,
        text: "Dr"
    }
];
