import * as actionTypes from "../actionTypes";
import axios from "axios";

import mockPharmacies from "../../mock/pharmacies";
import {
    cornwallPharmacies,
    wolverhamptonPharmacies
} from "../../lookup/en/pharmacies";
import { API_BASE } from "../../lookup/api";

const PHARMACY_API_URL = `${API_BASE}/bplocations.ashx`;

export const setPharmaciesAction = (pharmacies) => ({
    type: actionTypes.SET_PHARMACIES,
    pharmacies
});

export const choosePharmacyAction = (pharmacy) => ({
    type: actionTypes.CHOOSE_PHARMACY,
    pharmacy
});

export const getCornwallPharmaciesAction = () => {
    return (dispatch) => dispatch(setPharmaciesAction(cornwallPharmacies));
};

export const getWolverhamptonPharmaciesAction = () => {
    return (dispatch) => dispatch(setPharmaciesAction(wolverhamptonPharmacies));
};

export const getPharmaciesAction = (
    token,
    latitude,
    longitude,
    top = 5,
    skip = 0
) => {
    if (process.env.REACT_APP_MOCK_API) {
        return (dispatch) =>
            dispatch(setPharmaciesAction(mockPharmacies.value));
    }

    let form = {
        Token: token,
        Lat: latitude,
        Lng: longitude,
        Top: top,
        Skip: skip
    };

    return (dispatch) => {
        return axios
            .post(PHARMACY_API_URL, JSON.stringify(form), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(({ data }) => {
                if (data.Error < 0) {
                    console.error(`Error: ${data.ErrorMessage}`);
                } else {
                    dispatch(setPharmaciesAction(data.value));
                }
            })
            .catch((error) => {
                console.error(error);
                console.error(
                    "This is most likely a CORS error caused by basic auth. Are you in development mode?"
                );
            });
    };
};
