import React from "react";
import { connect } from "react-redux";

import "./Home.scss";
import WelcomeMobile from "../../components/welcome/WelcomeMobile";
import WelcomeDesktop from "../../components/welcome/WelcomeDesktop";
import { WhatIsHealthCheck } from "../../components/what-is-health-check/WhatIsHealthCheck";
import { Link } from "react-router-dom";
import content from "../../lookup/en/content";

const Home = ({ userStatus }) => (
    <>
        <div className="home-container">
            <WelcomeMobile userStatus={userStatus} />
            <WelcomeDesktop userStatus={userStatus} />

            <section className="desktop-only">
                <WhatIsHealthCheck />
            </section>

            <section className="small-devices-only">
                <span className="powered-by">
                    {content.Footer.PoweredByTickertest}
                </span>

                <img
                    src={`${process.env.PUBLIC_URL}/assets/ce_marking.svg`}
                    alt="CE mark"
                    className="ce-mark"
                />

                <section className="terms">
                    <Link to="/about">{content.Footer.About}</Link>
                    <Link to="/faqs">{content.Footer.FAQs}</Link>
                    <Link to="/terms">{content.Footer.Terms}</Link>
                    <Link to="/privacy">{content.Footer.Disclaimers}</Link>
                    <Link to="/cookies">{content.Footer.Cookies}</Link>
                    <Link to="/contact">{content.Footer.Contact}</Link>
                </section>
            </section>
        </div>
    </>
);

export default connect(
    (state) => ({
        userStatus: state.form.userStatus
    }),
    {}
)(Home);
