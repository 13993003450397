import React, { Component } from "react";
import content from "../../lookup/en/content";

import "./Tutorial.scss";

class Tutorial extends Component {
    state = {
        redraw: false
    };

    componentDidMount() {
        this.redraw = setInterval(() => {
            this.setState({
                redraw: !this.state.redraw
            });
        }, 10);
    }

    componentWillUnmount() {
        clearTimeout(this.redraw);
    }

    dismiss() {
        const { dismiss, setTutorialViewed, elementReference } = this.props;

        dismiss();
        setTutorialViewed(elementReference);
    }

    render() {
        const { show, elementReference, position, text, tutorials } =
            this.props;

        const el = Array.from(
            document.querySelectorAll(elementReference)
        ).filter(
            (elem) =>
                elem.offsetWidth > 0 ||
                elem.offsetHeight > 0 ||
                elem.getClientRects().length > 0
        )[0];
        if (!el) return null;

        const rect = el.getBoundingClientRect();
        let left = rect.left;
        let top = rect.top;
        let modifierClass = "";

        if (position === "bottom") {
            top += rect.height;
            left += rect.width / 2 - 105;
            modifierClass = "bottom";
        } else if (position === "bottom-right") {
            top += rect.height;
            left += rect.width;
            modifierClass = "bottom bottom-right";
        } else if (position === "top") {
            left += rect.width / 2 - 105;
            modifierClass = "top";
        } else if (position === "left") {
            left -= 220;
            top += 5;
            modifierClass = "left";
        }

        let showState = show;
        if (tutorials && tutorials.indexOf(elementReference) > -1) {
            showState = false;
        }

        return (
            <div
                className={
                    showState
                        ? "tutorial-overlay"
                        : "tutorial-overlay dismissed"
                }
            >
                <div
                    className={`tutorial-message ${modifierClass}`}
                    style={{ left, top }}
                >
                    <p>{text}</p>
                    <button onClick={this.dismiss.bind(this)}>
                        {content.Tutorials.Dismiss}
                    </button>
                </div>
            </div>
        );
    }
}

export default Tutorial;
