import * as actionTypes from "../actionTypes";

export const setPreferenceAction = (key, value) => {
    sessionStorage.setItem(`preference_${key}`, value);
    return {
        type: actionTypes.SET_PREFERENCE,
        key,
        value
    };
};
