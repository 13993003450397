import React from "react";

import "./InputField.scss";
import Button from "../button/Button";
import content from "../../lookup/en/content";

class InputField extends React.Component {
    onButtonClick(event) {
        if (event) event.preventDefault();

        const { buttonClick, name } = this.props;
        const input = document.getElementById(name);
        const value = input ? input.value : null;
        buttonClick(value);
    }

    onInput() {
        const { onInput, name } = this.props;
        if (!onInput) return;

        const input = document.getElementById(name);
        onInput(input.value);
    }

    onKeyDown(e) {
        let code = e.keyCode ? e.keyCode : e.which;
        if (code === 13 || code === 10) {
            e.target.blur();
        }
    }

    render() {
        const {
            label,
            name,
            buttonText,
            buttonClick,
            defaultValue,
            placeholder,
            prefilledValue,
            options,
            error
        } = this.props;

        const contents = (
            <fieldset className="input-field">
                {label && <label>{label}</label>}
                {error && (
                    <span className="error">{content.Errors[name][error]}</span>
                )}
                {options && (
                    <div>
                        <select
                            className={error ? "invalid" : ""}
                            onChange={this.onInput.bind(this)}
                            id={name}
                            name={name}
                        >
                            <option value={-1}>
                                {content.Steps.PleaseSelect}
                            </option>
                            {options.map((option) => (
                                <option value={option.value} key={option.text}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {!prefilledValue && !options && (
                    <div>
                        <input
                            className={error ? "invalid" : ""}
                            type="text"
                            id={name}
                            name={name}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            autoComplete="off"
                            onInput={this.onInput.bind(this)}
                            onKeyDown={this.onKeyDown.bind(this)}
                        />
                        {buttonText && buttonClick && (
                            <Button
                                href="#"
                                onClick={this.onButtonClick.bind(this)}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                )}
                {prefilledValue && !options && (
                    <div>
                        <p>{prefilledValue}</p>
                        {buttonText && buttonClick && (
                            <Button
                                href="#"
                                isPrimary
                                onClick={this.onButtonClick.bind(this)}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                )}
            </fieldset>
        );

        if (buttonClick) {
            return (
                <form onSubmit={this.onButtonClick.bind(this)}>{contents}</form>
            );
        }

        return contents;
    }
}

export default InputField;
