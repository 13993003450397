import React from "react";
import { Link } from "react-router-dom";

import "./Button.scss";

class Button extends React.Component {
    onClick(e) {
        this.props.onClick(e);
    }

    onKeyPress(e) {
        if (e.keyCode !== 9) e.preventDefault();
        if (e.keyCode === 13) this.props.onClick();
    }

    render() {
        const {
            isPrimary,
            greyed,
            isExternal,
            href,
            children,
            disabled,
            onClick
        } = this.props;

        let className = "button";
        if (isPrimary) className += " primary";
        if (greyed) className += " greyed";
        if (disabled) className += " disabled";

        if (onClick) {
            return (
                <span
                    tabIndex={0}
                    className={className}
                    onClick={this.onClick.bind(this)}
                    onKeyDown={this.onKeyPress.bind(this)}
                >
                    {children}
                </span>
            );
        }

        return (
            <Link
                to={`${process.env.PUBLIC_URL}${href}`}
                className={className}
                target={isExternal ? "_blank" : ""}
            >
                {children}
            </Link>
        );
    }
}

export default Button;
