import React from "react";

import "./InfoModal.scss";

class InfoModal extends React.Component {
    close() {
        const { closeModal } = this.props;

        closeModal();
    }

    render() {
        const { title, content, open, danger } = this.props;

        return (
            <div className={open ? "info-modal open" : "info-modal"}>
                <div className={danger ? "modal danger" : "modal"}>
                    <span
                        className="close-modal"
                        onClick={this.close.bind(this)}
                    >
                        &#10005;
                    </span>
                    {title && (
                        <h2>
                            {!danger && <span className="help-icon">?</span>}
                            {danger && <span className="warning-icon"></span>}
                            {title}
                        </h2>
                    )}
                    <div className="content">{content}</div>
                </div>
            </div>
        );
    }
}

export default InfoModal;
