import React, { Component } from "react";
import { connect } from "react-redux";
import {
    checkKeyAction,
    setUserKeyAction
} from "../../actions/auth/authActions";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import { clearAllFormValuesAction } from "../../actions/form/formActions";
import { PAGE_IDS } from "../../lookup/api";
import Home from "../home/Home";

class Key extends Component {
    componentDidMount() {
        const {
            setUserKeyAction,
            checkKeyAction,
            clearAllFormValuesAction,
            trackPageViewAction
        } = this.props;
        const paramKey = this.props.match.params.key;

        if (paramKey) {
            clearAllFormValuesAction(paramKey);
            setUserKeyAction(paramKey);
            console.log("key component");

            this.setState({
                redirect: true
            });
        }

        let key = localStorage.getItem("userKey");
        if (key) {
            checkKeyAction(key);
        }

        trackPageViewAction(PAGE_IDS.HOMEPAGE);
    }

    render() {
        return <Home />;
    }
}

export default connect(
    (state) => ({
        userStatus: state.form.userStatus
    }),
    {
        setUserKeyAction,
        checkKeyAction,
        clearAllFormValuesAction,
        trackPageViewAction
    }
)(Key);
