import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    value: 0,
    heartAge: null,
    typicalScore: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_RISK:
            return {
                ...state,
                value: action.value
            };
        case actionTypes.SET_HEART_AGE:
            return {
                ...state,
                heartAge: action.heartAge
            };
        case actionTypes.SET_TYPICAL_SCORE:
            return {
                ...state,
                typicalScore: action.typicalScore
            };
        default:
            return state;
    }
};
