import React from "react";
import { connect } from "react-redux";

import "./BloodPressureKitOrderPlaced.scss";
import content from "../../lookup/en/content";
import { Link } from "react-router-dom";

const SUMMARY_URL = `${process.env.PUBLIC_URL}/test-summary`;

class BloodPressureKitOrderPlaced extends React.Component {
    render() {
        return (
            <div className="blood-pressure-kit-order-placed">
                <div className="order-confirmation">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/man-jogging-2.png`}
                        alt={content.OrderPlaced.Title}
                    />
                    <h2>{content.OrderPlaced.Title}</h2>
                    <p>{content.OrderPlaced.Content}</p>
                </div>

                <Link
                    className="return"
                    to={`${process.env.PUBLIC_URL}/test/9`}
                >
                    <span>
                        <h3>{content.OrderPlaced.Return}</h3>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/next-step.png`}
                            alt="Click here to go to the next section"
                        />
                    </span>
                </Link>
            </div>
        );
    }
}

export default connect()(BloodPressureKitOrderPlaced);
