import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";

import {
    convertCmToFeetAndInches,
    convertFeetAndInchesToCm,
    convertKgToStoneAndLbs,
    convertStoneAndLbsToKg
} from "../../util";
import {
    postcodeLookupAction,
    clearAddressesAction,
    chooseAddressAction,
    setPersonalDetailsAction,
    setAddressComponentAction,
    submitAddressForm,
    addressValidatedAction
} from "../../actions/address/addressActions";
import {
    setPostcodeAction,
    setHeightInCmAction,
    setHeightInFeetAction,
    setHeightInInchesAction,
    setWeightInStoneAction,
    setWeightInLbsAction,
    setWeightInKgAction,
    setStatusAction
} from "../../actions/form/formActions";
import { mapFormAndGetRisk } from "../../actions/risk/riskActions";
import InputField from "../../components/input-field/InputField";
import "./RequiredBloodTest.scss";
import Button from "../../components/button/Button";
import content from "../../lookup/en/content";
import InfoModal from "../../components/info-modal/InfoModal";
import { PAGE_IDS } from "../../lookup/api";
import { trackPageViewAction } from "../../actions/analytics/analyticsActions";
import FieldCard from "../../components/field-card/FieldCard";
import { titles } from "../../lookup/en/formValues";

const ORDER_CONFIRM_URL = `${process.env.PUBLIC_URL}/required-test-order-placed`;
const SUMMARY_URL = `${process.env.PUBLIC_URL}/test-summary`;

class RequiredBloodTest extends React.Component {
    state = {
        addressFormOpen: null,
        infoModal: false,
        heightUnit: "feet",
        weightUnit: "stone",
        bmiModal: false
    };

    componentWillMount() {
        const { trackPageViewAction, setStatusAction } = this.props;

        const token = localStorage.getItem("token");
        trackPageViewAction(PAGE_IDS.BLOOD_TEST_ADDRESS_INPUT);
        setStatusAction(token, 5);
    }

    postcodeLookup(value) {
        this.props.postcodeLookupAction(value);
    }

    mapAddress(rawAddress) {
        let components = rawAddress.split(", ");
        let addressString = "";
        components.forEach((component, index) => {
            if (index > 0 && component !== "") addressString += ", ";
            if (component !== "") addressString += component;
        });

        return {
            line1: components[0],
            line2: components[1],
            line3: components[2],
            line4: components[3],
            locality: components[4],
            town: components[5],
            county: components[6],
            addressString
        };
    }

    chooseAddress(address) {
        this.props.chooseAddressAction(address);
        this.setState({
            addressFormOpen: false
        });
    }

    setPersonalDetails(key, value) {
        this.props.setPersonalDetailsAction(key, value);
    }

    setAddressComponent(key, value) {
        this.props.setAddressComponentAction(key, value);
    }

    openAddressForm() {
        this.props.clearAddressesAction();
        this.setState({
            addressFormOpen: true
        });
    }

    submitForm() {
        const { token, addresses, submitAddressForm } = this.props;
        submitAddressForm(token, addresses, "bloodTest");
    }

    toggleInfoModal() {
        this.setState({
            infoModal: !this.state.infoModal
        });
    }

    toggleBMIModal() {
        this.setState({
            bmiModal: !this.state.bmiModal
        });
    }

    changeHeight(unit, value) {
        const {
            form,
            setHeightInCmAction,
            setHeightInFeetAction,
            setHeightInInchesAction
        } = this.props;

        let cmValue;

        if (unit === "feet") {
            const inchValue = form.heightInInches ? form.heightInInches : 0;
            cmValue = convertFeetAndInchesToCm(value, inchValue);
            setHeightInFeetAction(value);
            setHeightInInchesAction(inchValue);
            setHeightInCmAction(cmValue);

            localStorage.setItem("heightInFeet", value);
            localStorage.setItem("heightInInches", inchValue);
        } else if (unit === "inches") {
            const feetValue = form.heightInFeet ? form.heightInFeet : 0;
            cmValue = convertFeetAndInchesToCm(feetValue, value);
            setHeightInFeetAction(feetValue);
            setHeightInInchesAction(value);
            setHeightInCmAction(cmValue);

            localStorage.setItem("heightInFeet", feetValue);
            localStorage.setItem("heightInInches", value);
        } else if (unit === "cm") {
            const imperialValue = convertCmToFeetAndInches(value);
            cmValue = value;
            setHeightInFeetAction(imperialValue.feet);
            setHeightInInchesAction(imperialValue.inches);
            setHeightInCmAction(value);

            localStorage.setItem("heightInFeet", imperialValue.feet);
            localStorage.setItem("heightInInches", imperialValue.inches);
        }

        localStorage.setItem("heightInCm", cmValue);

        this.updateRisk("Height", cmValue);
    }

    changeHeightUnit() {
        const { heightUnit } = this.state;

        const newUnit = heightUnit === "feet" ? "cm" : "feet";
        localStorage.setItem("heightUnit", newUnit);
        this.setState({
            heightUnit: newUnit
        });
    }

    changeWeight(unit, value) {
        const {
            form,
            setWeightInStoneAction,
            setWeightInLbsAction,
            setWeightInKgAction
        } = this.props;

        let kgValue;

        if (unit === "stone") {
            const lbValue = form.weightInLbs ? form.weightInLbs : 0;
            kgValue = convertStoneAndLbsToKg(value, lbValue);
            setWeightInStoneAction(value);
            setWeightInLbsAction(lbValue);
            setWeightInKgAction(kgValue);

            localStorage.setItem("weightInStone", value);
            localStorage.setItem("weightInLbs", lbValue);
        } else if (unit === "lbs") {
            const stoneValue = form.weightInStone ? form.weightInStone : 0;
            kgValue = convertStoneAndLbsToKg(stoneValue, value);
            setWeightInStoneAction(stoneValue);
            setWeightInLbsAction(value);
            setWeightInKgAction(kgValue);

            localStorage.setItem("weightInStone", stoneValue);
            localStorage.setItem("weightInLbs", value);
        } else if (unit === "kg") {
            const imperialValue = convertKgToStoneAndLbs(value);
            kgValue = value;
            setWeightInStoneAction(imperialValue.stone);
            setWeightInLbsAction(imperialValue.lbs);
            setWeightInKgAction(value);

            localStorage.setItem("weightInStone", imperialValue.stone);
            localStorage.setItem("weightInLbs", imperialValue.lbs);
        }

        localStorage.setItem("weightInKg", kgValue);

        this.updateRisk("Weight", kgValue);
    }

    changeWeightUnit() {
        const { weightUnit } = this.state;

        const newUnit = weightUnit === "stone" ? "kg" : "stone";
        localStorage.setItem("weightUnit", newUnit);
        this.setState({
            weightUnit: newUnit
        });
    }

    updateRisk(changedKey = null, changedValue = null) {
        const { form, token, mapFormAndGetRisk } = this.props;
        mapFormAndGetRisk(
            token || localStorage.getItem("token"),
            form,
            changedKey,
            changedValue
        );
    }

    render() {
        const { includeDiabetes, addresses, form, remote } = this.props;
        const { addressFormOpen, infoModal } = this.state;

        if (form.userStatus > 5) return <Redirect to={SUMMARY_URL} />;

        if (addresses.bloodTestOrderPlaceSuccess)
            return <Redirect to={ORDER_CONFIRM_URL} />;

        return (
            <div className="blood-test">
                <div className="content">
                    <div className="intro">
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/blood-test-empty.png`}
                                alt={content.FreeBloodTest.Title}
                            />
                            <h2>{content.FreeBloodTest.Title}</h2>

                            {!remote
                                ? content.FreeBloodTest.RemoteTestIntro
                                : includeDiabetes
                                ? content.FreeBloodTest.RequiredIntroDiabetes
                                : content.FreeBloodTest.RequiredIntro}

                            <p>
                                <strong>{content.FreeBloodTest.More}</strong>
                                <span
                                    className="help"
                                    onClick={this.toggleInfoModal.bind(this)}
                                >
                                    ?
                                </span>
                            </p>
                            <InfoModal
                                title={content.FreeBloodTest.ModalTitle}
                                content={
                                    <>
                                        <iframe
                                            className="video"
                                            src="https://www.youtube.com/embed/fyhyqajHeT4"
                                        ></iframe>
                                        {remote
                                            ? content.FreeBloodTest.Content
                                            : content.FreeBloodTest
                                                  .RemoteContent}
                                    </>
                                }
                                open={infoModal}
                                closeModal={this.toggleInfoModal.bind(this)}
                            />

                            {remote && <h3>{content.FreeBloodTest.Address}</h3>}
                        </div>
                    </div>

                    {remote && (
                        <form>
                            <InputField
                                label={content.FreeBloodTest.Form.Title}
                                name="titleID"
                                error={addresses.errors.titleID}
                                defaultValue={addresses.personalDetails.titleID}
                                onInput={this.setPersonalDetails.bind(
                                    this,
                                    "titleID"
                                )}
                                options={titles}
                            />

                            <InputField
                                label={content.FreeBloodTest.Form.FirstName}
                                name="firstName"
                                error={addresses.errors.firstName}
                                defaultValue={
                                    addresses.personalDetails.firstName
                                }
                                onInput={this.setPersonalDetails.bind(
                                    this,
                                    "firstName"
                                )}
                            />
                            <InputField
                                label={content.FreeBloodTest.Form.LastName}
                                name="lastName"
                                error={addresses.errors.lastName}
                                defaultValue={
                                    addresses.personalDetails.lastName
                                }
                                onInput={this.setPersonalDetails.bind(
                                    this,
                                    "lastName"
                                )}
                            />
                            <InputField
                                label={content.FreeBloodTest.Form.PostCode}
                                name="postalCode"
                                error={addresses.errors.postalCode}
                                buttonText={
                                    content.FreeBloodTest.Form
                                        .PostCodeLookupButton
                                }
                                defaultValue={
                                    addresses.selectedAddress.postalCode
                                }
                                buttonClick={this.postcodeLookup.bind(this)}
                            />
                            {addresses.lookupAddresses.length > 0 && (
                                <>
                                    <span
                                        className="address-not-listed"
                                        onClick={this.openAddressForm.bind(
                                            this
                                        )}
                                    >
                                        {content.FreeBloodTest.Form.CantFind}
                                    </span>
                                    <ul className="address-lookup">
                                        {addresses.lookupAddresses.map(
                                            (address) => {
                                                const addressMap =
                                                    this.mapAddress(address);
                                                return (
                                                    <li
                                                        key={addressMap.line1}
                                                        onClick={this.chooseAddress.bind(
                                                            this,
                                                            addressMap
                                                        )}
                                                    >
                                                        {
                                                            addressMap.addressString
                                                        }
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </>
                            )}

                            {addresses.selectedAddress.line1 &&
                                !addressFormOpen && (
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .Title
                                        }
                                        name="address"
                                        prefilledValue={
                                            addresses.selectedAddress
                                                .addressString
                                        }
                                        buttonText={
                                            content.FreeBloodTest.Form.Address
                                                .Edit
                                        }
                                        buttonClick={this.openAddressForm.bind(
                                            this
                                        )}
                                    />
                                )}

                            {addressFormOpen && (
                                <>
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .Line1
                                        }
                                        name="line1"
                                        error={addresses.errors.line1}
                                        onInput={this.setAddressComponent.bind(
                                            this,
                                            "line1"
                                        )}
                                        defaultValue={
                                            addresses.selectedAddress.line1
                                        }
                                    />
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .Line2
                                        }
                                        name="line2"
                                        onInput={this.setAddressComponent.bind(
                                            this,
                                            "line2"
                                        )}
                                        defaultValue={
                                            addresses.selectedAddress.line2
                                        }
                                    />
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .Locality
                                        }
                                        error={addresses.errors.locality}
                                        name="locality"
                                        onInput={this.setAddressComponent.bind(
                                            this,
                                            "locality"
                                        )}
                                        defaultValue={
                                            addresses.selectedAddress.locality
                                        }
                                    />
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .Town
                                        }
                                        name="town"
                                        error={addresses.errors.town}
                                        onInput={this.setAddressComponent.bind(
                                            this,
                                            "town"
                                        )}
                                        defaultValue={
                                            addresses.selectedAddress.town
                                        }
                                    />
                                    <InputField
                                        label={
                                            content.FreeBloodTest.Form.Address
                                                .County
                                        }
                                        name="county"
                                        error={addresses.errors.county}
                                        onInput={this.setAddressComponent.bind(
                                            this,
                                            "county"
                                        )}
                                        defaultValue={
                                            addresses.selectedAddress.county
                                        }
                                    />
                                </>
                            )}

                            <div className="submit">
                                <Button onClick={this.submitForm.bind(this)}>
                                    {content.FreeBloodTest.Form.Submit}
                                </Button>
                            </div>
                        </form>
                    )}

                    {!remote && (
                        <div className="submit">
                            <Button href="/test/1">
                                {content.FreeBloodTest.Form.StartTest}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        form: state.form,
        remote: state.form.remoteBloodTest,
        includeDiabetes: state.form.includeDiabetes,
        postalCode: state.form.postalCode,
        addresses: state.address,
        token: state.auth.token
    }),
    {
        postcodeLookupAction,
        clearAddressesAction,
        setPostcodeAction,
        chooseAddressAction,
        setPersonalDetailsAction,
        setAddressComponentAction,
        submitAddressForm,
        addressValidatedAction,
        trackPageViewAction,
        setHeightInCmAction,
        setHeightInFeetAction,
        setHeightInInchesAction,
        setWeightInStoneAction,
        setWeightInLbsAction,
        setWeightInKgAction,
        setStatusAction,
        mapFormAndGetRisk
    }
)(RequiredBloodTest);
