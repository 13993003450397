import React from "react";
import ReactDOM from "react-dom";

import "./OptionToggle.scss";
import InfoModal from "../info-modal/InfoModal";

class OptionToggle extends React.Component {
    state = {
        infoModalOpen: false
    };

    toggleInfoModal() {
        const node = ReactDOM.findDOMNode(this);
        const newState = !this.state.infoModalOpen;
        const card = node.closest(".field-card");

        if (newState) card.classList.add("open-modal");
        else card.classList.remove("open-modal");

        this.setState({
            infoModalOpen: newState
        });
    }

    select(e) {
        const el = e.target;
        if (el.classList.contains("option-toggle")) {
            this.props.select();
        }
    }

    render() {
        const {
            selected,
            secondaryClass,
            helpTitle,
            helpText,
            content,
            disabled
        } = this.props;
        return (
            <div
                className={`option-toggle ${secondaryClass} ${
                    selected ? "selected" : ""
                } ${disabled ? "disabled" : ""}`}
                onClick={this.select.bind(this)}
            >
                {helpText && (
                    <span
                        className="help"
                        onClick={this.toggleInfoModal.bind(this)}
                    >
                        ?
                    </span>
                )}
                {helpText && (
                    <InfoModal
                        title={helpTitle}
                        content={helpText}
                        open={this.state.infoModalOpen}
                        closeModal={this.toggleInfoModal.bind(this)}
                    />
                )}
                {content}
            </div>
        );
    }
}

export default OptionToggle;
