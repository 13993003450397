import { LOCATION_CHANGE } from "connected-react-router";
import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
    logoVisible: false,
    contentsIndex: 0,
    tutorials: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                logoVisible:
                    action.payload.location.pathname !==
                        `${process.env.PUBLIC_URL}/` &&
                    !action.payload.location.pathname.includes("/key/")
            };
        case actionTypes.SET_CONTENTS_INDEX:
            return {
                ...state,
                contentsIndex: action.value
            };
        case actionTypes.SET_TUTORIAL_VIEWED:
            return {
                ...state,
                tutorials: `${state.tutorials}:${action.value}`
            };
        default:
            return state;
    }
};
