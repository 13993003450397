import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import TestStep from "../../test-step/TestStep";
import FieldCard from "../../field-card/FieldCard";

import content from "../../../lookup/en/content";

import "./BPWarningStep.scss";

class BPWarningStep extends Component {
    componentDidUpdate() {
        const { activeStep } = this.props;
        if (activeStep === 10) {
            localStorage.setItem("bpWarningScreen", "viewed");
        }
    }

    render() {
        const {
            activeStep,
            bloodPressureEmergency,
            bloodPressureHigh,
            bloodPressureLow,
            lastStep,
            remoteBT,
            remoteBP,
            missingSections,
            missingSectionsNotIncludingBP,
            userStatus
        } = this.props;

        if (
            activeStep === 12 &&
            !bloodPressureEmergency &&
            !bloodPressureHigh &&
            !bloodPressureLow
        ) {
            return (
                <Redirect
                    to={`${process.env.PUBLIC_URL}/${
                        remoteBT &&
                        ((remoteBP && missingSections.length === 0) ||
                            (!remoteBP &&
                                missingSectionsNotIncludingBP.length === 0)) &&
                        userStatus < 5
                            ? "blood-test"
                            : "test-summary"
                    }`}
                />
            );
        }

        return (
            <TestStep
                activeStep={activeStep}
                stepIndex={12}
                lastStep
                remoteBT={remoteBT}
                missingSections={missingSections}
                missingSectionsNotIncludingBP={missingSectionsNotIncludingBP}
            >
                <section className="bp-warning-step">
                    <FieldCard cardTitle={content.Steps.BloodPressure.Title}>
                        <img
                            src="/assets/alert.png"
                            alt={content.Steps.BloodPressure.Title}
                        />
                        {bloodPressureEmergency &&
                            content.Steps.BloodPressure.EmergencyText.Content}
                        {!bloodPressureEmergency &&
                            bloodPressureHigh &&
                            content.Steps.BloodPressure.HighReadingText.Content}
                        {bloodPressureLow &&
                            content.Steps.BloodPressure.LowReadingText.Content}
                    </FieldCard>
                </section>
            </TestStep>
        );
    }
}

export default BPWarningStep;
