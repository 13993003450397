import React, { Component } from "react";

import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import "./Divider.scss";

class Divider extends Component {
    componentDidMount() {
        const { completeFormPartChangeAction, changing } = this.props;

        setTimeout(function() {
            if (changing) completeFormPartChangeAction();
        }, 2500);
    }

    componentDidUpdate() {
        const { completeFormPartChangeAction, changing } = this.props;

        setTimeout(function() {
            if (changing) completeFormPartChangeAction();
        }, 2500);
    }

    render() {
        const { icon, partTitle, partNumber, show, spinner } = this.props;

        return (
            <div className={show ? "divider show" : "divider "}>
                <img src={icon} alt={partTitle} />
                <h2>{partNumber}</h2>
                <h3>{partTitle}</h3>
                {spinner && <LoadingSpinner />}
            </div>
        );
    }
}

export default Divider;
